import {SavingsWrapper} from "../../../_versionTwo/wrappers";
import {EditSavingForm, SavingsForm, SavingsImportForm} from "./form";
import SavingsDetails from "./SavingsDetails";
import BreadCrumb from "../layouts/BreadCrumb";
import {ErrorAlert, SuccessAlert} from "../../../components/alert";
import {CardSummary, ExpandCollapse, Loader} from "../_components";
import {SavingsFilter} from "./_components";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../config/redux/reducers";
import {AuthState} from "../../../config/redux/reducers/tmsAuthReducer";
import {SavingsReducerState} from "../../../config/redux/reducers/tmsSavingsReducer";
import {useEffect, useState} from "react";
import {calculateTime, getRequest, setGeneralAction} from "../../../config/redux/actions";
import {types} from "../../../config/redux/types";
import {remote_url} from "../../../config/urls/remote";
import {DEFAULT_INACTIVE_TIME} from "../../../config/config";
import {savingsInitDatable} from "../../../config/datatableConfig";

const AllSavings = () => {
    //'Bulk Import'
    let  extraButtons = ['Bulk Import']
    const dispatch = useDispatch()
    const {token,account_key, user,loginTime,role_id,isAdminMode} = useSelector<AppState,AuthState>((state) =>state.tmsAuthReducer)
    const {allThriftSavings,singleSavings,newDataBot,summary,isLoading_t,savingsFilter,error_msg,success_msg} = useSelector<AppState,SavingsReducerState>((state) =>state.tmsSavingsReducer)
    //
    const [showSavingsForm, setShowSavingsForm] = useState(false)
    const [showSavingImportForm, setSavingImportForm] = useState(false)
    const [showDeleteForm, setShowDeleteForm] = useState(false)
    const [showEditForm, setEditForm] = useState('')
    // ccc
    let user_id = "all"

    const openSavingsForm = (item:boolean) => {
        setShowSavingsForm(item)
        dispatch(setGeneralAction(types.GET_USER, {user:user}))
        dispatch(setGeneralAction(types.CLEAR_SAVING_DATA,[]))
    }

    const handleBulkImportModalFForm = (data:boolean) => {
        setSavingImportForm(data)
    }
    //::: GET SAVINGS
    const getSavings =  () => {
        // let user_id = isAdminMode ? "all" : user.id
        const getData = {
            token,
            url: `${remote_url.savings}?fromDate=${savingsFilter.fromDate}&toDate=${savingsFilter.toDate}&status=${savingsFilter.status}&user_id=${user_id}`,
            actionType : types.GET_SAVINGS,
            postData : [],
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            errorAction : types.SET_ERROR,
            accountKey: account_key,
            loading :{
                isLoading :false,
                isLoading_ : false,
                isLoading_t : true
            }
        }
        dispatch(getRequest(getData))
        handleDataTable()
    }

    const setSavings = (x:any, action_type:string) => {
        if(action_type==='view_detail'){
            dispatch( setGeneralAction(types.SET_SINGLE_SAVINGS,x))
        }
        //
        if(action_type==='view_edit'){
            console.log('here')
            setEditForm('show')
            dispatch( setGeneralAction(types.SET_SINGLE_EDIT_SAVINGS,x))
        }
        if(action_type==='delete'){
            setShowDeleteForm(true)
            dispatch( setGeneralAction(types.SET_SINGLE_EDIT_SAVINGS,x))
        }

    }
    //

    useEffect(() => {
        //clearing any stored trans reference or trans id data
        dispatch(setGeneralAction(types.SET_TRANS_REF, {transaction_ref :'', trans_id : ''}))
        handleDataTable()
        const res = calculateTime(loginTime)
        if(res <= DEFAULT_INACTIVE_TIME){
            // getSavings()
        }
    },[])



    const handleDataTable = () => {
        const ordering =Number(savingsFilter.status)===1?[4, 'desc']:[3, 'desc'];
        const buttons = [1, 2].includes(role_id) ? extraButtons : ['Add Savings'];
        const bulkImportModalFForm = [1, 2].includes(role_id) ? handleBulkImportModalFForm : '';
        savingsInitDatable(allThriftSavings, setSavings, role_id, buttons, bulkImportModalFForm,'',ordering);
    }



    if(newDataBot){
        handleDataTable()
        dispatch(setGeneralAction(types.RESET_SAVINGS_BOT,[]))
    }
    return(
        <SavingsWrapper openSavingsForm={openSavingsForm} setSavingImportForm={setSavingImportForm}
                        setEditForm={setEditForm} showEditForm={showEditForm}
                        showSavingImportForm={showSavingImportForm}
                        showSavingsForm={showSavingsForm}
                        currentPage={'All Cooperative Savings'}>
            <>
                <ExpandCollapse name={'Savings Summary'} id={'savingsSummary'} id_={'savingsSummary_'} id_1={'savingsSummary_1'}>
                    <div className="row">
                        { summary.length >0 && summary.map((item,i=1)=>
                            <div className="col-xxl-3 col-lg-3" key={i+2}>
                                <CardSummary
                                    bg={item.bg}
                                    bgTextColor = {item.bgTextColor}
                                    iconBg={item.iconBg}
                                    iconColor = {item.iconColor}
                                    textColor={item.textColor}
                                    icon={item.icon}
                                    amount={item.amount}
                                    text={item.text}
                                    badgeBg={item.badgeBg}
                                    totalNo = {item.total_count}
                                />
                            </div>
                        )}
                    </div>
                </ExpandCollapse>

                <ExpandCollapse name={'Savings Filter'} id={'SavingsFilter'} id_={'SavingsFilter_'} id_1={'SavingsFilter_1'}>
                    <SavingsFilter _user_id={user_id}/>
                </ExpandCollapse>

                <ExpandCollapse name={'All Cooperative Savings'} id={'Savings'} id_={'Savings_'} id_1={'Savings_1'}>
                    <div className="row">
                        <div className="col-12">



                            {isLoading_t &&  <Loader/> }
                            <div className="table-responsive">
                                <table className="table table-sm table-centered table-striped table-hover table-responsive w-100 dt-responsive nowrap dataTable no-footer dtr-inline collapsed" role={'grid'} id="products-datatable">
                                    <thead className="">
                                    <tr>
                                        {/*<th className="all">Payer's Name</th>*/}
                                        <th className="all">Reference</th>
                                        {/*<th>Actual Amount</th>*/}
                                        <th>Amount</th>
                                        <th>Channel</th>
                                        <th>Created Date</th>
                                        <th>Payment Date</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>



                </ExpandCollapse>

            </>
        </SavingsWrapper>
    )

}

export default AllSavings