
import { useDispatch } from "react-redux"
import { setGeneralAction } from "../../config/redux/actions"
import { types } from "../../config/redux/types"
import React, {useEffect} from "react";
import {useNotifications} from "reapop";
interface Props {
    msg : any
}

const ErrorAlert:React.FC<Props> = ({msg}) => {
    //timer to clear msg after few sec
    const dispatch = useDispatch()
    const {notify,dismissNotifications} = useNotifications()

    useEffect(() => {
        showErrorNotification()
    },[])

    const closeTab = () => {
        dispatch(setGeneralAction(types.CLEAR_MSG,[]))
    }



    const showErrorNotification = () => {
        const finalMsg = typeof msg === 'string' ? msg : typeof msg==='object' ? 'Unable to Process Request' : typeof msg
        notify(finalMsg, 'error')
        setTimeout(() => {
            dispatch(setGeneralAction(types.CLEAR_MSG,[]))
            dismissNotifications()
        },5000)
    }


    return <>
    </>
}

export default ErrorAlert