import BulkImportWrapper from "../_components/BulkImportWrapper";
import React from "react";
import {Spinner} from "../../../components";
import {remote_url} from "../../../config/urls/remote";
import {types} from "../../../config/redux/types";
import {postRequest, postRequestFile} from "../../../config/redux/actions";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../config/redux/reducers";
import {AuthState} from "../../../config/redux/reducers/tmsAuthReducer";
import {MemebersState} from "../../../config/redux/reducers/tmsMemeberReducerState";

interface FormProps {
    file : any
}

interface ComponentProps {
    show : boolean
    handleImportModal : any
}
const UserBulkImport:React.FC<ComponentProps> = ({show,handleImportModal}) => {
    const page = 'bulk_form'
    const {token,account_key,user,role_id} = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
    const { register, handleSubmit, formState: { errors },reset } = useForm<FormProps>()
    const {loading_,error_msg,success_msg} = useSelector<AppState,MemebersState>((state)=> state.tmsMemeberReducerState)

    const dispatch = useDispatch()


    const addNewMember = (data:FormProps) => {

        const postData = {
            token,
            url: `${remote_url.thriftMemebers}/bulk-member`,
            actionType : types.CREATE_USER,
            postData :{
                file :  data.file[0]
            },
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            errorAction : types.SET_ERROR,
            accountKey: account_key,
            loading :{
                isLoading :false,
                isLoading_ : true,
                isLoading_t : false
            }
        }

        dispatch(postRequestFile(postData))
    }


    if(success_msg && page === 'bulk_form'){
        // reset()
        handleImportModal(false)
    }



    return (
        <BulkImportWrapper handleImportModal={handleImportModal} show={show} link={`users_import.xlsx`}>
            <form className="ps-3 pe-3" onSubmit={handleSubmit(addNewMember)}>
                <div className="mb-3">
                    <label htmlFor="username" className="form-label">File(CSV/EXCEL)</label>
                    <input className="form-control" type="file"
                           {...register('file',{required: 'Please Select an Excel File'})}
                    />
                </div>

                <div className="mb-3 text-center">
                    {
                        loading_ ? <Spinner color="primary"/> :
                            <button className="btn btn-primary" type="submit">Upload Data</button>
                    }
                </div>
            </form>
        </BulkImportWrapper>
    )
}

export default UserBulkImport