

// 
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import {calculateTime, getRequest} from "../../../../config/redux/actions"
import { AppState } from "../../../../config/redux/reducers"
import { AuthState } from "../../../../config/redux/reducers/tmsAuthReducer"
import { types } from "../../../../config/redux/types"
import { url } from "../../../../config/urls"
import { remote_url } from "../../../../config/urls/remote"
import DashboardWrapper from "../../DashboardWrapper"
import BreadCrumb from "../../layouts/BreadCrumb"
import { CircularLoader } from "../../_components"
import DocumentUploadForm from "./DocumentUploadForm"
import {DEFAULT_INACTIVE_TIME} from "../../../../config/config";



const ThriftDocuments = () => {
    const {account_doucments,token,account_key,loading_,thrift_account,loginTime,user,role_id} =  useSelector<AppState, AuthState>((state) => state.tmsAuthReducer)
    const dispatch = useDispatch()

    useEffect(() => {
        let time_diff = calculateTime(loginTime)
        if(time_diff <= DEFAULT_INACTIVE_TIME){
            getDocument()
        }

    },[])
    //

    const getDocument = () => {
            
        const postData = {
            token,
            url: remote_url.updateAccountDocUpload,
            actionType : types.GET_DOCUMENT,
            postData : [],
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            errorAction : types.SET_ERROR,
            accountKey: account_key,
            loading :{
                isLoading :false,
                isLoading_ : true,
                isLoading_t : false
            }
            }
            //
        dispatch(getRequest(postData))
    }


    return <DashboardWrapper showWarning={false}>
        <BreadCrumb pageTitle="Thrift Account Document" current="Thrift Account Document" main="Thrift Settings"/>
     
        <div className="container-fluid">
            <DocumentUploadForm/>
            {/*  */}
            <div className="row mb-2">
                <div className="col-sm-4">
                    <Link 
                        className="btn btn-primary btn-rounded mb-3"
                        to="#0"     
                        data-bs-toggle="offcanvas" 
                        data-bs-target="#documentForm"
                        aria-controls="documentForm"
                    >
                        <i className="mdi mdi-plus" /> 
                        Upload Document
                    </Link>
                </div>
            </div> 

            {/*  */}
                  { 
          thrift_account?.account_branch.is_profile_complete === 1  && thrift_account?.account_branch.business_reg_document && thrift_account?.account_branch.status === 0  &&
            <div className=" mt-3 alert alert-danger alert-dismissible fade show" role="alert">
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    <i className="dripicons-warning me-2"></i>
                    You need to Submit your Thrift Account Profile for Approval,
                    Kindly make use of the button below
                    <Link to={`${url.thriftAccountSettings}/#approval`}> Fix Now </Link>
            </div>
        }
             {loading_ && <CircularLoader/> }

           <div className="row">
            
                {/* project card */}
                { 
                    account_doucments?.map((document) => 
                    <div  className="col-md-3 col-xxl-3" key={document?.id}>   
                        <div className="card d-block" >
                            <img className="card-img-top" src={document.document} alt="document icon" height={150} /> 
                        
                            <div className="card-img-overlay">
                                <div  className={`badge  bg-success  text-light p-1`}> Approved</div>
                            </div>
                            <div className="card-body position-relative">
                                <h4 className="mt-0 mb-3">
                                    <Link to="#0" className="text-title">
                                    {document.document_type}
                                    </Link>
                                </h4>
                            </div> 
                        </div>  
                    </div>
                )}
             
        
       
       </div>
        </div>

    </DashboardWrapper>
}


export default ThriftDocuments