import {Link, Navigate} from "react-router-dom"
import { url } from "../../config/urls"
import AuthWrapper from "./AuthWrapper"
import {useForm} from "react-hook-form";
import {remote_url} from "../../config/urls/remote";
import {types} from "../../config/redux/types";
import {postRequest} from "../../config/redux/actions";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../config/redux/reducers";
import {AuthState} from "../../config/redux/reducers/tmsAuthReducer";
import {Spinner} from "../../components";
import {ErrorAlert, SuccessAlert} from "../../components/alert";
import {Header,Footer} from "./components";
import {pageKeys} from "./SuccessScreen";


type FormValues ={
    email : string,
}
 const ForgotPassword = () => {
    const {loading,success_msg,error_msg} = useSelector<AppState,AuthState>((state)=> state.tmsAuthReducer)
     const { register, handleSubmit,setValue ,formState: { errors } } = useForm<FormValues>({mode:'onTouched'})
     const dispatch = useDispatch()


    const forgotPassword = (data:FormValues) => {
        const postData = {
            token : '',
            url:remote_url.forgotPass,
            actionType: types.FORGOT_PASSWORD,
            postData : data,
            secretData : {
                email:'',
                mac_id :'',
                r_id:0
            },
            // reset,
            errorAction : types.SET_ERROR,
            loading :{
                isLoading :true,
                isLoading_ : false,
                isLoading_t: false
            },
            accountKey :''
        }
        //
        dispatch(postRequest(postData))
    }


    if(success_msg!==""){
        setValue("email","")
    }
     if(success_msg){
         return <Navigate to={`${url.successScreenPre}${pageKeys[4]}`}/>
     }

  return (

    <AuthWrapper>

        {success_msg && <SuccessAlert msg={success_msg}/>}
        {error_msg && <ErrorAlert msg={error_msg}/>}

          <div className="card">
              <Header/>
                            
                            <div className="card-body p-4">
                                
                                <div className="text-center w-75 m-auto">
                                    <h4 className="text-dark-50 text-center mt-0 fw-bold">Forgot Password</h4>
                                    <p className="text-muted mb-4">Enter your email address and we'll send you an email with instructions to reset your password.</p>
                                </div>

                                <form onSubmit={handleSubmit(forgotPassword)}>
                                    <div className="mb-3">
                                        <label htmlFor="email_address" className="form-label">Email address</label>
                                        <input
                                            className={`form-control ${errors.email && 'is-invalid'}`}
                                               type="email"
                                               id="email_address"
                                               placeholder="Enter your email"
                                               {...register('email',{
                                                   required: 'this field is require',
                                                   pattern: {
                                                       value:/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                                       message:'invalid email format'
                                                   }
                                               })}
                                        />
                                        {errors.email  && (<div className="invalid-feedback">{errors.email.message}</div>)}
                                    </div>

                                    <div className="mb-0 text-center">
                                        {loading ? <Spinner color={'primary'} />: <button className="btn btn-primary" type="submit">Reset Password</button> }
                                    </div>
                                </form>
                            </div> 
                        </div>


                        <Footer url={url.home} linkText={'Log In'} callToActionText={'Back to'}  />

    </AuthWrapper>
  )
}

export default ForgotPassword