import React from 'react'

//
interface Props  {
    pageTitle : string,
    main :string,
    second? : string,
    current : string,
    showToggle ? : boolean

}
const BreadCrumb : React.FC<Props> = ({pageTitle,main,second,current,showToggle=true}) => {
  return (
    <>
     <div className="row mx-1">
                            <div className="col-sm-12  py-2 px-2">
                                <div className="page-title-box">
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="#0">{main}</a>
                                            </li>
                                            {second && 
                                                <li className="breadcrumb-item">
                                                    <a href="#0"> {second}</a>
                                                </li> 
                                            }
                                           
                                            <li className="breadcrumb-item active">{current}</li>
                                        </ol>
                                    </div>
                                    <h4 className="page-title">{pageTitle}</h4>
                                </div>
                            </div>
                               {/*  */}
                               {/* {showToggle &&    <div className=' my-1'>
                                    <div className="form-check form-switch mb-1">
                                    <input className="form-check-input" type="checkbox" name="color-scheme-mode" value="light" id="light-mode-check" checked={true}/>
                                    <label className="form-check-label" htmlFor="light-mode-check">Light Mode</label>
                                    </div>
                               </div>
                                }
                             */}
                                {/*  */}
                        </div>
    </>
  )
}

export default BreadCrumb