interface Props{
    color : string

}

export const Spinner : React.FC<Props> = ({color}) => {
    return <>
    <div className="d-flex justify-content-center">
        <div className={`spinner-border text-${color}`} role="status"></div>
    </div>
    </>
}


// export default Spinner