import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {Link, Navigate} from "react-router-dom"
import { setGeneralAction } from "../../config/redux/actions"
import { AppState } from "../../config/redux/reducers"
import { AuthState } from "../../config/redux/reducers/tmsAuthReducer"
import { types } from "../../config/redux/types"
import { url } from "../../config/urls"
import {emptyCache,APP_VERSION} from "../../config/config";

interface Props {
  children : React.ReactNode

}


const AuthWrapper: React.FC<Props> = ({children}) => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setGeneralAction(types.CLEAR_ONBOARD_DATA,[]))
        emptyCache().then((r) =>{} )
    },[])


    return (
        <>

            <div  className="authentication-bg">
                <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xxl-5 col-lg-6">
                             


                                {children}
                            

                            </div> 
                        </div>
                    </div>
                </div>
                {/*<footer className="footer footer-alt bg-primary-lighten">*/}
                {/*    2022 - 2023 © ThriftMS -  <a target={'_blank'} rel={'noreferrer'} className={'text-muted'} href={'https://deesuntech.com/'}>deesuntech.com</a> (<small>v{APP_VERSION}</small>)*/}

                {/*</footer>*/}
            </div>
        </>
    )
}


export default AuthWrapper