// import model
// actions props
//types
//define state for reducer
// intial state
// create the reducers
// export
import {
    LoanApplicationModel,
    LoanApplicationProps,
    LoanAppSummaryModel,
    LoanRepaymentModel,
    LoanRepaymentProps,
    LoanTypesModel,
    LoanTypesProps
} from "../../model/authModel";

import {ActionProps} from "../actions";

import {types} from "../types";
import string from "../../strings/string";


export interface LoansReducerState{
    loading : boolean,
    loading_ : boolean,
    isLoading_t:boolean,
    success_msg  : string ,
    transaction_id  : string ,
    transaction_reference  : string ,
    error_msg : string,
    loan_types : LoanTypesModel[],
    loan_type : LoanTypesModel,
    loans_app : LoanApplicationModel[],
    newDataBot : boolean,
    loan : LoanApplicationModel,
    loanPayments :LoanRepaymentModel[] //record of how a particular loan was repaid
    loanPayment : LoanRepaymentModel,
    loanAppSummary : LoanAppSummaryModel[],
    loanFilter : {
        fromDate : string,
        toDate : string,
        status : string
    },
}
//
const initialState = {
    loading:false,
    loading_: false,
    isLoading_t: false,
    transaction_id :  '',
    transaction_reference :  '',
    success_msg  : '',
    error_msg : '',
    loan_types : [],
    loan_type : LoanTypesProps,
    loans_app : [],
    newDataBot : false,
    loan: LoanApplicationProps,
    loanPayment:LoanRepaymentProps,
    loanPayments : [],
    loanAppSummary : [],
    loanFilter : {
        fromDate : '',
        toDate : '',
        status : '3'
    },
}


const tmsLoansReducer = (state:LoansReducerState= initialState, action:ActionProps) => {
    //
    switch (action.type) {
        case types.SET_TRANS_REF:
            return{
                ...state,
                transaction_id : action.payload?.trans_id,
                transaction_reference : action.payload?.transaction_ref
            }

        case types.CLEAR_MSG:
            return{
                ...state,
                loading:false,
                loading_:false,
                loading_t:false,
                success_msg : '',
                error_msg : ''
            }

        case types.SET_LOANS_TYPE:

            return {
                ...state,
                loan_type: action.payload
            }
            
        case types.SET_LOADING:
            return {
                ...state, //loading_
                loading:action.loading?.isLoading,
                loading_ :action.loading?.isLoading_,
                isLoading_t: action.loading?.isLoading_t,
                success_msg : '',
                error_msg : ''
        }
        case types.SET_ERROR:
            return {
                ...state,
                loading:false,
                loading_:false,
                isLoading_t : false,
                success_msg : '',
                error_msg : action.payload
            }
        //
        case types.GET_LOANS_TYPES :
            return {
                ...state,
                  loading :false,
                 loading_ : false,
                 isLoading_t:false,
                newDataBot: true,
                loan_types: action.payload?.data,
            }
        //
        case types.CREATE_LOANS_TYPES :
            return {
                ...state,
                  loading :false,
                 loading_ : false,
                 isLoading_t:false,
                newDataBot : true,
                loan_types: action.payload?.data,
                success_msg: action.payload?.message,
                // has_loan_settings:1
            }
        case types.CREATE_LOAN_APPLICATION :
            return {
                ...state,
                loading :false,
                loading_ : false,
                isLoading_t:false,
                loans_app: action.payload?.data,
                success_msg: action.payload?.message
            }
        //
        case types.RESET_LOANS_BOT:
            return {
                ...state,
                newDataBot: false
            }
        //
        case types.CLEAR_LOAN_MSG:
            return {
                ...state,
                loading :false,
                loading_ : false,
                isLoading_t:false,
                error_msg :'',
                success_msg :'',
                loanPayments:[],
                loanPayment : '',
                loan:''

            }
        case types.GET_LOAN_APPLICATION:
            return {
                  ...state,
                loading :false,
                loading_ : false,
                isLoading_t:false,
                success_msg: action.payload?.message,
                loans_app: action.payload?.data,
                loanPayments:[],
                loanAppSummary: action.payload?.loanAppSummary,
                newDataBot:true,
            }
        case types.SET_LOANS_SAVINGS_REPAYMENT:
            return {
                ...state,
                loading : false, 
                loading_ : false,
                isLoading_t:false,
                loan :action.payload,
                loanPayments:[],
            }

        case types.UPDATE_LOAN_APP_STATUS:
            return{
                ...state,
                 loading :false,
                 loading_ : false,
                isLoading_t:false,
                loans_app: action.payload?.data.loanApps,
                loan : action.payload?.data.loanApp,
                loanPayments:[],
                success_msg: action.payload?.message,
                newDataBot:true,
            }
        case types.GET_LOAN_REPAYMENT_BREAKDOWN:
            return{
                ...state,
                loading :false,
                loading_ : false,
                isLoading_t:false,
                loanPayments: action.payload?.data,
            }
        case types.REPAY_LOAN:
            return{
                 ...state,
                loading :false,
                loading_ : false,
                isLoading_t:false,
                loanPayments: action.payload?.data.loanApp,
                loans_app : action.payload?.data.loanApps,
                loanPayment : ''
            }
        case types.SET_LOAN_PAYMENT_DATA:
            // set a single item, selected from the loan breakdown repayment table
            return {
                ...state,
                loading :false,
                loading_ : false,
                isLoading_t:false,
                loanPayment: action.payload,
            }

        case types.SET_LOAN_FILTERS:
            return {
                ...state,
                loanFilter : {
                    fromDate : action.payload?.fromDate,
                    toDate : action.payload?.toDate,
                    status: action.payload?.status
                }
            }
        case types.VERIFY_LOANS:
            return{
                ...state,
                loading :false,
                loading_ : false,
                isLoading_t:false,
                success_msg: action.payload?.message
            }
    
        case types.LOG_OUT:
            return {
                ...state,
                 loading :false,
                 loading_ : false,
                 isLoading_t:false,
                 error_msg :'',
                 success_msg :'',
                 loan_types:[],
                 loans_app : [],
                 loan :'',
                 loanPayment:'',
                 newDataBot : false,
                 loanPayments:[],
                 loanFilter : {
                    fromDate : '',
                    toDate : '',
                    status : 0
               },
               has_loan_settings: 0
            }
            
        default:
            return state;
    }

}

export default tmsLoansReducer