import DashboardWrapper from "../../DashboardWrapper";
import BreadCrumb from "../../layouts/BreadCrumb";
import {EmailLeftSmsSidebar} from "../email_sms/components";
import {SendNotification} from "./components";
import styles from '../../styles/push-notification.module.css'

const Notifications = () => {
    return<DashboardWrapper  showWarning={false} keys={'email-chat'}>
        <BreadCrumb pageTitle={'Push Notification'} main={'Dashboard'} current={'Communication'}/>
        {/*    */}

        {/*  */}
        <div className="container-fluid">


            <div className="row">
                {/* Right Sidebar */}

                <div className="col-12">
                    <div className="card">
                        <div className={"card-header"}>
                            <div className={'text-sm-end'}>
                                <button className={'btn btn-primary '} data-bs-toggle="modal" data-bs-target="#compose-modal"> Send Notification </button>
                            </div>

                        </div>

                        <div className="card-body">
                            <>
                                <SendNotification/>
                                <div className="mt-3">
                                    <ul className="email-list">

                                        <li className="unread">
                                            <div className="email-sender-info">
                                                <div className="checkbox-wrapper-mail">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="mail1" />
                                                        <label className="form-check-label" htmlFor="mail1" />
                                                    </div>
                                                </div>
                                                <span className="star-toggle mdi mdi-star-outline text-warning" />
                                                <a href="javascript: void(0);" className="email-title">Lucas Kriebel (via Twitter)</a>
                                            </div>
                                            <div className="email-content">
                                                <a href="javascript: void(0);" className="email-subject">Lucas Kriebel (@LucasKriebel) has sent
                                                    you a direct message on Twitter! &nbsp;–&nbsp;
                                                    <span>@LucasKriebel - Very cool :) Nicklas, You have a new direct message.</span>
                                                </a>
                                                <div className="email-date">11:49 am</div>
                                            </div>
                                            <div className="email-action-icons">
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-archive email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-delete email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-email-open email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-clock email-action-icons-item" /></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                                {/* end .mt-4 */}
                                <div className="row">
                                    <div className="col-7 mt-1">
                                        Showing 1 - 20 of 289
                                    </div> {/* end col*/}
                                    <div className="col-5">
                                        <div className="btn-group float-end">
                                            <button type="button" className="btn btn-light btn-sm"><i className="mdi mdi-chevron-left" /></button>
                                            <button type="button" className="btn btn-info btn-sm"><i className="mdi mdi-chevron-right" /></button>
                                        </div>
                                    </div> {/* end col*/}
                                </div>
                                {/* end row*/}
                            </>
                            {/* end inbox-rightbar*/}
                        </div>
                        {/* end card-body */}
                        <div className="clearfix" />
                    </div> {/* end card-box */}
                </div> {/* end Col */}
            </div>
        </div>
    </DashboardWrapper>
}

export default  Notifications