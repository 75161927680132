import React from "react";

interface Props{
    closeModal : any
    modalTitle : string
    showModal : boolean
    modalSize : string //-lg,-full-width,-scrollable,-sm
    children : React.ReactNode,
}

const GeneralModal:React.FC<Props> = ({children,closeModal,modalTitle,showModal,modalSize}) => {

    return(
        <>
            <div className={`modal fade ${showModal ? 'show':''} `} id="bs-example-modal-lg" tabIndex={-1} aria-labelledby="myLargeModalLabel"
                 style={{display:showModal?'block':'none'}}
                 aria-modal="true" role="dialog">
                <div className={`modal-dialog ${modalSize}`}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myLargeModalLabel">{modalTitle}</h4>
                            <button
                                type="button" className="btn-close"
                                data-bs-dismiss="modal" aria-label="Close"
                                onClick={()=>closeModal(false)}>
                            </button>
                        </div>
                        <div className="modal-body">
                            {children}
                        </div>
                    </div>
                </div>
            </div>

            {showModal &&  <div className="modal-backdrop fade show"></div> }


        </>
    )
}

export default GeneralModal