import {useRef, useState} from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {postRequest, setGeneralAction} from '../../../../config/redux/actions';
import { AppState } from '../../../../config/redux/reducers';
import { AuthState } from '../../../../config/redux/reducers/tmsAuthReducer';
import { types } from '../../../../config/redux/types';
import { remote_url } from '../../../../config/urls/remote';

import DashboardWrapper from "../../DashboardWrapper"
import BreadCrumb from "../../layouts/BreadCrumb"
import ToastAlert from '../../_components/ToastAlert';
import {Login} from "../../../auth";


interface FormValues {
    old_password : string ,
    new_password : string,
    confirm_new_password : string
}

const  ChangePassword = () => {
     const { register, handleSubmit, watch,formState: { errors },reset } = useForm<FormValues>()
     const {token,account_key,error_msg,success_msg,user,role_id,logUserOut} = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
    const [showOldPassword, setShowOldPassword] = useState(false)
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [showNewConfirmPassword, setShowNewConfirmPassword] = useState(false)

    const password = useRef({});
    password.current = watch("new_password", "");

     const dispatch = useDispatch()



    const changePassword = (data:FormValues) => {
         const postData = {
                token,
                url: remote_url.changePassword,
                actionType : types.CHANGE_PASSWORD,
                postData : data,
             secretData : {
                 email:user.email,
                 mac_id :user.mac_id,
                 r_id: role_id
             },
                errorAction : types.SET_ERROR,
                accountKey: account_key,
                loading :{
                    isLoading :true,
                    isLoading_ : false,
                    isLoading_t : false
                }
                // reset
            }
        //
         dispatch(postRequest(postData))
    }
    //
    // if(logUserOut){
    //     return <Login/>
    // }


    return <>
      <DashboardWrapper>
        <BreadCrumb pageTitle="Change Password" current="Change Password" main="Settings"/>
        
        {success_msg && <ToastAlert  msg={success_msg}/>}

        <div className="container-fluid card p-4 col-sm-12 col-md-8 offset-md-2">
            <form onSubmit={handleSubmit(changePassword)}>
                <div className="row">
                    <div className="col-12">
                        <div className="row mb-3">
                            <label className="col-md-3 col-form-label" htmlFor="old password">Old Password</label>
                            <div className="col-md-7">
                                <div className={'input-group input-group-merge'}>
                                    <input
                                        type={showOldPassword?'text':'password'}
                                        className={`form-control ${ (errors.old_password || error_msg) && 'is-invalid'}`}
                                        {...register('old_password',{
                                               required: 'old password is required',
                                        })}
                                    />
                                    <div className="input-group-text" onClick={()=>setShowOldPassword(!showOldPassword)}>
                                        <span className="password-eye"></span>
                                    </div>
                                    {errors.old_password  && (<div className="invalid-feedback">{errors.old_password.message}</div>)}
                                    {error_msg && (<div className="invalid-feedback">{error_msg}</div>)}
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label className="col-md-3 col-form-label" htmlFor="password">New Password</label>
                            <div className="col-md-7">
                                <div className={'input-group input-group-merge'}>
                                    <input
                                        type={showNewPassword ? 'text': 'password'}
                                        className={`form-control ${errors.new_password && 'is-invalid'}`}
                                        {...register('new_password', {
                                                   required: 'password field is required',
                                                   minLength:{value:6, message:'Password cannot be less than 6 character'},
                                               })}
                                    />
                                    <div className="input-group-text" onClick={()=>setShowNewPassword(!showNewPassword)}>
                                        <span className="password-eye"></span>
                                    </div>
                                    {errors.new_password  && (<div className="invalid-feedback">{errors.new_password.message}</div>)}
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label className="col-md-3 col-form-label" htmlFor="confirm">Confirm  password</label>
                            <div className="col-md-7">
                                <div className={'input-group input-group-merge'}>
                                    <input
                                        type={showNewConfirmPassword ? 'text':'password'}
                                        className={`form-control ${errors.confirm_new_password && 'is-invalid'}`}
                                        {...register('confirm_new_password',{
                                            required: 'confirm password field is required',
                                            validate: value => value === password.current || "The passwords do not match"
                                        })}
                                    />
                                    <div className="input-group-text" onClick={()=>setShowNewConfirmPassword(!showNewConfirmPassword)}>
                                        <span className="password-eye"></span>
                                    </div>
                                    {errors.confirm_new_password  && (<div className="invalid-feedback">{errors.confirm_new_password.message}</div>)}
                                </div>
                            </div>
                        </div>
                        <div className={'row pull-right float-right'}>
                            <div className={'col-md-7'}></div>
                            <div className='col-md-3'>
                                <input type="submit" value="Change Password" className='btn btn-primary btn-sm'/>
                            </div>
                        </div>


                    </div> 
                </div> 
            </form>
        </div>
  
     </DashboardWrapper>
    </>
}

export default ChangePassword