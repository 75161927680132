import {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {postRequest, setGeneralAction} from "../../../config/redux/actions";
import {AppState} from "../../../config/redux/reducers";
import {AuthState} from "../../../config/redux/reducers/tmsAuthReducer";
import {types} from "../../../config/redux/types";

import DashboardWrapper from "../DashboardWrapper"
import BreadCrumb from "../layouts/BreadCrumb"
import {CardSummary, Loader} from '../_components';
import AddNewExpense from "./AddNewExpense";
import {ExpenseReducerState} from "../../../config/redux/reducers/tmsExpenseReducer";

import {ExpenseDetails, ExpenseFilter} from "./components";
import {expensesInitDatatable} from "../../../config/datatableConfig";
import {remote_url} from "../../../config/urls/remote";
import {ErrorAlert, SuccessAlert} from "../../../components/alert";






const Expenses = () => {
    let customButtons:string[] = ['Add Expense']
    const [showDetails,setShowDetails] = useState(false)
    const [showExpenseForm,setShowExpenseForm] = useState(false)
    const dispatch = useDispatch()

    const {role_id,token,user,account_key} = useSelector<AppState,AuthState>((state)=>state.tmsAuthReducer)

    const {
        loading_,
        loading_t,
        expenses,
        expense,
        newDataBot,
        income_total,
        expense_total,
        success_msg,error_msg
    } = useSelector<AppState, ExpenseReducerState>((state) => state.tmsExpenseReducer)



    const clearData = () => {
        dispatch(setGeneralAction(types.CLEAR_MSG,[]));
        dispatch(setGeneralAction(types.CLEAR_EXPENSE,[]));
    }
    const handleShowExpenseForm = (item:boolean) => {
        setShowExpenseForm(item)
    }

    const setUpDataTable = () => {
        if([1,2].includes(role_id)){
            expensesInitDatatable(expenses,setSingleExpense,customButtons,handleShowExpenseForm,'')
        }
        else {
            expensesInitDatatable(expenses,setSingleExpense,[],'','')
        }


    }


    const setSingleExpense = (x:any) => {
        dispatch(setGeneralAction(types.SET_SINGLE_EXPENSE, x))
        setShowDetails(true)
    }

    const confirmPayment = () => {
            const postData = {
                token,
                url: remote_url.verifyExpenses,
                actionType: types.CREATE_NEW_EXPENSE,
                postData: {
                    trans_ref: expense.transaction_reference,
                    gateway_id:expense.gateway_used,
                    requery:true
                },
                secretData : {
                    email:user.email,
                    mac_id :user.mac_id,
                    r_id: role_id
                },
                errorAction: types.SET_ERROR,
                accountKey: account_key,
                loading: {
                    isLoading: false,
                    isLoading_: true,
                    isLoading_t: false
                }
            }
            dispatch(postRequest(postData))
    }


    useEffect(() => {
        dispatch(setGeneralAction(types.CLEAR_MSG,[]));
        dispatch(setGeneralAction(types.CLEAR_EXPENSE,[]));
        dispatch(setGeneralAction(types.SET_TRANSACTION_EXPENSES_REF, {transaction_ref:'',trans_id:''}))
    }, []);

    if (newDataBot) {
        setUpDataTable()
        dispatch(setGeneralAction(types.RESET_TRANSACTION_BOT, []))
    }


    //
    return <DashboardWrapper>
        <BreadCrumb pageTitle='Expenses ' main='Dashboard' current='Expenses'/>

        {success_msg && <SuccessAlert msg={success_msg}/>}
        {error_msg && <ErrorAlert msg={error_msg}/>}

        { showDetails && <ExpenseDetails expense={expense} backdrop={true} show={''}  requery={confirmPayment}  dispatch={dispatch}  isLoading={loading_} /> }
        {showExpenseForm && <AddNewExpense   handleModalFunc={handleShowExpenseForm}  requery={confirmPayment}/> }

        <div className="row">
            <div className="col-xxl-4 col-lg-4">
                <CardSummary amount={income_total} bg="primary" badgeBg="info" iconBg="danger" textColor="text-white" text={'Income'}/>
            </div>

            <div className="col-xxl-4 col-lg-4">
                <CardSummary amount={expense_total} bg="danger" badgeBg="dark" iconBg="white"
                             textColor="text-white" iconColor="success" icon='account-multiple' text={'Expense'}/>
            </div>

            <div className="col-xxl-4 col-lg-4">
                <CardSummary amount={income_total + expense_total} bg="info" badgeBg="primary" iconBg="danger"  textColor="text-white" text={'Overall Total'}/>
            </div>

        </div>
        {/* filter */}
        <ExpenseFilter/>
        {/* summary */}
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="row mb-2">
                            <div className="col-4"> {loading_t && <Loader/>} </div>

                        </div>





                        <div className="table-responsive">
                            <table className="table table-centered table-striped w-100 dt-responsive nowrap" id="products-datatable">
                                <thead className="table">
                                <tr>
                                    <th className="all">Name</th>
                                    <th>Reference</th>
                                    <th>Expense Type</th>
                                    <th>Amount</th>
                                    <th>Date Created</th>
                                    <th>Status</th>
                                    <th style={{width: "85px"}}>Action</th>
                                </tr>
                                </thead>
                                {/* <tbody>

                                    </tbody> */}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </DashboardWrapper>
}

export default Expenses