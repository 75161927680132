

import DashboardWrapper from "../DashboardWrapper"
import BreadCrumb from "../layouts/BreadCrumb"
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../config/redux/reducers";
import {AuthState} from "../../../config/redux/reducers/tmsAuthReducer";
import {useEffect} from "react";
import {calculateTime, getRequest, setGeneralAction} from "../../../config/redux/actions";
import {remote_url} from "../../../config/urls/remote";
import {types} from "../../../config/redux/types";
import {DEFAULT_INACTIVE_TIME} from "../../../config/config";
import {SupportTicketReducerState} from "../../../config/redux/reducers/tmsSupportTicketReducer";
import {Link} from "react-router-dom";
import {NewSupportForm} from "./components";
import {Chat} from "./chats";
import LeftHandSide from "./LeftHandSide";
import RightHandSide from "./RightHandSide";
import EmptyChat from "./EmptyChat";
import {ComingSoon} from "../../../components";





const SupportsDashboard = () => {
  const {loginTime,token,account_key,user,role_id } = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
  const {supportsConversations,supportsConversation,isNew} = useSelector<AppState,SupportTicketReducerState>((state)=> state.tmsSupportTicketReducer)
  const dispatch = useDispatch()

  useEffect(() => {
    let time_diff = calculateTime(loginTime)
    if(time_diff<=DEFAULT_INACTIVE_TIME){
      //function goes here
      // getSupportConversations()
    }
  },[])

  const getSupportConversations =  () => {
    //gets all the tickets available for this account, which is majorly used by this account
    const getData = {
      token,
      url: remote_url.getSupportTicketsConversation,
      actionType : types.GET_SUPPORT_TICKET_CONVERSATION,
      postData : [],
      secretData : {
        email:user.email,
        mac_id :user.mac_id,
        r_id: role_id
      },
      errorAction : types.SET_ERROR,
      accountKey: account_key,
      loading :{
        isLoading :false,
        isLoading_ : false,
        isLoading_t : true
      }
    }
    //
    dispatch(getRequest(getData))
  }




    return<DashboardWrapper showWarning={false}>
        <BreadCrumb pageTitle="Supports Ticket" main="Support" current="Support Ticket" showToggle={false}/>
       <div className="row">
         {/*<LeftHandSide/>*/}
         {/*{*/}
         {/*  supportsConversation.id === 0 ? <EmptyChat/> : <RightHandSide/>*/}
         {/*}*/}
        <ComingSoon imgName={'support_feature-coming-soon'}/>
       </div>

      <NewSupportForm/>
    </DashboardWrapper>
}


export default SupportsDashboard