import React from "react";

interface ComponentProps {
    text : string,
    fontSize ? : string
}

const HelperText:React.FC<ComponentProps> = ({text,fontSize='14'}) => {
    return <>
        <p className={`text-muted font-${fontSize}`}>{text}</p>
    </>
}
export default  HelperText