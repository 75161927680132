import { Link } from "react-router-dom"
import { url } from "../../config/urls"
import AuthWrapper from "./AuthWrapper"
import {useSelector} from "react-redux";
import {AppState} from "../../config/redux/reducers";
import {AuthState} from "../../config/redux/reducers/tmsAuthReducer";
import {SuccessAlert} from "../../components/alert";

const AccountVerified = () => {
    const {success_msg} = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
    return <AuthWrapper>
        <div className="card">
            <div className="card-header pt-4 pb-4 text-center bg-primary">
                <Link to={url.home}>
                    <span>
                        <img src={"/assets/img/b.png"} alt="" height="48"/>
                    </span>
                </Link>
            </div>

            <div className="card-body p-4">
                <div className="text-center m-auto">
                    <img src="/assets/images/mail_sent.svg" alt="mail sent" height="64" />
                    <h4 className="text-dark-50 text-center mt-4 fw-bold">Email Verification Successfully</h4>
                    <p className="text-muted mb-4">
                        Your have Successfully verified your email.
                        Please click on the button below to go back to your dashboard.
                    </p>

                    {success_msg && <SuccessAlert msg={success_msg}/> }

                </div>


                <div className="mb-0 text-center">
                    <Link to={url.dashboard} className="btn btn-primary" type="submit">
                        <i className="mdi mdi-home me-1"></i> Back to Dashboard
                    </Link>
                </div>


            </div>
        </div>
    </AuthWrapper>
}

export default AccountVerified