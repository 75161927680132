import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {Link, useParams, useSearchParams} from "react-router-dom"
import {postRequest, setGeneralAction} from "../../../../config/redux/actions"
import { AppState } from "../../../../config/redux/reducers"
import { AuthState } from "../../../../config/redux/reducers/tmsAuthReducer"
import { types } from "../../../../config/redux/types"
import { appTxt } from "../../../../config/strings"
import { remote_url } from "../../../../config/urls/remote"
import DashboardWrapper from "../../DashboardWrapper"
import BreadCrumb from "../../layouts/BreadCrumb"
import {ErrorAlert, SuccessAlert} from "../../../../components/alert";
import {url} from "../../../../config/urls";
import {VerificationComponent} from "../../_components";

export type UrlParams = {
    transaction_reference: string,
    gateway_id: string
}
const SubscriptionVerify = () => {  
    const {loading_,token,account_key,transaction_ref,success_msg,error_msg,user,role_id,transaction_id} = useSelector<AppState, AuthState>((state) => state.tmsAuthReducer)
    const [searchParams] = useSearchParams();
    let {transaction_reference, gateway_id} = useParams<UrlParams>()
    const dispatch = useDispatch()
    

    useEffect(() => {
        //
        verify()
    },[])

    const verify = () => {
        let trans_id = searchParams.get('transaction_id') ?? '';
        let finalTransId = ''


        //I Dont want this to be replaced with empty data
        if(trans_id !== ''){
            dispatch(setGeneralAction(types.SET_SUB_TRANS_REF, {
                transaction_ref:transaction_reference,
                trans_id
            }))
            finalTransId = trans_id
        }
        else{
            finalTransId = transaction_id
        }

         
        const postData = {
                token,
                url: remote_url.verifyPlanSub,
                actionType : types.VERIFY_SUBSCRIPTION,
                postData : {
                    tranxRef: transaction_reference,
                    gateway_id,
                    transaction_id: finalTransId
                },
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
                errorAction : types.SET_ERROR,
                accountKey: account_key,
                loading :{
                    isLoading :true,
                    isLoading_ : false,
                    isLoading_t : false
                }
                // reset
            }
            //
        dispatch(postRequest(postData))

    }


    return <DashboardWrapper> 
            <>

                {/*{success_msg && <SuccessAlert msg={success_msg}/>}*/}
                {/*{error_msg && <ErrorAlert msg={error_msg}/>}*/}

                <VerificationComponent
                    transaction_ref={transaction_ref}
                    loading_={loading_}
                    success_msg={success_msg}
                    error_msg={error_msg}
                    verificationFunction={verify}
                    url={url.thriftSubscriptions}
                />
            </>

        </DashboardWrapper>
}

export default SubscriptionVerify