import {Link, Navigate, useParams} from "react-router-dom"
import {useForm} from 'react-hook-form';

import {url} from "../../config/urls"
import AuthWrapper from "./AuthWrapper"
import {remote_url} from "../../config/urls/remote";
import {types} from "../../config/redux/types";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {AppState} from "../../config/redux/reducers";
import {AuthState} from "../../config/redux/reducers/tmsAuthReducer";
import {ErrorAlert, SuccessAlert} from "../../components/alert";
import {postRequest, setGeneralAction} from "../../config/redux/actions";
import {Spinner} from "../../components";
import {Footer, Header} from "./components";
import axios from "axios";
import {pageKeys} from "./SuccessScreen";

/**
 * email
 * name
 * password
 */
export type MembersSignUpFormValues ={
    email : string,
    name : string,
    password : string,
    phone_no : string,
    agree: boolean,
    first_name:string,
    last_name:string,
    token :  string
}

type BranchData = {
    branch_name:string,
    logo : string
}

type UrlParams = {
    accountToken ?: string
}
const MemberSignUp = () =>
{
    const  [tempData, setTempData]  = useState<MembersSignUpFormValues>()
       const [errorScreen, setShowErrorScreen] = useState(false)
    const [showSuccessScreen,setShowSuccessScreen] = useState(false)
    const [branch,setBranch] = useState<BranchData>()
    //
    let {accountToken} = useParams<UrlParams>()
    //
    const { register, handleSubmit,setValue, formState: { errors },reset } = useForm<MembersSignUpFormValues>()
    const [showPassword, setShowPassword] = useState<boolean>(false)
    //@3
    const dispatch = useDispatch();
    //@4
    const {showOtpScreen,temp_form_data,loading,success_msg,token,error_msg}= useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)

    useEffect(() => {
        dispatch(setGeneralAction(types.CLEAR_MSG,[]))
    },[])

    const signUp = async (data:MembersSignUpFormValues) => {
        let  postData;
        if(data.token){
            postData = tempData
        }
        else{
            data.name = data.first_name + " " + data.last_name
            postData = data
            setTempData(data)
            dispatch(setGeneralAction(types.SET_TEMP_FORM_DATA, data))
        }

        const reqData = {
            token : '',
            url:`${remote_url.userSignUp}/${accountToken}`,
            actionType: types.SIGN_UP,
            postData : postData,
            // returnData: true,
            secretData : {
                email:'',
                mac_id :'',
                r_id :0
            },
            // reset,
            errorAction : types.SET_ERROR,
            loading :{
                isLoading :true,
                isLoading_ : false
            },
            accountKey :''
        }
       dispatch(postRequest(reqData))
    }



    const getThriftDetails = async () => {
        try{
            const resp = await axios.get(`${remote_url.getAuthBranchDetail}/${accountToken}`)
            if(resp.status===200){
                setBranch(resp.data.data)
            }
        }catch (e){
            setShowErrorScreen(true)
        }


    }

    useEffect(() => {
        getThriftDetails().then(r => {})
    }, [showSuccessScreen]);

    if(success_msg && !showOtpScreen){
        return <Navigate to={`${url.successScreenPre}${pageKeys[0]}`}/>
    }


    return (
        <>
            <AuthWrapper>
                <div className="card shadow-lg">
                    <Header logo={branch ? (branch.logo ?? '/assets/img/b.png') :'/assets/img/b.png'}/>
                    <div className="card-body p-4">
                        {/*text-center w-90 m-auto*/}
                        <div className="text-center w-75 m-auto mb-3">
                            <h4 className="text-dark-50 text-center mt-0 fw-bold">
                                { branch?.branch_name ?
                                    `${branch?.branch_name} - `
                                    :
                                    ""
                                }
                                Member's Form
                            </h4>
                        </div>

                        {!loading && success_msg && <SuccessAlert msg={success_msg}/>}
                        {!loading && error_msg && <ErrorAlert msg={error_msg}/> }
                        {success_msg }


                        {errorScreen ?
                            <div className={'alert alert-warning'}>
                                Dear Member,
                                <br/>
                                <br/>

                                It has come to our attention that there might be an issue with the Cooperative Registration Link provided.
                                <br/>
                                <br/>

                                Could you please confirm the registration link directly with your cooperative administrator to ensure its correctness?

                            </div>
                            :
                            showOtpScreen ?
                                <form onSubmit={handleSubmit(signUp)}>

                                    <div className={'mb-3'}>
                                        <label htmlFor="password" className="form-label">OTP({tempData?.email})</label>
                                        <div className={'form-group'}>
                                            <input
                                                type={'text'}
                                                className={'form-control'}
                                                {...register('token',{
                                                    required:{
                                                        value: true,
                                                        message: 'OTP is Required'
                                                    }
                                                })}
                                            />
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label">Enter your current Password</label>
                                        <div className="input-group input-group-merge">
                                            <input type={showPassword ? "text": "password"}
                                                   className={`form-control ${errors.password && 'is-invalid'}`}
                                                   placeholder="Enter your password"
                                                   {...register('password',{
                                                       required: '*Password is Required',
                                                       minLength: { value: 6, message: 'Your Password is too Short' },
                                                   })}
                                            />
                                            <div className="input-group-text"  onClick={()=> setShowPassword(!showPassword)}>
                                                <span className="password-eye"></span>
                                            </div>
                                            {/*  */}
                                            {errors.password  && (<div className="invalid-feedback">
                                                {errors.password.message}</div>)}
                                        </div>
                                    </div>

                                    <div className={'float-end'}>
                                        <input type={'submit'} value={'Link account'} className={'btn btn-primary'}/>
                                    </div>

                                </form>
                                :
                            <form  onSubmit={handleSubmit(signUp)}>
                                <div className="mb-3">
                                    <label htmlFor="emailaddress" className="form-label"> Email Address </label>
                                    <input
                                        className={`form-control ${ (errors.email || error_msg?.email) && 'is-invalid'}`}
                                        type="text" placeholder="Enter your email"
                                        {...register('email',{
                                            required: 'email is Required',
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "invalid email address"
                                            }
                                        })}
                                    />
                                    {errors.email  && (<div className="invalid-feedback">{errors.email.message}</div>)}
                                    {error_msg?.email   && (<div className="invalid-feedback">{error_msg?.email[0]}</div>)}
                                </div>

                                <div className="mb-3 row">
                                    <div className="col-md-6">
                                        <label htmlFor="fullname" className="form-label">First Name</label>
                                        <input
                                            className={`form-control ${(errors.first_name || error_msg?.first_name ) && 'is-invalid'}`}
                                            type="text" placeholder="Enter your first name"
                                            {...register('first_name',{
                                                required: '*Name is Required',
                                            })}
                                        />
                                        {errors.first_name  && (<div className="invalid-feedback">{errors.first_name.message}</div>)}
                                        {error_msg.first_name   && (<div className="invalid-feedback">{error_msg?.first_name[0]}</div>)}
                                    </div>

                                    <div className="col-md-6">
                                        <label htmlFor="fullname" className="form-label">Last Name</label>
                                        <input
                                            className={`form-control ${(errors.last_name || error_msg?.last_name ) && 'is-invalid'}`}
                                            type="text" placeholder="Enter your name"
                                            {...register('last_name',{
                                                required: '*last_name is required',

                                            })}
                                        />
                                        {errors.last_name  && (<div className="invalid-feedback">{errors.last_name.message}</div>)}
                                        {error_msg.last_name   && (<div className="invalid-feedback">{error_msg?.last_name[0]}</div>)}
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="fullname" className="form-label">Phone Number</label>
                                    <input
                                        className={`form-control ${(errors.phone_no || error_msg?.phone_no ) && 'is-invalid'}`}
                                        type="text"
                                        placeholder="Enter your phone number"
                                        {...register('phone_no',{
                                            required: '*Phone Number is Required',
                                            pattern: {
                                                value: /^[0-9]{11}$/,
                                                message: "invalid phone number"
                                            },
                                            maxLength: { value: 11, message: 'phone number is too long,11 digit allowed' },
                                            minLength: { value: 11, message: 'phone number is not complete,11 digit allowed' },
                                        })}
                                    />
                                    {errors.phone_no  && (<div className="invalid-feedback">{errors.phone_no.message}</div>)}
                                    {error_msg?.phone_no   && (<div className="invalid-feedback">{error_msg?.phone_no[0]}</div>)}
                                </div>


                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label">Password</label>
                                    <div className="input-group input-group-merge">
                                        <input type={showPassword ? "text": "password"}
                                               className={`form-control ${errors.password && 'is-invalid'}`}
                                               placeholder="Enter your password"
                                               {...register('password',{
                                                   required: '*Password is Required',
                                                   minLength: { value: 6, message: 'Your Password is too Short' },
                                               })}
                                        />
                                        <div className="input-group-text"  onClick={()=> setShowPassword(!showPassword)}>
                                            <span className="password-eye"></span>
                                        </div>
                                        {/*  */}
                                        {errors.password  && (<div className="invalid-feedback">
                                            {errors.password.message}</div>)}
                                    </div>
                                </div>


                                <div className="mb-3">
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className={`form-check-input ${errors.agree && 'is-invalid'}`}
                                            id="checkbox-signup"
                                            {...register('agree',{
                                                required: '*you must agree to the terms and condition',
                                            })}
                                        />
                                        <label className="form-check-label" htmlFor="checkbox-signup"> I accept <Link to="#0" className="text-muted">Terms and Conditions</Link></label>
                                        {errors.agree  && (<div className="invalid-feedback">{errors.agree.message}</div>)}
                                    </div>
                                </div>

                                <div className="mb-3 text-center">
                                    {
                                        loading ?
                                            <Spinner color="primary"/>
                                            :
                                            <button className="btn btn-primary" type="submit"> Sign Up </button>
                                    }
                                </div>
                                {!loading && success_msg && <SuccessAlert msg={success_msg}/>}
                                {!loading && error_msg && <ErrorAlert msg={error_msg}/> }
                            </form>
                        }

                    </div>
                    <Footer url={url.signIn} linkText={'Login'} callToActionText={'Already have an Account?'}/>
                </div>
            </AuthWrapper>
        </>
    )
}

export default MemberSignUp