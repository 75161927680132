import { useDispatch, useSelector } from "react-redux"
import { getRequest } from "../../../../config/redux/actions"
import { AppState } from "../../../../config/redux/reducers"
import { AuthState } from "../../../../config/redux/reducers/tmsAuthReducer"
import { types } from "../../../../config/redux/types"
import { remote_url } from "../../../../config/urls/remote"
import React from "react";
import {CustomModalWrapper} from "../../_components";

interface Props {
    show ? : boolean
    onClose : any

}
const ConfirmApproval:React.FC<Props> = ({show, onClose}) => {
    const {token, account_key,user,role_id,success_msg} = useSelector<AppState, AuthState>((state) => state.tmsAuthReducer)
    const dispatch = useDispatch()

        const submitForApproval = () => {
            
        const postData = {
            token,
            url: remote_url.profileApprovalReq,
            actionType : types.UPDATE_ACC_DETAILS,
            postData : [],
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            errorAction : types.SET_ERROR,
            accountKey: account_key,
            loading :{
                isLoading :true,
                isLoading_ : false,
                isLoading_t : false
            }
            }
            //
        dispatch(getRequest(postData))
    }

    if(success_msg){
        onClose(false)
    }
    return <>
    <div>
        {/* Standard modal */}
        <CustomModalWrapper  onClickFunc={onClose} modalTitle={'Thrift Account Approval'}>
            <div>
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-body">
                            You are about to submit your Thrift Account for approval
                            <div className={'alert alert-info'}>
                                <p>
                                    Before you proceed to submit your Thrift Account for approval, we want to extend a warm reminder to
                                    double-check all the details you've provided.
                                </p>

                                <p>
                                    Your account is a gateway to a world of financial opportunities, and ensuring accuracy now will pave the way for seamless transactions later.
                                    Take a moment to review each entry, from personal information to financial details, ensuring they are up-to-date and correctly entered.
                                    Your diligence not only expedites the approval process but also ensures your experience with Thrift is tailored to your needs from the start.
                                    Should you have any questions or need assistance, our support team is here to help.
                                </p>

                            </div>


                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" data-bs-dismiss="modal" onClick={()=> onClose(false)}>Close</button>
                            <button type="button" className="btn btn-primary" onClick={()=> submitForApproval() }>Proceed</button>
                        </div>
                    </div>
                </div>
            </div>
        </CustomModalWrapper>
      </div>
    </>
}


export default ConfirmApproval