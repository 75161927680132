import DashboardWrapper from "../DashboardWrapper";
import BreadCrumb from "../layouts/BreadCrumb";
import {useEffect} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import {postRequest, setGeneralAction} from "../../../config/redux/actions";
import {types} from "../../../config/redux/types";
import {useDispatch, useSelector} from "react-redux";
import {remote_url} from "../../../config/urls/remote";
import {AppState} from "../../../config/redux/reducers";
import {AuthState} from "../../../config/redux/reducers/tmsAuthReducer";
import {ExpenseReducerState} from "../../../config/redux/reducers/tmsExpenseReducer";
import {url} from "../../../config/urls";
import {VerificationComponent} from "../_components";

type UrlParams = {
    transaction_ref ?: string
    gateway_id: string
}



const ExpenseVerification = () => {
    const {token, account_key,user,role_id} = useSelector<AppState, AuthState>((state) => state.tmsAuthReducer)
    let {transaction_ref,gateway_id} = useParams<UrlParams>()
    const [searchParams] = useSearchParams();
    const {
        transaction_id,
        loading_,
        success_msg,
        error_msg
    } = useSelector<AppState, ExpenseReducerState>((state) => state.tmsExpenseReducer)

    const dispatch = useDispatch();


    //


    const paymentVerification = () => {
        if (transaction_ref) {
            let trans_id = searchParams.get('transaction_id') ?? '';
            let finalTransId = ''
            if(trans_id !== ''){
                dispatch(setGeneralAction(types.SET_TRANSACTION_EXPENSES_REF, {transaction_ref,trans_id:trans_id}))
                finalTransId = trans_id
            }
            else{
                finalTransId = transaction_id
            }
            // dispatch(setGeneralAction(types.SET_TRANSACTION_EXPENSES_REF, {transaction_ref,trans_id:trans_id}))
            // dispatch(setGeneralAction(types.CLEAR_EXPENSE, []))

            const postData = {
                token,
                url: remote_url.verifyExpenses,
                actionType: types.VERIFY_EXPENSE,
                postData: {
                    trans_ref: transaction_ref,
                    gateway_id,
                    trans_id:finalTransId,
                },
                secretData : {
                    email:user.email,
                    mac_id :user.mac_id,
                    r_id: role_id
                },
                errorAction: types.SET_ERROR,
                accountKey: account_key,
                loading: {
                    isLoading: false,
                    isLoading_: true,
                    isLoading_t: false
                }
            }
            dispatch(postRequest(postData))
        }
    }


    useEffect(() => {
        paymentVerification()
    }, [])


    return (
        <DashboardWrapper showWarning={false}>
            <BreadCrumb pageTitle={'Payment Verification'} main={'Expense'} current={'Verification'}/>

           <VerificationComponent
               transaction_ref={transaction_ref}
               loading_={loading_}
               success_msg={success_msg}
               error_msg={error_msg}
               url={url.expenses}
               verificationFunction={paymentVerification}
           />

        </DashboardWrapper>
    )
}

export default ExpenseVerification