import { appTxt } from "../../../config/strings"

interface Props {
    bg? : string,
    badgeBg?: string,
    iconBg ? : string,
    iconColor ? : string,
    icon ? : string,
    textColor? : string
    amount ?:number,
    text ? : string 
    bgTextColor ?: string,
    totalNo ? : number
}

const CardSummary: React.FC<Props> = ({bg='primary', badgeBg='info', iconBg='light-lighten',iconColor='white',icon='currency-ngn',
textColor='', amount=0, text='', bgTextColor='text-white', totalNo=''}) => {
    return <>
        <div className={`card widget-flat bg-${bg} text-${bgTextColor}`}>
            <div className="card-body">
                <div className="float-end">
                    <i className={`mdi mdi-${icon} widget-icon bg-${iconBg} rounded-circle text-${iconColor}`}></i>
                </div>
                <h5 className={`fw-normal mt-0 ${textColor} `} title="Revenue">{text}</h5>
                <h3 className={`mt-3 mb-3 ${textColor}`}> {amount.toLocaleString()}</h3>
                <p className="mb-0">
                    <span className={`badge bg-${badgeBg} me-1`}>
                        {/* <i className="mdi mdi-arrow-up-bold"></i>  */}
                        Total No :
                        </span>
                    <span className={`text-nowrap ${textColor}`}>{totalNo}</span>
                </p>
            </div>
        </div>
    
     </>
}


export default CardSummary