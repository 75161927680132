 // import model
// actions props
//types
//define state for reducer
// intial state
// create the reducers
// export


import { ActionProps } from "../actions";
import { types } from "../types";



export interface LayoutSettingsState{
    /**
     * color scheme
     * width
     * left side bar 
     */
 
  
    leftbarTheme :{
        left_sidebar_default : boolean,
        left_sidebar_light : boolean,
        left_sidebar_dark : boolean,
        //
    },
    leftbarLayout : {
        left_sidebar_fixed : boolean,
        left_sidebar_condensed : boolean,
        left_sidebar_scrollable : boolean,
    },
    dataLayoutMode : {
        data_layout_mode_fluid : boolean,
        data_layout_mode_boxed : boolean
    },
    layoutMode: {
        darkMode : boolean,
        lightMode :boolean
    }


}
//
const initialState = {


  leftbarTheme :{
        left_sidebar_default : false,
        left_sidebar_light : false,
        left_sidebar_dark : true,
        //
    },


    leftbarLayout : {
        left_sidebar_fixed : true,
        left_sidebar_condensed : false,
        left_sidebar_scrollable : false,
        
    },


    dataLayoutMode : {
        data_layout_mode_fluid : true,
        data_layout_mode_boxed : false
    },


    layoutMode: {
        darkMode : false,
        lightMode :true
    }

  
}


const tmsLayoutSettingsReducer = (state:LayoutSettingsState = initialState, action:ActionProps) => {
    //
    switch (action.type) {

        case types.UPDATE_APP_SETTINGS_LEFTBAR_THEME: 
        return {
            ...state,
            leftbarTheme :{
                left_sidebar_default : action?.payload?.left_sidebar_default,
                left_sidebar_light :action?.payload?.left_sidebar_light,
                left_sidebar_dark : action?.payload?.left_sidebar_dark
            }
        }

        case types.UPDATE_APP_SETTINGS_LEFTBAR_LAYOUT : 
        return {
            ...state,
            leftbarLayout : {
                left_sidebar_fixed : action.payload?.left_sidebar_fixed,
                left_sidebar_condensed : action.payload?.left_sidebar_condensed,
                left_sidebar_scrollable : action.payload?.left_sidebar_scrollable,
            }
         }

         case types.UPDATE_APP_SETTINGS_LAYOUT:
            return {
                ...state,
                dataLayoutMode: {
                    data_layout_mode_fluid : action.payload?.data_layout_mode_fluid,
                    data_layout_mode_boxed : action.payload?.data_layout_mode_boxed

                }
            }
        //
        case types.UPDATE_APP_LAYOUT_MODE:
            return {
                ...state,
                layoutMode: {
                    darkMode : action.payload?.darkMode,
                    lightMode :action.payload?.lightMode
                }
            }
        //
        case types.LOG_OUT:
            return {
                ...state,
            }
            
        default:
            return state;
    }

}

export default tmsLayoutSettingsReducer