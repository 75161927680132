import { ApexOptions } from "apexcharts";
import { SavingDashboardAnalyticModel } from "../../../../config/model/authModel";
import { appTxt } from "../../../../config/strings";


export const sampleData =  [10, 20, 5, 15, 10, 20, 15, 25, 20, 30, 25, 40, 30, 50, 35];



// ? TOP PERFORMING USER
export const savingsDashboardTopPerFormingUser : ApexOptions = {
			chart: {
				height: 150,
				type: 'bar',
				stacked: !0,
				sparkline:{
				enabled:false
				} },

			plotOptions: {
				bar: {
					horizontal: !1,
					columnWidth: '26%',
					dataLabels: { position: 'top' },
				},
			},
			dataLabels: {
				enabled: false,
				// offsetY: -24,
				// style: { fontSize: '12px', colors: ['#727cf5'] },
			},

			legend: { show: !1 },
			colors: ['#727cf5'],
			xaxis: {
				labels: { show: !1 },
				axisTicks: { show: !1 },
				axisBorder: { show: !1 },
			},
			yaxis: { labels: { show: !1 } },
			fill: {
				type: 'gradient',
				gradient: {
					inverseColors: !0,
					shade: 'light',
					type: 'horizontal',
					shadeIntensity: 0.25,
					gradientToColors: void 0,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 100, 100, 100],
				},
			},
			tooltip: {
				y: {
					formatter: function (e) {
						return appTxt.naira_ + e.toLocaleString()
					},
				},
			},

}

//? Configuration for Savings Monthly Report Chart
export const savingsMonthlyAreaGraphConfig : ApexOptions = {
    chart: { height: 309, type: 'area' },
    dataLabels: { enabled: !1 },
    stroke: { curve: 'smooth', width: 4 },
    legend: { show: !1 },
    colors:  ['#727cf5'],
    xaxis: {
        tooltip: { enabled: !1 },
        axisBorder: { show: !1 },
        labels: {},
    },
    yaxis: {
        labels: {
            formatter: function (e) {
                return appTxt.naira_ +  e.toLocaleString()
            },
            offsetX: -15,
        },
    },
    fill: {
        type: 'gradient',
        gradient: {
            type: 'vertical',
            shadeIntensity: 1,
            inverseColors: !1,
            opacityFrom: 0.45,
            opacityTo: 0.05,
            stops: [45, 100],
        },
    },
	
}

//?used to set total count of savings
export const savingsMonthlyBarGraphConfig : ApexOptions = {
    chart: { 
        height: 60, 
        type: 'bar', 
        // stacked: !1, 
        // stackType: '100%',
        toolbar: { show: !0 },
        sparkline: { enabled: !1 } 
    },
	plotOptions: { 
        bar: { 
            columnWidth: '25%' ,
            horizontal: !1
        } 
    },
    stroke: { width: 0, colors: ['#fff'] },
	xaxis: { crosshairs: { width: 0 } },
    yaxis: {
        labels: {
            formatter: function (e) {
                return e.toLocaleString()
                // return appTxt.naira_ +  e.toLocaleString()
            },
            // offsetX: -15,
        },
        // title: { text: 'User Savings (NGN)' },
        min: 0
        
    },
    colors: ['#727cf5'],
	tooltip: {
		fixed: { enabled: !1 },
		x: { show: !0 },
		y: {
			title: {
				formatter: function (o) {
					return ''
				},
			},
		},
		marker: { show: !1 },
	},
    fill: { opacity: 1 },
    legend: { position: 'top', horizontalAlign: 'center', offsetY: 10 },
    grid: { borderColor: '#f1f3fa', padding: { top: 0 } },
    responsive: [
        { breakpoint: 600, options: { yaxis: { show: !1 }, 
        legend: { show: !1 } } },
    ],
	dataLabels: {
		enabled: false
	}
}


//? BRANCH PERTFORMA
export const savingsDashboardBranchPerformanceReport : ApexOptions = {
	chart: { height: 268, type: 'radialBar' },
	plotOptions: {
	radialBar: {
		dataLabels: {
			name: { fontSize: '22px' },
			value: { fontSize: '16px' },
			total: {
				show: !0,
				// label: 'OS',
				// formatter: function (e) {
				// 	return e.a
				// },
			},
		},
	},
},
	colors: ["#727cf5","#0acf97","#fa5c7c","#ffbc00"],
	// series: [44, 55, 67, 83],
	// labels: ['Windows', 'Macintosh', 'Linux', 'Android'],
}


/*
datatables.net-bs5
datatables.net-responsive-bs5
   "datatables.net-bs5": "^1.12.1",
    "datatables.net-responsive-bs5": "^2.3.0",
//
    "datatables-buttons": "^1.0.3",
    "datatables.net": "^1.11.5",
    "datatables.net-bs5": "^1.11.5",
    "datatables.net-buttons": "^2.2.3",
    "datatables.net-buttons-bs5": "^2.2.2",
    "datatables.net-buttons-dt": "^2.2.3",
    "datatables.net-dt": "^1.12.1",
    "datatables.net-responsive": "^2.3.0",
    "datatables.net-responsive-bs": "^2.3.0",
    "datatables.net-responsive-bs5": "^2.3.0",
    "datatables.net-responsive-dt": "^2.3.0",
     "@types/datatables.net-buttons": "^1.4.7",
     "jquery": "^3.6.1",
 */