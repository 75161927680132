import {
    AccountTypeModel,
    AuditLogModel,
    BankTypeModel,
    ThriftBankAccModel,
    AuditLogProps,
    UsersModel,
    UsersProps,
    AccountTypeObject,
    CountryMOdel,
    BussinessRegTypesModel,
    NotificationModel,
    StatesModel,
    AccountDocument,
    SubscriptionPlanModel,
    SubscriptionPlanProps,
    SubscriptionTransactionModel,
    UserBranchModel,
    UserBranchProps,
    SubscriptionTransactionProp,
    PushNotificationPayloadModel,
    PushNotificationPayloadProps,
    PushNotificationDesignModel,
    FullUserDetails,
    FullUserDetailsProps,
    ThriftBankAccProps,
    SearchModel,
    SearchProps,
    QuickMenuModel,
    QuickMenuLinksProps,
    SavingsBankAccountsModel, PaymentChannel, AllUserRoles
} from "../../model/authModel";
import {ActionProps} from "../actions";
import {types} from "../types";
import {MembersSignUpFormValues} from "../../../pages/auth/MemberSignUp";


export interface AuthState {
    temp_form_data : MembersSignUpFormValues  |  null
    showOtpScreen : boolean,
    transaction_id:string,
    transaction_ref:string,
    showBankAccountForm : boolean,
    isAdminMode : boolean,
    quickMenu : QuickMenuModel,
    loginTime: number,
    currentTime: number,
    loading: boolean,
    loading_: boolean,
    loading_t?: boolean,
    success_msg: string,
    error_msg: any,
    token: string,
    thrift_accounts: UserBranchModel[],
    thrift_account: UserBranchModel,
    account_doucments: AccountDocument[],
    account_key: string,
    role_id: number,
    role_text: string,
    has_account_number: number,
    remember_me: boolean,
    banks: BankTypeModel[]
    thriftBankAccounts: ThriftBankAccModel[],
    thriftBankAccount : ThriftBankAccModel,
    newData: boolean, //USED TO MONITOR NEW DATA, ESPECIALLY ON DATA TABLE, IN ORDER TO RECREATE THE DATA
    newBankData : boolean,
    auditLogs: AuditLogModel[]
    auditLog: AuditLogModel,
    users: UsersModel[],
    user: UsersModel,
    isLogout: boolean,
    countries: CountryMOdel[],
    business_registration_types: BussinessRegTypesModel[],
    documents_types: BussinessRegTypesModel[],
    payment_channels: PaymentChannel[],
    gateways: PaymentChannel[],
    lockScreen: boolean,
    top_bar_notifications: NotificationModel[],
    has_loan_settings: number,
    allStates: StatesModel[],
    subscription_plans: SubscriptionPlanModel[],
    subscription_transactions: SubscriptionTransactionModel[]
    subscription_transaction: SubscriptionTransactionModel,
    showPushNotification: boolean,
    pushNotification: PushNotificationPayloadModel,
    pushNotificationDesigns: PushNotificationDesignModel[],
    logUserOut : boolean,
    userFullDetails:FullUserDetails,
    all_roles : AllUserRoles[],
    user_membership_status : AllUserRoles[],
    isAppOutdatedAction : {
        isAppOutdated : boolean,
        action : string
    },
    isMultipleDevice: boolean,
    searchData : SearchModel,
    searchLoading:boolean,
}

const initialState:AuthState = {
    showOtpScreen :false,
    temp_form_data : null,
    transaction_id:'',
    transaction_ref:'',
    showBankAccountForm : false,
    isAdminMode : false,
    quickMenu : QuickMenuLinksProps,
    loginTime: 0,
    currentTime: 0,
    loading: false,
    loading_: false,
    loading_t: false,
    searchLoading:false,
    success_msg: '',
    error_msg: '',
    //
    token: '',
    thrift_accounts: [],
    account_doucments: [],
    user_membership_status : [],
    all_roles: [],
    //selected account
    thrift_account: UserBranchProps,
    account_key: '',
    role_id: 0,
    role_text: '',
    has_account_number: 0,
    //end of selected account 
    remember_me: false,
    banks: [],
    thriftBankAccounts: [],
    thriftBankAccount : ThriftBankAccProps,
    newData: false,
    newBankData : false,
    auditLogs: [],
    auditLog: AuditLogProps,
    users: [],
    user: UsersProps,
    isLogout: false,
    countries: [],
    business_registration_types: [],
    documents_types: [],
    lockScreen: false,
    top_bar_notifications: [],
    has_loan_settings: 0,
    allStates: [],
    subscription_plans: [],
    subscription_transactions: [],
    subscription_transaction: SubscriptionTransactionProp,
    showPushNotification: false,
    pushNotification: PushNotificationPayloadProps,
    pushNotificationDesigns: [],
    logUserOut : false,
    userFullDetails : FullUserDetailsProps,
    isAppOutdatedAction :{
        isAppOutdated:  false,
        action : ''
    },
    payment_channels: [],
    gateways: [],
    isMultipleDevice : false,
    searchData : SearchProps,
}


const tmsAuthReducer = (state: AuthState = initialState, action: ActionProps) => {
    switch (action.type) {
        case types.SET_ADMIN_MODE:
            return {
                ...state,
                isAdminMode : action?.payload?.adminMode
            }

        case types.SET_SUB_TRANS_REF:
            return{
                ...state,
                transaction_id : action?.payload?.trans_id,
                transaction_ref : action?.payload?.transaction_ref,
            }

        case types.SEARCH_DATA:
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                searchLoading : false,
                success_msg: '',
                error_msg : '',
                searchData : action.payload?.data
            }

        case types.REJECT_INVITE :
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                success_msg: action.payload?.message
            }


        case types.SET_SUCCESS_MSG:
            return{
                ...state,
                success_msg: action.payload
            }


        case types.RESET_APP_STATE:
            return{
                ...state,
                isAppOutdatedAction: {
                    isAppOutdated:  false,
                    action:   ''
                }
            }

        case types.SET_APP_STATE:
            return{
                ...state,
                isAppOutdatedAction : {
                    isAppOutdated: action.isAppOutdatedAction?.isAppOutdated,
                    action : action.isAppOutdatedAction?.action
                }
            }
        case types.GET_USER_FULL_DETAILS:
            return{
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                userFullDetails : action.payload?.data
            }
        case types.GET_PUSH_NOTIFICATIONS_DESIGN:
            return {
                ...state,
                error_msg: '',
                loading: false,
                loading_: false,
                loading_t: false,
                pushNotificationDesigns: action.payload?.data
            }

        case types.SET_PUSH_NOTIFICATION:
            return {
                ...state,
                showPushNotification: true,
                pushNotification: action.payload
            }
        case types.CLEAR_PUSH_NOTIFICATION:
            return {
                ...state,
                showPushNotification: false,
                pushNotification: PushNotificationPayloadProps
            }

        case types.CREATE_PUSH_NOTIFICATION:
            return {
                ...state,
                success_msg: action.payload?.message,
                error_msg: '',
                loading: false,
                loading_: false,
                loading_t: false

            }

        case types.CREATE_EMAIL_SMS:
            return {
                ...state,
                success_msg: action.payload?.message,
                error_msg: '',
                loading: false,
                loading_: false,
                loading_t: false
            }


        case types.VERIFY_EMAIL:
            return {
                ...state,
                success_msg: action.payload?.message,
                error_msg: '',
                loading: false,
                loading_: false,
                loading_t: false
            }

        case types.CONFIRM_VERIFY_EMAIL:
            return {
                ...state,
                loading: false,
                loading_t: false,
                loading_: false,
                user: action.payload?.data,
                success_msg: action.payload?.message
            }

        case types.FORGOT_PASSWORD:
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                success_msg: action.payload?.message,
                error_msg: ''
            }

        case types.PASSWORD_RESET:
            return{
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                success_msg: action.payload?.message,
                error_msg: ''
            }

        case types.CLEAR_MSG:
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                success_msg: '',
                error_msg: ''
            }

        case types.LOGOUT_FROM_ALL_DEVICE :
            return {
                ...state,
                isMultipleDevice:false,
                loading: false,
                loading_: false,
                loading_t: false,
                success_msg: action.payload?.message,
                error_msg: ''
            }

        case types.MULTIPLE_DEVICE:
            return {
                ...state,
                isMultipleDevice : true
            }
        case types.CLEAR_ONBOARD_DATA:
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                success_msg: '',
                error_msg: ''
            }
        case types.SET_ACCOUNT_KEY:
            let role_id = Number(action.payload?.role_id)
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                success_msg: '',
                error_msg: '',
                //
                account_key: action.payload?.account_branch_key,
                role_id: role_id,
                role_text: action.payload?.role_text,
                has_account_number: action.payload?.has_account_number,
                //
                thrift_account: action.payload?.thrift_account,
                isAdminMode: [1, 2].includes(role_id)
            }
        //
        case types.SWITCH_ACCOUNT:
            let roleId = Number(action.payload?.role_id)
            return {
                ...state,
                account_key: action.payload?.account_branch_key,
                role_id: roleId,
                role_text: action.payload?.role_text,
                has_account_number: action.payload?.has_account_number,
                thrift_account: action.payload?.thrift_account,
                isAdminMode: [1, 2].includes(roleId)
            }
        //
        case types.SET_LOADING:
            return {
                ...state,
                loading: action.loading?.isLoading,
                loading_: action.loading?.isLoading_,
                loading_t: action.loading?.isLoading_t,
                searchLoading : action.loading?.searchLoading,
                success_msg: '',
                error_msg: ''
            }
        //
        case types.SET_ERROR:
            console.log(action.payload)
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                success_msg: '',
                error_msg: action.payload,
            }
        //
        case types.SIGN_UP:
            let  isOtpScreen = action.payload?.data?.isLinkAccount

            return {
                ...state,
                loading: false,
                success_msg: action.payload?.message,
                showOtpScreen : isOtpScreen,
                error_msg: '',
            }
        //
        case types.SIGN_IN:
            let ms = new Date();
            return {
                ...state,
                loading: false,
                isLogout: false,
                success_msg: action.payload?.message,
                error_msg: '',

                token: action.payload?.data.token,
                thrift_accounts: action.payload?.data.accounts,
                user: action.payload?.data.user,
                countries: action.payload?.data.countries,
                business_registration_types: action.payload?.data.business_registration_types,
                documents_types: action.payload?.data.documents_types,
                subscription_plans: action.payload?.data.subscription_plans,
                payment_channels: action.payload?.data.payment_channels,
                gateways: action.payload?.data.gateways,
                user_membership_status: action.payload?.data.user_membership_status,
                all_roles: action.payload?.data.all_roles,
                //
                loginTime: ms.getTime(),
                currentTime: ms.getTime(),
            }
        // 
        case types.ACTIVATE_LOCK_SCREEN :
            return {
                ...state,
                lockScreen: true
            }
        //
        case types.LOGIN_AGAIN:
            let ms_ = new Date();
            return {
                ...state,
                loading: false,
                isLogout: false,
                success_msg: action.payload?.message,
                error_msg: '',
                token: action.payload?.data.token,
                loginTime: ms_.getTime(),
                currentTime: ms_.getTime(),
                lockScreen: false,
                thrift_account: action.payload?.data.thrift_account
            }
        //SET_CURRENT_TIME
        case types.SET_CURRENT_TIME:
            let newD = new Date();
            return {
                ...state,
                loginTime: newD.getTime(),
                // lockScreen : false,
            }
        //CHANGE_PASSWORD
        case types.CHANGE_PASSWORD:
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                success_msg: action.payload?.message,
                user: action.payload?.data,
                isLogout: true
            }
        //UPDATE_PROFILE
        case types.UPDATE_PROFILE:
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                success_msg: action.payload?.message,
                user: action.payload?.data
            }
        //
        case types.GET_STATES:
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                success_msg: '',
                error_msg: '',
                allStates: action.payload?.data

            }
        //
        case types.GET_BANKS:
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                banks: action.payload?.data,
                success_msg: '',
                error_msg: '',
            }
        //
        case types.UPDATE_BANK_DETAILS_PREFERENCE:
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                newData:true,
                thriftBankAccount: action.payload?.data,
                success_msg: action.payload?.message,
                error_msg : ''
            }

        case types.SET_BANK_DETAILS :
            return {
                ...state,
                thriftBankAccount : action.payload
            }

        case types.CREATE_BANKS:
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                success_msg: action.payload?.message,
                error_msg: '',
                thriftBankAccounts: action.payload?.data,
                has_account_number: true,
                newBankData: true,
            }
        //
        case types.GET_THRIFTS_BANKS:
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                success_msg: action.payload?.message,
                error_msg: '',
                thriftBankAccounts: action.payload?.data,
                newBankData : true
            }

        case types.RESET_NEW_BANK_DATA_BOT:
            return {
                ...state,
                newBankData:false
            }

        case types.RESET_NEW_DATA_BOT:
            return {
                ...state,
                newData: false,
            }
        case types.GET_AUDIT_LOG:
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                success_msg: '',
                error_msg: '',
                newData: true,
                auditLogs: action.payload?.data.audits,
                users: action.payload?.data.users,
                auditLog: ''
            }
        case types.SET_AUDIT_LOG:
            return {
                ...state,
                auditLog: action.payload
            }
        //helps to set the has_loan_settings to true when this is filled, by tapping into the payload of this type
        case types.CREATE_LOANS_TYPES:
            return {
                ...state,
                thrift_account : action.payload?.thrift_account
            }
        case types.UPDATE_ACC_DETAILS:
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                success_msg: action.payload?.message,
                thrift_account: action.payload?.data,
                // role_id: action.payload?.data
                error_msg: '',
            }
        //
        case types.ADD_DOCUMENT:
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                success_msg: action.payload?.message,
                thrift_account: action.payload?.data.thrift_account,
                account_doucments: action.payload?.data.account_doucments
            }

        case types.GET_DOCUMENT:
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                account_doucments: action.payload?.data
            }

        case types.GET_NAVBAR_NOTIFICATIONS:
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                top_bar_notifications: action.payload?.data,
                quickMenu : QuickMenuLinksProps,
            }

        case types.GET_SUBSCRIPTION_PLAN:
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                subscription_plans: action.payload?.data,
                // thrift_account : action.payload?.thrift_account

            }
        //
        case types.GET_BRANCH_DETAILS:
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                thrift_account: action.payload?.data
            }

        case types.GET_SUBSCRIPTION_TRANSACTIONS:
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                subscription_transactions: action.payload?.data,
                newData: true

            }

        case types.SET_SINGLE_SUBSCRIPTION_TRANS_DETAILS :
            return {
                ...state,
                subscription_transaction: action.payload
            }

        //
        case types.VERIFY_SUBSCRIPTION :
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                success_msg: action.payload?.message,
                subscription_transaction: action.payload?.data.subscription_transaction,
                thrift_account: action.payload?.data.thrift_account
            }

        case types.UPGRADE_PLAN:
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                success_msg: action.payload?.message,
                error_msg: '',
                subscription_transaction: action.payload?.data
            }
        case types.GET_SAVINGS_ACCOUNT_DETAILS:
            return {
                ...state,
                savingsAccount:[]
            }

        //
        case types.LOG_OUT:
            return {
                ...state,
                loginTime: 0,
                currentTime: 0,
                isLogout: true,
                loading: false,
                loading_: false,
                loading_t: false,
                success_msg: action.payload?.message,
                error_msg: '',
                token: '',
                thrift_accounts: [],
                thrift_account: null,
                account_key: '',
                role_id: '',
                remember_me: false,
                banks: [],
                thriftBankAccounts: [],
                newData: false,
                auditLogs: [],
                auditLog: '',
                users: [],
                user: {},
                role_text: '',
                business_registration_types: [],
                countries: [],
                lockScreen: false,
                top_bar_notifications: [],
                has_loan_settings: 0,
                subscription_plans: [],
                subscription_transactions: [],
                documents_types: [],
                account_doucments: [],
                showPushNotification: false,
                allStates: [],
                subscription_transaction: SubscriptionTransactionProp,
                pushNotificationDesigns: [],
                logUserOut : false,
                isMultipleDevice : false,
                thriftBankAccount : ThriftBankAccProps,
                quickMenu : QuickMenuLinksProps,
                isAdminMode:false
            }
        //
        default:
            return state;
    }
}


export default tmsAuthReducer;