import {Spinner} from "../../../components";
import React from "react";
import {Link} from "react-router-dom";

interface Props{
    show : boolean,
    link:string,
    children : React.ReactNode,
    handleImportModal : any
}
const BulkImportWrapper:React.FC<Props> = ({show,children,handleImportModal,link}) => {


    return(
        <>
            <div style={{top:0,right:0,marginRight:0,marginLeft:'auto'}}>
                <div
                    className={`modal fade ${show ?'show':''}`}
                    id="bs-example-modal-lg"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="myLargeModalLabel"
                    aria-modal="true" style={{display:'block'}}>
                    <div className="modal-dialog modal-md" >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myLargeModalLabel"> Bulk Import </h4>

                                <button type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={()=>handleImportModal(false)}>
                                </button>

                            </div>

                            <div className="modal-body">
                                {children}
                            </div>

                            <div className="modal-footer">
                                <a
                                    href={`${process.env.REACT_APP_BACKEND_APP_URL}sample/${link}`}
                                    className={'text-center'}  download>
                                    Download Sample
                                </a>
                            </div>


                        </div>
                    </div>
                </div>

                {show && <div className={`modal-backdrop fade show`}></div> }

            </div>
        </>
    )
}
export default BulkImportWrapper