interface  ComponentProps {
    type : string,
    icon : string,
    msg : string
}

const WarningAlert:React.FC<ComponentProps> = ({type, icon,msg}) => {
    return <>
        <div className={`mt-3 alert alert-${type} alert-dismissible fade show`} role="alert">
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            <i className={`dripicons-${icon} me-2`}></i>
            {msg}
        </div>
    </>
}

export default  WarningAlert