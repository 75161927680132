import {remote_url} from "../../../../../config/urls/remote";
import {types} from "../../../../../config/redux/types";
import {postRequestFile} from "../../../../../config/redux/actions";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../../config/redux/reducers";
import {AuthState} from "../../../../../config/redux/reducers/tmsAuthReducer";
import {useForm} from "react-hook-form";
import {ErrorAlert} from "../../../../../components/alert";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import {ImagePreviewContainer} from "../../../../../components/form";


interface TabProps {
    // children : React.ReactNode
    setNextTab : any
}

interface DocumentProps{
    thrift_document : string
    proof_of_address : string
}

const Documents:React.FC<TabProps> = ({setNextTab}) =>{
    const dispatch  = useDispatch()
    //
    const { register, handleSubmit, formState: { errors }, reset } = useForm<DocumentProps>()
    //
    const {loading_,token,account_key,user,role_id,thrift_account,business_registration_types} = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
    //
    const [accDoc, setAccDoc] = useState({preview:''})
    const [proofAddressDoc, setProofAddressDoc] = useState({preview:''})
    const [docError, setDocError] = useState('')

    const handleDocumentUpload = (e:any) => {
        const size = e.target.files[0]['size']
        const type = e.target.files[0]['type'] //image/jpeg @image/png

        if (size <= 15000000) {
            if (type === 'image/jpeg' || type === 'image/png' || type==='image/jpg') {
                setDocError('')
                setAccDoc({preview: URL.createObjectURL(e.target.files[0])})
            } else {
                setDocError('this file type is not allowed')
            }
        } else {
            setDocError('image is too large')
        }
    }


    const handleProofUpload = (e:any) => {
        const size = e.target.files[0]['size']
        const type = e.target.files[0]['type'] //image/jpeg @image/png

        if (size <= 15000000) {
            if (type === 'image/jpeg' || type === 'image/png' || type==='image/jpg') {
                setDocError('')
                setProofAddressDoc({preview: URL.createObjectURL(e.target.files[0])})
            } else {
                setDocError('this file type is not allowed')
            }
        } else {
            setDocError('image is too large')
        }
    }

    const onBusinessRegFileForm = (data:DocumentProps) => {
        let thrift_documentFinalLogoFile = data.thrift_document!==undefined ? data.thrift_document[0] : ''
        let proofOfAddressFinalLogoFile = data.proof_of_address!==undefined ? data.proof_of_address[0]  : ''

        const reqData = new FormData()
        reqData.append('thrift_document',thrift_documentFinalLogoFile)
        reqData.append('proof_of_address',proofOfAddressFinalLogoFile)
        reqData.append('upload_type','documents')

        const postData = {
            token,
            url: remote_url.updateAccountDetails,
            actionType : types.UPDATE_ACC_DETAILS,
            postData : reqData,
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            errorAction : types.SET_ERROR,
            accountKey: account_key,
            loading :{
                isLoading :true,
                isLoading_ : false,
                isLoading_t : false
            }
            // reset
        }
        //
        dispatch(postRequestFile(postData))
    }


    // <ViewDocumentComponent
    //     url={thrift_account.account_branch.business_reg_document}
    //     name={'Business Reg Document'}
    //     size={'2.3 MB'}/>



    return (
       <>
           <div className={'row gap-2 py-4 px-3'}>
               <form onSubmit={handleSubmit(onBusinessRegFileForm)}>
                   <table className={'table'}>
                       <thead>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                       </thead>

                       <tbody>
                            <tr>
                                <td>
                                    Business Reg Document Upload (<small>{thrift_account.account_branch.business_registration_type}</small>)
                                </td>
                                <td>
                                    {
                                        thrift_account.account_branch.business_reg_document ?
                                            <ImagePreviewContainer img={thrift_account.account_branch.business_reg_document}/>
                                            :
                                            <div className={'col-md-12 shadow-md px-3  dropzone dz-clickable mb-3  mr-2'}>
                                                <div className="mb-3 mt-3 mt-xl-0">
                                                    <p className="text-muted font-14">Recommended image size 400x400 (px).</p>
                                                    {docError &&   <ErrorAlert msg={docError}/>}
                                                    <div className="fallback">
                                                        <>
                                                            <input
                                                                type="file"
                                                                className={`form-control ${errors.thrift_document && 'is-invalid'}`}
                                                                {...register('thrift_document',{
                                                                    required: 'a valid document is required',
                                                                    onChange : (e) => handleDocumentUpload(e)
                                                                })}
                                                                disabled= {Number(thrift_account?.account_branch?.can_edit) === 0}
                                                            />
                                                            {errors.thrift_document  && (<div className="invalid-feedback">{errors.thrift_document.message}</div>)}
                                                        </>
                                                    </div>
                                                    {accDoc.preview &&  <ImagePreviewContainer img={accDoc.preview}/>}
                                                </div>
                                            </div>
                                    }
                                </td>
                            </tr>

                           <tr>
                               <td>Proof of Address</td>
                               <td>
                                   {thrift_account.account_branch.proof_of_address ? <ImagePreviewContainer img={thrift_account.account_branch.proof_of_address}/>

                                   :
                                       <div className={'col-md-12 shadow-md px-3 py-4 dropzone dz-clickable mb-3'}>
                                           <div className="mb-3 mt-3 mt-xl-0">
                                               <p className="text-muted font-14">Recommended image size 400x400 (px).</p>
                                               {docError &&   <ErrorAlert msg={docError}/>}
                                               <div className="fallback">
                                                   {
                                                       <>
                                                           <input
                                                               type="file"
                                                               className={`form-control ${errors.proof_of_address && 'is-invalid'}`}
                                                               {
                                                                   ...register('proof_of_address',{
                                                                       required: 'a valid document is required',
                                                                       onChange : (e) => handleProofUpload(e)
                                                                   })
                                                               }
                                                               disabled= {Number(thrift_account?.account_branch?.can_edit) === 0}
                                                           />
                                                           {errors.proof_of_address  && (<div className="invalid-feedback">{errors.proof_of_address.message}</div>)}
                                                       </>
                                                   }
                                               </div>
                                               {proofAddressDoc.preview  && <ImagePreviewContainer img={proofAddressDoc.preview}/>}

                                           </div>
                                       </div>
                                   }

                               </td>
                           </tr>
                       </tbody>
                       <tfoot>
                           <tr>
                               <td></td>
                               <td>
                                   <div className={'float-end d-flex gap-2'}>
                                       <Link to={''} className={'btn btn-outline-success'}  onClick={()=>setNextTab('account_info')}>Back</Link>
                                       {/*&& thrift_account.account_branch.proof_of_address*/}
                                       {
                                           ( !thrift_account.account_branch.business_reg_document   || !thrift_account.account_branch.proof_of_address) &&
                                           <input type={'submit'} className={'btn btn-dark'} value={'Submit Document'}/>
                                       }
                                       <Link to={''} className={'btn btn-outline-success'} onClick={()=>setNextTab('information')}>Next</Link>
                                   </div>
                               </td>
                           </tr>
                       </tfoot>
                   </table>
               </form>



           </div>
       </>
    )
}

export default Documents