import DashboardWrapper from "../DashboardWrapper"
import BreadCrumb from "../layouts/BreadCrumb"



const SupportTicketDetail = ()  => {
    return <DashboardWrapper> 
    <BreadCrumb main="Support" current="Support Detail" pageTitle="Ticket Details" showToggle={false}/>
    {/*  */}
    <div className="card px-5"> 
            <div className="row">
        <div className="col-xxl-8 col-xl-7">
            
            <div className="card d-block">
                <div className="card-body">
                    <div className="dropdown card-widgets">
                        <a href="#" className="dropdown-toggle arrow-none" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className='uil uil-ellipsis-h'></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end">
                            
                            <a href="javascript:void(0);" className="dropdown-item">
                                <i className='uil uil-file-upload me-1'></i>Attachment
                            </a>
                            
                            <a href="javascript:void(0);" className="dropdown-item">
                                <i className='uil uil-edit me-1'></i>Edit
                            </a>
                            
                            <a href="javascript:void(0);" className="dropdown-item">
                                <i className='uil uil-file-copy-alt me-1'></i>Mark as Duplicate
                            </a>
                            <div className="dropdown-divider"></div>
                            
                            <a href="javascript:void(0);" className="dropdown-item text-danger">
                                <i className='uil uil-trash-alt me-1'></i>Delete
                            </a>
                        </div> 
                    </div> 
                    
                    <div className="form-check float-start">
                        <input type="checkbox" className="form-check-input" id="completedCheck"/>
                        <label className="form-check-label" htmlFor="completedCheck">
                            Mark as completed
                        </label>
                    </div> 
                    
                    <div className="clearfix"></div>

                    <h3 className="mt-3">Draft the new contract document for sales team</h3>

                    <div className="row">
                        <div className="col-6">
                            
                            <p className="mt-2 mb-1 text-muted fw-bold font-12 text-uppercase">Assigned To</p>
                            <div className="d-flex">
                                <img src="assets/images/users/avatar-9.jpg" alt="Arya S" className="rounded-circle me-2" height="24"/>
                                <div>
                                    <h5 className="mt-1 font-14">
                                        Arya Stark
                                    </h5>
                                </div>
                            </div>
                            
                        </div> 

                        <div className="col-6">
                            
                            <p className="mt-2 mb-1 text-muted fw-bold font-12 text-uppercase">Due Date</p>
                            <div className="d-flex">
                                <i className='uil uil-schedule font-18 text-success me-1'></i>
                                <div>
                                    <h5 className="mt-1 font-14">
                                        Today 10am
                                    </h5>
                                </div>
                            </div>
                            
                        </div> 
                    </div> 


                    <h5 className="mt-3">Overview:</h5>

                    <p className="text-muted mb-4">
                        This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer. Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up.
                    </p>

                    
                    <h5 className="mt-4 mb-2 font-16">Checklists/Sub-tasks</h5>
                    <div className="form-check mt-1">
                        <input type="checkbox" className="form-check-input" id="checklist1"/>
                        <label className="form-check-label strikethrough" htmlFor="checklist1">
                            Find out the old contract documents
                        </label>
                    </div>
                    
                    <div className="form-check mt-1">
                        <input type="checkbox" className="form-check-input" id="checklist2"/>
                        <label className="form-check-label strikethrough" htmlFor="checklist2">
                            Organize meeting sales associates to understand need in detail
                        </label>
                    </div>
                    
                    <div className="form-check mt-1">
                        <input type="checkbox" className="form-check-input" id="checklist3"/>
                        <label className="form-check-label strikethrough" htmlFor="checklist3">
                            Make sure to cover every small details
                        </label>
                    </div>
                    

                </div> 
                
            </div> 

            <div className="card">
                <div className="card-header">
                    <h4 className="my-1">Comments (51)</h4>
                </div>
                <div className="card-body">
                    
                    <div className="d-flex">
                        <img className="me-2 rounded-circle" src="/assets/images/users/avatar-3.jpg" alt="Generic placeholder" height="32"/>
                        <div className="w-100">
                            <h5 className="mt-0">Jeremy Tomlinson <small className="text-muted float-end">5 hours ago</small></h5>
                            Nice work, makes me think of The Money Pit.

                            <br/>
                            <a href="javascript: void(0);" className="text-muted font-13 d-inline-block mt-2"><i className="mdi mdi-reply"></i> Reply</a>

                            <div className="d-flex mt-3">
                                <a className="pe-2" href="#">
                                    <img src="/assets/images/users/avatar-4.jpg" className="rounded-circle" alt="Generic placeholder" height="32"/>
                                </a>
                                <div className="w-100">
                                    <h5 className="mt-0">Thelma Fridley <small className="text-muted float-end">3 hours ago</small></h5>
                                    i'm in the middle of a timelapse animation myself! (Very different though.) Awesome stuff.

                                    <br/>
                                    <a href="javascript: void(0);" className="text-muted font-13 d-inline-block mt-2">
                                        <i className="mdi mdi-reply"></i> Reply
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex mt-3">
                        <img className="me-2 rounded-circle" src="/assets/images/users/avatar-5.jpg" alt="Generic placeholder image" height="32"/>
                        <div className="w-100">
                            <h5 className="mt-0">Kevin Martinez <small className="text-muted float-end">1 day ago</small></h5>
                            It would be very nice to have.

                            <br/>
                            <a href="javascript: void(0);" className="text-muted font-13 d-inline-block mt-2"><i className="mdi mdi-reply"></i> Reply</a>
                        </div>
                    </div>

                    <div className="text-center mt-2">
                        <a href="javascript:void(0);" className="text-danger"><i className="mdi mdi-spin mdi-loading me-1"></i> Load more </a>
                    </div>

                    <div className="border rounded mt-4">
                        <form action="#" className="comment-area-box">
                            <textarea rows={3} className="form-control border-0 resize-none" placeholder="Your comment..."></textarea>
                            <div className="p-2 bg-light d-flex justify-content-between align-items-center">
                                <div>
                                    <a href="#" className="btn btn-sm px-1 btn-light"><i className='mdi mdi-upload'></i></a>
                                    <a href="#" className="btn btn-sm px-1 btn-light"><i className='mdi mdi-at'></i></a>
                                </div>
                                <button type="submit" className="btn btn-sm btn-success"><i className='uil uil-message me-1'></i>Submit</button>
                            </div>
                        </form>
                    </div> 

                </div> 
            </div>
            
        </div> 

        <div className="col-xxl-4 col-xl-5">

            <div className="card">
                <div className="card-body">
                    <h5 className="card-title mb-3">Attachments</h5>

                    <form action="/" method="post" className="dropzone" id="myAwesomeDropzone" data-plugin="dropzone" data-previews-container="#file-previews" data-upload-preview-template="#uploadPreviewTemplate">
                        <div className="fallback">
                            <input name="file" type="file"/>
                        </div>

                        <div className="dz-message needsclick">
                            <i className="h3 text-muted dripicons-cloud-upload"></i>
                            <h4>Drop files here or click to upload.</h4>
                        </div>
                    </form>

                    
                    <div className="dropzone-previews mt-3" id="file-previews"></div>

                    
                    <div className="d-none" id="uploadPreviewTemplate">
                        <div className="card mt-1 mb-0 shadow-none border">
                            <div className="p-2">
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <img data-dz-thumbnail="" src="#" className="avatar-sm rounded bg-light" alt=""/>
                                    </div>
                                    <div className="col ps-0">
                                        <a href="javascript:void(0);" className="text-muted fw-bold" data-dz-name=""></a>
                                        <p className="mb-0" data-dz-size=""></p>
                                    </div>
                                    <div className="col-auto">
                                        
                                        <a href="" className="btn btn-link btn-lg text-muted" data-dz-remove="">
                                            <i className="dripicons-cross"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    


                    <div className="card my-1 shadow-none border">
                        <div className="p-2">
                            <div className="row align-items-center">
                                <div className="col-auto">
                                    <div className="avatar-sm">
                                        <span className="avatar-title rounded">
                                            .ZIP
                                        </span>
                                    </div>
                                </div>
                                <div className="col ps-0">
                                    <a href="javascript:void(0);" className="text-muted fw-bold">Hyper-admin-design.zip</a>
                                    <p className="mb-0">2.3 MB</p>
                                </div>
                                <div className="col-auto">
                                    
                                    <a href="javascript:void(0);" className="btn btn-link btn-lg text-muted">
                                        <i className="dripicons-download"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-1 shadow-none border">
                        <div className="p-2">
                            <div className="row align-items-center">
                                <div className="col-auto">
                                    <img src="/assets/images/projects/project-1.jpg" className="avatar-sm rounded" alt="file-image"/>
                                </div>
                                <div className="col ps-0">
                                    <a href="javascript:void(0);" className="text-muted fw-bold">Dashboard-design.jpg</a>
                                    <p className="mb-0">3.25 MB</p>
                                </div>
                                <div className="col-auto">
                                    
                                    <a href="javascript:void(0);" className="btn btn-link btn-lg text-muted">
                                        <i className="dripicons-download"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-0 shadow-none border">
                        <div className="p-2">
                            <div className="row align-items-center">
                                <div className="col-auto">
                                    <div className="avatar-sm">
                                        <span className="avatar-title bg-secondary text-light rounded">
                                            .MP4
                                        </span>
                                    </div>
                                </div>
                                <div className="col ps-0">
                                    <a href="javascript:void(0);" className="text-muted fw-bold">Admin-bug-report.mp4</a>
                                    <p className="mb-0">7.05 MB</p>
                                </div>
                                <div className="col-auto">
                                    
                                    <a href="javascript:void(0);" className="btn btn-link btn-lg text-muted">
                                        <i className="dripicons-download"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    </div>
    
 
   
    
    </DashboardWrapper>
}


export default SupportTicketDetail




