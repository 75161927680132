import React from "react";
import DashboardWrapper from "../../../pages/admin/DashboardWrapper";
import {EditSavingForm, SavingsForm, SavingsImportForm} from "../../../pages/admin/savings/form";
import SavingsDetails from "../../../pages/admin/savings/SavingsDetails";
import BreadCrumb from "../../../pages/admin/layouts/BreadCrumb";
import {ErrorAlert, SuccessAlert} from "../../../components/alert";
import {AllThriftSavingsModel} from "../../../config/model/authModel";
import {useSelector} from "react-redux";
import {AppState} from "../../../config/redux/reducers";
import {SavingsReducerState} from "../../../config/redux/reducers/tmsSavingsReducer";


interface Props {
    children: JSX.Element,
    showSavingsForm: boolean,
    openSavingsForm:any,
    showSavingImportForm : boolean,
    setSavingImportForm : any,
    showEditForm  : string,
    setEditForm:any,
    currentPage : string
    // singleSavings: AllThriftSavingsModel
}

const SavingsWrapper: React.FC<Props> = ({children,showSavingsForm,
                         openSavingsForm,showSavingImportForm,setSavingImportForm,showEditForm,setEditForm,currentPage}) => {
    const {allThriftSavings,singleSavings,newDataBot,summary,isLoading_t,savingsFilter,error_msg,success_msg} = useSelector<AppState,SavingsReducerState>((state) =>state.tmsSavingsReducer)
    //
    return (
        <DashboardWrapper key="savings_all">
            <>
                {/*{showDeleteForm && <ConfirmSavingsDelete show={'show'} closeModal={setShowDeleteForm}/> }*/}
                {showSavingsForm && <SavingsForm show={showSavingsForm} showSavingFormFunc={openSavingsForm} />}
                {showSavingImportForm &&  <SavingsImportForm show={showSavingImportForm} handleImportModal={setSavingImportForm}/> }

                {showEditForm === 'show' && <EditSavingForm show={showEditForm} closeModalFunc={setEditForm}/>}
                <SavingsDetails show="" backdrop={true} saving={singleSavings}/>


                <BreadCrumb pageTitle={currentPage} main="Savings" current={currentPage}/>
                {error_msg &&  <ErrorAlert msg={error_msg}/> }
                {success_msg && <SuccessAlert msg={success_msg}/>}
                {children}
            </>
        </DashboardWrapper>
    )
}


export default SavingsWrapper