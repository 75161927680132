import { SavingsVerify,SavingsDashboard,Savings,SavingsSettings,  } from "../../pages/admin/savings"
import {url} from "../urls"
import AllSavings from "../../pages/admin/savings/AllSavings";


export const savingsRoute = [
  {
  url : url.savingsCallback,
  component: <SavingsVerify/>
  },
  
  {
    url: url.savingsDashboard,
    component : <SavingsDashboard/>
  },
  {
    url: url.savings,
    component :<Savings/>
  },
  {
    url: url.all_savings,
    component :<AllSavings/>
  },
  {
    url: url.savings_,
    component :<Savings/>
  },
  {
    url: url.savingsSettings,
    component :<SavingsSettings/>
  },
]




