import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { AppState } from "../../../config/redux/reducers"
import { AuthState } from "../../../config/redux/reducers/tmsAuthReducer"
import { adminRoute } from "../../../config/routes/adminRoute"

import { url } from "../../../config/urls"

import React from "react";




const Sidebar = () => {
    const {thrift_account,role_id} = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
    return <>
        <div className="leftside-menu" key={'1'}>
            {/* logo */}
            <Link to="#0" className="logo text-center logo-light">
                    <span className="logo-lg">
                        <img src="/assets/img/black.png" alt="" height="30"/>
                    </span>
                    <span className="logo-sm">
                        <img src="/assets/images/logo_sm.png" alt="" height="16"/>
                    </span>
            </Link>

            <Link to="#0" className="logo text-center logo-dark">
                    <span className="logo-lg">
                        <img src="/assets/img/light.png" alt="" height="30"/>
                    </span>
                <span className="logo-sm">
                        <img src="/assets/images/logo_sm_dark.png" alt="" height="16"/>
                    </span>
            </Link>




            <div className="h-100" id="leftside-menu-container" data-simplebar="">
                <ul className="side-nav">
                    {adminRoute.map((item,i=0) => <div key={i}>
                    
                    {
                        item.has_pre_title &&   item.role_id.includes(role_id) &&
                        <li className="side-nav-title side-nav-item" key={`pres_${i+1}}`}>
                            {item.pre_title_text}
                        </li> 
                    }


                    <li className="side-nav-item" key={`pre_children_${i+1}`} >
                        {item.has_children ? item.role_id.includes(role_id) &&
                         <div key={`${i+5}_dev`}>
                            <Link 
                            key={`pre_pre_children_children_${i+1}`}
                            data-bs-toggle="collapse" 
                            to={`#${item.id}`}
                            aria-expanded="false"
                            aria-controls={item.id} 
                            className={`side-nav-link ${item.isDisable ? 'disabled-link':''}`}

                            style={{pointerEvents:item.is_new && item.is_active ?  "none" : 'auto'}}
                            // style={{ "pointerEvents" : !item.is_new && !item.is_active ?  "none" : ''}}
                            >
                                <i className={item.icon_name}></i>
                                {!item.is_new && !item.is_active ?
                                    <span className="badge bg-secondary text-light float-end">soon</span>
                                    :
                                    ''
                                }

                                <span> {item.title} </span>
                                <span className="menu-arrow"></span>
                            </Link>

                            <div className="collapse" id={item.id} key={`role_${i+2}`}>
                                <ul className="side-nav-second-level">
                                    {item.children.map((sub_item,j=2) =>
                                        sub_item.role_id.includes(role_id) &&
                                    <li key={`children_h_${j+1}`}>
                                        <Link
                                            className={`${item.isDisable ? 'disabled-link':''}`}
                                            to={sub_item.route}>{sub_item.title}</Link>
                                    </li> 
                                    )}
                                </ul>
                            </div>
                         </div>
                        :
                           item.role_id.includes(role_id) &&
                            <Link
                                to={!item.is_new && !item.is_active ? '' : item.route}
                                 key={`role_${item.id}`}
                                  className={`side-nav-link ${item.isDisable ? 'disabled-link':''}`}

                            >
                                <i className={item.icon_name}></i>
                                {item.is_new && item.is_active ?
                                <span className="badge bg-secondary text-light float-end">New</span>
                                    :
                                    !item.is_new && !item.is_active
                                    ?
                                        <span className="badge bg-secondary text-light float-end">soon</span>
                                        :
                                        ""
                                }
                                {
                                    item.has_badge &&
                                    <span className={`badge ${item.badge_color}  text-light float-end`}>
                                        {item.badge_text}
                                    </span>
                                }
                                <span> {item.title} </span>
                            </Link>
                        }
                    </li>
                    </div> )}
                    
                </ul>





                {
                    [1,2].includes(role_id)  &&
                    (thrift_account?.account_branch?.account_branch_subscription_id === 1 || thrift_account?.account_branch?.account_branch_subscription_id === 2)
                
                && 
                <>
                 <div className="help-box text-white text-center">
                    <Link to="#0" className="float-end close-btn text-white">
                        <i className="mdi mdi-close"></i>
                    </Link>
                    <img src="/assets/images/help-icon.svg" height="90" alt="Helper Icon"/>
                        <h5 className="mt-3">Unlimited Access</h5>
                        <p className="mb-3">
                            Upgrade to plan to get access to unlimited reports</p>
                        <Link to={url?.pricing} className="btn btn-outline-light btn-sm">
                            Upgrade
                        </Link>
                </div>
                
                </> }
               
                <div className="clearfix"></div>
            </div>
        </div>
    </>
}


export default Sidebar