import {ApexOptions} from "apexcharts";


export {default as changePassword} from './ChangePassword'
 
export {default as MyAccountSettings} from './MyAccountSettings'

export {default as MyProfile} from './MyProfile'



export const savingsUserDashboardInit : ApexOptions = {
    chart: {
        height: 60,
        type: 'bar',
        // stacked: !1,
        // stackType: '100%',
        toolbar: { show: !0 },
        sparkline: { enabled: !1 }
    },
    plotOptions: {
        bar: {
            columnWidth: '25%' ,
            horizontal: !1
        }
    },
    stroke: { width: 0, colors: ['#fff'] },
    xaxis: { crosshairs: { width: 0 } },
    yaxis: {
        labels: {
            formatter: function (e) {
                return e.toLocaleString()
                // return appTxt.naira_ +  e.toLocaleString()
            },
            // offsetX: -15,
        },
        // title: { text: 'User Savings (NGN)' },
        min: 0

    },
    colors: ['#727cf5','#57BBD8'],
    tooltip: {
        fixed: { enabled: !1 },
        x: { show: !0 },
        y: {
            title: {
                formatter: function (o) {
                    return ''
                },
            },
        },
        marker: { show: !1 },
    },
    fill: { opacity: 1 },
    // legend: { position: 'top', horizontalAlign: 'center', offsetY: 10 },
    // grid: { borderColor: '#f1f3fa', padding: { top: 0 } },
    // responsive: [
    //     { breakpoint: 600, options: { yaxis: { show: !1 },
    //             legend: { show: !1 } } },
    // ],
}
