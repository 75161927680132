export enum types  {

    SET_SUB_TRANS_REF = 'SET_SUB_TRANS_REF',
    SET_TEMP_FORM_DATA = 'SET_TEMP_FORM_DATA',
    SET_ADMIN_MODE = 'SET_ADMIN_MODE',
    SET_DASHBOARD_FILTER = 'SET_DASHBOARD_FILTER',
    SET_APP_STATE= 'SET_APP_STATE',
    RESET_APP_STATE= 'RESET_APP_STATE',
    CLEAR_ONBOARD_DATA = "CLEAR_ONBOARD_DATA",
    SET_LOADING = "SET_LOADING",
    SET_SUCCESS_MSG = "SET_SUCCESS_MSG",

    SEARCH_DATA = "SEARCH_DATA",
    SET_VIRTUAL_ACCOUNT_MODAL = "SET_VIRTUAL_ACCOUNT_MODAL",
    //
    LOGIN_AGAIN = 'LOGIN_AGAIN',
    SET_CURRENT_TIME = 'SET_CURRENT_TIME',
    SIGN_UP = "SIGN_UP",
    SIGN_IN = "SIGN_IN",
    VERIFY_EMAIL = "VERIFY_EMAIL",
    CONFIRM_VERIFY_EMAIL = "CONFIRM_VERIFY_EMAIL",
    SET_ERROR = "SET_ERROR",
    LOG_OUT = "LOG_OUT",
    SET_ACCOUNT_KEY = "SET_ACCOUNT_KEY",
    FORGOT_PASSWORD = "FORGOT_PASSWORD",
    PASSWORD_RESET = "PASSWORD_RESET",
    //BANKS
    GET_BANKS = 'GET_BANKS',
    CREATE_BANKS = "CREATE_BANKS",
    GET_THRIFTS_BANKS = 'GET_THRIFTS_BANKS',
    SET_BANK_DETAILS = 'SET_BANK_DETAILS',
    UPDATE_BANK_DETAILS_PREFERENCE = 'UPDATE_BANK_DETAILS_PREFERENCE',

    REJECT_INVITE = 'REJECT_INVITE',
    //SAVINGS
    SET_SAVINGS_DASHBOARD_FILTER = 'SET_SAVINGS_DASHBOARD_FILTER',
    CREATE_SAVINGS = 'CREATE_SAVINGS',
    UPDATE_SAVINGS_DETAILS = 'UPDATE_SAVINGS_DETAILS',
    GET_SAVINGS = 'GET_SAVINGS',
    VERIFY_SAVINGS = "VERIFY_SAVINGS",
    REQUERY_SAVINGS = "REQUERY_SAVINGS",
    SET_TRANS_REF = 'SET_TRANS_REF',
    CLEAR_SAVING_DATA = 'CLEAR_SAVING_DATA',
    SET_SINGLE_SAVINGS = 'SET_SINGLE_SAVINGS',
    SET_SINGLE_EDIT_SAVINGS = 'SET_SINGLE_EDIT_SAVINGS',
    RESET_SAVINGS_BOT = "RESET_SAVINGS_BOT",
    SET_SAVINGS_FILTER = "SET_SAVINGS_FILTER",
    //LOANS
    SET_LOANS_DASHBOARD_FILTER = "SET_LOANS_DASHBOARD_FILTER",
    GET_LOANS_TYPES = 'GET_LOANS_TYPES',
    SET_LOANS_TYPE = 'SET_LOANS_TYPE',
    CREATE_LOANS_TYPES = 'CREATE_LOANS_TYPES',
    CREATE_LOAN_APPLICATION = 'CREATE_LOAN_APPLICATION',
    GET_LOAN_APPLICATION = 'GET_LOAN_APPLICATION',
    RESET_LOANS_BOT = 'RESET_LOANS_BOT',
    SET_LOANS_SAVINGS_REPAYMENT = 'SET_LOANS_SAVINGS_REPAYMENT',
    UPDATE_LOAN_APP_STATUS = 'UPDATE_LOAN_APP_STATUS',
    GET_LOAN_REPAYMENT_BREAKDOWN = 'GET_LOAN_REPAYMENT_BREAKDOWN',
    CLEAR_LOAN_MSG = 'CLEAR_LOAN_MSG',
    SET_LOAN_FILTERS = 'SET_LOAN_FILTERS',
    SET_LOAN_PAYMENT_DATA = 'SET_LOAN_PAYMENT_DATA',
    REPAY_LOAN = 'REPAY_LOAN', //
    VERIFY_LOANS = 'VERIFY_LOANS', //

    //:::::::::::::::::::::::::::::::::::::::::
    CREATE_NEW_EXPENSE = 'CREATE_NEW_EXPENSE',
    //EXPENSE
    GET_EXPENSES = 'GET_EXPENSES',
    SET_TRANSACTION_EXPENSES_REF = 'SET_TRANSACTION_EXPENSES_REF',
    VERIFY_EXPENSE = 'VERIFY_EXPENSE',
    //
    RESET_NEW_DATA_BOT = 'RESET_NEW_DATA_BOT',
    RESET_NEW_BANK_DATA_BOT = 'RESET_NEW_BANK_DATA_BOT',
    //AUDIT LOG
    GET_AUDIT_LOG = "GET_AUDIT_LOG",
    SET_AUDIT_LOG = "SET_AUDIT_LOG",

    //USERS 
    GET_USERS = 'GET_USERS',
    GET_USER = 'GET_USER',
    RESET_NEW_USERS = 'RESET_NEW_USERS',
    CREATE_USER = 'CREATE_USER',
    SET_MEMBER_FILTER = 'SET_MEMBER_FILTER',

    //
    GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA",
    RESET_DASHBOARD_BOT = "RESET_DASHBOARD_BOT",
    GET_SAVINGS_DASHBOARD_DATA = "GET_SAVINGS_DASHBOARD_DATA",
    GET_LOANS_DASHBOARD_DATA = "GET_LOANS_DASHBOARD_DATA",

    //TRANSACTION  MODULE 
    GET_TRANSACTIONS = 'GET_TRANSACTIONS',
    RESET_TRANSACTION_BOT = "RESET_TRANSACTION_BOT",



    MULTIPLE_DEVICE = "MULTIPLE_DEVICE",
    LOGOUT_FROM_ALL_DEVICE = "LOGOUT_FROM_ALL_DEVICE",
    CLEAR_MSG = "CLEAR_MSG",
    //SETTINGS
    UPDATE_ACC_DETAILS = "UPDATE_ACC_DETAILS",
    ADD_DOCUMENT = 'ADD_DOCUMENT',
    GET_DOCUMENT = 'GET_DOCUMENT',

    ACTIVATE_LOCK_SCREEN = 'ACTIVATE_LOCK_SCREEN',
    //
    GET_NAVBAR_NOTIFICATIONS = 'GET_NAVBAR_NOTIFICATIONS',
    GET_SAVINGS_ACCOUNT_DETAILS = 'GET_SAVINGS_ACCOUNT_DETAILS',


    //
    UPDATE_APP_SETTINGS_LEFTBAR_THEME = "UPDATE_APP_SETTINGS_LEFTBAR_THEME",
    UPDATE_APP_SETTINGS_LEFTBAR_LAYOUT = "UPDATE_APP_SETTINGS_LEFTBAR_LAYOUT",
    UPDATE_APP_SETTINGS_LAYOUT = "UPDATE_APP_SETTINGS_LAYOUT",
    UPDATE_APP_LAYOUT_MODE = "UPDATE_APP_LAYOUT_MODE",
    //
    GET_STATES = 'GET_STATES',
    // Branch
    CREATE_NEW_BRANCH = 'CREATE_NEW_BRANCH',
    GET_BRANCH = 'GET_BRANCH',

    SWITCH_ACCOUNT = 'SWITCH_ACCOUNT',

    CHANGE_PASSWORD = 'CHANGE_PASSWORD',
    UPDATE_PROFILE = 'UPDATE_PROFILE',
    'GET_USER_FULL_DETAILS' = 'GET_USER_FULL_DETAILS',

    GET_SUBSCRIPTION_PLAN = 'GET_SUBSCRIPTION_PLAN',
    GET_SUBSCRIPTION_TRANSACTIONS = 'GET_SUBSCRIPTION_TRANSACTIONS',
    UPGRADE_PLAN = 'UPGRADE_PLAN',
    SET_SINGLE_SUBSCRIPTION_TRANS_DETAILS = 'SET_SINGLE_SUBSCRIPTION_TRANS_DETAILS',
    VERIFY_SUBSCRIPTION = 'VERIFY_SUBSCRIPTION',
    GET_BRANCH_DETAILS = 'GET_BRANCH_DETAILS',

    SET_EXPENSE_FILTER = 'SET_EXPENSE_FILTER',
    SET_SINGLE_EXPENSE = 'SET_SINGLE_EXPENSE',
    CLEAR_EXPENSE = 'CLEAR_EXPENSE',

    CREATE_EMAIL_SMS = 'CREATE_EMAIL_SMS',
    CREATE_PUSH_NOTIFICATION = 'CREATE_PUSH_NOTIFICATION',
    GET_EXPENSE_ANALYTICS = 'GET_EXPENSE_ANALYTICS',
    //
    SET_PUSH_NOTIFICATION = 'SET_PUSH_NOTIFICATION',
    CLEAR_PUSH_NOTIFICATION = 'CLEAR_PUSH_NOTIFICATION',
    GET_PUSH_NOTIFICATIONS_DESIGN = 'GET_PUSH_NOTIFICATIONS_DESIGN',
    //SUPPORT TICKET
    GET_SUPPORT_TICKET_CONVERSATION = 'GET_SUPPORT_TICKET_CONVERSATION',
    CREATE_SUPPORT_TICKET_CONVERSATION = 'CREATE_SUPPORT_TICKET_CONVERSATION',
    SET_CONVERSATION_PAGE = "SET_CONVERSATION_PAGE",
    ADD_NEW_SUPPORT_TICKET = 'ADD_NEW_SUPPORT_TICKET',
    GET_SUPPORT_TICKET_MESSAGE = 'GET_SUPPORT_TICKET_MESSAGE',
    ADD_SUPPORT_TICKET_MESSAGE = 'ADD_SUPPORT_TICKET_MESSAGE',
    UPDATE_MSG_TEMP = 'UPDATE_MSG_TEMP',
}

//types  --> 