import {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {SubscriptionPlanModel, SubscriptionPlanProps} from "../../../../config/model/authModel"
import {calculateTime, getRequest, setGeneralAction} from "../../../../config/redux/actions"
import {AppState} from "../../../../config/redux/reducers"
import {AuthState} from "../../../../config/redux/reducers/tmsAuthReducer"
import {types} from "../../../../config/redux/types"
import {appTxt} from "../../../../config/strings"
import {remote_url} from "../../../../config/urls/remote"
import DashboardWrapper from "../../DashboardWrapper"
import BreadCrumb from "../../layouts/BreadCrumb"
import UpgradeSubModal from "./UpgradeSubModal"
import {DEFAULT_INACTIVE_TIME} from "../../../../config/config";

const Pricing = () => {

    const {
        token,
        account_key,
        subscription_plans,
        thrift_account,
        loginTime,
        user,
        role_id
    } = useSelector<AppState, AuthState>((state) => state.tmsAuthReducer)
    const dispatch = useDispatch()

    const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlanModel>(SubscriptionPlanProps)
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        let time_diff = calculateTime(loginTime)
        if (time_diff <= DEFAULT_INACTIVE_TIME) {
            getPlans()
            getAccountSub()
            getThriftAccountDetails()
        }
    }, [])


    const handleSelection = (item: SubscriptionPlanModel) => {
        setShowModal(true)
        dispatch(setGeneralAction(types.CLEAR_MSG, []))
        setSelectedPlan(item)
    }


    const getPlans = () => {
        const getData = {
            token,
            url: remote_url.subscriptionPlans,
            actionType: types.GET_SUBSCRIPTION_PLAN,
            postData: [],
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            errorAction: types.SET_ERROR,
            accountKey: account_key,
            loading: {
                isLoading: false,
                isLoading_: false,
                isLoading_t: true
            }
        }
        //
        dispatch(getRequest(getData))
    }

    /**gets all the branch **/
    const getAccountSub = () => {
        const postData = {
            token,
            url: remote_url.branches,
            actionType : types.GET_BRANCH,
            postData : [],
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            errorAction : types.SET_ERROR,
            accountKey: account_key,
            loading :{
                isLoading :false,
                isLoading_ : true,
                isLoading_t : false
            }
            // reset
        }
        dispatch(getRequest(postData))
    }

    const getThriftAccountDetails = () => {
        const postData = {
            token,
            url: remote_url.getThriftAccountDetails,
            actionType : types.UPDATE_ACC_DETAILS,
            postData : [],
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            errorAction : types.SET_ERROR,
            accountKey: account_key,
            loading :{
                isLoading :false,
                isLoading_ : false,
                isLoading_t : false
            }
        }
        dispatch(getRequest(postData))
    }


    return <DashboardWrapper showWarning={false}>
        {/* */}
        <BreadCrumb pageTitle="Pricing" main="Subscription" current="Pricing"/>
        <div className="row">

            <UpgradeSubModal
                plan={selectedPlan}
                setShowModalFunc={setShowModal}
                modalStatus={showModal}
            />

            <div className="col-xl-12 col-lg-12">
                <div className="card">
                    <div className="card-body">
                    <span className="float-start m-2 me-4">
                        <img src={thrift_account?.account_branch?.logo ?? '/assets/images/users/avatar-1.jpg'}
                             style={{height: '100px', width:'100px'}}
                             alt=""
                             className="rounded-circle img-thumbnail"/>
                    </span>
                        <div className="">
                            <h4 className="mt-1 mb-1">
                                {thrift_account.account_branch?.account_branch_subscription?.subscription?.sub_name} Pack {''}
                                {thrift_account.account_branch.account_branch_subscription.status=== 1 ?
                                    <small className="badge bg-success">active</small>
                                    :
                                    <small className="badge bg-danger">expired</small>}
                            </h4>
                            <p className="font-13">
                                <small className={'text-primary'}>Start Date: </small>
                                <b>{thrift_account?.account_branch?.account_branch_subscription?.start_date}</b>
                                <span>{" "}</span>
                                <small className={'text-primary'}>End Date: </small>
                                <b>{thrift_account?.account_branch?.account_branch_subscription?.end_date}</b>
                                <span></span>
                                {/*<small className="font-13 text-muted text-info">available resources for your thrift account </small>*/}
                            </p>

                            <ul className="mb-0 list-inline">
                                <li className="list-inline-item me-3">
                                    <div className="card py-2 px-3">
                                        <h5 className="mb-1">{thrift_account.account_branch?.account_branch_subscription?.sms ?? 'Unlimited'}</h5>
                                        <p className="mb-0 font-13">SMS</p>
                                    </div>
                                </li>
                                <li className="list-inline-item me-3">
                                    <div className="card py-2 px-3">

                                        <h5 className="mb-1">{thrift_account?.account_branch?.account_branch_subscription?.email ?? 'Unlimited'}</h5>
                                        <p className="mb-0 font-13">Email</p>
                                    </div>
                                </li>
                                <li className="list-inline-item">
                                    <div className="card py-2 px-3">
                                        <h5 className="mb-1">{thrift_account?.account_branch?.account_branch_subscription?.branch ?? 'Unlimited'}</h5>
                                        <p className="mb-0 font-13"> Branch </p>
                                    </div>

                                </li>
                                <li className="list-inline-item">
                                    <div className="card py-2 px-3">

                                        <h5 className="mb-1">{thrift_account?.account_branch?.account_branch_subscription?.members ?? 'Unlimited'}</h5>
                                        <p className="mb-0 font-13">Members </p>
                                    </div>
                                </li>
                                <li className="list-inline-item">
                                    <div className="card py-2 px-3">
                                        <h5 className="mb-1">{thrift_account?.account_branch?.account_branch_subscription?.push_notification ?? 'Unlimited'}</h5>
                                        <p className="mb-0 font-13"> Notification </p>
                                    </div>
                                </li>
                            </ul>

                        </div>
                        {/* end div*/}
                    </div>
                    {/* end card-body*/}
                </div>
            </div>


        </div>
        {/*pricing*/}
        <div className="card p-2">
            <div className="row justify-content-center">
                <div className="col-xxl-10">
                    {/* Pricing Title*/}
                    {/* <div className="text-center">
                    <h3 className="mb-2">Our Plans and Pricing</h3>
                    <p className="text-muted w-50 m-auto">  We have plans and prices that fit your business perfectly. Make your client site a success with our products.</p>
                </div> */}
                    {/* Plans */}
                    <div className="row mt-sm-5 mt-3 mb-3">
                        {subscription_plans.map((item, i = 1) =>
                            <div className="col-md-4" key={i}>
                                <div className={`card card-pricing 
                                    ${thrift_account?.account_branch?.account_branch_subscription.subscription_id === item.id && 'card-pricing-recommended'} `}>
                                    <div className="card-body text-center">
                                        {thrift_account?.account_branch?.account_branch_subscription.subscription_id === item.id &&
                                            <div className="card-pricing-plan-tag">
                                                {thrift_account.account_branch.account_branch_subscription.status===1 ?'Active' : 'Expired'}
                                            </div>
                                        }

                                        <p className="card-pricing-plan-name fw-bold text-uppercase">{item.sub_name} Pack</p>
                                        <i className="card-pricing-icon dripicons-briefcase text-primary"/>
                                        <h2 className="card-pricing-price">{appTxt.naira_} {Number(item.amount).toLocaleString()}
                                            <span> / Month</span></h2>
                                        <ul className="card-pricing-features">
                                            <li>{item.branch} TMS Branch</li>
                                            <li>{item.members } TMS Members</li>

                                            {/*Dynamic Content*/}
                                            {item?.subscription_details?.map((plan)=>
                                                <li>{
                                                    plan?.value ?
                                                    <span className={'uil uil-check  text-success h-6'}></span>
                                                    : <span className={'uil uil-times text-danger'}></span>
                                                }
                                                    {plan?.label}
                                                </li>
                                            )}


                                            {/*<li>{item.sms ? item.sms : 'Unlimited'} SMS</li>*/}
                                            {/*<li>{item.email ? item.email : 'Unlimited'} Email</li>*/}
                                            {/*<li>{item.push_notification || item.push_notification=== 0 ? item.push_notification : 'Unlimited'} Push Notification</li>*/}
                                            <li>24x7 Support</li>
                                        </ul>
                                        {
                                            thrift_account?.account_branch?.account_branch_subscription_id === item.id
                                            &&
                                            thrift_account?.account_branch?.account_branch_subscription?.status === 1
                                                ?
                                                <button
                                                    className="btn btn-primary mt-4 mb-2 btn-rounded"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#bs-example-modal-lg"
                                                    disabled={
                                                        ( thrift_account?.account_branch?.account_branch_subscription?.subscription_id === 1 &&
                                                        thrift_account?.account_branch?.account_branch_subscription?.status === 1)
                                                        // ||
                                                        // (
                                                        //     thrift_account?.account_branch?.account_branch_subscription.subscription_id !== 1 &&
                                                        //     thrift_account?.account_branch?.account_branch_subscription?.status === 1
                                                        // )
                                                    }
                                                    onClick={() => setSelectedPlan(item)}
                                                >
                                                    Buy
                                                </button>
                                                :
                                                <button
                                                    onClick={() => handleSelection(item)}
                                                    className="btn btn-primary mt-4 mb-2 btn-rounded"
                                                    type="button"
                                                    data-bs-toggle="offcanvas"
                                                    data-bs-target="#offcanvasExample"
                                                    aria-controls="offcanvasExample"

                                                >
                                                    Upgrade
                                                </button>
                                        }


                                    </div>
                                </div>
                                {/* end Pricing_card */}
                            </div>
                        )}


                    </div>
                    {/* end row */}
                </div>
                {/* end col*/}
            </div>
        </div>

    </DashboardWrapper>
}


export default Pricing