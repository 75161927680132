import { MembersModel, MembersProps, UsersModel, UsersProps, UserSummaryModel } from "../../model/authModel";
import { ActionProps } from "../actions";
import { types } from "../types";





export interface MemebersState{
 loading : boolean,
    loading_ : boolean,
    loading_t ? :boolean,
    success_msg  : string ,
    error_msg : string,
    users : MembersModel[],
    summary :UserSummaryModel[],
    userDetails : MembersModel,
    newDataBot: boolean,
    memberFilter : {
        status : string,
        role : string,
        branch : string
    }
} 


const initialState = {
    loading:false,
    loading_: false,
    loading_t : false,
    success_msg  : '',
    error_msg : '',
    users : [],
    userDetails: MembersProps,
    summary : [],
    newDataBot : false,
    memberFilter : {
        status : 'all',
        role : 'all',
        branch : ''
    }
}

const tmsMemeberReducerState = (state:MemebersState = initialState , action: ActionProps ) => {
    switch (action.type) {

        case types.SET_MEMBER_FILTER:
            return {
                ...state,
                memberFilter : action.payload
            }

        
        case types.CLEAR_MSG:
            return{
                ...state,
                loading:false,
                loading_:false,
                loading_t:false,
                success_msg : '',
                error_msg : ''
            }

        case types.SET_ERROR:
            return{
                ...state,
                loading : false,
                loading_ : false,
                loading_t : false,
                success_msg : '',
                error_msg : action.payload
            }

           case types.SET_LOADING:
            return {
                ...state,
                loading:action.loading?.isLoading,
                loading_ :action.loading?.isLoading_,
                loading_t : action.loading?.isLoading_t,
                success_msg : '',
                error_msg : ''
        }

        case types.CREATE_SAVINGS:
            //in some case users data will be returned when savings are created from members page
            return {
                ...state,
                loading : false,
                loading_ : false,
                loading_t : false,
                users: action.payload?.data?.users ? action.payload?.data?.users : state.users,
                newDataBot:true
            }

        case types.GET_USERS:
            return{
                ...state,
                loading : false,
                loading_ : false,
                loading_t : false,
                users: action.payload?.data?.users,
                summary : action.payload?.data?.summary,
                newDataBot:true
            }
        case types.GET_USER:
            return{
                ...state,
                loading : false,
                loading_ : false,
                loading_t : false,
                userDetails: action.payload,
                newDataBot:false
            }
        case types.CREATE_USER:
            return {
                ...state,
                loading : false,
                loading_ : false,
                loading_t : false,
                success_msg : action.payload?.message,
                error_msg : '',
                users: action.payload?.data.users,
                summary : action.payload?.data.summary,
                newDataBot:true
            }
        
        case types.RESET_NEW_USERS:
            return{
                ...state,
                newDataBot: false
            }

        case types.LOG_OUT:
            return{
                ...state,
                loading : false,
                loading_ : false,
                loading_t : false,
                success_msg : '',
                error_msg : '',
                users : [],
                userDetails : '',
                newDataBot : false,
                summary : []
            }
         //
        default:
            return state;
    }


}



export default tmsMemeberReducerState