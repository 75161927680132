import React from "react";


type profileProps = {
    profile_url : string ,
    email : string,
    name : string,
    summaryText : string,
    summaryValue : string | number,
    showDesc ? : boolean
}

const ProfileDetailCard:React.FC<profileProps> = ({profile_url,email,name,summaryText,summaryValue,showDesc=false}) => {
    return <>
        <div className="mt-1 mb-3 text-center">
            <img src={profile_url } alt="imag"  className="img-thumbnail avatar-lg rounded-circle"/>
            <h4>{name}</h4>

            <button className="btn btn-primary btn-sm mt-1">
                <i className="uil uil-envelope-add me-1"/>
            </button>
            &nbsp;

            <button className="btn btn-info btn-sm mt-1">
                <i className="uil uil-phone-alt me-1"/>
            </button>
            {
                showDesc &&
                <p className="text-muted mt-2 font-14">
                    {summaryText}: <strong>{summaryValue}</strong>
                </p>
            }


        </div>
    </>
}

export default  ProfileDetailCard