import React from "react";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../config/redux/reducers";
import {RootState} from "../../../_config/redux/store";
import {
    setSettlementFilter
} from "../../../_config/redux/feacture/settlementsReconcilations/settlementReconciliationSlice";
import {Spinner} from "../../../../components";


interface FormValues {
    from_date : string
    to_date : string
    status : string
}
const SettlementFilter = ()=>  {
    const dispatch = useDispatch()
    const {settlement_filter,settlement_loading} = useSelector((state:RootState)=>state.settlementReconciliation) ?? null
    const { register, handleSubmit, getValues ,formState: { errors },reset } = useForm<FormValues>()
    const handleChange = (data:FormValues) => {
        dispatch(setSettlementFilter(data))
    }

    return(
        <div className="row mb-2 ">
            <div className="col-xl-10">
                <form className="row gy-2 gx-2 align-items-center justify-content-xl-start justify-content-between" onSubmit={handleSubmit(handleChange)}>

                    <div className="col-auto">
                        <div className="d-flex align-items-center">
                            <label htmlFor="status-select" className="me-2"> From </label>
                            <input
                                defaultValue={settlement_filter?.from_date}
                                type={'date'} className={'form-control'} {...register('from_date')}/>
                        </div>
                    </div>

                    <div className="col-auto">
                        <div className="d-flex align-items-center">
                            <label htmlFor="status-select" className="me-2"> Date </label>
                            <input
                                defaultValue={settlement_filter?.to_date}
                                type={'date'} className={'form-control'} {...register('to_date')}/>
                        </div>
                    </div>

                    <div className="col-auto">
                        <div className="d-flex align-items-center">
                            <label htmlFor="status-select" className="me-2"> Status</label>
                            <select
                                className="form-select"
                                defaultValue={settlement_filter?.status}
                                {...register('status')}
                                id="status-select">
                                {/*success, processing, pending or failed*/}
                                <option value={''}> Select Status</option>
                                <option value={'1'}> success</option>
                                <option value={'2'}> processing</option>
                            </select>
                        </div>
                    </div>


                    <div className="col-auto">
                        {
                            settlement_loading ? <Spinner color={'primary'}/> :
                                <button type="submit" className="btn btn-outline-primary"><i className="mdi mdi-sea me-1" /> Search</button>
                        }

                    </div>





                </form>
            </div>

        </div>
    )
}


export default SettlementFilter