import DashboardWrapper from "../DashboardWrapper";
import BreadCrumb from "../layouts/BreadCrumb";
import {VerificationComponent} from "../_components";
import {url} from "../../../config/urls";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../config/redux/reducers";
import {LoansReducerState} from "../../../config/redux/reducers/tmsLoansReducer";
import {postRequest, setGeneralAction} from "../../../config/redux/actions";
import {types} from "../../../config/redux/types";
import {remote_url} from "../../../config/urls/remote";
import {useParams, useSearchParams} from "react-router-dom";

import {AuthState} from "../../../config/redux/reducers/tmsAuthReducer";

export type UrlParams = {
    transaction_reference : string
    gateway_id: string
}
const LoanVerify = () => {
    const {token,account_key,user,role_id} = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
    const {success_msg,loading_,error_msg,transaction_id} = useSelector<AppState,LoansReducerState>((state) => state.tmsLoansReducer)
    const [searchParams] = useSearchParams();
    let {transaction_reference,gateway_id} = useParams<UrlParams>()
    const dispatch = useDispatch()

    useEffect(()=> {
        setLoansRef()
    },[])


    const setLoansRef = () => {
        if(transaction_reference){
            let trans_id = searchParams.get('transaction_id') ?? '';
            let finalTransId = ''
            if(trans_id !== ''){
                dispatch(setGeneralAction(types.SET_TRANS_REF,{transaction_reference,trans_id}))
                finalTransId = trans_id
            }
            else{
                finalTransId = transaction_id
            }


            const postData = {
                token,
                url: remote_url.verifyLoans,
                actionType : types.VERIFY_LOANS,
                postData : {
                    transRef:transaction_reference,
                    gateway:gateway_id,
                    transaction_id: finalTransId
                },
                secretData : {
                    email:user.email,
                    mac_id :user.mac_id,
                    r_id: role_id
                },
                errorAction : types.SET_ERROR,
                accountKey: account_key,
                loading :{
                    isLoading : false,
                    isLoading_ : true
                }
            }
            dispatch(postRequest(postData))
        }
    }




    return <DashboardWrapper showWarning={false}>
        <BreadCrumb pageTitle={'Page'} main={'Page'} current={'Page'}/>
        <>
            {
                transaction_reference &&
                <VerificationComponent
                    transaction_ref={transaction_reference}
                    loading_={loading_}
                    success_msg={success_msg}
                    error_msg={error_msg}
                    verificationFunction={setLoansRef}
                    url={url.loansApp}
                />
            }
        </>


    </DashboardWrapper>
}

export default  LoanVerify