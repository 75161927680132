import {useSelector} from "react-redux";
import {AppState} from "../../../../config/redux/reducers";
import {ExpenseReducerState} from "../../../../config/redux/reducers/tmsExpenseReducer";
import React, {useState} from "react";
import {BankDetailsWrapperComponent} from "../../_components";
import {AuthState} from "../../../../config/redux/reducers/tmsAuthReducer";
import {SuccessAlert} from "../../../../components/alert";

interface Props {
    dispatch :any
    isLoading : boolean
    requery : any
}
const ViewExpense:React.FC<Props> = ({dispatch,isLoading,requery}) => {
    const {success_msg } = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
    const {expense} = useSelector<AppState,ExpenseReducerState>((state)=>state.tmsExpenseReducer)
    const [loading, setLoading] = useState(false)

    const payNow = () => {
        setLoading(true)
        window.location.href = expense.initialized_url
    }
    return <>

        {success_msg && <SuccessAlert msg={success_msg}/>}

        <div className={'table-responsive'}>
            <table className={'table table-center table-condensed'}>
                <thead></thead>
                <tbody>


                <tr>
                    <td>Transaction Reference</td>
                    <td>{expense.transaction_reference}</td>
                </tr>

                <tr>
                    <td>amount</td>
                    <td>{expense.amount}</td>
                </tr>
                <tr>
                    <td>transaction type</td>
                    <td>{expense.transaction_type}</td>
                </tr>
                <tr>
                    <td>purpose</td>
                    <td>{expense.purpose}</td>
                </tr>
                <tr>
                    <td>category</td>
                    <td>{expense.category===0 ? 'Thrift': 'Member' }</td>
                </tr>

                <tr>
                    <td>channel</td>
                    <td>{expense.channel }</td>
                </tr>

                <tr>
                    <td>Status</td>
                    <td>
                        {
                            expense.payment_status === 0  ?
                                <div className={'badge bg-danger'}>Unpaid</div>
                                :
                                <div className={'badge bg-success'}>Paid</div>
                        }
                    </td>
                </tr>
                {
                    expense.payment_status === 0 && expense.channel === 'card' &&
                    <tr>
                        <td>-</td>
                        <td>

                                <div className={'btn btn-primary'} onClick={() => payNow()}>Pay Now</div>

                        </td>
                    </tr>
                }
                </tbody>


            </table>
            {
                Number(expense.payment_status)===0 && expense.channel==='bank' &&
                <BankDetailsWrapperComponent
                    bank_name={expense.bank_name}
                    account_number={expense.account_number}
                    amount_paid={expense.amount_paid}
                    account_name={expense.account_name}
                    expires_at={expense.expires_at}
                    dispatch={dispatch}
                    loading={isLoading}
                    requeryNow={requery}
                    showBtn={Number(expense.payment_status)===0}
                    />
            }

        </div>

    </>
}

export default ViewExpense