import {TableWrapper} from "../../_component";
import SettlementFilter from "../filters/SettlementFilter";
import {inAppSettlementDatatableConfig} from "../../../_config/dataTableConfig";
import {resetDatatable} from "../../../_config/redux/feacture/settlementsReconcilations/settlementReconciliationSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../_config/redux/store";
import {useEffect} from "react";

const BankSettlement = () => {
    const dispatch = useDispatch()
    const {settlements_bank, current_gateway,isNewSettlementData} = useSelector((state:RootState)=>state.settlementReconciliation)

    const initializeDataTable = () => {
        //&& current_gateway === '3'
        inAppSettlementDatatableConfig(settlements_bank,'bank_settlement_table',[1, 'desc'],[])
        dispatch(resetDatatable({}))
    }


    if(isNewSettlementData){
       initializeDataTable()
    }


    useEffect(() => {
        initializeDataTable()
    }, []);


    return (<>
        <TableWrapper
            id={'bank_settlement_table'}
            tableHeaders={["Reference", "Acc No", "Bank Name", "Acc Name", "Amount Settled", "Settlement Date", "Action"]}
        >
            <SettlementFilter/>
        </TableWrapper>
    </>)
}
export default BankSettlement