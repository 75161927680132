import {useSelector} from "react-redux";
import {AppState} from "../../../../../config/redux/reducers";
import {AuthState} from "../../../../../config/redux/reducers/tmsAuthReducer";
import {Link} from "react-router-dom";
import {ImagePreviewContainer} from "../../../../../components/form";
import React, {useState} from "react";
import ConfirmApproval from "../ConfirmApproval";

const PreviewData = () => {
    const [showConfirmModal,setConfirmModal] = useState(false)
    const {gateways,thrift_account,loading_,token,
        account_key,success_msg,error_msg,countries,allStates,business_registration_types,user,role_id} = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)

    const handleApprovalModal = (item:boolean) => {
        setConfirmModal(item)
    }

    return(
        <div className={'p-sm-5'}>
            {showConfirmModal &&  <ConfirmApproval onClose={setConfirmModal}/> }
            <table className={'table table-borderless'}>
                <thead>

                </thead>

                <tbody>
                    <tr>
                        <td>Cooperative Document</td>
                        <td><ImagePreviewContainer img={thrift_account.account_branch.business_reg_document}/></td>
                    </tr>

                    <tr>
                        <td>Means of  Identification </td>
                        <td><ImagePreviewContainer img={user.id_document}/></td>
                    </tr>

                    <tr>
                        <td>Proof of Address</td>
                        <td><ImagePreviewContainer img={thrift_account.account_branch.proof_of_address}/></td>
                    </tr>

                    <tr>
                        <td>Photo  ID</td>
                        <td><ImagePreviewContainer img={user.profile_image}/></td>
                    </tr>

                    <tr >
                        <td colSpan={2}>
                            {
                                thrift_account?.account_branch?.status === 2 ?
                                <>
                                    <span>{' '}</span>
                                    <Link to="#" className='btn btn-outline-danger'>Waiting for Approval</Link>
                                </>
                                :
                                Number(thrift_account?.account_branch?.status) === 1 ?
                                    <div className={'alert alert-success   '}>
                                        We hope this message finds you well. We wanted to inform you about the status of your account approval.
                                        We are pleased to inform you that your account status has been <b>Approved</b>
                                        <br/>
                                        Thank you for choosing ThriftMs. We look forward to serving you and providing you with a seamless experience.
                                    </div>
                                    :
                                    Number(thrift_account?.account_branch?.status) === 2 ?
                                        <div className={'alert alert-danger  '}>
                                            We hope this message finds you well. We wanted to inform you about the status of your account disapproved.
                                            here is the reason for disapproval : <i>{thrift_account?.account_branch?.approval_reason}</i>
                                            <br/>
                                            Thank you for choosing ThriftMs. We look forward to serving you and providing you with a seamless experience.
                                        </div>
                                        :
                                        <div className={'alert alert-warning'}>
                                           <i>
                                               We hope this message finds you well. We kindly remind you to ensure that all necessary documents have been uploaded and that the provided information is accurate.
                                               Completing this step ensures a smooth and expedited verification process for your account
                                               Thank you for your attention to this matter. <br/>
                                               If you have any questions or require assistance, please don't hesitate to reach out to our support team at <a href={'mailto:info@thrift.com'}>
                                               [info@thrift.com]</a> .<br/>
                                               We're here to help!

                                           </i>
                                            <br/>

                                        </div>
                            }
                        </td>
                    </tr>

                  <tr>
                      <td></td>
                      <td>
                          {
                              thrift_account?.account_branch?.is_profile_complete === 1
                              &&
                              thrift_account?.account_branch?.business_reg_document !== null
                              && thrift_account?.account_branch?.status !==2
                              &&  thrift_account?.account_branch?.status !==1
                              &&
                              <span
                                  onClick={()=>handleApprovalModal(true)}
                                  id={"approval"}
                                  className='btn btn-outline-danger float-end'
                                  data-bs-toggle="modal"
                                  data-bs-target="#standard-modal">
                          Submit for Approval
                  </span>
                          }
                      </td>
                  </tr>
                </tbody>
            </table>





        </div>
    )
}


export default PreviewData