import React from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Spinner } from "../../../../components"
import { getRequest, postRequestFile } from "../../../../config/redux/actions"
import { AppState } from "../../../../config/redux/reducers"
import { AuthState } from "../../../../config/redux/reducers/tmsAuthReducer"
import { BranchReducerState } from "../../../../config/redux/reducers/tmsBranchReducer"
import { types } from "../../../../config/redux/types"
import { remote_url } from "../../../../config/urls/remote"
import {ErrorAlert, SuccessAlert} from "../../../../components/alert";
import {GeneralFormInput} from "../../../../components/form";
import {CustomModalWrapper} from "../../_components";


type FormValues ={
    branch_name : string,
    city : string,
    address : string,
    state_id : string,
    country_id : string,
    logo : string,
    business_registration_type : string,
    business_registration_no : string,
    email: string,
    phone_no : string 
}

type BranchFormProps = {
    closeFormFunc:any
}


const AddBranchForm :React.FC<BranchFormProps> = ({closeFormFunc}) => {
    const isAddForm = true;
     const { register, handleSubmit, formState: { errors },reset } = useForm<FormValues>()
     const {loading_,loading,success_msg,error_msg} = useSelector<AppState,BranchReducerState>((state)=> state.tmsBranchReducer)
     const {countries,token,account_key,allStates,business_registration_types,role_id,user} = useSelector<AppState,AuthState>((state)=> state.tmsAuthReducer)
     const dispatch = useDispatch()

    const addNewBranch = (data:FormValues) => {
        const reqData = new FormData()
        reqData.append('address',data.address)
        reqData.append('branch_name',data.branch_name)
        //branch_name
        reqData.append('city',data.city)
        reqData.append('country_id',data.country_id)
        reqData.append('email',data.email)
        reqData.append('logo', data.logo[0])
        reqData.append('phone_no',data.phone_no)
        reqData.append('state_id',data.state_id)
        reqData.append('business_registration_no',data.business_registration_no)
        reqData.append('business_registration_type',data.business_registration_type)

            const postData = {
                token,
                url: remote_url.branches,
                actionType : types.CREATE_NEW_BRANCH,
                postData : reqData,
                secretData : {
                    email:user.email,
                    mac_id :user.mac_id,
                    r_id: role_id
                },
                errorAction : types.SET_ERROR,
                accountKey: account_key,
                loading :{
                    isLoading :false,
                    isLoading_ : true,
                    isLoading_t : false
                }
                // reset
            }
            //
        dispatch(postRequestFile(postData))

    }
    //
    const clearData = () => {
         // reset()
        closeFormFunc(false)
    }
    //
    const getStatesUnderACountry = (id:string) => {
        if(id !==''){
            const postData = {
                    token,
                    url: remote_url.getStates + id,
                    actionType : types.GET_STATES,
                    postData : [],
                secretData : {
                    email:user.email,
                    mac_id :user.mac_id,
                    r_id: role_id
                },
                    errorAction : types.SET_ERROR,
                    accountKey: account_key,
                    loading :{
                        isLoading :false,
                        isLoading_ : false,
                        isLoading_t : false
                    }
                    // reset
                }
            dispatch(getRequest(postData))
        
        }
        else{

        }
    }

    if(success_msg && isAddForm){
        clearData()
    }
    //
    return <>
         <CustomModalWrapper onClickFunc={clearData}  modalTitle={'Add New Branch'}>


                    <div className="offcanvas-body">
                        {
                             <form onSubmit={handleSubmit(addNewBranch)}>
                                <div>
                                    <GeneralFormInput type={'text'} register={register} label={'branch name'} fieldName={'branch_name'} errors={errors.branch_name?.message}/>
                                    <GeneralFormInput
                                        type={'select'}
                                        register={register}
                                        label={'Country'}
                                        fieldName={'country_id'}
                                        selectFunction={getStatesUnderACountry}
                                        errors={errors.country_id?.message}
                                    >
                                        <option value={''}> Select Country</option>
                                        { countries.map((country)=> <option key={country.id} value={country.id}> {country.country_name} </option>)}
                                    </GeneralFormInput>
                                    {/*  */}
                                    <GeneralFormInput type={'select'} register={register} label={'State'} fieldName={'state_id'} errors={errors.state_id?.message}
                                     selectFunction={false}>
                                        <option value={''}> Select State </option>
                                        {allStates.map((state) => <option key={state.id} value={state.id}> {state.state_name} </option>)}
                                    </GeneralFormInput>

                                    <GeneralFormInput type={'text'} register={register} label={'City'} fieldName={'city'} errors={errors.city?.message}/>
                                    <GeneralFormInput type={'email'} register={register} fieldName={'email'}  label={'Email'} errors={errors.email?.message}/>
                                    <GeneralFormInput type={'text'} register={register} label={'phone no'} fieldName={'phone_no'} errors={errors.phone_no?.message}/>
                                    <GeneralFormInput type={'textarea'} register={register} label={'address'} fieldName={'address'} errors={errors.address?.message}/>
                                    <GeneralFormInput type={'file'} register={register} label={'logo'} fieldName={'logo'} errors={errors.logo?.message} />
                                    <GeneralFormInput type={'select'} register={register} label={'business registration typ'} fieldName={'business_registration_type'}
                                        errors={errors.business_registration_type?.message}>
                                        <option value={''}> Select Registration Type</option>
                                        {business_registration_types?.map((item)=>  <option key={item.key} value={item.key}>{item.name}</option>)}
                                    </GeneralFormInput>
                                    {/*business registration no*/}
                                    <GeneralFormInput
                                        type={'text'}
                                        register={register}
                                        label={'Thrift Reg No'}
                                        fieldName={'business_registration_no'}
                                        errors={errors.business_registration_no?.message}
                                    />
                                    { loading_ ? <Spinner color='primary'/>  : <input type="submit" value="Add new Branch" className="btn btn-outline-primary" />}
                                </div>
                             </form>
                        }
                    </div>
                    {loading_ && <div className="modal-backdrop fade show"></div>}
                </CustomModalWrapper>
    </>
}

export default AddBranchForm