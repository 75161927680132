
import CryptoJS from 'crypto-js'
import {types} from "./redux/types";
import {url} from "./urls";
export const APP_VERSION:string = process.env.REACT_APP_APP_VERSION ?? "0.0.0";



const appMode:string = process.env.REACT_APP_APP_MODE ?? ""
const backendKey:string = process.env.REACT_APP_BACKEND_KEY ??""
const frontendKey:string = process.env.REACT_APP_FRONTEND_KEY ?? ''
const frontend_url:string =   process.env.REACT_APP_FRONTEND_URL ?? ''

const remote_url:string =     `${process.env.REACT_APP_BACKEND_APP_URL}api/`
export const PAYSTACK_KEY = process.env.REACT_APP_PAY_STACK_KEY ?? ""
export const DEFAULT_INACTIVE_TIME = process.env.REACT_APP_DEFAULT_INACTIVE_TIME ?? 10;
//
export const FIREBASE_V_API_KEY = process.env.REACT_APP_FIREBASE_V_API_KEY ?? ""

//computing secret key 
export interface MsgProps {
    email:string,
    mac_id:string,
    r_id : number
}

//
export const hmac_512 = (msg:MsgProps, token:string) => {
    // console.log(CryptoJS.HmacSHA512(`${backendKey}estherayanda32@gmail.com${frontendKey}`, '216|635067C1244015111511151115111510')
    //     .toString(CryptoJS.enc.Hex));


    const message = `${backendKey}${msg?.email}${frontendKey}`

	let newHMAC = CryptoJS.HmacSHA512(message, msg.mac_id)

	return newHMAC.toString(CryptoJS.enc.Hex)
}

//
export const hmac_512_non_token = () => {
	let newHMAC = CryptoJS.HmacSHA512(frontendKey, backendKey)
	return newHMAC.toString(CryptoJS.enc.Hex)
}


export const generateValidRoleIdKey = (token:string,id:number,mac_id:string,accountKey:string) => {
    return  `${accountKey}${id}${mac_id}${id}`
}


export const appConfig = {
    appMode,
    remote_url,
    frontend_url,
    backendKey,
    frontendKey
}

export const  emptyCache = async () => {
    // console.log('am here in the cache function:: start here')
    if('caches' in window){
        caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach(name => {
                caches.delete(name)
                    .then(r => console.log(`${name} cached cleared :: ${r}`));
            })
        });

        // Makes sure the page reloads. Changes are only visible after you refresh.
        // window.location.reload();
        return true;
    }
}


export  const validateAppVersionResponse = (headers:any,dispatch:any) => {
    let app_version_resp = ""
    let action = ""
    let app_version_resp_full = headers?.xappversion
    let current_app_version = `TMS${APP_VERSION}`

    let  app_version_array = app_version_resp_full?.split('_')

    if(app_version_array !== undefined){
        app_version_resp =  app_version_array[0]
        action = app_version_array[1]
    }else{
        app_version_resp = `TMS${APP_VERSION}`
        action = ''
    }
    let isAppOutdated = current_app_version !== app_version_resp;
    dispatch({
        type: types.SET_APP_STATE,
        isAppOutdatedAction : {
            isAppOutdated,
            action : ''
        }
    })

    //checking if app is outdated
    return true

}


export const errorResponseCheck = (error:any,dispatch:any) => {
    if(error?.response?.data?.message==="multiple devices login"){
        dispatch({
            type : types.MULTIPLE_DEVICE
        })
    }
    if(error?.response?.data?.message==="Unauthenticated."){
        dispatch({
            type : types.LOG_OUT
        })
        window.location.reload()
        return true;
    }
}

export const bankAccountPurposeArray = [
    'All (savings,loans,expenses)',
    'Savings only',
    'Loans only',
    'Expenses only',
    // 'Savings and Loans only',
    // 'Savings and Expenses only',
    // 'Loans and Expenses only'
]

