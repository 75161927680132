
import {useEffect, useState} from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { LoanApplicationModel } from "../../../config/model/authModel"

import { postRequest, setGeneralAction } from "../../../config/redux/actions"
import { AppState } from "../../../config/redux/reducers"
import { AuthState } from "../../../config/redux/reducers/tmsAuthReducer"
import { LoansReducerState } from "../../../config/redux/reducers/tmsLoansReducer"
import { types } from "../../../config/redux/types"
import { appTxt } from "../../../config/strings"


import DashboardWrapper from "../DashboardWrapper"
import BreadCrumb from "../layouts/BreadCrumb"
import {ExpandCollapse, Loader} from "../_components"
import { LoanApplicationForm, LoanRepaymentDetails } from "./components"
import LoanAppFilter from "./components/LoanAppFilter"
import LoanAppSummaryCard from "./components/LoanAppSummaryCard"
import {loanApplicationInitDatatable} from "../../../config/datatableConfig";
import {ErrorAlert, SuccessAlert} from "../../../components/alert";
import {CreateLoanGuarantorForm} from "./index";





const LoansApplications = () => {
    //

    const [showLoanAppForm,setLoanAppForm] = useState(false)
    const [showLoanRepaymentModal,setShowLoanRepaymentModal] = useState('')
    const {user,role_id} = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
    const {isLoading_t,loans_app,newDataBot,loanAppSummary,success_msg,error_msg} = useSelector<AppState,LoansReducerState>((state)=> state.tmsLoansReducer)
    //
    const dispatch = useDispatch()
    //



    const setLoanAppDetails = (x:any) => {
        setShowLoanRepaymentModal("false")
        dispatch( setGeneralAction(types.SET_LOANS_SAVINGS_REPAYMENT,x))
    }

    const setUserLoanData = (data:boolean) => {
        setLoanAppForm(data)
        dispatch(setGeneralAction(types.GET_USER, {user:user}))
    }


    // const handleBulkImportModal = (data:boolean) => {
    //     setLoanAppForm(data)
    // }


const  loanApplicationDatatableInit = () => {
        if([0].includes(role_id)){
            loanApplicationInitDatatable(loans_app,setLoanAppDetails,['Request for Loan'],setUserLoanData,'')
        }
        else{
            loanApplicationInitDatatable(loans_app,setLoanAppDetails,['Request for Loan'],setUserLoanData,'')
        }

}

useEffect(() =>{
    dispatch(setGeneralAction(types.CLEAR_LOAN_MSG,[]))
    loanApplicationDatatableInit()
},[])

 if(newDataBot){
     loanApplicationDatatableInit()
    dispatch(setGeneralAction(types.RESET_LOANS_BOT,[]))
 }
    
    return <DashboardWrapper successMsg={success_msg}>

        <BreadCrumb current="Loans Applications" pageTitle="Loans Application" main="Loans"/>
        {showLoanAppForm &&   <LoanApplicationForm show={showLoanAppForm} userD={user} handleFormModal={setLoanAppForm}/> }

        { showLoanRepaymentModal==='false' && <LoanRepaymentDetails show={showLoanRepaymentModal} closeModal={setShowLoanRepaymentModal}/>}

        {success_msg && <SuccessAlert msg={success_msg}/>}
        {error_msg && <ErrorAlert msg={error_msg}/>}

        <ExpandCollapse name={'Loan Summary'} id={'LoanSummary'} id_={'LoanSummary_1'} id_1={'LoanSummary_'}>
            <div className="row">
                { loanAppSummary?.map((item,i=0) =>
                    <div key={i} className={item?.size}>
                        <LoanAppSummaryCard bg={item?.bg} leadText={item?.leadText} text={item?.text} iconName={item?.iconName}/>
                    </div>
                )
                }
            </div>
        </ExpandCollapse>
        {/* */}
        <ExpandCollapse name={'Loan Filter'} id={'LoanFilter'} id_={'LoanFilter_1'} id_1={'LoanFilter_2'}>
            <LoanAppFilter/>
        </ExpandCollapse>
         {/*  */}
        <ExpandCollapse name={'Loans Applications'} id={'LoanApp_'} id_={'LoanApp_1'} id_1={'LoanApp_2'}>
            <div className="row">
                <div className="col-12">
                    {isLoading_t && <Loader/> }
                    {/*table-responsive*/}
                    <div className="">
                        {/*dt-responsive*/}
                        <table className="table table-striped table-condensed    w-100  nowrapo" id="products-datatable">
                            <thead className="">
                            <tr>
                                <th>User</th>
                                <th>Loan Reference</th>
                                <th>Amount Borrowed</th>
                                <th>Expected Amount</th>
                                <th>Amount Returned</th>
                                <th>Application Date</th>
                                <th>Action</th>
                            </tr>
                            </thead>

                        </table>
                    </div>

                </div>
            </div>
        </ExpandCollapse>

    </DashboardWrapper>
}


export default LoansApplications