import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams, useSearchParams } from "react-router-dom"
import { Spinner } from "../../../components"
import ProgressBar from "../../../components/progressbar/ProgressBar"
import { postRequest } from "../../../config/redux/actions"
import { AppState } from "../../../config/redux/reducers"
import { AuthState } from "../../../config/redux/reducers/tmsAuthReducer"
import { MemebersState } from "../../../config/redux/reducers/tmsMemeberReducerState"
import { types } from "../../../config/redux/types"
import { appTxt } from "../../../config/strings"
import { url } from "../../../config/urls"
import { remote_url } from "../../../config/urls/remote"
import DashboardWrapper from "../DashboardWrapper"
import BreadCrumb from "../layouts/BreadCrumb"

const UserDetail = () => {
  const {userDetails,newDataBot,summary,loading_t} = useSelector<AppState,MemebersState>((state)=> state.tmsMemeberReducerState)
    const {token,account_key,role_id,user}  = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
  const dispatch = useDispatch()

  const {id}= useParams()
  

    useEffect(() =>{},[])


    const getMember = async () => {
        //
            const postData = {
                token,
                url: `${remote_url.thriftMemebers}/{$id}`,
                actionType : types.GET_USERS,
                postData : {},
                secretData : {
                    email:user.email,
                    mac_id :user.mac_id,
                    r_id: role_id
                },
                errorAction : types.SET_ERROR,
                accountKey: account_key,
                loading :{
                    isLoading :false,
                    isLoading_ : false,
                    isLoading_t : true
                }
            }
            //
            dispatch(postRequest(postData)) 
            // initDatable()
    }


    return <DashboardWrapper>
        <BreadCrumb pageTitle="User Details" current="User Details" main="Users"/>
        {/*  */}
           <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-5">
                                                <a href="javascript: void(0);" className="text-center d-block mb-4">
                                                    <img src="/assets/images/products/product-5.jpg" className="img-fluid" style={{maxWidth: "280px"}} alt="Product-img"/>
                                                </a>

                                                {/* <div className="d-lg-flex d-none justify-content-center">
                                                    <a href="javascript: void(0);">
                                                        <img src="/assets/images/products/product-1.jpg" className="img-fluid img-thumbnail p-2" style={{maxWidth: "75px"}} alt="Product-img"/>
                                                    </a>
                                                    <a href="javascript: void(0);" className="ms-2">
                                                        <img src="/assets/images/products/product-6.jpg" className="img-fluid img-thumbnail p-2" style={{maxWidth: "75px"}} alt="Product-img"/>
                                                    </a>
                                                    <a href="javascript: void(0);" className="ms-2">
                                                        <img src="/assets/images/products/product-3.jpg" className="img-fluid img-thumbnail p-2" style={{maxWidth: "75px"}} alt="Product-img"/>
                                                    </a>
                                                </div> */}
                                            </div> 
                                            <div className="col-lg-7">
                                                <form className="ps-lg-4">
                                                   
                                                    <h3 className="mt-0">{userDetails?.user?.name}
                                                    <Link to={url.users} className="text-muted">
                                                        <i className="text-danger mdi mdi-close-outline ms-2"></i></Link> </h3>
                                                    <p className="mb-1">Date Joined: {userDetails.user.created_at}</p>

                                                    <div className="mt-4">
                                                        <h6 className="font-14">Actions</h6>
                                                        <div className="d-flex">
                                                            <button type="button" className="btn btn-primary ms-2"> Savings</button>
                                                            <button type="button" className="btn btn-info ms-2"> Loans</button>
                                                        </div>
                                                    </div>
                                        
                                                    
                                                    <div className="mt-4">
                                                        <h6 className="font-14">Address:</h6>
                                                        <p>{userDetails?.user?.address}</p>
                                                    </div>

                                                  
                                                    <div className="mt-4">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <h6 className="font-14">Savings:</h6>
                                                                <p className="text-sm lh-150">{appTxt.naira_}{userDetails?.savings_sum_thrift_amount}</p>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <h6 className="font-14">Loan:</h6>
                                                                <p className="text-sm lh-150">{appTxt.naira_}0</p>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <h6 className="font-14">Pending Loan:</h6>
                                                                <p className="text-sm lh-150">{appTxt.naira_}0</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </form>
                                            </div> 
                                        </div> 

                                        <div className="table-responsive mt-4">
                                            <table className="table table-bordered table-centered mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>S</th>
                                                        <th>Details</th>
                                                      
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Phone Number</td>
                                                        <td>{userDetails.user.phone_no}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Email</td>
                                                        <td>{userDetails.user.email}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Approval Status</td>
                                                        <td>{userDetails.user.approval_status}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Join </td>
                                                        <td>{userDetails.user.created_at}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Status</td>
                                                        <td>{userDetails.user.status}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>State</td>
                                                        <td>{userDetails.user.state_id}</td>
                                                    </tr>
                                               
                                                </tbody>
                                            </table>
                                        </div> 
                                        
                                    </div> 
                                </div>
                            </div> 
                        </div>
    </DashboardWrapper>
}



export default UserDetail