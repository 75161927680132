interface Props  {
    //
    bg:string,
    leadText : string,
    text : string,
    iconName : string
}


const NumberCardSummary : React.FC<Props> = ({bg,leadText, text, iconName})=> {
    return <>
        <div className={`card text-white ${bg}  overflow-hidden`}>
            <div className="card-body">
                <div className="toll-free-box text-center">
                    <h4> <i className={`mdi ${iconName}`}></i> {leadText} : {text}</h4>
                </div>
            </div> 
        </div>
    </>
}


export default NumberCardSummary