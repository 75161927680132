import SettlementReconcilationWrapper from "./SettlementReconcilationWrapper";
import {TabOne} from "../_component";
import {TabItem} from "../_component/Tabs/TabOne";
import {useDispatch, useSelector} from "react-redux";

import {
    setCurrentSettlementTab
} from "../../_config/redux/feacture/settlementsReconcilations/settlementReconciliationSlice";
import {RootState} from "../../_config/redux/store";
import {BankSettlement, FlutterwaveSettlement, PaystackSettlemeent} from "./tabs";



const Tabs:TabItem[] = [
    {
        tabName : "FlutterWave",
        href :  '1',
        dataBsToggle : true,
        ariaExpanded : 'true',
        className : 'active',
        icon : '',
        active : 'active',
        component : <FlutterwaveSettlement />
    },
    {
        tabName : "PayStack",
        href :  '2',
        dataBsToggle : true,
        ariaExpanded : 'true',
        className : 'active',
        icon : '',
        active : 'active',
        component : <PaystackSettlemeent />
    },
    {
        tabName : "Bank Transfer",
        href :  '3',
        dataBsToggle : true,
        ariaExpanded : 'true',
        className : 'active',
        icon : '',
        active : 'active',
        component : <BankSettlement />
    },

]
//

const Settlement = () => {
    const dispatch = useDispatch()
    const {current_gateway} = useSelector((state:RootState)=> state.settlementReconciliation)

    //
    const handleTabChange = (tab:string) => {
        dispatch(setCurrentSettlementTab(tab))
    }



    return (
        <SettlementReconcilationWrapper>
            <>
                <TabOne isActive={current_gateway} tabs={Tabs} onTabClick={handleTabChange}/>
            </>
        </SettlementReconcilationWrapper>
    )
}

export default Settlement