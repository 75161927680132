 // import model
// actions props
//types
//define state for reducer
// intial state
// create the reducers
// export
import {AccountTypeModel, UserBranchModel} from "../../model/authModel";

import { ActionProps } from "../actions";

import { types } from "../types";



export interface BranchReducerState{
    loading : boolean,
    loading_ : boolean,
    isLoading_t:boolean,
    success_msg  : string ,
    error_msg : string,
    branches : UserBranchModel[],
    newDataBot : boolean
}
//
const initialState = {
    loading:false,
    loading_: false,
    isLoading_t: false,
    success_msg  : '',
    error_msg : '',
    branches : [],
    newDataBot : false
}


const tmsBranchReducer = (state:BranchReducerState= initialState, action:ActionProps) => {
    //
    switch (action.type) {
        case types.CLEAR_MSG:
            return{
                ...state,
                loading:false,
                loading_:false,
                isLoading_t:false,
                success_msg : '',
                error_msg : '',
                requery : false
            }
            
        case types.SET_LOADING:
            return {
                ...state,
                loading:action.loading?.isLoading,
                loading_ :action.loading?.isLoading_,
                isLoading_t: action.loading?.isLoading_t,
                success_msg : '',
                error_msg : '',
                requery : false
        }
        //
        case types.SET_ERROR:
            return {
                ...state,
                loading:false,
                loading_:false,
                isLoading_t:false,
                success_msg : '',
                error_msg : action.payload
            }
         //
        case types.CREATE_NEW_BRANCH: 
        return {
            ...state,
            loading:false,
            loading_:false,
            isLoading_t:false,
            success_msg : action.payload?.message,
            branches : action.payload?.data,
            newDataBot : true
        }

        case types.GET_BRANCH:
            return {
                ...state, 
                loading:false,
                loading_:false,
                isLoading_t:false,
                success_msg : '',
                branches : action.payload?.data,
                newDataBot : true

            }
        case types.RESET_TRANSACTION_BOT :
            return{
                ...state,
                newDataBot : false
            }




  
    
        case types.LOG_OUT:
            return {
                ...state,
                 loading :false,
                 loading_ : false,
                 isLoading_t:false,
                 error_msg :'',
                 success_msg :'',
                 branches : []
            }
            
        default:
            return state;
    }

}

export default tmsBranchReducer