const appName = 'AOT'

export const appTxt = {
    'app_name' : appName,
    'logo' : "/assets/img/b.png",
   
    'login_wel_heading'  : `Welcome to ${appName}`,
    'login_wel_text' : 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Deleniti',
    'login_wel_text_' : 'Lorem ipsum dolor, sit amet.',
   
    //form field text
    'email_placeholder' : 'example@mail.com',
    'password_placeholder' : '6+ strong character',
    'c_password_placeholder' : '6+ strong character',
    'acc_name' :'Acccount Name',
    'acc_name_placeholder' :'Enter your account name',
    'fn_placeholder' : 'Enter your first name',
    'ln_placeholder' : 'Enter your first name',
    'phone_placeholder' : 'Enter your phone number',
    //label
    'email_label': 'Email',
    'phone_no_label': 'Phone Number',
    'password_label' : 'Password',
    'confirm_password_label' : 'Confirm password',
    //form button
    'forgot_pass_btn' : 'Send',
   
    'no_account' :'Dont Have an Account?',
    
    'signup_text' :'Sign Up',
    'login_btn' : 'Sign in',
    'forgot_pass' : 'Forgot password?',
    'forgot_pass_h' : 'Forgot Your Password?',
    'or_signin_with' : 'or sign in with',
    
    'fb_alt_img' :'Facebook Icon',
    'naira_' :'₦',
    '' :'',
}