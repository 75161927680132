import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import '../../assets/css/icons.min.css'
import {calculateTime, postRequest, setGeneralAction} from '../../config/redux/actions';
import {AppState} from '../../config/redux/reducers';
import {AuthState} from '../../config/redux/reducers/tmsAuthReducer';
import {LayoutSettingsState} from '../../config/redux/reducers/tmsLayoutSettingsReducer';
import {types} from '../../config/redux/types';
import {url} from '../../config/urls';
import {remote_url} from '../../config/urls/remote';
import {LockScreen} from '../auth';
// import
import {useAppSelector} from "../../config/redux/useTypedSelector";
import {Sidebar, TopNavBar, Footer, RightHandSide} from "./layouts";
import {OverrlayLoader} from './_components';
import {emptyCache} from "../../config/config";
import PushNotification from "../_components/notification/PushNotification";


//
interface Props {
    children: React.ReactNode
    showWarning?: boolean,
    keys?: string,
    successMsg ?: string,
    errorNsg ?: string,
}

const DashboardWrapper: React.FC<Props> = ({children, showWarning = true, keys,successMsg,errorNsg}) => {

    const {
        dataLayoutMode,
        leftbarTheme,
        leftbarLayout,
        layoutMode,
    } = useSelector<AppState, LayoutSettingsState>((state) => state.tmsLayoutSettingsReducer)

    const [lTime, setLtime] = useState<number>(0)

    //
    const {
        loading,
        token,
        has_account_number,
        loginTime,
        account_key,
        isLogout,
        lockScreen,
        thrift_account,
        user,
        showPushNotification,
        role_id,
        isAppOutdatedAction
    } = useSelector<AppState, AuthState>((state) => state.tmsAuthReducer)

    //
    const dispatch = useDispatch()


    //
    useEffect(() => {
        document?.getElementById("mainBody")?.classList?.remove("sidebar-enable");
        checkInActivity()
    }, [])

//class =
    //SET UP APP SETTINGS

    //
    const getTopBarNotification = () => {
        //
        const postData = {
            token,
            url: remote_url.notifications,
            actionType: types.GET_NAVBAR_NOTIFICATIONS,
            postData: {
                notification_type: 0 //
            },
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            // reset,
            errorAction: types.SET_ERROR,
            loading: {
                isLoading: false,
                isLoading_: false,
                isLoading_t: false
            },
            accountKey: account_key
        }
        //
        dispatch(postRequest(postData))
    }
    //
    const checkInActivity = () => {
        const min = calculateTime(loginTime)
        //
        if (min <= 6) {
            setLtime(min)
            dispatch(setGeneralAction(types.SET_CURRENT_TIME, []))
            getTopBarNotification()
        } else {
            setLtime(min)
        }
    }


    const downloadUpdate = async () => {
        let download = await emptyCache()
        if(download){
            dispatch(setGeneralAction(types.RESET_APP_STATE,[]))
        }
    }

    //
    if (lTime > 6 || lockScreen) {
        return <LockScreen/>
    }

    if(isAppOutdatedAction?.isAppOutdated){
        downloadUpdate().then( (r) => {
            //don nothing
        })
    }


    return (<>
        {loading && <OverrlayLoader/>}

        <div className="wrapper" key={keys} style={{background:'#fff'}}>

            <Sidebar/>
            <div className="content-page">
                <div className="content">
                    <TopNavBar/>
                    { isAppOutdatedAction?.isAppOutdated && <p className={'alert alert-warning px-3 mt-2'}> App Update Available
                            <span className={'text-primary'} onClick={()=> downloadUpdate()}>
                                <u> Download now</u>
                            </span>
                        </p>
                    }

                    {/*  */}
                    {showWarning && [1,2].includes(role_id) &&
                        <div>
                            {
                                has_account_number === 0 &&
                                <div className=" mt-3 alert alert-danger alert-dismissible fade show" role="alert">
                                    <button type="button" className="btn-close" data-bs-dismiss="alert"
                                            aria-label="Close"></button>
                                    <i className="dripicons-warning me-2"></i> You are yet to add your
                                    Thrift/Cooperative Bank details
                                    <Link to={url.thriftBankListing}> <u> fix now </u> </Link>
                                </div>
                            }
                            {/**/}
                            {
                              thrift_account?.account_branch?.status===1 &&  thrift_account?.account_branch?.has_loan_settings === 0 &&
                                <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                    <button type="button" className="btn-close" data-bs-dismiss="alert"
                                            aria-label="Close"></button>
                                    <i className="dripicons-warning me-2"></i>
                                    You are yet to setup your loans setting
                                    <Link className='text-dark' to={url.loansSettings}> <u> fix now </u> </Link>
                                </div>
                            }
                            {
                                thrift_account?.account_branch?.is_profile_complete === 0 &&
                                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                    <button type="button" className="btn-close" data-bs-dismiss="alert"
                                            aria-label="Close"></button>
                                    <i className="dripicons-wrong me-2"></i>
                                    You are yet to complete your Thrift profile!
                                    <Link to={url.thriftAccountSettings}> <u> fix now </u> </Link>
                                </div>
                            }

                        </div>
                    }

                    {/*21 - 22*/}

                    {
                        showWarning && [1,2,3].includes(role_id) && user?.is_profile_complete < 22 &&
                        <div className="alert alert-warning alert-dismissible fade show" role="alert">
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="alert"
                                    aria-label="Close"></button>
                            <i className="dripicons-warning me-2"></i>
                            You are yet to setup your Personal Profile
                            <Link className='text-dark' to={url.myAccount}> <u> fix now </u> </Link>
                        </div>
                    }

                    {
                        showWarning && [1,2,3].includes(role_id) && !user?.email_verified_at  &&
                        <div className="alert alert-warning alert-dismissible fade show" role="alert">
                            <button type="button" className="btn-close" data-bs-dismiss="alert"
                                    aria-label="Close"></button>
                            <i className="dripicons-warning me-2"></i>
                            You are yet to verify you email
                            <Link className='text-dark' to={url.myAccount}> <u> fix now </u> </Link>
                        </div>
                    }

                    {
                        [1,2].includes(role_id) &&
                        thrift_account?.account_branch?.is_profile_complete === 1 &&
                        thrift_account?.account_branch?.business_reg_document === null
                        &&
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            <button type="button" className="btn-close" data-bs-dismiss="alert"
                                    aria-label="Close"></button>
                            <i className="dripicons-wrong me-2"></i>
                            You are yet to Upload a valid Document for your Thrift Account!
                            <Link to={url.thriftAccountSettings}> <u> fix now </u> </Link>
                        </div>
                    }


                    {/**/}
                    {showPushNotification && <PushNotification/> }

                    {children}

                </div>
                <Footer/>
            </div>
        </div>
        {/*<RightHandSide/>*/}
    </>)


}


export default DashboardWrapper