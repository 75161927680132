

import DashboardWrapper from "../DashboardWrapper";
// 

import { SummaryCard } from "./components/SummaryCard";
import RightHandSideCard from "./components/RightHandSideCard";
import LineChartCard from "./components/LineChartCard";
import { useEffect } from "react";
import { remote_url } from "../../../config/urls/remote";
import { types } from "../../../config/redux/types";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../config/redux/reducers";
import { AuthState } from "../../../config/redux/reducers/tmsAuthReducer";
import {getRequest} from "../../../config/redux/actions";
import { AnalyticState } from "../../../config/redux/reducers/tmsAnalyticsReducer";

import { buildCardSummary, buildCardSummaryLine } from "./graph/init";
import { cardSummaryModel } from "../../../config/model/authModel";
import {CircularLoader, OtpVerification} from "../_components";
import {DashboardFilter, ReveneuCompareBarChart, TopUserPerformaceChartCard} from "./components";


const Dashboard = () => {
    const dispatch = useDispatch()
    const {role_id,token,account_key,user,loading} = useSelector<AppState, AuthState>((state)=> state.tmsAuthReducer)
    const {dashboard,loading_t} = useSelector<AppState,AnalyticState>((state)=> state.tmsAnalyticsReducer) || null


    useEffect(() => {
        dispatch(getRequest({
            accountKey: account_key,
            actionType: types.GET_SAVINGS_ACCOUNT_DETAILS,
            errorAction: types.SET_ERROR,
            loading,
            secretData: {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            url: remote_url.getSavingsBankAccount,
            token}))
    }, []);


    ///
    return (
    <DashboardWrapper>

        {/*<OtpVerification/>*/}

        <div className="container-fluid">
            <DashboardFilter/>
            { loading_t && <CircularLoader/>}
            {/* card summary starts here */}
            <div className="row">
                {
                dashboard?.cardSummary?.map((item:cardSummaryModel,i=0) =>
                        <SummaryCard
                            className = {item?.className}
                            classNameOne={item?.classNameOne}
                            key={i+1}
                            types={item?.types}
                            title={item?.title} 
                            total={item?.total.toLocaleString()}
                            arrow={item?.arrow}
                            percentage={item?.percentage} 
                            data={item?.types==='bars' ? buildCardSummary(item) : buildCardSummaryLine(item)}
                            id={item?.id}
                        /> 
                    )
                }
            </div>
            {/* Card Summary Ends Here */}

            <div className="row">
                <LineChartCard 
                    data={dashboard}
                    id="dash-revenue-chart"
                    col={role_id === 3 ? "12" : "7"}
                />

                {
                    [1,2].includes(role_id) &&
                    <TopUserPerformaceChartCard
                        data={dashboard}
                        id="dash-campaigns-chart"
                    />
                }

            </div>

            <div className="row">
                {/* <LeftHandSide/> */}
                <ReveneuCompareBarChart
                 data={dashboard}  
                 id="i"
                />
                {/* <MiddleCard/> */}
                <RightHandSideCard/>
            </div>

        </div>
    </DashboardWrapper>
    )
}

export default Dashboard