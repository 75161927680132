import { Link, useNavigate  } from "react-router-dom"
import {useDispatch, useSelector } from "react-redux"

import { AuthState } from "../../../config/redux/reducers/tmsAuthReducer"
import { AppState } from "../../../config/redux/reducers"
import { url } from "../../../config/urls"
import {postRequest, setGeneralAction} from "../../../config/redux/actions"
import { types } from "../../../config/redux/types"
import { AccountTypeModel, UserBranchModel } from "../../../config/model/authModel"
import {QuickMenu, TopNavBarSearch} from "./top_nav_bar";
import {useState} from "react";
import {SuccessAlert} from "../../../components/alert";







const TopNavBar = () => {
    const [showMsg, setShowMsg] = useState(false)
    const [accountName, setAccountName] = useState('')
    const {role_id,role_text,user,thrift_accounts, thrift_account,top_bar_notifications} = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
    const dispatch = useDispatch()
    const navigate  = useNavigate()
    //:::::::::::::::::::: :::::::::::::::::::
   
    const switchAccount = (account_details:UserBranchModel) => {
        const switchData = {
            account_branch_key : account_details?.account_branch?.account_branch_key,
            role_id : account_details?.role_id,
            role_text: account_details?.role_text,
            has_account_number: account_details?.account_branch?.has_account_number,
            thrift_account : account_details,
        }
        //
        dispatch(setGeneralAction(types.SWITCH_ACCOUNT,switchData))
        setShowMsg(true)
        setAccountName(account_details?.account_branch?.branch_name)
        //
        // window.location.reload()
        // navigate(url.dashboard,{replace:true})
        setTimeout(() => {
            window.location.reload()
            // navigate(url.dashboard,{replace:true})
        }, 2000)
    }




    return <>
        {showMsg && <SuccessAlert msg={`You have switched to ${accountName} Successfully, You will be redirected to the Dashboard Page `}/>}
        <div className="navbar-custom">
            <ul className="list-unstyled topbar-menu float-end mb-0">
               
                <li className="dropdown notification-list d-lg-none">
                    <Link to="#" className="nav-link dropdown-toggle arrow-none" data-bs-toggle="dropdown" role="button"
                       aria-haspopup="false" aria-expanded="false">
                        <i className="dripicons-search noti-icon"></i>
                    </Link>
                    <div className="dropdown-menu dropdown-menu-animated dropdown-lg p-0">
                        <form className="p-3">
                            <input type="text" className="form-control" placeholder="Search ..."
                                   aria-label="Recipient's username"/>
                        </form>
                    </div>
                </li>

                {/*  */}

            
                <li className="dropdown notification-list topbar-dropdown">
                    {/*  */}
                    <Link 
                        className="nav-link dropdown-toggle arrow-none" 
                        data-bs-toggle="dropdown" 
                        to="#"
                        role="button" 
                        aria-haspopup="false" 
                        aria-expanded="false"
                        >
                            <img src={thrift_account?.account_branch?.logo} 
                                alt="account logo" 
                                className="me-0 me-sm-1" 
                                 height={"30"}
                                 width={"30"}
                            />
                            <span className="align-middle d-none d-sm-inline-block">{thrift_account?.account_branch?.branch_name}</span>
                        <i className="mdi mdi-chevron-down d-none d-sm-inline-block align-middle"></i>
                    </Link>
                    
                    {/*other accounts  */}
                    <div className="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu">
                        {thrift_accounts?.length>0 && thrift_accounts?.map((account) =>  account?.id !== thrift_account?.id &&
                            <>
                                <Link
                                    key={account?.id}
                                    onClick={()=> switchAccount(account)}
                                    to="#0"
                                    className="dropdown-item notify-item" py-3>
                                    <img src={account?.account_branch?.logo} alt="user" className="me-1" height={"30"} width={"30"}/>
                                    <span className="align-middle"> {account?.account_branch?.branch_name} </span>
                                    <span className="badge badge-outline-success btn-sm">Switch</span>
                                </Link>
                                <hr className="my-0" />
                            </>

                        )}
                    </div>
                </li>

                {/* Notifications  */}
                <li className="dropdown notification-list">
                    <Link 
                        className="nav-link dropdown-toggle arrow-none" 
                        data-bs-toggle="dropdown" 
                        to="#" 
                        role="button" 
                        aria-haspopup="false" 
                        aria-expanded="false"
                        >
                        <i className="dripicons-bell noti-icon"></i>
                        {top_bar_notifications?.length>0 &&  <span className="noti-icon-badge"></span> }
                    </Link>

                    <div className="dropdown-menu dropdown-menu-end dropdown-menu-animated dropdown-lg">
                        <div className="dropdown-item noti-title">
                            <h5 className="m-0">
                                {/* <span className="float-end">
                                    <Link to="#" className="text-dark">
                                        <small>Clear All</small>
                                    </Link>
                                </span> */}
                                Notification(s)
                            </h5>
                        </div>
                        {/* notification lists :: bg-primary,  bg-info */}
                        <div style={{maxHeight: "230px"}} data-simplebar="">
                            {/*{*/}
                            {/*    top_bar_notifications?.length > 0 */}
                            {/*        ?*/}
                            {/*            top_bar_notifications.map((notification) =>*/}
                            {/*                <div key={notification?.id}>*/}
                            {/*                <Link key={`test_${notification?.id}`} to="#" className="dropdown-item notify-item">*/}
                            {/*                    <div className={`notify-icon ${notification?.icon_bg_color}`}>*/}
                            {/*                        <i className={notification?.icon_name}></i>*/}
                            {/*                    </div>*/}
                            {/*                    <p className="notify-details">{notification?.title}</p>*/}
                            {/*                    <p className="text-muted mb-0 user-msg">*/}
                            {/*                        <small>{notification?.message}</small>*/}
                            {/*                        <br/>*/}
                            {/*                        <small className="text-muted">{notification?.created_at}</small>*/}
                            {/*                    </p>*/}
                            {/*                </Link>*/}
                            {/*                </div>*/}
                            {/*            )*/}
                            {/*        :*/}
                            {/*     ''*/}
                            {/*}*/}
                        </div>

                        <Link to="#0"
                           className="dropdown-item text-center text-primary notify-item notify-all">
                            View All
                        </Link>
                    </div>
                </li>
                {/* notifications */}
                {/* start quick menu */}
                <QuickMenu/>

                {/* quick menu */}
                {/* start of settings */}
                <li className="notification-list">
                    <Link className="nav-link end-bar-toggle" to="#">
                        <i className="dripicons-gear noti-icon"></i>
                    </Link>
                </li>
                {/* end of settings  */}
                {/* Profile Image and DropDwon Option  */}
                <li className="dropdown notification-list">
                    <Link className="nav-link dropdown-toggle nav-user arrow-none me-0" data-bs-toggle="dropdown" to="#"
                       role="button" aria-haspopup="false" aria-expanded="false">
                            <span className="account-user-avatar">
                                <img src={user?.profile_image!==''?user?.profile_image : user.alternate_profile_image} alt="user's profile " className="rounded-circle"/>
                            </span>
                        <span>
                            <span className="account-user-name">{user?.name}</span>
                            <span className="account-position">{role_text}</span>
                        </span>
                    </Link>
                    {/*  */}
                    <div
                        className="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
                        <div className=" dropdown-header noti-title">
                            <h6 className="text-overflow m-0">Welcome !</h6>
                        </div>

                        <Link to={url.myAccount} className="dropdown-item notify-item">
                            <i className="mdi mdi-account-circle me-1"></i>
                            <span>Profile Settings </span>
                        </Link>

                        {role_id === 1 &&  <Link to={url.thriftAccountSettings} className="dropdown-item notify-item">
                            <i className="mdi mdi-account-edit me-1"></i>
                            <span>Account Settings</span>
                        </Link>}



                        <Link to={url.supportsHome} className="dropdown-item notify-item">
                            <i className="mdi mdi-lifebuoy me-1"></i>
                            <span>Support</span>
                        </Link>


                        <Link  className="dropdown-item notify-item" 
                            onClick={()=>dispatch(() => dispatch(setGeneralAction(types.ACTIVATE_LOCK_SCREEN,[])))}
                            to={url.accLock}
                            >
                            <i className="mdi mdi-lock-outline me-1"></i>
                            <span>Lock Screen</span>
                        </Link>


                        <Link to={url.preLogOut} className="dropdown-item notify-item">
                            <i className="mdi mdi-logout me-1"></i>
                            <span>Logout</span>
                        </Link>
                    </div>
                </li>
                {/* end of profile droop down */}
            </ul>

            {/* search */}
            <button className="button-menu-mobile open-left">
                <i className="mdi mdi-menu"></i>
            </button>

            <TopNavBarSearch/>
        </div>
    </>
}


export default  TopNavBar