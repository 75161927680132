import React from "react";

interface Props{
    children : React.ReactNode
    onClickFunc?:any
    modalTitle : string
}

const CustomModalWrapper:React.FC<Props> = ({children,onClickFunc,modalTitle }) => {

    return(
        <>
            <section className="c-modal" style={{overflowY:"auto"}} >
                <div className="modal-header">
                    <h4 className="modal-title" id="myLargeModalLabel">{modalTitle}</h4>
                    <button
                        type="button" className="btn-close"
                        data-bs-dismiss="modal" aria-label="Close"
                        onClick={()=>onClickFunc(false)}>
                    </button>
                </div>


                {children}



            </section>
            <div className="c-modal-overlay" onClick={()=>onClickFunc()}></div>
        </>
    )
}

export default CustomModalWrapper
