import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../config/redux/reducers";
import {AuthState} from "../../../../config/redux/reducers/tmsAuthReducer";
import DashboardWrapper from "../../DashboardWrapper";
import BreadCrumb from "../../layouts/BreadCrumb";
import {appTxt} from "../../../../config/strings";
import {Link} from "react-router-dom";
import {url} from "../../../../config/urls";
import {remote_url} from "../../../../config/urls/remote";
import {types} from "../../../../config/redux/types";
import {formatCash, getRequest} from "../../../../config/redux/actions";
import {useEffect} from "react";
import Chart from "react-apexcharts";
import {savingsMonthlyBarGraphConfig} from "../../savings/graph/init";
import {savingsUserDashboardInit} from "./index";

const MyProfile = () => {
    const {user,role_text,role_id,token,account_key,userFullDetails} =useSelector<AppState,AuthState>((state)=>state.tmsAuthReducer)
    const dispatch = useDispatch()


    useEffect(()=> {
        getUserDetails()
    },[])

    const getUserDetails = () => {
        const postData = {
            token,
            url: remote_url.getProfileFullDetails,
            actionType : types.GET_USER_FULL_DETAILS,
            postData : [],
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            errorAction : types.SET_ERROR,
            accountKey: account_key,
            loading :{
                isLoading : false,
                isLoading_ : false,
                isLoading_t : false
            }
        }
        dispatch(getRequest(postData))
    }

    return  <DashboardWrapper >
        <BreadCrumb pageTitle={'My Profile'} main={'Profile Settings'} current={'Profile'}/>
        <div className="row">
            <div className="col-sm-12">
                {/* Profile */}
                <div className="card bg-primary">
                    <div className="card-body profile-user-box">
                        <div className="row">
                            <div className="col-sm-8">
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <div className="avatar-lg">
                                            <img src={user.profile_image} height={70} width={70} alt={user.name} className="rounded-circle " />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div>
                                            <h4 className="mt-1 mb-1 text-white">{user.name}</h4>
                                            <p className="font-13 text-white-50"> {role_text}</p>
                                            <ul className="mb-0 list-inline text-light">
                                                <li className="list-inline-item me-3">
                                                    <h5 className="mb-1">{appTxt.naira_} {userFullDetails?.overall_total_savings} </h5>
                                                    <p className="mb-0 font-13 text-white-50">Savings</p>
                                                </li>

                                                <li className="list-inline-item">
                                                    <h5 className="mb-1">{appTxt.naira_}  {userFullDetails?.overall_total_loans}</h5>
                                                    <p className="mb-0 font-13 text-white-50"> Pending Loans </p>
                                                </li>

                                                <li className="list-inline-item">
                                                    <h5 className="mb-1">{appTxt.naira_}  {userFullDetails?.overall_total_expenses}</h5>
                                                    <p className="mb-0 font-13 text-white-50">Expenses</p>
                                                </li>
                                            </ul>
                                        </div>
                                        <hr/>
                                        <small className={'text-white'}>N.B: current month summary</small>
                                    </div>
                                </div>
                            </div> {/* end col*/}
                            <div className="col-sm-4">
                                <div className="text-center mt-sm-0 mt-3 text-sm-end">
                                    <Link to={url.myAccount} type="button" className="btn btn-light">
                                        <i className="mdi mdi-account-edit me-1" /> Edit Profile
                                    </Link>
                                </div>
                            </div> {/* end col*/}
                        </div> {/* end row */}
                    </div> {/* end card-body/ profile-user-box*/}
                </div>
                {/*end profile/ card */}
            </div> {/* end col*/}
        </div>
        {/* end row */}

        <div className="row">
            <div className="col-xl-4">
                {/* Personal-Information */}
                <div className="card">
                    <div className="card-body">
                        <h4 className="header-title mt-0 mb-3">Profile Details</h4>
                        <p className="text-muted font-13">
                            {/*Hye, I’m Michael Franklin residing in this beautiful world. I create websites and mobile apps with great UX and UI design.*/}
                            {/*I have done work with big companies like Nokia, Google and Yahoo. Meet me or Contact me for any queries. One Extra line for filling space.*/}
                            {/*Fill as many you want.*/}
                        </p>
                        <hr />
                        <div className="text-start">
                            <p className="text-muted"><strong>Full Name :</strong> <span className="ms-2">{user.name}</span></p>
                            <p className="text-muted"><strong>Mobile :</strong><span className="ms-2">{user.phone_no}</span></p>
                            <p className="text-muted"><strong>Email :</strong> <span className="ms-2">{user.email}</span></p>
                            <p className="text-muted"><strong>Location :</strong> <span className="ms-2">{user.address}</span></p>
                            {/*<p className="text-muted"><strong>Languages :</strong>*/}
                            {/*    <span className="ms-2"> {user.country_id}</span>*/}
                            {/*</p>*/}
                            {/*<p className="text-muted mb-0" id="tooltip-container"><strong>Elsewhere :</strong>*/}
                            {/*    <a className="d-inline-block ms-2 text-muted" data-bs-container="#tooltip-container" data-bs-placement="top"*/}
                            {/*       data-bs-toggle="tooltip" href={""} title="Facebook"><i className="mdi mdi-facebook" /></a>*/}
                            {/*    <a className="d-inline-block ms-2 text-muted" data-bs-container="#tooltip-container" data-bs-placement="top"*/}
                            {/*       data-bs-toggle="tooltip" href={""} title="Twitter"><i className="mdi mdi-twitter" /></a>*/}
                            {/*    <a className="d-inline-block ms-2 text-muted" data-bs-container="#tooltip-container" data-bs-placement="top" data-bs-toggle="tooltip"*/}
                            {/*       href={""} title="Skype"><i className="mdi mdi-skype" /></a>*/}
                            {/*</p>*/}
                        </div>
                    </div>
                </div>
                {/* Personal-Information */}
                {/* Toll free number box*/}
                <div className="card text-white bg-info overflow-hidden">
                    <div className="card-body">
                        <div className="toll-free-box text-center">
                            <h4> <i className="mdi mdi-email" />
                                Support : <Link className={'text-white'} to={`mailto:supports@thriftms.com`}> supports@thriftms.com </Link>
                            </h4>
                        </div>
                    </div>
                    {/* end card-body*/}
                </div> {/* end card*/}
                {/* End Toll free number box*/}
                {/* Messages*/}
                <div className="card">
                    <div className="card-body">
                        <h4 className="header-title mb-3">Messages</h4>
                        <div className="inbox-widget">
                            {userFullDetails?.support_tickets?.map((item) => <div className="inbox-item">
                                <div className="inbox-item-img"><img src="assets/images/users/avatar-2.jpg" className="rounded-circle" alt="" /></div>
                                <p className="inbox-item-author">Tomaslau</p>
                                <p className="inbox-item-text">I've finished it! See you so...</p>
                                <p className="inbox-item-date">
                                    <a href="#" className="btn btn-sm btn-link text-info font-13"> Reply </a>
                                </p>
                            </div> )}
                        </div>
                        {/* end inbox-widget */}
                    </div> {/* end card-body*/}
                </div>
                {/* end card*/}
            </div> {/* end col*/}
            <div className="col-xl-8">
                {/* Chart*/}
                <div className="card">
                    <div className="card-body">
                        <h4 className="header-title mb-3">Savings Amount &amp; Count</h4>
                        <div dir="ltr">
                            <div style={{height: '260px'}} className="chartjs-chart">
                                <Chart
                                    type="bar"
                                    options={{
                                        ...savingsUserDashboardInit,
                                        ...{labels:userFullDetails?.savings_amount_chart?.labels}}}
                                    series={[
                                        {
                                            name: 'Amount',
                                            data: userFullDetails?.savings_amount_chart?.series,
                                        }
                                    ]}
                                    className="apex-charts "
                                    id="high-performing-product"
                                    height= "260px"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Chart*/}
                <div className="row">
                    <div className="col-sm-4">
                        <div className="card tilebox-one">
                            <div className="card-body">
                                <i className="dripicons-basket float-end text-muted" />
                                <h6 className="text-muted text-uppercase mt-0">Savings</h6>
                                <h2 className="m-b-20">{formatCash(userFullDetails?.overall_total_savings_years)}</h2>
                                <span className="text-muted h6">{appTxt.naira_}{userFullDetails?.overall_total_savings_years.toLocaleString()}</span>
                                <hr/>
                                <span className="text-muted">Current Year</span>
                            </div> {/* end card-body*/}
                        </div> {/*end card*/}
                    </div>{/* end col */}
                    <div className="col-sm-4">
                        <div className="card tilebox-one">
                            <div className="card-body">
                                <i className="dripicons-box float-end text-muted" />
                                <h6 className="text-muted text-uppercase mt-0">Loans</h6>
                                <h2 className="m-b-20"><span>{formatCash(userFullDetails?.overall_total_loans_years)}</span></h2>
                                <span className="text-muted h6">{appTxt.naira_}{userFullDetails?.overall_total_loans_years.toLocaleString()}</span>
                                <hr/>
                                <span className="text-muted">Current Year</span>
                            </div>
                        </div> {/*end card*/}
                    </div>{/* end col */}
                    <div className="col-sm-4">
                        <div className="card tilebox-one">
                            <div className="card-body">
                                <i className="dripicons-jewel float-end text-muted" />
                                <h6 className="text-muted text-uppercase mt-0">Expenses</h6>
                                <h2 className="m-b-20">{formatCash(userFullDetails?.overall_total_expenses_years)}</h2>
                                <span className="text-muted h6">{appTxt.naira_}{userFullDetails?.overall_total_expenses_years.toLocaleString()}</span>
                                <hr/>
                                <span className="text-muted">Current Year</span>
                            </div> {/* end card-body*/}
                        </div> {/*end card*/}
                    </div>{/* end col */}
                </div>
                {/* end row */}
                <div className="card">
                    <div className="card-body">
                        <h4 className="header-title mb-3">Unpaid Transaction</h4>
                        <div className="table-responsive">
                            <table className="table table-hover table-centered mb-0">
                                <thead>
                                <tr>
                                    <th>Transaction Reference</th>
                                    <th>Type</th>
                                    <th>Amount</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {userFullDetails?.unpaid_transactions?.map((item,i=0)=>
                                    <tr key={i}>
                                        <td>{item.transaction_reference}</td>
                                        <td>Savings</td>
                                        <td>
                                            {item.thrift_amount}{}
                                        </td>
                                        <td>
                                            <span className="badge bg-primary">pay now</span>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div> {/* end table responsive*/}
                    </div> {/* end col*/}
                </div> {/* end row*/}
            </div>
            {/* end col */}
        </div>
        {/* end row */}
    </DashboardWrapper>
}


export default  MyProfile