

import styles from '../styles/overlay.module.css'
import React from "react";
type LoaderProps = {
    showLoader ? : boolean
}

const OverrlayLoader:React.FC<LoaderProps> = ({showLoader=true}) => {
    return <>
        <div className={styles.overlay} >
            {showLoader &&
                <img src="/assets/images/Spinner-1s-200px.svg" alt="gif loaoder"/>
            }
        </div>
    </>
}


export default OverrlayLoader