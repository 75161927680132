import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, Navigate, useNavigate } from "react-router-dom"
import {AccountTypeModel, BranchModel, MembersModel, UserBranchModel} from "../../../config/model/authModel"
import {calculateTime, getRequest, setGeneralAction, setLoading} from "../../../config/redux/actions"
import { AppState } from "../../../config/redux/reducers"
import { AuthState } from "../../../config/redux/reducers/tmsAuthReducer"
import { BranchReducerState } from "../../../config/redux/reducers/tmsBranchReducer"
import { types } from "../../../config/redux/types"
import { url } from "../../../config/urls"
import { remote_url } from "../../../config/urls/remote"
import DashboardWrapper from "../DashboardWrapper"
import BreadCrumb from "../layouts/BreadCrumb"
import { CircularLoader, Loader, OverrlayLoader } from "../_components"
import AddBranchForm from "./components/AddBranchForm"
import {DEFAULT_INACTIVE_TIME} from "../../../config/config";
import {branchInitDatatable, expensesInitDatatable, membersInitDatatable} from "../../../config/datatableConfig";
import {ErrorAlert, SuccessAlert} from "../../../components/alert";




const BranchHome = () => {
   const [showAddForm, setShowAddForm] = useState(false)
    const {branches,loading_,newDataBot,success_msg,error_msg} = useSelector<AppState, BranchReducerState>((state)=> state.tmsBranchReducer)
    const {token,account_key,loading,thrift_account,loginTime,user,role_id} = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
    const [check, setCheck] = useState(false)
    const dispatch = useDispatch()

    //
    useEffect(() => {
        //
        const time_diff = calculateTime(loginTime)
        if(time_diff<=DEFAULT_INACTIVE_TIME){
            getBranches()
        }
    },[check])

    const showAddBranchForm = (data:boolean) => {
       setShowAddForm(data)
    }


    const switchAccount = (account_details:UserBranchModel) => {
        dispatch(setLoading({
			isLoading: true,
			isLoading_: false,
		    isLoading_t : false
		}))

        const switchData = {
            account_branch_key : account_details.account_branch.account_branch_key,
            role_id : account_details.role_id,
            role_text: account_details.role_text,
            has_account_number: account_details.account_branch.has_account_number,
            thrift_account : account_details,
        }

        dispatch(setGeneralAction(types.SWITCH_ACCOUNT,switchData))
        
        setTimeout(() => {
            setCheck(true)
            dispatch(setLoading({
                isLoading: false,
                isLoading_: false,
                isLoading_t : false
            }))
            // navigate(url.dashboard,{replace:true})
        }, 500)

    }


    const getBranches = () => {
        const postData = {
                token,
                url: remote_url.branches,
                actionType : types.GET_BRANCH,
                postData : [],
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
                errorAction : types.SET_ERROR,
                accountKey: account_key,
                loading :{
                    isLoading :false,
                    isLoading_ : false,
                    isLoading_t : false
                }
                // reset
            }
         dispatch(getRequest(postData))
    }

    const setBranchDetail = () => {

    }

    if (newDataBot) {
        branchInitDatatable(branches,setBranchDetail,['New Branch'],showAddBranchForm,'')
        dispatch(setGeneralAction(types.RESET_TRANSACTION_BOT, []))
    }


     return <DashboardWrapper> 
        {loading && <OverrlayLoader/>}
         {success_msg && <SuccessAlert msg={success_msg}/> }
         {error_msg && <ErrorAlert msg={error_msg}/> }

        <BreadCrumb pageTitle="Branch Management" main="Branch" current="Branch"/>
         {showAddForm && <AddBranchForm closeFormFunc={showAddBranchForm}/>}

           {/* Start Content*/}
      <div className="container-fluid">
        {loading_ && <Loader/> }
          <div className="table-responsive">
              <table className="table table-centered w-100 dt-responsive nowrap" id="products-datatable">
                  <thead className="table-light">
                  <tr>
                      <th className="all">Name</th>
                      <th>Main Account</th>
                      <th>Email</th>
                      <th>Phone No</th>
                      <th> Reg No </th>
                      <th>Profile Status</th>
                  </tr>
                  </thead>
                  {/* <tbody>

                                    </tbody> */}
              </table>
          </div>
      </div> 
      {/* container */}
    </DashboardWrapper>
}


export default BranchHome