import { Link } from "react-router-dom"
import { url } from "../../config/urls"
import AuthWrapper from "./AuthWrapper"

const ConfirmEmailPage = () => {
    return <AuthWrapper>
        <div className="card">
            <div className="card-header pt-4 pb-4 text-center bg-primary">
                <Link to={url.home}>
                    <span>
                        <img src={"/assets/img/b.png"} alt="" height="48"/>
                    </span>
                </Link>
            </div>

            <div className="card-body p-4">
                <div className="text-center m-auto">
                    <img src="/assets/images/mail_sent.svg" alt="mail sent" height="64" />
                    <h4 className="text-dark-50 text-center mt-4 fw-bold">Please check your email</h4>
                    <p className="text-muted mb-4">
                        A email has been send to <b>youremail@domain.com</b>.
                        Please check for an email from company and click on the included link to
                        reset your password. 
                    </p>
                </div>

                <form action="">
                    <div className="mb-0 text-center">
                        <button className="btn btn-primary" type="submit">
                            <i className="mdi mdi-home me-1"></i> Back to Home
                        </button>
                    </div>
                </form>

            </div> 
        </div>    
    </AuthWrapper>
}

export default ConfirmEmailPage