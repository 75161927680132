import OrderWrapper from "../../wrappers/OrderWrapper";
import React, {useEffect, useState} from "react";
import {TableWrapper} from "../_component";




const AllOrders = () => {
    const [addNewModal , setAddNewModal] = useState(false)
    return (
        <OrderWrapper
            main={'Store Fronts'}
            currentPage={'Orders'}
            current={'All Orders'}
            second={'Orders'}
            showAddNewForm={setAddNewModal}>
             <>
                 {/*<TableWrapper id={'order_table'} tableHeaders={*/}
                 {/*    [*/}
                 {/*        "",*/}
                 {/*        "Order ID",*/}
                 {/*        "Date",*/}
                 {/*        "Payment Status",*/}
                 {/*        "Total",*/}
                 {/*        "Payment Method",*/}
                 {/*        "Order Status",*/}
                 {/*        "Action",*/}
                 {/*    ]*/}
                 {/*}/>*/}
             </>
        </OrderWrapper>
    );
}

export default AllOrders