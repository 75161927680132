import {useEffect, useState} from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import {  ThriftBankAccModel } from "../../../../config/model/authModel"
import {calculateTime, getRequest, setGeneralAction} from "../../../../config/redux/actions"
import { AppState } from "../../../../config/redux/reducers"
import { AuthState } from "../../../../config/redux/reducers/tmsAuthReducer"
import { types } from "../../../../config/redux/types"
import { remote_url } from "../../../../config/urls/remote"
import DashboardWrapper from "../../DashboardWrapper"
import BreadCrumb from "../../layouts/BreadCrumb"
import { Loader } from "../../_components"
// import { AccountNavTab } from "../_components"
import { AddNewBankForm } from "./forms"
import {DEFAULT_INACTIVE_TIME} from "../../../../config/config";
import {bankInitDatable} from "../../../../config/datatableConfig";
import {ViewBankDetails} from "../thrifts";


// const $ = require('jquery')
// $.DataTable = require('datatables.net')

const BankListing = () => {
    const [showAddBankForm,setShowAddBankForm] = useState(false)
    const {success_msg,thriftBankAccounts,account_key,token,loading_t,newBankData,loginTime,user,role_id} = useSelector<AppState,AuthState>((state)=> state.tmsAuthReducer)
    //
    const dispatch = useDispatch()
    useEffect(() => {
        let time_diff = calculateTime(loginTime)
        if(time_diff <= DEFAULT_INACTIVE_TIME){
            getThriftBanks()
        }
    },[])

    //::
    const getThriftBanks =  () => {
         const getData = {
            token,
            url: remote_url.coopBankAccs,
            actionType : types.GET_THRIFTS_BANKS,
            postData : [],
             secretData : {
                 email:user.email,
                 mac_id :user.mac_id,
                 r_id: role_id
             },
            errorAction : types.SET_ERROR,
            accountKey: account_key,
            loading :{
                isLoading :false,
                isLoading_ : false,
                isLoading_t : true
            }
        }
         dispatch(getRequest(getData))
        bankInitDatable(thriftBankAccounts,setBankDetail)
    }



    const setBankDetail = (data:ThriftBankAccModel) => {
        dispatch(setGeneralAction(types.SET_BANK_DETAILS,data))
    }
    //HELPS TO RESET THE DATATABLE AFTER RECEIVE UPDATED DATA
    if(newBankData){
        bankInitDatable(thriftBankAccounts,setBankDetail)
        dispatch(setGeneralAction(types.RESET_NEW_BANK_DATA_BOT,[]))
    }
    if(success_msg){
        // handleAddNewBankForm()
    }


    //::::
    return (
        <DashboardWrapper showWarning={false}>
         <BreadCrumb pageTitle="Bank Accounts" current="Bank Listing" main="Thrift Settings"/>
            {showAddBankForm && <AddNewBankForm onClickFunc={setShowAddBankForm} /> }

            <ViewBankDetails/>
                
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        {/*{loading_t && <Loader/> }  */}
                                        <div className="row mb-2">
                                                <div className="col-sm-4"> </div>
                                                <div className="col-sm-8">
                                                    <div className="text-sm-end">
                                                        {/* <button type="button" className="btn btn-success mb-2 me-1"><i className="mdi mdi-cog"></i></button> */}
                                                        {/* <button type="button" className="btn btn-light mb-2 me-1">Import</button> */}
                                                        {/* <button type="button" className="btn btn-light mb-2">Export</button> */}
                                                        {thriftBankAccounts.length===0 &&
                                                        <Link to="#0"
                                                              onClick={()=>setShowAddBankForm(true)}
                                                              className="btn btn-primary mb-2"
                                                        >
                                                            <i className="mdi mdi-plus"></i>
                                                        </Link>
                                                        }
                                                    </div>
                                                </div>
                                        </div>
                                        

              
                                        <div className="table-responsive">
                                            <table className="table table-centered w-100 dt-responsive nowrap" id="thriftsBankAccountListingDataTable">
                                                <thead className="table table-striped table-responsive">
                                                    <tr>
                                                        <th>Account Name</th>
                                                        <th>Account Number</th>
                                                        <th>Bank Name</th>
                                                        <th>Verification Status</th>
                                                        <th>Status</th>
                                                        <th>Purpose</th>
                                                        <th style={{width:"85px"}}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                </tbody>
                                           
                                            </table>
                                        </div>
                                    </div> 
                                </div> 
                            </div> 
                        </div>
                    </div> 
                </div> 
           
        </DashboardWrapper>
    )
}

export default BankListing