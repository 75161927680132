import React, {useEffect } from 'react';
import DashboardWrapper from '../DashboardWrapper'
import BreadCrumb from '../layouts/BreadCrumb'
import Chart from "react-apexcharts";

// import ''
// import '../../../assets/css/responsive.bootstrap5.css'
// import '../../../assets/css/dataTables.bootstrap5.css'
import { campaignsChartOption, revenueChartOptions, savingsMothlyGraph, transactionsGraph } from '../../../config/charts/optionConfig';
import RadialBarCard from '../dashboard/components/RadialBarCard';
import LineChartCard from '../dashboard/components/LineChartCard';
import { CardSummary } from '../_components';
import {expenseGraph, incomeGraph, incomesExpensesGraph} from "./graph/graphInit";
import {remote_url} from "../../../config/urls/remote";
import {types} from "../../../config/redux/types";
import {postRequest} from "../../../config/redux/actions";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../config/redux/reducers";
import {AuthState} from "../../../config/redux/reducers/tmsAuthReducer";
import {AnalyticState} from "../../../config/redux/reducers/tmsAnalyticsReducer";
import {savingsMonthlyAreaGraphConfig} from "../savings/graph/init";
import {ExpenseDashboardFilter} from "./components";


const TransactionsDashboard = () => {

    const dispatch = useDispatch()
    const {token,account_key,user,role_id} = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
    const {expensesDashboardYear,expenseDashboard} = useSelector<AppState,AnalyticState>((state) => state.tmsAnalyticsReducer)

    useEffect(() => {
        getExpensesAnalytics()
    },[])
    //
    const getExpensesAnalytics = () => {
        const postData = {
            token,
            url: remote_url.expensesAnalytics,
            actionType : types.GET_EXPENSE_ANALYTICS,
            postData : {
                year : expensesDashboardYear
            },
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            errorAction : types.SET_ERROR,
            accountKey: account_key,
            loading :{
                isLoading :false,
                isLoading_ : false,
                isLoading_t : true
            }
        }
        dispatch(postRequest(postData))
    }
    //
    return (
        <DashboardWrapper>
            <ExpenseDashboardFilter/>
            {/* summary */}
            <div className={'row'}>
                <div className={'col-sm-12 col-md-12 col-lg-6 mr-1'}>
                    <div className={'card'}>
                        <div className={'card-header'}>Expenses Chart</div>
                        <div className={'card-body'}>
                            <Chart
                                type="bar"
                                options={{
                                    ...expenseGraph,
                                    labels:expenseDashboard.expenses.labels
                                }}
                                series={[
                                    {
                                        data:expenseDashboard.expenses.series,
                                        name:expenseDashboard.expenses.name
                                    },
                                ]}
                                className="apex-charts mt-3 mb-3"
                                id="country-chart"
                                height= "300px"
                            />

                        </div>

                    </div>
                </div>

                <div className={'col-sm-12 col-md-12 col-lg-6 '}>
                    <div className={'card'}>
                        <div className={'card-header'}>Income Chart</div>
                        <div className={'card-body'}>
                            <Chart
                                type="bar"
                                options={{
                                    ...incomeGraph,
                                    labels:expenseDashboard.incomes.labels
                                }}
                                series={[
                                    {
                                        data:expenseDashboard.incomes.series,
                                        name:expenseDashboard.incomes.name
                                    },
                                ]}
                                className="apex-charts mt-3 mb-3"
                                id="country-chart"
                                height= "300px"
                            />

                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className={'col-12'}>
                    <div className={'card px-3 py-4'}>

                        <Chart
                            type="area"
                            options={{...incomesExpensesGraph,
                                ...{labels:expenseDashboard.incomes.labels}}}
                            series={[
                                {
                                    name: 'Income',
                                    data: expenseDashboard.incomes.series,
                                },
                                {
                                    name:'Expense',
                                    data: expenseDashboard.expenses.series,
                                },
                            ]}
                            id="sessions-overview"
                            className="apex-charts mt-3"
                            height= "309px"
                        />

                    </div>
                </div>
            </div>





        </DashboardWrapper>
    )
}


export default TransactionsDashboard