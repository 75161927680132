


interface Props  {
    //
    bg:string,
    leadText : string,
    text : string,
    iconName : string
}
   
const LoanAppSummaryCard : React.FC<Props> = ({bg,leadText, text, iconName}) => {
    return <> 
        <div className={`card text-white ${bg} overflow-hidden`}>
            <div className="card-body">
                <div className="toll-free-box text-center">
                    <h5> <i className={`mdi ${iconName}`}></i>
                        {leadText}
                        <hr/>
                        {text}
                    </h5>
                </div>
            </div> 
        </div>
    </>
}


export default LoanAppSummaryCard