import React from "react";
import {Link} from "react-router-dom";

export interface TabItem{
    component : React.ReactNode,
    href :  string //"#home-b2"
    tabName : string
    dataBsToggle : boolean//"tab"
    ariaExpanded : string // false , true
    className : string
    icon : string
    active : string //nav-link active

}
interface Props{
    isActive :string
    tabs :TabItem[]
    onTabClick :any,

}

const TabOne :React.FC<Props> = ({tabs,isActive,onTabClick}) => {
    return (
        <>

            <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                {tabs.map((tab,i=0)=>
                    <li className="nav-item" key={i}>
                        <Link
                            to={`#${tab.href}`}
                            data-bs-toggle={tab.dataBsToggle}
                            onClick={()=>onTabClick(tab.href)}
                            // aria-expanded={tab.ariaExpanded}
                            className={`nav-link ${isActive === tab.href ? 'active' : ''} `}>
                            <i className="mdi mdi-home-variant d-md-none d-block"></i>
                            <span className="d-none d-md-block">{tab.tabName}</span>
                        </Link>
                    </li>
                )}
            </ul>



            <div className="tab-content">
                {tabs.map((item,j=0)=>
                    <div key={j} className={`tab-pane ${isActive === item.href ? 'show active' : ''}`} id="home-b2">
                        {item.component}
                    </div>
                )}
            </div>
        </>
    )
}



export default TabOne