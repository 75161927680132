import {useEffect} from "react"
import {useForm} from "react-hook-form"
import {useDispatch, useSelector} from "react-redux"
import {calculateTime, postRequest, setGeneralAction} from "../../../../config/redux/actions"
import {AppState} from "../../../../config/redux/reducers"
import {AuthState} from "../../../../config/redux/reducers/tmsAuthReducer"
import {types} from "../../../../config/redux/types"
import {remote_url} from "../../../../config/urls/remote"
import {DEFAULT_INACTIVE_TIME} from "../../../../config/config";
import {LoansReducerState} from "../../../../config/redux/reducers/tmsLoansReducer";


interface FormValues {
    fromDate : string, 
    toDate : string,
    status : string
}

const LoanAppFilter = () => {
    const {token,account_key,loginTime,user,role_id} = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
    const {isLoading_t,loans_app,newDataBot,loanFilter} = useSelector<AppState,LoansReducerState>((state)=> state.tmsLoansReducer)
    const dispatch = useDispatch()
    const { register, handleSubmit, formState: { errors },reset } = useForm<FormValues>()
    
    
    useEffect(() => {
        let current_time = calculateTime(loginTime)

        if(current_time <= DEFAULT_INACTIVE_TIME){
            getLoansApplication({
                fromDate:loanFilter.fromDate,
                toDate:loanFilter.toDate,
                status: loanFilter.status
            })
        }
    },[])
   

 const getLoansApplication = (data:FormValues) => {
      //
      dispatch(setGeneralAction(types.SET_LOAN_FILTERS,{
        fromDate:data.fromDate,
        toDate: data.toDate,
        status: data.status
      }))

    const postData = {
        token,
        url: remote_url.getLoanApplication,
        actionType : types.GET_LOAN_APPLICATION,
        postData :data,
        secretData : {
            email:user.email,
            mac_id :user.mac_id,
            r_id: role_id
        },
        errorAction : types.SET_ERROR,
        accountKey: account_key,
        loading :{
            loading: false,
            loading_ :false,
            isLoading_t : true
        }
        // reset
    }
    //
    dispatch(postRequest(postData))
 }


 return <>
    
      <div className='card px-3 py-4'>
            <form  onSubmit={handleSubmit(getLoansApplication)}>
                <div className='row'>
                    {/* from Date */}
                    <div className='col-lg-3'>
                        <div className="mb-3">
                        <label htmlFor="example-date" className="form-label">From Date</label>
                        <input className="form-control"  type="date" {...register('fromDate')}/>
                    </div>
                    </div>
                    {/* to Date */}
                    <div className='col-lg-3'>
                        <div className="mb-3">
                        <label htmlFor="example-date" className="form-label">To Date</label>
                        <input className="form-control"  type="date"
                        {...register('toDate')}
                        />
                    </div>
                    </div>
                    {/* transaction status  */}
                    <div className='col-lg-3'>
                        <div className="mb-3">
                            <label htmlFor="example-date" className="form-label">Loan Status</label>
                            <select  {...register('status')} className="form-select mb-3" defaultValue={loanFilter?.status}>
                                <option>select transaction status</option>
                                <option value="0">Pending</option>
                                <option value="1">Approved</option>
                                <option value="2">Disapproved</option>
                                <option value="3">Disbursed</option>
                                <option value="4">Payment Completed</option>
                            </select>
                        </div>
                    </div>
                    {/* filter button */}
                    <div className='col-lg-3'>
                        <div className="mb-3">
                            <label htmlFor="example-date" className="form-label">-</label>
                            <input className="form-control btn btn-primary" type="submit" name="date" value="Filter"/>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        </>
}



export default LoanAppFilter