import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {AppState} from "../../../../config/redux/reducers";
import {AuthState} from "../../../../config/redux/reducers/tmsAuthReducer";

const QuickMenu = () => {
    const {quickMenu} = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
    return <>
        <li className="dropdown notification-list d-none d-sm-inline-block">
            <Link className="nav-link dropdown-toggle arrow-none" data-bs-toggle="dropdown" to="#" role="button"
                  aria-haspopup="false" aria-expanded="false">
                <i className="dripicons-view-apps noti-icon"></i>
            </Link>
            <div className="dropdown-menu dropdown-menu-end dropdown-menu-animated dropdown-lg p-0">

                <div className="p-2">
                    <div className="row g-0">
                        {quickMenu?.links?.map((link, j=0) => <div key={j} className="col">
                            <Link className="dropdown-icon-item" to={link.link}>
                                <i className={link.iconName}></i>
                                <span>{link.name}</span>
                            </Link>
                        </div>)
                        }
                    </div>

                    <div className="row g-0">
                        {quickMenu?.links_two?.map((link,i=0) => <div  key={i} className="col">
                            <Link  className="dropdown-icon-item" to={link.link}>
                                <i className={`${link.iconName} `}></i>
                                <span>{link.name}</span>
                            </Link>
                        </div>)
                        }
                    </div>

                </div>

            </div>
        </li>
    </>
}

export default  QuickMenu