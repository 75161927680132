import React from "react";

interface ComponentProps{
    label_title : string,
    html_for : string
}

const LabelWrapper:React.FC<ComponentProps> = ({label_title,html_for}) => {
    return <>
        <label htmlFor={html_for} className="mb-0">
            {label_title}
        </label>
    </>
}


export default  LabelWrapper