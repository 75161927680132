import { useDispatch, useSelector } from "react-redux"
import { Link, Navigate, useNavigate } from "react-router-dom"
import { postRequest } from "../../config/redux/actions"
import { AppState } from "../../config/redux/reducers"
import { AuthState } from "../../config/redux/reducers/tmsAuthReducer"
import { types } from "../../config/redux/types"
import { url } from "../../config/urls"
import { remote_url } from "../../config/urls/remote"
import { Logout } from "../auth"
import DashboardWrapper from "./DashboardWrapper"

const PreLogout = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {token, success_msg,account_key,user,role_id} = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
    //:::::::::::::::::::: :::::::::::::::::::

 

const logOut = async  ()  => {
    const postData = {
        token,
        accountKey:account_key,
        url:remote_url.logout,
        actionType: types.LOG_OUT,
        postData : [],
        secretData : {
            email:user.email,
            mac_id :user.mac_id,
            r_id: role_id
        },
        errorAction : types.LOG_OUT,
        loading :{
            isLoading :true,
            isLoading_ : false,
            loading_t : false
        }
    }
    dispatch(postRequest(postData))      
}

if(success_msg!=="" && token ===''){
    return <Navigate to={url.logout}/>
     // return <Logout/>
}
    
    return <>
    <DashboardWrapper showWarning={false}>
        <div className="tab-pane show active" id="bs-modals-preview">
            <div id="standard-modal" className="modal fade show" tabIndex={-1} aria-labelledby="standard-modalLabel" style={{display: "block"}} aria-modal="true" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="standard-modalLabel">Confirm Logout?</h4>
                            <Link to={url.dashboard} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></Link>
                        </div>

                        <div className="modal-body">Are you sure you want to Logout?</div>
                        <div className="modal-footer">
                            <Link to={url.dashboard} className="btn btn-light" data-bs-dismiss="modal">Close</Link>
                            <button className="btn btn-info px-2" onClick={()=>logOut()}>Logout</button>
                        </div>

                    </div>

                </div>

            </div>

        </div>
        <div className="modal-backdrop fade show"></div>
    </DashboardWrapper>
    </>
}


export default PreLogout