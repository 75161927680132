import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import {calculateTime, postRequest, setGeneralAction} from "../../../../config/redux/actions"
import { AppState } from "../../../../config/redux/reducers"
import { AnalyticState } from "../../../../config/redux/reducers/tmsAnalyticsReducer"
import { AuthState } from "../../../../config/redux/reducers/tmsAuthReducer"
import { types } from "../../../../config/redux/types"
import { remote_url } from "../../../../config/urls/remote"
import {DEFAULT_INACTIVE_TIME} from "../../../../config/config";

const LoansDashboardFilter = () => {
    const {token,account_key,loginTime,user,role_id,isAdminMode} = useSelector<AppState, AuthState>((state)=> state.tmsAuthReducer)
    const {loansDashboardYear} = useSelector<AppState,AnalyticState>((state) => state.tmsAnalyticsReducer)
    const dispatch = useDispatch()

    //
    useEffect(() => {
        const time_diff = calculateTime(loginTime)
        //
        if(time_diff<=DEFAULT_INACTIVE_TIME){
            getLoansAnalytics(loansDashboardYear)
        }
    },[])


    //
    const getLoansAnalytics = (year :number) => {
        dispatch(setGeneralAction(types.SET_LOANS_DASHBOARD_FILTER,year))
        const postData = {
                token,
                url: remote_url.loansAnaltics,
                actionType : types.GET_LOANS_DASHBOARD_DATA,
                postData : {
                    year : year,
                    user_id : isAdminMode ? 'all' : user.id
                },
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
                errorAction : types.SET_ERROR,
                 accountKey: account_key,
                loading :{
                    isLoading :false,
                    isLoading_ : false,
                    isLoading_t : true
                }
        }
        dispatch(postRequest(postData))
    }



    return     <>
        <div className="row">
            <div className="col-12">
                <div className="page-title-box">
                    <div className="page-title-right">
                        <form className="d-flex">
                            {/* fromDate */}
                            <div className="input-group">
                                <select className="form-control form-control-light form-select" 
                                id="dash-daterange" 
                                defaultValue={loansDashboardYear} 
                                onChange={(e) => getLoansAnalytics(Number(e.target.value))
                                }>
                                    <option value={2021}>2018</option>
                                    <option value={2021}>2019</option>
                                    <option value={2021}>2020</option>
                                    <option value={2021}>2021</option>
                                    <option value={2022}>2022</option>
                                    <option value={2023}>2023</option>
                                    <option value={2024}>2024</option>
                                </select>
                                {/*<span className="input-group-text bg-primary border-primary text-white" */}
                                {/*    onClick={()=>getLoansAnalytics()} >*/}
                                {/*    <i className="mdi mdi-arrow-right font-13"></i>*/}
                                {/*</span>*/}
                            </div>
                            {/* button */}
                            {/* <Link to="" className="btn btn-primary ms-2">
                                <i className="mdi mdi-autorenew"></i>
                            </Link> */}
                        </form>
                    </div>
                    <h4 className="page-title">Loans Dashboard</h4>
                </div>
            </div>
        </div>
    </>
}
export default LoansDashboardFilter