import React from "react";

interface Props{
    imgName ? : string
}

const ComingSoon: React.FC<Props> = ({imgName='coming_soon'}) => {
    return <div className={'card p-5'}>
        <div className={'text-center'}>
            <img src={`/assets/img/${imgName}.gif`} alt={'empty chat'} height={'300'} width={'300'}/>
        </div>
    </div>
}

export default  ComingSoon