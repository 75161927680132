import {copyYourLink, toNigerianCurrency} from "../../../config/redux/actions";
import {Spinner} from "../../../components";
import React from "react";

interface  Props{
    bank_name :string
    account_number :string
    amount_paid :string | number
    account_name :string
    expires_at :string
    dispatch:any,
    loading:boolean,
    requeryNow:any,
    showBtn : boolean

}
const BankDetailsWrapperComponent:React.FC<Props> = ({bank_name,account_number,dispatch,
                                                         amount_paid,account_name,expires_at,loading,requeryNow,showBtn}) => {
    return(
        <>
            <div className="alert alert-success text-dark" role="alert">
                <h4 className="alert-heading">Bank Transfer Details!</h4>
                <p>Kindly make a transfer to the account details below.</p>
                <hr/>
                <div className="mb-0 text-dark">
                    Bank : <b> {bank_name} </b> <br/>
                    Account Number:<b>{account_number}</b>
                    <span className={'px-1'} style={{cursor:'pointer'}}
                          onClick={()=>copyYourLink(account_number,dispatch,'Bank Account Number Copied')}>
                                                  <i className={'uil uil-copy-alt'}></i>
                                                </span>

                    <br/>

                    Account Name : <b>{account_name}</b><br/>
                    Expires at: <b>{expires_at}</b><br/>
                    Amount:<b>{toNigerianCurrency(Number(amount_paid))}</b>
                    <span className={'px-1'} style={{cursor:'pointer'}}
                                  onClick={()=>copyYourLink(amount_paid,dispatch,'Amount Copied')}>
                                                  <i className={'uil uil-copy-alt'}></i>
                                                </span>


                </div>
                <hr/>
                {showBtn &&
                    <>
                        {
                            loading
                                ?
                                <Spinner color="primary"/>
                                :
                                <div onClick={()=>requeryNow()} className={'btn btn-outline-primary btn-sm text-center'}>
                                    I have made payment
                                </div>
                        }
                    </>
                }
            </div>
        </>
    )
}

export default BankDetailsWrapperComponent