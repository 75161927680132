import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {AppState} from "../../../../config/redux/reducers";
import {AuthState} from "../../../../config/redux/reducers/tmsAuthReducer";
import {SavingsReducerState} from "../../../../config/redux/reducers/tmsSavingsReducer";
import {MemebersState} from "../../../../config/redux/reducers/tmsMemeberReducerState";
import {remote_url} from "../../../../config/urls/remote";
import {types} from "../../../../config/redux/types";
import {postRequest, setGeneralAction} from "../../../../config/redux/actions";
import {MembersProps} from "../../../../config/model/authModel";
import {Link} from "react-router-dom";
import {ViewSavings} from "../_components";

import {GeneralFormInput} from "../../../../components/form";
import {Spinner} from "../../../../components";
import {removeBackDropModal, removeClass} from "../../../../config/action";

type FormValues = {
    transaction_reference: string,
    amount: string,
    payment_status: string,
    saving_transaction_status: string,
    channel: string,
    description: string,
    bank_name: string,
    paid_at: string,

}
type SavingsFormProps = {
    show: string,
    closeModalFunc?: any,
    // userD  : UsersModel
}

const EditSavingForm: React.FC<SavingsFormProps> = ({show ,closeModalFunc}) => {
    const dispatch = useDispatch()
    const {
        register,
        handleSubmit,
        formState: {errors},
        reset,
        setValue,
        watch,
        getValues
    } = useForm<FormValues>({mode: 'onTouched'})
    const {token, account_key, user, role_id} = useSelector<AppState, AuthState>((state) => state.tmsAuthReducer)
    const {
        singleSavings,
        loading_,
        success_msg,
        error_msg,
        savingsFilter
    } = useSelector<AppState, SavingsReducerState>((state) => state.tmsSavingsReducer)
    const {userDetails, memberFilter} = useSelector<AppState, MemebersState>((state) => state.tmsMemeberReducerState)
    const amountSavings = watch('amount')
    const selectedChannel = singleSavings.payment_channel
    const [showDesc, setShowDesc] = useState(singleSavings.payment_channel)
    const [showPaidAt, setPaidAt] = useState(false)





    //CREATING SAVINGS
    const createSavings = (data: FormValues) => {
        let trans_status =Number(data.saving_transaction_status);
        const postData = {
            token,
            url: remote_url.updateSavings,
            actionType: types.UPDATE_SAVINGS_DETAILS,
            postData: {
                transaction_reference: singleSavings.transaction_reference,
                amount: singleSavings.thrift_amount,
                payment_status: trans_status===1?1:0,
                saving_transaction_status: trans_status,
                channel: singleSavings.payment_channel,
                description: data.description ?? singleSavings.description,
                bank_name: data.bank_name ?? singleSavings.bank_name,
                paid_at: trans_status === 1 ? data.paid_at : singleSavings.paid_at,
                //
                fromDate: savingsFilter.fromDate,
                toDate: savingsFilter.toDate,
                status: savingsFilter.status,
                user_id: userDetails?.user?.id,
                //in case the request is coming from members page
                statusFilter: memberFilter.status,
                roleFilter: memberFilter.role,
                branchFilter: memberFilter.branch
            },
            secretData: {
                email: user.email,
                mac_id: user.mac_id,
                r_id: role_id
            },
            errorAction: types.SET_ERROR,
            accountKey: account_key,
            loading: {
                isLoading: false,
                isLoading_: true,
                isLoading_t: false
            }
            // reset
        }
        //
        dispatch(postRequest(postData))
    }


    if (success_msg) {
        closeModalFunc('')
        removeBackDropModal()
    }


    const clearData = () => {
        setValue('amount', '')
        setValue('channel', '')
        dispatch(setGeneralAction(types.GET_USER, MembersProps))
        dispatch(setGeneralAction(types.CLEAR_SAVING_DATA, []))
    }

    useEffect(() => {
        // clearData()
    }, [])
    //
    return <>
        <div className={`offcanvas offcanvas-end ${show}`}
             tabIndex={-1}
             id="savingsEdit"
             aria-labelledby="offcanvasRightLabel"
             // data-bs-backdrop={"false"}
        >
            {/* data-bs-backdrop="true" */}
            <div className="offcanvas-header">
                <h5 id="offcanvasRightLabel">
                    <small>{singleSavings ? 'h Update Savings Details: ' : 'New Savings for :'} {show}</small>
                            <b className={'text-success'}>{userDetails?.user?.name}</b>
                </h5>
                <Link to="#0" onClick={() => clearData()} className="btn-close text-reset" data-bs-dismiss="offcanvas"
                      aria-label="Close"></Link>
            </div>

            <div className="offcanvas-body">
                {
                    <form onSubmit={handleSubmit(createSavings)}>

                        <div>
                            <table className={'table table-striped'}>
                                <tbody>
                                    <tr>
                                        <td>Reference</td>
                                        <td>{singleSavings.transaction_reference}</td>
                                    </tr>

                                    <tr>
                                        <td>Amount</td>
                                        <td>{singleSavings.actual_amount}</td>
                                    </tr>

                                    <tr>
                                        <td>Payment Status</td>
                                        <td>{singleSavings.payment_status}</td>
                                    </tr>

                                    <tr>
                                        <td>Payment Channel</td>
                                        <td>{singleSavings.payment_channel}</td>
                                    </tr>
                                    <tr>
                                        <td>Description</td>
                                        <td>{singleSavings.description}</td>
                                    </tr>

                                    <tr>
                                        <td>Bank Name</td>
                                        <td>{singleSavings.bank_name}</td>
                                    </tr>

                                    <tr>
                                        <td>Paid At</td>
                                        <td>{singleSavings.paid_at}</td>
                                    </tr>
                                </tbody>
                            </table>

                            {/* saving_transaction_status */}
                            <div className="mb-3">
                                <label htmlFor="simpleinput" className="form-label">Savings Approval Status</label>
                                <select className="form-select mb-3"
                                        {...register('saving_transaction_status')}
                                        defaultValue={singleSavings.payment_status??''}
                                        onChange={ (e)=>setPaidAt(!showPaidAt)}
                                >
                                    <option value='2'> Disapproved</option>
                                    <option value='1'> approved</option>
                                </select>
                            </div>


                            {
                                showDesc === 'bank' &&
                                <>

                                    <GeneralFormInput defaultVal={singleSavings.bank_name} type={'text'}
                                                      register={register} label={'Bank'} fieldName={'bank_name'}/>
                                </>
                            }

                            <GeneralFormInput defaultVal={singleSavings.description} type={'textarea'}
                                              register={register} label={'Description'}
                                              fieldName={'description'}/>

                            {/* paid_at */}
                            {
                                showPaidAt &&
                                <div className="mb-3">
                                    <label htmlFor="simpleinput" className="form-label">Paid At</label>
                                    <input type="datetime-local"
                                           className={`form-control ${errors.paid_at?.message && 'is-invalid'}`}
                                           {...register('paid_at',{required:{
                                                   value:true,
                                                   message:'paid at is required'
                                               }})}
                                    />
                                </div>
                            }



                            {
                                loading_
                                    ?
                                    <Spinner color='primary'/>
                                    :
                                    <input type="submit" value="Update Savings" className="btn btn-outline-primary"/>
                            }
                        </div>
                        {/*  */}
                    </form>
                }
            </div>

        </div>

        {
           // <div id={'offcanvas-backdrop'} className={`offcanvas-backdrop fade ${show}`}></div>
        }
    </>
}

export default EditSavingForm



{/* amount */}
{/*
                              <div className="mb-3">
                                <label htmlFor="simpleinput" className="form-label">Amount</label>
                                <input
                                    type="text"
                                    defaultValue={singleSavings.actual_amount}
                                       className={`form-control ${errors.amount && 'is-invalid'}`}
                                       {...register('amount', {
                                           required: '*amount is required',
                                           pattern: {
                                               value: /^(?:0|[1-9]\d+|)?(?:.?\d{0,2})?$/,
                                               message: 'invalid amount enter'
                                           }
                                       })}
                                />
                                <small className={'helper-text'}>amount
                                    <b className={'text-primary'}>{Number(amountSavings).toLocaleString()}</b>
                                </small>
                                {errors.amount && <div className="invalid-feedback"> {errors.amount.message}</div>}
                            </div>

                            payment_status

                            <div className="mb-3">
                                <label htmlFor="simpleinput" className="form-label">Payment Status</label>
                                <select className="form-select mb-3"
                                        {...register('payment_status', {
                                            required: '*channel is Required',
                                            onChange: (e) => setShowDesc(e.target.value)
                                        })}
                                        defaultValue={singleSavings.payment_status}
                                >
                                    <option value='0'> unpaid</option>
                                    <option value='1'> paid</option>
                                </select>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="simpleinput" className="form-label">Payment Channel<
                                    sub className={'text-success'}>current payment
                                    channel: {singleSavings.payment_channel}</sub>
                                </label>
                                <select
                                    className="form-select mb-3"
                                    {...register('channel', {
                                        required: '*channel is Required',
                                        onChange: (e) => setShowDesc(e.target.value)
                                    })}
                                    defaultValue={selectedChannel}
                                >
                                    <option value=''>Select payment Channel</option>
                                    <option value='bank'>Bank Deposit/Bank Transfer</option>
                                    {role_id !== 3 && <option value='cash'>Cash</option>}
                                </select>
                            </div>
                        */}