import React from 'react'
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";


interface Props {
    className : string,
    classNameOne:string,
types : string,
 title : string,
 total : string,
 arrow : string ,
 percentage : string ,
 data : ApexOptions,
 id : string
 series ? : any
}



export const SummaryCard:React.FC<Props> = ({
    classNameOne,
                                                types ,
                                                title,total,arrow,
                                                percentage,data,id,
                                                series,
                                                className}) => {
  return (
    <>
     <div className={className}>
                                <div className={`card rounded ${classNameOne} shadow-lg`}>
                                    <div className="card-body">
                                        <div className="row align-items-center">
                                            <div className="col-6">
                                                <h4 className=" text-white text-bold fw-bold mt-0 text-truncate"
                                                    title={title}>{title}</h4>
                                                <h3 className={arrow==='up'? "my-2 py-1 text-success": "my-2 py-1 text-danger"}>{total}</h3>
                                                <p className="mb-0 text-muted">
                                                    <span className={arrow==='up'? "text-success me-2": "text-danger me-2"}>
                                                        <i className={arrow === 'up' ? "mdi mdi-arrow-up-bold":"mdi mdi-arrow-down-bold"}>
                                                        </i>
                                                            {percentage}%
                                                        </span>
                                                </p>
                                            </div>
                                            <div className="col-6">
                                                <div className="text-end">
                                                <Chart
                                                    type={types==="bar"? 'bar':'line'}
                                                    options={data}
                                                    series={data.series}
                                                    className="apex-charts"
                                                    id={id}
                                                />
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
    
    
    </>
  )
}
