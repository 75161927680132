import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../config/redux/reducers";
import {AuthState} from "../../../../config/redux/reducers/tmsAuthReducer";
import {useForm} from "react-hook-form";
import {remote_url} from "../../../../config/urls/remote";
import {types} from "../../../../config/redux/types";
import {postRequest} from "../../../../config/redux/actions";
import {Spinner} from "../../../../components";
import {ErrorAlert, SuccessAlert} from "../../../../components/alert";

interface FormValues {
    status : string ,
    account_for : string,
    id: number
}

const ViewBankDetails = () => {

    const dispatch = useDispatch()
    const { register, handleSubmit, formState: { errors },reset } = useForm<FormValues>()
    const {thriftBankAccount,token,role_id,account_key,user,loading_,success_msg,error_msg} = useSelector<AppState,AuthState>((state)=> state.tmsAuthReducer)

    const updatePreference = (data:FormValues) => {
        //
        data.id = thriftBankAccount.id
        const postData = {
            token,
            url: remote_url.coopBankAccountsUpdatePreference,
            actionType : types.UPDATE_BANK_DETAILS_PREFERENCE,
            postData : data,
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            errorAction : types.SET_ERROR,
            accountKey: account_key,
            loading :{
                isLoading :false,
                isLoading_ : true,
                isLoading_t : false
            }
        }
        //
        dispatch(postRequest(postData))
    }

    return <>
        <div className="offcanvas offcanvas-end" tabIndex={-1}  id="bankDetails" aria-labelledby="bankDetails">
            <div className="offcanvas-header">
                <h5 id="bankDetails">Bank Details </h5>

                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
                {success_msg && <SuccessAlert msg={success_msg}/>}
                {error_msg && <ErrorAlert msg={error_msg}/>}

                <table className={'table table-striped table-condensed '}>
                    <thead> </thead>
                    {/**/}
                    <tbody>
                        <tr>
                            <td>Bank Name</td>
                            <td>{thriftBankAccount?.bank?.name}</td>
                        </tr>

                        <tr>
                            <td>Account Name</td>
                            <td>{thriftBankAccount?.account_name}</td>
                        </tr>

                        <tr>
                            <td>Account Number</td>
                            <td>{thriftBankAccount?.account_number}</td>
                        </tr>

                        <tr>
                            <td>Status</td>
                            <td>{thriftBankAccount?.status === 1? <span>Active</span> : <span>Inactive</span>}</td>
                        </tr>

                        <tr>
                            <td>Verification Status</td>
                            <td>{thriftBankAccount?.is_verified===1 ? <span>Verified</span>: <span>Unverified</span>}</td>
                        </tr>
                    </tbody>
                    {/**/}
                </table>
                {/* */}
                <hr/>
                {/*

                 <div className={'shadow p-3'}>
                    <div className={''}>
                        <form onSubmit={handleSubmit(updatePreference)} id={"form"}>
                            <div>
                                <label>Update Account Status</label>
                                <select className={`form-select mb-2 ${errors.status && 'is-invalid'}`}
                                        defaultValue={thriftBankAccount?.status}
                                        {...register('status',{
                                            required:"this field is required"
                                        })}>

                                    <option value={''}>Select Bank Account status</option>
                                    <option value={'0'}>Inactive</option>
                                    <option value={'1'}>Active</option>
                                </select>
                                {errors.status && <small className='invalid-feedback'>{errors.status.message}</small>}
                            </div>
                            <div>
                                <label>Update Account Purpose</label>
                                <select className={`form-select mb-2 ${errors.account_for && 'is-invalid'}`}  defaultValue={thriftBankAccount?.account_for}
                                        {...register('account_for',{
                                            required:'this field is required'
                                        })}>
                                    <option value={''}>Select Bank Account Purpose</option>
                                    <option value={'0'}>All(Savings,Loans,Expenses)</option>
                                    <option value={'1'}>Savings Only</option>
                                    <option value={'2'}>Loans Only</option>
                                    <option value={'3'}>Expenses Only</option>
                                </select>
                                {errors.account_for && <small className='invalid-feedback'>{errors.account_for.message}</small>}
                            </div>

                        </form>

                    </div>
                    <div className={''}>
                        {loading_ ? <Spinner color={'primary'}/> : <button form={'form'} className={'btn btn-info form-control'} >Update Preference</button> }

                    </div>
                </div>
                */}



                {success_msg && <SuccessAlert msg={success_msg}/>}
                {error_msg && <ErrorAlert msg={error_msg}/>}
            </div>
        </div>

    </>
}

export default  ViewBankDetails