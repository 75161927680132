import {CustomModalWrapper, ProfileDetailCard} from "../_components";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../config/redux/reducers";
import {AuthState} from "../../../config/redux/reducers/tmsAuthReducer";
import {MemebersState} from "../../../config/redux/reducers/tmsMemeberReducerState";
import React from "react";
import {useForm} from "react-hook-form";
import {remote_url} from "../../../config/urls/remote";
import {types} from "../../../config/redux/types";
import {postRequest, setGeneralAction} from "../../../config/redux/actions";
import {Spinner} from "../../../components";

interface FormValues {
    status : string
    role_id : string
}
interface Props{
    closeFunc : any
}
const EditUserForm:React.FC<Props> = ({closeFunc}) => {
    let current_page = 'EditUser'
    const dispatch = useDispatch()
    const { register, handleSubmit, formState: { errors },reset,setValue } = useForm<FormValues>()
    const {success_msg,userDetails,loading_,memberFilter} = useSelector<AppState,MemebersState>((state)=> state.tmsMemeberReducerState)
    const {token,account_key,user,role_id,user_membership_status,all_roles} = useSelector<AppState,AuthState>((state)=> state.tmsAuthReducer)

   //updateMembers

    const updateMembers = (data:FormValues) => {

        const postData = {
            token,
            url: `${remote_url.updateMembers}/${userDetails?.user?.id}`,
            actionType : types.CREATE_USER,
            postData : data,
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            errorAction : types.SET_ERROR,
            accountKey: account_key,
            loading :{
                isLoading :false,
                isLoading_ : true,
                isLoading_t : false
            }
        }
        dispatch(postRequest(postData))
    }

    if(success_msg && current_page==='EditUser'){
        closeFunc(false)
    }

    return (
        <CustomModalWrapper  modalTitle={'Edit User'} onClickFunc={closeFunc}>

                <div className={'card-body'}>
                    {
                        userDetails &&  userDetails.user &&
                        <>
                            <ProfileDetailCard
                                profile_url={userDetails?.user?.profile_image}
                                email={userDetails?.user?.email}
                                name={userDetails?.user?.name}
                                summaryText={'Email'}
                                summaryValue={''}
                            />
                            <table className={'table  table-striped'}>
                                <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                </tr>
                                </thead>

                                <tbody>
                                <tr>
                                    <td>Full  Name</td>
                                    <td>{userDetails?.user?.name}</td>
                                </tr>

                                <tr>
                                    <td>Email</td>
                                    <td>{userDetails?.user?.email}</td>
                                </tr>

                                <tr>
                                    <td>Mode of Registration</td>
                                    <td>{userDetails?.registration_route}</td>
                                </tr>

                                <tr>
                                    <td>Status</td>
                                    <td>{userDetails?.status}</td>
                                </tr>

                                <tr>
                                    <td>Status</td>
                                    <td>{userDetails?.role_text}</td>
                                </tr>


                                </tbody>



                            </table>
                            <form className={'p-4'} onSubmit={handleSubmit(updateMembers)}>
                                <div className="form-group mb-3">
                                    <label>Member's Status</label>
                                    <select className={'form-select'}
                                            defaultValue={userDetails.status}
                                            {...register('status',{required:true})}>
                                        <option value={''}> Select  Status </option>
                                        {user_membership_status.map((item)=> <option key={item.id} value={item.id}>{item.name}</option>)}
                                    </select>
                                </div>

                                <div className="form-group mb-3">
                                    <label>Member's Role</label>
                                    <select className={'form-select'}
                                            defaultValue={userDetails.role_id}
                                            {...register('role_id',{required:true})}>
                                        <option value={''}>Select  User Role</option>
                                        {all_roles?.map((role,j=0)=>
                                            <option key={j} value={role.id}>{role.name}</option>
                                        )}
                                    </select>
                                </div>

                                <div className={'float-end'}>
                                    {loading_ ? <Spinner color={'primary'}/>  :  <input type={'submit'} value={'Update User'} className={'btn btn-info'}/> }
                                </div>

                            </form>

                        </>
                    }

                </div>

                <div className={'card-footer'}>
                </div>
        </CustomModalWrapper>
    )
}

export default EditUserForm