// /


import Chart from "react-apexcharts";
import {  topPerformingUsersConfig, transactionsGraph } from "../graph/init";
import { DashboardAnalyticModel } from "../../../../config/model/authModel";
//

interface Props {
 data : DashboardAnalyticModel,
 id : string,
 col?: string
}


const ReveneuCompareBarChart: React.FC<Props> = ({data,id,col='5'}) => {
    return  <>
      <div className="col-xl-8 col-lg-12">
            <div className="card">
                <div className="card-body">
                    {/* 
                        <div className="dropdown float-end">
                        <Link to="#0" className="dropdown-toggle arrow-none card-drop"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="mdi mdi-dots-vertical"></i>
                        </Link>
                        <div className="dropdown-menu dropdown-menu-end">
                            <Link to="#0" className="dropdown-item">Today</Link>

                            <Link to="#0" className="dropdown-item">Yesterday</Link>

                            <Link to="#0" className="dropdown-item">Last Week</Link>

                            <Link to="#0" className="dropdown-item">Last Month</Link>
                        </div>
                        </div> 
                    */}

                    <h4 className="header-title mb-3"><span className="badge bg-primary">Loans</span> Disbursed vs Returned</h4>
                    <Chart
                            type="bar"
                            options={{...transactionsGraph,...{labels : data?.savings?.labels}}}
                            series={
                                [
                                    { name: 'Disbursed', data: data?.loans?.series },
                                    { name: 'Returned', data: data?.loans?.returned },
                                    // { name: 'Inventory', data: data.inventories.series },
                                ]
                            }
                            height="300"
                            className="apex-charts"
                            id="dash-revenue-chart"
                        />
                   

                </div>
            </div>
       </div>
    </>
}


export default ReveneuCompareBarChart