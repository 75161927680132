import {Link} from "react-router-dom";
import React from "react";

type Props ={
    url :string,
    linkText:string,
    callToActionText:string
}
const Footer:React.FC<Props> = ({url,linkText,callToActionText}) => {
    return <>
        <div className="row">
            <div className="col-12 text-center">
                <p className="text-muted">{
                callToActionText}
                <Link to={url} className="text-muted ms-1"><b>{linkText}</b></Link>
                </p>
            </div>
        </div>
    </>
}

export default Footer