import {useForm} from "react-hook-form"
import {ErrorAlert, SuccessAlert} from "../../../../../components/alert";
import {remote_url} from "../../../../../config/urls/remote";
import {types} from "../../../../../config/redux/types";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../../config/redux/reducers";
import {MemebersState} from "../../../../../config/redux/reducers/tmsMemeberReducerState";
import {AuthState} from "../../../../../config/redux/reducers/tmsAuthReducer";
import {postRequest} from "../../../../../config/redux/actions";
import {useEffect} from "react";


type FormValues = {
    title: string,
    message: string,
    in_app_link : string,
    is_multiple_notification : boolean,
    push_notification_design_id : string,
    type: string, //push_notification
    color: string,
    total_no_of_receiver: number
}

const SendNotification = () => {
    const {register, handleSubmit, formState: {errors}, reset} = useForm<FormValues>()
    const {
        token,
        account_key,
        success_msg,
        error_msg,
        pushNotificationDesigns,
        user,
        role_id
    } = useSelector<AppState, AuthState>((state) => state.tmsAuthReducer)

    const dispatch = useDispatch();

    useEffect(() => {
        getPushNotificationDesigns()
    },[])

    //
    const onSubmit = (data: FormValues) => {
        //CREATE_EMAIL_SMS
        data.color = ""
        data.in_app_link =""
        data.is_multiple_notification = true


        const postData = {
            token,
            url: remote_url.sendPushNotification,
            actionType: types.CREATE_PUSH_NOTIFICATION,
            postData: data,
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            errorAction: types.SET_ERROR,
            accountKey: account_key,
            loading: {
                isLoading: true,
                isLoading_: true,
                isLoading_t: false
            }
        }
        //
        dispatch(postRequest(postData))
    }

    const getPushNotificationDesigns= () => {
        //
        const postData = {
            token,
            url: remote_url.getPushNotificationDesigns,
            actionType: types.GET_PUSH_NOTIFICATIONS_DESIGN,
            postData: {},
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            errorAction: types.SET_ERROR,
            accountKey: account_key,
            loading: {
                isLoading: false,
                isLoading_: false,
                isLoading_t: true
            }
        }
        dispatch(postRequest(postData))
    }

    return <>
        <div id="compose-modal" className="modal fade" tabIndex={-1} role="dialog"
             aria-labelledby="compose-header-modalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header modal-colored-header bg-primary">
                        <h4 className="modal-title" id="compose-header-modalLabel">Send Email to Members</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="p-1">



                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="modal-body px-3 pt-3 pb-0">
                                {success_msg && <SuccessAlert msg={success_msg}/>}
                                {error_msg && <ErrorAlert msg={error_msg}/>}
                                {/*receiver */}
                                <div className="mb-2">
                                    <label htmlFor="msgto" className="form-label">Push Notification Design</label>
                                    <select
                                        className = {`form-select`}
                                        {...register('push_notification_design_id')}
                                    >
                                        <option value={''}>Push Notification Design</option>
                                        {pushNotificationDesigns?.length>0 && pushNotificationDesigns?.map((design,i=0) =>
                                            <option key={i} value={i}>
                                                {design?.id}
                                            </option>
                                        )}
                                    </select>
                                    {errors.push_notification_design_id && <div className={'invalid-feedback'}>{" "}</div>}
                                </div>
                                {/*subject*/}
                                <div className="mb-2">
                                    <label htmlFor="mailsubject" className="form-label">Notification Title</label>
                                    <input type="text"
                                           className={`form-control && ${errors.title && 'is-invalid'}`}
                                           placeholder="Your subject"
                                           {...register('title', {
                                               required: '*receiver is Required',
                                           })}
                                    />
                                    {errors.title && <div className={'invalid-feedback'}>{errors.title.message}</div>}
                                </div>
                                {/* message body */}
                                <div className="write-mdg-box mb-3">
                                    <label className="form-label">Message</label>
                                    <textarea
                                        rows={5}
                                        className={`form-control && ${errors.message && 'is-invalid'}`}
                                        defaultValue={""}
                                        {...register(
                                            'message',
                                            {
                                                required: '*receiver is Required',
                                            })}
                                    />
                                </div>
                            </div>

                            <div className="px-3 pb-3">
                                <button type="submit" className="btn btn-primary">
                                    <i className="mdi mdi-send me-1"/> Send Message
                                </button>
                                <button type="button" className="btn btn-light" data-bs-dismiss="modal">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
                {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
        </div>

    </>
}

export default  SendNotification
