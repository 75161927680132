

import {createAsyncThunk} from "@reduxjs/toolkit";

import {postRequestNew, RequestPropsNew} from "../../../generalAction";




export const getSettlementRequests = createAsyncThunk("settlements/get-settlements", async (reqData:RequestPropsNew,thunkAPI) => {
    let res = await postRequestNew(reqData)
    if(res?.status){
        return res.data
    }
    else {
        return thunkAPI.rejectWithValue(res)
    }
})