import {Link, Navigate} from "react-router-dom"
import { useForm } from 'react-hook-form';

import { url } from "../../config/urls"
import AuthWrapper from "./AuthWrapper"
import { remote_url } from "../../config/urls/remote";
import { types } from "../../config/redux/types";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { AppState } from "../../config/redux/reducers";
import { AuthState } from "../../config/redux/reducers/tmsAuthReducer";
import { ErrorAlert, SuccessAlert } from "../../components/alert";
import { postRequest, setGeneralAction } from "../../config/redux/actions";
import { Spinner } from "../../components";
import {Footer, Header} from "./components";
import {pageKeys} from "./SuccessScreen";

type FormValues ={
    email : string,
    name : string,
    first_name:string
    last_name:string,
    account_name : string,
    password : string,
    phone_no : string,
    agree: boolean
}


const AccountSignUp = () =>
{
    const { register, handleSubmit,setValue, formState: { errors },reset } = useForm<FormValues>()
        const [showPassword, setShowPassword] = useState<boolean>(false)
    //@3
    const dispatch = useDispatch();
    //@4
    const {loading,success_msg,token,error_msg}= useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)

    useEffect(() => {
        //
        dispatch(setGeneralAction(types.CLEAR_MSG,[]))
    },[])

    const signUp = (data:FormValues) => {
        //
        data.name = data.first_name + " " + data.last_name
        const postData = {
            token : '',
            url:remote_url.accSignUp,
            actionType: types.SIGN_UP,
            postData : data,
            secretData : {
                email:'',
                mac_id :'',
                r_id :0
            },
            // reset,
            errorAction : types.SET_ERROR,
            loading :{
                isLoading :true,
                isLoading_ : false
            },
            accountKey :''
        }
        dispatch(postRequest(postData))
        //TODO :: clear form data

      
    }

    if(success_msg !==""){
        setValue('account_name','')
        setValue("email","")
        setValue("name","")
        setValue("phone_no","")
        setValue("password","")
        setValue("first_name","")
        setValue("last_name","")
        setValue("agree",false)
    }

    if(success_msg){
        return <Navigate to={`${url.successScreenPre}${pageKeys[1]}`}/>
    }

    return (
        <AuthWrapper>
            <div className="card shadow-lg" style={{background:'red !important'}}>
                <Header/>

                <div className="card-body px-4">
                    <div className="text-center w-90 m-auto">
                        <h4 className="text-dark-50 text-center mt-0 fw-bold">Cooperative Sign Up Page</h4>
                        <p className=" mb-4 alert alert-warning">
                            This page is specifically designed for creating a <b> Cooperative Account. </b>
                            If you are a <u> member </u>, kindly request the registration link from your <b>cooperative executive</b> to sign up as a member.
                        </p>
                    </div>

                    {!loading && success_msg && <SuccessAlert msg={success_msg}/>}
                    {!loading && error_msg && <ErrorAlert msg={error_msg}/> }


                    <form  onSubmit={handleSubmit(signUp)}>
                            <div className="mb-2">
                                <label htmlFor="fullname" className="form-label newLabel">Cooperative Society Name</label>
                                <input
                                    className={`form-control authFormControl ${errors.account_name && 'is-invalid'}`}
                                    type="text"
                                    placeholder="Enter cooperative name"
                                    {...register('account_name',{
                                        required: '*Email is Required',
                                        // minLength: { value: 6, message: 'Your Password is too Short' },
                                    })}

                                />
                                {errors.account_name  && (<div className="invalid-feedback">{errors.account_name.message}</div>)}
                            </div>



                            <div className="mb-2">
                                <label htmlFor="email-address" className="form-label newLabel">Email Address(Administrative Email)</label>
                                <input
                                    className={`form-control authFormControl ${ (errors.email || error_msg?.email) && 'is-invalid'}`}
                                    type="text" placeholder="Enter your email"
                                    {...register('email',{
                                        required: 'email is Required',
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "invalid email address"
                                        }
                                    })}
                                />
                                {errors.email  && (<div className="invalid-feedback">{errors.email.message}</div>)}
                                {error_msg?.email   && (<div className="invalid-feedback">{error_msg?.email[0]}</div>)}
                            </div>

                            <div className="mb-2 row">
                                <div className={'col-6'}>
                                    <label htmlFor="fullname" className="form-label newLabel">First Name</label>
                                    <input
                                        className={`form-control authFormControl ${errors.first_name && 'is-invalid'}`}
                                        type="text"
                                        placeholder="Enter your first name"
                                        {...register('first_name',{
                                            required: '*First Name is Required',
                                            // minLength: { value: 6, message: 'Your Password is too Short' },
                                        })}
                                    />
                                    {errors.first_name  && (<div className="invalid-feedback">{errors.first_name.message}</div>)}
                                </div>

                                <div className={'col-6'}>
                                    <label htmlFor="full name" className="form-label newLabel">Last Name</label>
                                    <input
                                        className={`form-control authFormControl ${errors.name && 'is-invalid'}`}
                                        type="text" placeholder="Enter your name"
                                        {...register('last_name',{
                                            required: '*last name is required',

                                        })}
                                    />
                                    {errors.last_name  && (<div className="invalid-feedback">{errors.last_name.message}</div>)}
                                </div>



                            </div>

                            <div className="mb-2">
                                <label htmlFor="fullname" className="form-label newLabel">Phone Number</label>
                                <input
                                    className={`form-control authFormControl ${(errors.phone_no || error_msg?.phone_no ) && 'is-invalid'}`}
                                    type="text"
                                    placeholder="Enter your phone number"
                                    {...register('phone_no',{
                                        pattern: {
                                            value: /^[0-9]{11}$/,
                                            message: "invalid phone number"
                                        },
                                        required: '*Phone Number is Required',
                                        maxLength: { value: 11, message: 'phone number is too long,11 digit allowed' },
                                        minLength: { value: 11, message: 'phone number is not complete,11 digit allowed' },

                                    })}
                                />
                                {errors.phone_no  && (<div className="invalid-feedback">{errors.phone_no.message}</div>)}
                                {error_msg?.phone_no   && (<div className="invalid-feedback">{error_msg?.phone_no[0]}</div>)}
                            </div>


                            <div className="mb-2">
                                <label htmlFor="password" className="form-label newLabel">Password</label>
                                <div className="input-group  input-group-merge">
                                    <input type={showPassword ? "text": "password"}
                                           className={`form-control authFormControl ${errors.password && 'is-invalid'}`}
                                           placeholder="Enter your password"
                                           {...register('password',{
                                               required: '*Password is Required',
                                               minLength: { value: 6, message: 'Your Password is too Short' },
                                           })}
                                    />
                                    <div className="input-group-text"  onClick={()=> setShowPassword(!showPassword)}>
                                        <span className="password-eye"></span>
                                    </div>
                                    {/*  */}
                                    {errors.password  && (<div className="invalid-feedback">
                                        {errors.password.message}</div>)}
                                </div>
                            </div>











                    <div className="mb-2">
                        <div className="form-check">
                            <input
                                type="checkbox"
                                className={`form-check-input ${errors.agree && 'is-invalid'}`}
                                id="checkbox-signup"
                                {...register('agree',{
                                    required: '*you must agree to the terms and condition',
                                })}
                            />
                            <label className="form-check-label" htmlFor="checkbox-signup"> I accept <Link to="#0" className="text-muted">Terms and Conditions</Link></label>
                            {errors.agree  && (<div className="invalid-feedback">{errors.agree.message}</div>)}
                        </div>
                    </div>

                    <div className="mb-3 text-center">
                        {
                            loading ?
                                <Spinner color="primary"/>
                                :
                                <button className="btn btn-primary" type="submit"> Sign Up </button>
                        }
                    </div>


                </form>
                </div>
            </div>

           <Footer url={url.signIn} linkText={'Log In'} callToActionText={'Already have account?'}/>
        </AuthWrapper>
    )
}

export default AccountSignUp