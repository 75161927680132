import OrderWrapper from "../../wrappers/OrderWrapper";
import TableWrapper from "../_component/TableWrapper";
import {GeneralModal} from "../_component";
import {useState} from "react";


const AllProducts = () =>{
    const [addNewModal , setAddNewModal] = useState(false)

        return (
            <OrderWrapper currentPage={'Products'}  main={'Store Fronts'}   current={'All  Products'}  second={'Products'} showAddNewForm={setAddNewModal}>
                <>
                    <GeneralModal closeModal={setAddNewModal} modalTitle={''} modalSize={'-scrollable'} showModal={addNewModal} key={''}>
                        <></>
                    </GeneralModal>

                    <TableWrapper
                        id={'product_table'}
                        tableHeaders={['','Product','Category','Created at','Price','Quantity','Status','Action']}
                    />
                </>
            </OrderWrapper>
        );
    }


export default AllProducts