import React from "react";
import {LabelWrapper} from "./index";

interface Props {
    defaultVal ?: string | number,
    type: string,
    register: any,
    label: string,
    fieldName: string,
    errors?: string,
    selectFunction?: any,
    children?: React.ReactNode,
    isReq ?: boolean
    disabled ? : boolean
}

const GeneralFormInput: React.FC<Props> = ({register, label, errors, type, fieldName, selectFunction, children,defaultVal,isReq=true,disabled}) => {
    return <>
        <div className="mb-3">
            <LabelWrapper label_title={`${label}`} html_for={`${label} input`}/>
            {
                type === 'email'
                    ?
                    <>
                        <input type="email" className={`form-control ${errors && 'is-invalid'}`}
                               {...register(`${fieldName}`, {
                                   required: '*this field  is required',
                                   pattern: {
                                       value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                       message: "invalid email address pattern"
                                   }
                               })}
                        />
                    </>
                    :
                    type === 'text'
                        ?
                        <>
                            <input type="text" defaultValue={defaultVal} className={`form-control ${errors && 'is-invalid'}`}
                                   {...register(`${fieldName}`,
                                       {
                                           required: '*this field is required',
                                       })}
                            />

                        </>
                        :
                        type === 'textarea' ?
                            <>
                            <textarea defaultValue={defaultVal} className={`form-control ${errors && 'is-invalid'}`}
                                      {...register(`${fieldName}`, {
                                          required: '*this field is required',
                                      })}
                            />
                            </>
                            :
                            type === 'file' ?
                                <>
                                    <input type="file"
                                           className={`form-control ${errors && 'is-invalid'}`} {...register(`${fieldName}`, {
                                        required: '*this field is required',
                                    })}/>
                                </>
                                :
                                type === 'select' ?
                                    <>
                                        <select
                                            id={label}
                                            className={`form-select ${errors && 'is-invalid'}`}
                                            defaultValue={defaultVal ?? ''}
                                            disabled={disabled?? false}
                                            {...register(`${fieldName}`,
                                                {
                                                    required : {
                                                        message: '*this field is required',
                                                        value: isReq
                                                    },
                                                    // onChange: (e: any) => selectFunction(e.target.value)
                                                    onChange: (e: any) => {
                                                        if(selectFunction){
                                                            selectFunction(e.target.value)
                                                        }
                                                    }
                                                }
                                            )
                                            }
                                        >
                                            {children}

                                        </select>
                                    </>
                                    :
                                    <></>
            }
            {errors && (<div className="invalid-feedback">{errors}</div>)}
        </div>
    </>
}

export default GeneralFormInput

