export const SettlementFilterProps = {
    status : 'success',
    from_date : '',
    to_date :''
}

export interface InAppSettlementModel{
   id: number,
   settlement_reference:string,
   settlement_account_number:string,
   settlement_bank_name:number,
   settlement_account_name:string,
   settlement_bank_code:number,
   amount:number,
   gateway_used_for_settlement: number,
   total_no_of_transactions: number,
   transaction_ids:number,
   status: number,
   response_status_code:number,
   created_at:string,
   updated_at:string,
   account_branch_id: number,
   description:string,
   settlement_category:string
}

export interface FlutterWaveSettlementModel{
    id: number,
    account_id: number,
    merchant_name: string,
    merchant_email: string,
    settlement_account: string,
    bank_code: string,
    transaction_date: string,
    due_date: string,
    processed_date: string,
    status: string,
    is_local: boolean,
    currency: string,
    gross_amount: number,
    app_fee: number,
    merchant_fee: number,
    chargeback: number,
    refund: number,
    stampduty_charge: number,
    net_amount: number,
    transaction_count: number,
    processor_ref: string,
    disburse_ref: string,
    disburse_message: string,
    channel: string,
    destination: string,
    fx_data: string,
    flag_message: string|null,
    source_bankcode: string,
    created_at: string,
    meta: [],
    refund_meta: [],
    chargeback_meta: []
}

export interface SettlementModel{
    "id": number,
    "domain":string,
    "status": string,
    "currency": string,
    "integration": number,
    "total_amount": number,
    "effective_amount": number,
    "total_fees": number,
    "total_processed": number,
    "deductions": number | null,
    "settlement_date": string,
    "settled_by": null,
    "createdAt": string,
    "updatedAt": string,
    "subaccount": {
        "id": number,
        "subaccount_code": string,
        "business_name": string,
        "description": string,
        "primary_contact_name": number | null,
        "primary_contact_email": number | null,
        "primary_contact_phone": number | null,
        "metadata": number | null,
        "percentage_charge": 1,
        "settlement_bank": string,
        "bank_id": number,
        "account_number":string,
        "currency": string,
        "active": boolean
    }
}


//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::
export interface SettlementReconciliationModel{

    settlements_pay_stack :SettlementModel[]
    settlements_bank :InAppSettlementModel[]
   settlements_flutter_wave : FlutterWaveSettlementModel[]
    settlement_filter : {
        status : string,
        from_date : string,
        to_date : string
    }

    settlement_loading :boolean
    current_gateway :string
    isNewSettlementData : boolean
}



