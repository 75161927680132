
import React, {useEffect, useState} from "react";
import AuthWrapper from "./AuthWrapper";
import {Footer, Header} from "./components";
import {url} from "../../config/urls";
import {Link, useParams, useSearchParams} from "react-router-dom";



export const pageKeys = [
    '9b5b1511-f1f7-41d9-9598-572c0c6a83fd', //0
    'aa09baef-610f-47ee-9535-bfe55300ad5a', //1
    '872792c7-0ca9-409a-b612-cf68f08a2664', //2
    '1f664936-bd56-4f5c-9ab7-71b4bded5f31',  //3
    'b6274ab9-a069-475c-bba4-b6a835a1a656',  //4:: Forgot Password
    '0507bd02-b42b-47bb-a2c0-e72f59fd68b7',  //5:: Password Reset
]
const pageContents = {
    '9b5b1511-f1f7-41d9-9598-572c0c6a83fd' : {
       btnText:'Proceed to Login',
        link :url.signIn,
        logo :'happy',
        txtColor:'text-info',
        btnColor:'btn-info',
        headerText :'Account Created Successfully',
        message: `You cooperative membership registration was successfully kindly click the button below to login into your dashboard.`

    },
    'aa09baef-610f-47ee-9535-bfe55300ad5a' : {
        btnText:'Proceed to Login',
        link :url.signIn,
        logo :'happy',
        txtColor:'text-info',
        btnColor:'btn-info',
        headerText :'Account Created Successfully',
        message: `Your cooperative account registration was successfully kindly click the button below to login into your dashboard to complete your account setup.`
    },
    '872792c7-0ca9-409a-b612-cf68f08a2664' : {
        btnText:'Proceed to Login',
        link :url.signIn,
        logo :'happy',
        txtColor:'text-info',
        btnColor:'btn-info',
        headerText :'Account Created Successfully',
        message: `Your cooperative account registration was successfully kindly click the button below to login into your dashboard to complete your account setup.`
    },
    '1f664936-bd56-4f5c-9ab7-71b4bded5f31' : {
        btnText:'Proceed to Login',
        link :url.signIn,
        logo :'sad',
        txtColor:'text-danger',
        btnColor:'btn-danger',
        headerText :'Invitation Declined Successfully',
        message: `Your cooperative invitation has been decline successfully,if you already have an account kindly click the button below to login into your dashboard.`
    },

    //Forgot Password
    'b6274ab9-a069-475c-bba4-b6a835a1a656' : {
        btnText:'Proceed to Login',
        link :url.signIn,
        logo :'happy',
        txtColor:'text-success',
        btnColor:'btn-success',
        headerText :'Password Reset Email Sent Successfully',
        message: `We have sent you an email to reset your password kindly check your email. if you already have an account kindly click the button below to login into your dashboard.`
    },
    //Password Reset
    '0507bd02-b42b-47bb-a2c0-e72f59fd68b7' : {
        btnText:'Proceed to Login',
        link :url.signIn,
        logo :'happy',
        txtColor:'text-success',
        btnColor:'btn-success',
        headerText :'Password Reset Successfully',
        message: `We have successfully reset your password. kindly click the button below to login into your dashboard.`

    },

    //sad
    'error' : {
        btnText:'Return Home',
        link :'',
        logo :'',
        headerText :'Page Not Found',
        txtColor:'text-danger',
        message: `It's looking like you may have taken a wrong turn. Don't worry...it happens to the best of us. Here's a little tip that might help you get back on track.`
    }
}

//See You Again !
//You are now successfully sign out.
//"Log In"
//"Back to"

type UrlParams = {
    key : string
}
interface PageProps {
    headerText:string,
    txtColor:string,
    message:string,
    btnColor:string,
    btnText:string,
    link:string,
    logo:string,

}

export const SuccessScreen = () => {
    let {key} = useParams<UrlParams>()
    // @ts-ignore
    const [pageContent, setPageContent] = useState<PageProps>()
    const [searchParams, setSearchParams] = useSearchParams();


    useEffect(() => {
        const myParam = searchParams.get('msg');
        console.log(myParam)
        if(key){
            // @ts-ignore
            let x = pageContents[key]
            if(myParam){
                x.headerText = myParam
            }
            // @ts-ignore
            setPageContent(x)
        }else{
            // pageContents.error
        }
    }, []);

    return(
  <AuthWrapper>
        <div className="card">
                <Header/>
            <div className="card-body p-4">
                <div className="text-center">
                    <h1 className="text-error"><i className={`mdi mdi-emoticon-${pageContent?.logo}`} /></h1>

                    <h4 className={`text-uppercase ${pageContent?.txtColor} mt-3`}>{pageContent?.headerText}</h4>
                    <p className="text-muted mt-3"> {pageContent?.message}</p>

                    <Link
                        to={pageContent?.link??''}
                        className={`btn ${pageContent?.btnColor} mt-3`}>
                        <i className="mdi mdi-reply" />  {pageContent?.btnText}
                    </Link>

                </div>
            </div> {/* end card-body*/}
        </div>
    </AuthWrapper>
    )
}

export default SuccessScreen