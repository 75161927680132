
import { useEffect } from "react";
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";

import { postRequest } from "../../../config/redux/actions";
import { AppState } from "../../../config/redux/reducers";
import { AnalyticState } from "../../../config/redux/reducers/tmsAnalyticsReducer";
import { AuthState } from "../../../config/redux/reducers/tmsAuthReducer";
import { types } from "../../../config/redux/types";
import { remote_url } from "../../../config/urls/remote";
import DashboardWrapper from '../DashboardWrapper'
import { CircularLoader } from "../_components";
import { SavingsMonthlyReport, SavingsMonthlyReportBarGraph } from "./graph";
import { savingsDashboardBranchPerformanceReport, savingsDashboardTopPerFormingUser } from "./graph/init";
import { SavingsDashboardFilter } from "./_components";




const SavingsDashboard = () => {
    //
    const {savingsDashboard,loading_t} = useSelector<AppState,AnalyticState>((state) => state.tmsAnalyticsReducer)
    const {role_id} = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)

  return (
    <DashboardWrapper>
        <div className="container-fluid">
            <SavingsDashboardFilter/>
            {loading_t && <CircularLoader/> }
            {/*  */}
               <div className="row">
                    <div className="col-xl-3 col-lg-4">
                        <div className="card bg-dark text-white tilebox-one">
                            <div className="card-body">
                                <i className='uil uil-window-restore   float-end'></i>
                                <h6 className="text-uppercase  mt-0">Monthly Savings</h6>
                                <h2 className="my-2 text-white" id="active-users-count">
                                    {savingsDashboard?.total_no_of_savings_current_month}
                                </h2>
                                <p className="mb-0 text-muted">
                                    <span className="text-muted text-white">{savingsDashboard?.total_no_of_savings_prev_month?.value} {''} </span>
                                    <span className="ml-3 text-white-50 text-nowrap">Since last month</span>
                                </p>
                            </div> 
                        </div>
                        {/*  */}
                        <div className="card bg-info text-white tilebox-one">
                            <div className="card-body">
                                <i className='uil uil-window-restore text-white float-end'></i>
                                <h6 className="text-uppercase text-white mt-0">Weekly Savings</h6>
                                <h2 className="my-2 text-white" id="active-views-count">
                                    {savingsDashboard?.total_no_of_savings_current_week}
                                </h2>
                                <p className="mb-0 text-muted">
                                    <span className="me-2 text-white-50">
                                        {savingsDashboard?.total_no_of_savings_prev_week?.value}</span>
                                        <span className="text-nowrap text-white-50">Since previous week</span>
                                </p>
                            </div> 
                        </div>
                        {/* Ads */}
                        <div className="card bg-primary text-white cta-box overflow-hidden">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <h3 className="m-0 fw-normal cta-box-title">
                                            {savingsDashboard?.savings_ads?.title}
                                             <i className="mdi mdi-arrow-right"></i></h3>
                                    </div>
                                    <img className="ms-3" src={savingsDashboard?.savings_ads?.img_url}
                                    width="92" alt="Ads"/>
                                </div>
                            </div>
                            
                        </div>
                    </div> 

                    <div className="col-xl-9 col-lg-8">
                        <div className="card card-h-100">
                            <div className="card-body">
                                <h4 className="header-title mb-3"> Total Number of Savings Per Month </h4>
                                {savingsDashboard?.news_ads?.title &&
                                <div className="alert alert-primary alert-dismissible fade show mb-3" role="alert">
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    {savingsDashboard?.news_ads?.title}
                                </div> }

                                {/*  */}
                                <div className="">
                                   
                                    <div dir="ltr">
                                        <SavingsMonthlyReportBarGraph data={savingsDashboard}/>
                                    </div>
                                </div>
                               
                            </div> 
                        </div> 
                    </div>
                </div>
        
              
              {/* MONTHLY REPORT */}
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                {/*  */}
                                <h4 className="header-title">Monthly Report</h4>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <SavingsMonthlyReport data={savingsDashboard}/>
                                    </div>
                                </div>
                            </div> 
                        </div> 
                    </div> 
                </div>


            {[1,2].includes(role_id) &&
                <>
                    {/* TOP PERFORMING USER and BRANCH PERFORMANCE */}
                    <div className="row">
                        <div className="col-xl-12 col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="header-title">Top performing User</h4>
                                    <div id="views-min" className="apex-charts mt-2" data-colors="#0acf97">
                                        <Chart
                                            type="bar"
                                            options={
                                                {
                                                    ...savingsDashboardTopPerFormingUser,
                                                    labels:savingsDashboard?.top_performaing_user?.labels??[]
                                                }
                                            }
                                            series={
                                                [
                                                    {
                                                        data:savingsDashboard?.top_performaing_user?.series??[],
                                                        name:''
                                                    },
                                                ]
                                            }
                                            className="apex-charts mt-2"
                                            id="country-chart"
                                            height= "310px"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* branch performance chart */}
                        {/*
                            <div className="col-xl-4 col-lg-6">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="header-title">Branch Performance</h4>
                                    <Chart
                                        type="radialBar"
                                        options={{...savingsDashboardBranchPerformanceReport,labels:savingsDashboard.branch_performance.labels}}
                                        series={savingsDashboard.branch_performance.series}
                                        className="apex-charts mt-3"
                                        id="sessions-os"
                                        height= "330px"
                                    />
                                </div>
                            </div>
                        </div>
                        */}

                    </div>
                </>
            }
        </div>
    </DashboardWrapper>
  )
}

export default SavingsDashboard