import {TableWrapper} from "../../_component";
import SettlementFilter from "../filters/SettlementFilter";
import {flutterWaveSettlementDatatableConfig} from "../../../_config/dataTableConfig";
import {resetDatatable} from "../../../_config/redux/feacture/settlementsReconcilations/settlementReconciliationSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../_config/redux/store";
import {useEffect} from "react";

const FlutterwaveSettlement = () => {
    const dispatch = useDispatch()
    const {settlements_flutter_wave, current_gateway,isNewSettlementData} = useSelector((state:RootState)=>state.settlementReconciliation)


    const initializeDataTable = () => {
        //current_gateway === '1'
        flutterWaveSettlementDatatableConfig(settlements_flutter_wave,'flutter_wave_settlement_table',[1, 'desc'],[])
        dispatch(resetDatatable({}))
    }


    if(isNewSettlementData){
       initializeDataTable()
    }


    useEffect(() => {
        initializeDataTable()
    }, []);

    return(
        <TableWrapper
            id={'flutter_wave_settlement_table'}
            // filterOptionsOne = {filterOption}
            // onChangeFilter = {getSettlement}
            tableHeaders={["Reference", "Acc No", "Bank Name", "Acc Name", "Amount Settled", "Settlement Date", "Action"]}
        >
            <SettlementFilter/>
        </TableWrapper>
    )
}

export default FlutterwaveSettlement