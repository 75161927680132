
// import {config} from './'

import { appConfig } from "../config"

 

const prefix = 'u/'
const superAdmin = `${appConfig.remote_url}${prefix}tms-su/`;

export const remote_url = {

    signIn : `${appConfig.remote_url}login`,
    getAuthBranchDetail : `${appConfig.remote_url}branch`,
    getInvitationDetail : `${appConfig.remote_url}invite-validation/in`,

    rejectInvite : `${appConfig.remote_url}decline-invite`,
    logoutFromAllDevices : `${appConfig.remote_url}logout-from-all-devices`,
    forgotPass : `${appConfig.remote_url}forgot-password`,
    resetPass : `${appConfig.remote_url}reset-password`,
    accSignUp : `${appConfig.remote_url}register-account`,

    userAccVerify : `${appConfig.remote_url}verify-user-account`,
    userSignUp : `${appConfig.remote_url}register`,

    logout : `${appConfig.remote_url}u/logout`,
    getSavingsBankAccount : `${appConfig.remote_url}u/my-savings-bank-accounts`,
    //
    banks : `${appConfig.remote_url}u/banks`,
    search : `${appConfig.remote_url}u/search`,
    //savings
    savings : `${appConfig.remote_url}u/savings`,
    updateSavings : `${appConfig.remote_url}u/savings/update-savings-details`,
    savingsUpdate : `${appConfig.remote_url}u/savings/update-savings`,
    verifySavings :`${appConfig.remote_url}u/verify/savings`, //verify/savings
    //Loans 
    getLoanTypes : `${appConfig.remote_url}u/loans/loans-type`,
    createLoanTypes : `${appConfig.remote_url}u/loans/loans-type/create`,
    updateLoanTypes : `${appConfig.remote_url}u/loans/loans-type/update`,
    submitLoanApplication : `${appConfig.remote_url}u/loans/apply`,
    getLoanApplication : `${appConfig.remote_url}u/loans`,
    updateStatus : `${appConfig.remote_url}u/loans/update-status`,
    getLoanRepaymentBreakdown : `${appConfig.remote_url}u/loans/repayment-breakdown`,
    loanRepayment : `${appConfig.remote_url}u/loans/loan-repayment`,
    verifyLoans : `${appConfig.remote_url}u/loans/verify`,
    //expenses
    createExpenses : `${appConfig.remote_url}u/expenses/create`,
    getExpenses : `${appConfig.remote_url}u/expenses`,
    verifyExpenses : `${appConfig.remote_url}u/expenses/verify`,

    //communication
    sendEmailOrSms : `${appConfig.remote_url}u/communication/send-email-or-sms`,
    sendPushNotification : `${appConfig.remote_url}u/communication/send-push-notification`,
    getPushNotificationDesigns : `${appConfig.remote_url}u/communication/push-notification-designs`,

    coopBankAccs: `${appConfig.remote_url}${prefix}thrifts-bank-accounts`,
    coopBankAccountsUpdatePreference: `${appConfig.remote_url}${prefix}thrifts-bank-accounts/update-preference`,
    //audit log
    auditLog : `${appConfig.remote_url}${prefix}audit-logs`,
    //
    users  : `${superAdmin}users`,
    dashboardAnaltics : `${appConfig.remote_url}${prefix}analytics/dashboard`,
    savingsAnaltics : `${appConfig.remote_url}${prefix}analytics/savings/dashboard`,
    loansAnaltics : `${appConfig.remote_url}${prefix}analytics/loans/dashboard`,
    expensesAnalytics : `${appConfig.remote_url}${prefix}analytics/expenses/dashboard`,
    // ?  Thrift Members
    thriftMemebers : `${appConfig.remote_url}${prefix}customer`,
    newMemebers : `${appConfig.remote_url}${prefix}customer/new-member`,
    updateMembers : `${appConfig.remote_url}${prefix}customer/update-user-details`,
    //transactions
    transactions : `${appConfig.remote_url}${prefix}transactions`,
    //settings
    updateAccountDetails : `${superAdmin}settings/update-thrift-account-details`,
    updateAccountDocUpload : `${superAdmin}settings/update-thrift-account-details/documents`,
    profileApprovalReq : `${superAdmin}settings/profile-approval-request`,
    getThriftAccountDetails : `${superAdmin}settings`,
    // profile settings
    changePassword : `${appConfig.remote_url}u/settings/change-password`,
    updateProfile : `${appConfig.remote_url}u/settings/update-profile`,
    getProfile : `${appConfig.remote_url}u/settings/user-profile`,
    getProfileFullDetails : `${appConfig.remote_url}u/settings/full-profile-details`,
    //
    subscriptionPlans : `${appConfig.remote_url}u/subscriptions/plans`,
    subscriptionTransactions : `${appConfig.remote_url}u/subscriptions/transactions`,
    upgradePlan : `${appConfig.remote_url}u/subscriptions/upgrade-plan`,
    verifyPlanSub : `${appConfig.remote_url}u/subscriptions/plan/verify`,
    //SUPPORTS
    getSupportTicketsConversation : `${appConfig.remote_url}u/supports`,
    createSupportTicketsConversation : `${appConfig.remote_url}u/supports/create-support-ticket`,
    getSupportChats : `${appConfig.remote_url}u/supports/chats`,
    addNewSupportChatMessage : `${appConfig.remote_url}u/supports/add-new-message`,
    //Settlement and Reconciliation
    getSettlement : `${appConfig.remote_url}u/settlement-reconciliation/settlements`,
    //
    loginAgain : `${appConfig.remote_url}u/re-login`,
    verifyEmail : `${appConfig.remote_url}u/settings/verify-email`,
    //
    notifications : `${appConfig.remote_url}u/notifications`,
    //
    getStates: `${appConfig.remote_url}u/states/`,
    //
    branches : `${appConfig.remote_url}u/branches`,
    getBranchDetail :`${appConfig.remote_url}u/branches/account-details`,
}