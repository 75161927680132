import DashboardWrapper from "../admin/DashboardWrapper";
import BreadCrumb from "../admin/layouts/BreadCrumb";
import {Link} from "react-router-dom";
import {url} from "../../config/urls";

const AuthNotFoundPage = () => {
    return <DashboardWrapper showWarning={false}>

        <BreadCrumb pageTitle={''} main={''} current={''}/>

        {/* end page title */}
        <div className="row justify-content-center">
            <div className="col-lg-4">
                <div className="text-center">
                    <img src="/assets/img/file-searching.svg" height={90} alt="File not found Image" />
                    <h1 className="text-error mt-4">404</h1>
                    <h4 className="text-uppercase text-danger mt-3">Page Not Found</h4>
                    <p className="text-muted mt-3">It's looking like you may have taken a wrong turn. Don't worry... it
                        happens to the best of us. Here's a
                        little tip that might help you get back on track.</p>
                    <Link to={url.dashboard} className="btn btn-outline-primary mt-3" >
                        <i className="mdi mdi-reply" /> Return Home
                    </Link>
                </div> {/* end /.text-center*/}
            </div> {/* end col*/}
        </div>
        {/* end row */}

    </DashboardWrapper>
}

export default  AuthNotFoundPage