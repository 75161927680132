import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {PushNotificationProps} from "./PushNotification";


const PushNotificationOne:React.FC<PushNotificationProps> = ({clearPushNotification,pushNotification,modalStateText}) => {

    useEffect(() => {

    }, []);



    return(
        <>
            <div

                className={`modal fade ${modalStateText}`}
                id="exampleModalCenter"
                tabIndex={-1}
                style={{display: "block",borderRadius:'7px',overflow:'hidden',backgroundColor:'transparent'}}
                role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content rounded-0">
                        <div className="modal-body bg-image overlay"
                             style={{backgroundImage: "url('/assets/notification/pexels-photo-295771.webp')"}}>

                            <div className="line px-3 to-front">
                                <div className="row align-items-center">
                                    <div className="col logo">
                                        <a href="#">
                                            <img
                                                style={{width:'30px'}}
                                                src="/assets/img/b.png"
                                                alt="bg"
                                                className="img-fluid"/>
                                        </a>
                                    </div>

                                    <div className="col-md-10 text-center">
                                        <h2></h2>
                                    </div>
                                    <div className="col text-right text-white       ">
                                        <Link to="#" className="btn-close text-white" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">
                                                <span className="icon-close2"></span>
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <div className="p-4 to-front">
                                <div className="text-center">
                                    <h3>{pushNotification?.title}</h3>
                                    <p className="mb-4">
                                        {pushNotification?.body}
                                    </p>

                                    {/*<form action="#" className="d-flex mb-4">*/}
                                    {/*    <input type="email" className="form-control mr-3" placeholder="Enter your email address"/>*/}
                                    {/*        <input type="submit" className="btn btn-primary" value="Subscribe"/>*/}
                                    {/*</form>*/}

                                    <p className="mb-0  d-flex justify-content-center gap-3 ">
                                        <Link to={pushNotification?.link} className={'btn btn-success'}  data-dismiss="modal">
                                            {pushNotification?.linkText??'Visit Page'}
                                        </Link>
                                        <Link to="#" className={'btn btn-outline-secondary'} onClick={()=>clearPushNotification()} data-dismiss="modal">
                                            Close
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*{modalStateText!=='' && <div className={`modal-backdrop fade ${modalStateText}`}></div> }*/}
        </>
    )
}




export default PushNotificationOne