import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "../../../../config/redux/reducers"
import { AuthState } from "../../../../config/redux/reducers/tmsAuthReducer"
import { types } from "../../../../config/redux/types"
import { remote_url } from "../../../../config/urls/remote"
import { postRequest, setGeneralAction } from "../../../../config/redux/actions"
import { Spinner } from "../../../../components";

import { ViewSavings } from "../_components"
import { SavingsReducerState } from "../../../../config/redux/reducers/tmsSavingsReducer"
import { Link } from "react-router-dom"
import React, {useEffect, useState} from "react"
import {MembersModel, MembersProps, UsersModel, UsersProps} from "../../../../config/model/authModel"
import { ViewDetails } from "../../audit_log/_components"
import SavingsDetails from "../SavingsDetails"
import {MemebersState} from "../../../../config/redux/reducers/tmsMemeberReducerState";
import {GeneralFormInput} from "../../../../components/form";
import {CustomModalWrapper, ProfileDetailCard} from "../../_components";

type FormValues ={
    amount : string,
    channel : string,
    user_id : number,
    description : string,
    bank_name : string,
    gateway : string
}
type SavingsFormProps = {
    show : boolean,
    showSavingFormFunc ? : any
    // userD  : UsersModel
}

const SavingsForm: React.FC<SavingsFormProps> = ({show,showSavingFormFunc}) => {
    const dispatch = useDispatch()
    const { register, handleSubmit, formState: { errors },reset,setValue,watch,getValues } = useForm<FormValues>({mode:'onTouched'})
    const {token, account_key,user,role_id,thrift_account,gateways,payment_channels} = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
    const {singleSavings,loading_, success_msg,error_msg,savingsFilter} = useSelector<AppState,SavingsReducerState>((state) => state.tmsSavingsReducer)
    const {userDetails,memberFilter} = useSelector<AppState,MemebersState>((state)=> state.tmsMemeberReducerState)
    const amountSavings = watch('amount')
    const [showDesc , setShowDesc] = useState('')



useEffect(() => {
    // console.log(window.location.pathname)
    //TODO at what point do u want clear data
    // clearData()
    console.log('hell')
    console.log(show)
},[])
    //CREATING SAVINGS
    const createSavings = (data:FormValues) => {
        const postData = {
           token,
           url: remote_url.savings,
           actionType : types.CREATE_SAVINGS,
           postData : {
                amount : data.amount,
                channel : data.channel,
                description : data.description ?? null,
                bank_name : data.bank_name ?? null,
               payment_gateway: data.gateway ?? null,
               //
               user_id : userDetails?.user?.id,
               fromDate: savingsFilter.fromDate,
               toDate : savingsFilter.toDate,
               status: savingsFilter.status,
               //in case the request is coming from members page
                statusFilter : memberFilter.status,
                roleFilter : memberFilter.role,
                branchFilter : memberFilter.branch
            },
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            errorAction : types.SET_ERROR,
            accountKey: account_key,
            loading :{
                isLoading :false,
                isLoading_ : true,
                isLoading_t:false
            }
            // reset
        }
        //
        dispatch(postRequest(postData))

    }
    //
    const clearData = () => {
        console.log('i ws here')
        setValue('amount','')
        setValue('channel','')
        setValue('description','')
        setValue('gateway','')
        setValue('bank_name','')
        dispatch(setGeneralAction(types.GET_USER, MembersProps))
        dispatch(setGeneralAction(types.CLEAR_SAVING_DATA,[]))
        showSavingFormFunc(false)
    }

    // if(success_msg ){
    //     clearData()
    // }
    //
    return <>
                <CustomModalWrapper onClickFunc={clearData} modalTitle={singleSavings ? 'Savings Details' : 'New Savings for :' }>


                    <div className="card-body">
                        <ProfileDetailCard
                            profile_url={userDetails?.user?.profile_image !==''?userDetails?.user?.profile_image : userDetails?.user?.alternate_profile_image }
                            email={userDetails?.user?.email}
                            name={userDetails?.user?.name}
                            summaryText={''}
                            summaryValue={''}
                        />
                        {
                            singleSavings 
                            ?
                                <>

                                    <ViewSavings  />
                                </>
                             :
                                <>
                                    <form onSubmit={handleSubmit(createSavings)}>
                                        <div>
                                            {/* user_id:: which will be the users key */}
                                            <div className="mb-3">
                                                <input type="hidden" defaultValue={user.id}  className="form-control"
                                                       {...register('user_id')}
                                                />
                                            </div>
                                            {/* amount */}
                                            <div className="mb-3">
                                                <label htmlFor="simpleinput" className="form-label">Amount</label>
                                                <input type="text"  className={`form-control ${errors.amount && 'is-invalid'}`}
                                                       {...register('amount',{
                                                           required: '*amount is required',
                                                           pattern : {
                                                               value:/^(?:0|[1-9]\d+|)?(?:.?\d{0,2})?$/,
                                                               message:'invalid amount enter'
                                                           }
                                                       })}
                                                />
                                                <small className={'helper-text'}>amount <b className={'text-primary'}>{Number(amountSavings).toLocaleString()}</b></small>
                                                {errors.amount && <div className="invalid-feedback"> {errors.amount.message}</div>}
                                            </div>
                                            {/* payment_channel */}
                                            <div className="mb-3">
                                                <label htmlFor="simpleinput" className="form-label">Payment Channel</label>
                                                <select className="form-select mb-3"
                                                        {...register('channel',{
                                                            required: '*channel is Required',
                                                            onChange : (e) => setShowDesc(e.target.value)
                                                        })}
                                                >
                                                    <option value=''>Select payment Channel</option>
                                                    {payment_channels.map((item,i=0)=>
                                                    item.role.includes(role_id) &&  <option key={i+1} value={item.key} >{item.name}</option>

                                                    )}

                                                </select>
                                            </div>

                                            {/* payment_gateway */}
                                            {showDesc==='card' &&
                                                <>
                                                    <div className="mb-3">
                                                        <label htmlFor="simpleinput" className="form-label">Payment Gateway</label>
                                                        <select className="form-select mb-3"
                                                                defaultValue={Number(thrift_account.account_branch.payment_gateway)}
                                                                {...register('gateway',{
                                                                    required: '*Payment Gateway is Required'})}
                                                        >
                                                            <option value={''}>Select your Preferred Gateway</option>
                                                            {gateways.map((item,i=0)=>
                                                                <option key={i+1} value={item.key} >{item.name}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </>
                                            }


                                            { showDesc==='bank' &&<>
                                                {/*<GeneralFormInput type={'textarea'} register={register} label={'Description'} fieldName={'description'}/>*/}
                                                {/*<GeneralFormInput type={'text'} register={register} label={'Bank Name(bank used for the transfer)'} fieldName={'bank_name'}/>*/}
                                            </>

                                            }

                                            {
                                                loading_
                                                    ?
                                                    <Spinner color='primary'/>
                                                    :
                                                    <input type="submit" value="add new Savings" className="btn btn-outline-primary" />
                                            }
                                        </div>
                                        {/*  */}
                                    </form>
                                </>
                        }
                    </div>
                    {loading_ && <div className="modal-backdrop fade show"></div>}
                </CustomModalWrapper>
            </>
}

export default SavingsForm