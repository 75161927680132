import {ComingSoon} from "../../../components";
import DashboardWrapper from "../DashboardWrapper";
import BreadCrumb from "../layouts/BreadCrumb";

const ReferralHome = () => {
    return <DashboardWrapper>
        <BreadCrumb pageTitle={'Referral'} main={'Communication'} current={'Referral'}/>
    <ComingSoon/>
    </DashboardWrapper>
}



export default  ReferralHome