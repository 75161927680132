import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {Spinner} from '../../../components';
import {ErrorAlert, SuccessAlert} from '../../../components/alert';
import {calculateTime, postRequest, setGeneralAction} from '../../../config/redux/actions';
import {AppState} from '../../../config/redux/reducers';
import {AuthState} from '../../../config/redux/reducers/tmsAuthReducer';
import {LoansReducerState} from '../../../config/redux/reducers/tmsLoansReducer';
import {types} from '../../../config/redux/types';
import {remote_url} from '../../../config/urls/remote';


import DashboardWrapper from "../DashboardWrapper"
import BreadCrumb from "../layouts/BreadCrumb"
import {Loader} from '../_components';
import {DEFAULT_INACTIVE_TIME} from "../../../config/config";
import {loansSettingsInitDatatable} from "../../../config/datatableConfig";
import {Link} from "react-router-dom";
import LoanTypeForm from "./LoanTypeForm";
import {CreateLoanGuarantorForm, LoanEditForm} from "./index";


const LoansSettings = () => {
    const [showGuanrantorModal,setShowGuanrantorModal] = useState(false)
    const dispatch = useDispatch()
    const [showAddLoanTypeForm, setShowAddLoanTypeForm] = useState(false)
    const [showEditLoanTypeForm, setShowEditLoanTypeForm] = useState(false)

    useEffect(() => {
        getLoanTypes()
    }, [])

    const {token,account_key, user,role_id} = useSelector<AppState, AuthState>((state) => state.tmsAuthReducer)

    const {
        loading_,
        loan_types,
        loan_type,
        isLoading_t,
        success_msg,
        error_msg,
        newDataBot
    } = useSelector<AppState, LoansReducerState>((state) => state.tmsLoansReducer)
    //


    const getLoanTypes = () => {
        const postData = {
            token,
            accountKey: account_key,
            url: remote_url.getLoanTypes,
            actionType: types.GET_LOANS_TYPES,
            postData: [],
            secretData: {
                email: user.email,
                mac_id: user.mac_id,
                r_id: role_id
            },
            errorAction: types.SET_ERROR,
            loading: {
                isLoading: false,
                isLoading_: false,
                isLoading_t: true
            }
        }
        dispatch(postRequest(postData))
    }
    //
    const setDetails = (data:any) =>
    {
        dispatch(setGeneralAction(types.SET_LOANS_TYPE,data))
        setShowEditLoanTypeForm(true)
    }

    const handleAddNewLoanTypeForms = (data:boolean) => {
        setShowAddLoanTypeForm(data)
    }
    const handleGuanrantorForm = (data:boolean)=> {
        setShowGuanrantorModal(!showGuanrantorModal)
    }

    // :::::::::::::::::::::::::
    if(newDataBot){
        loansSettingsInitDatatable(loan_types,setDetails,['Add New Loan Type','Guarantor Form'],handleAddNewLoanTypeForms,handleGuanrantorForm)
        dispatch(setGeneralAction(types.RESET_LOANS_BOT,[]))
    }





    return <DashboardWrapper>
        {showGuanrantorModal && <CreateLoanGuarantorForm handelCloseModal={handleGuanrantorForm}/> }
        {showAddLoanTypeForm && <LoanTypeForm onClickFunc={handleAddNewLoanTypeForms} />  }
        {showEditLoanTypeForm && <LoanEditForm current_screen={'update'} closeFunc={setShowEditLoanTypeForm} loan_type={loan_type}/> }


        {/* */}
        <BreadCrumb current="Loans Settings" pageTitle="Loans Settings" main="Loans"/>

        {success_msg && <SuccessAlert msg={success_msg}/>}
        {error_msg && <ErrorAlert msg={error_msg}/>}

        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            {isLoading_t && <Loader/>}
                            <table id={'loan-settings'} className={'table table-condensed loan-settings'}>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Percent Interest</th>
                                        <th>Repayment Duration</th>
                                        <th>Minimum Savings  Balance</th>
                                        <th>Membership Period</th>
                                        <th>Multiples of Savings</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                {/*<tbody></tbody>*/}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </DashboardWrapper>
}

export default LoansSettings