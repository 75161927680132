import { Link } from "react-router-dom"
import React from "react";

interface Props{
    logo ?  : string
}
const Header:React.FC<Props> = ({logo='/assets/img/b.png'}) => {
    return (<>

        <div className="card-header pt-3 pb-3 text-center bg-primary">
            <Link to="#">
                <span><img src={logo} alt="" height="30"/></span>
            </Link>
        </div>

    </>)
}

export  default Header