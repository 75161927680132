// import model
// actions props
//types
//define state for reducer
// intial state
// create the reducers
// export

import {ExpensesModel, ExpensesProps} from "../../model/authModel";
import {ActionProps} from "../actions";
import {types} from "../types";

export interface ExpenseReducerState {
    loading: boolean,
    loading_: boolean,
    loading_t: boolean,
    success_msg: string,
    transaction_id: string,
    error_msg: string,
    expenses: ExpensesModel[]
    expense: ExpensesModel,
    newDataBot: boolean,
    filter: {
        fromDate: string,
        toDate: string,
        status: string
    }
    income_total: number,
    expense_total: number,
    transaction_ref : string
}

//
const initialState = {
    loading: false,
    loading_: false,
    loading_t: false,
    success_msg: '',
    error_msg: '',
    transaction_id: '',
    expenses: [],
    expense: ExpensesProps,
    newDataBot: false,
    filter: {
        fromDate: '',
        toDate: '',
        status: 'all'
    },
    income_total: 0,
    expense_total: 0,
    transaction_ref : ''

}


const tmsExpenseReducer = (state: ExpenseReducerState = initialState, action: ActionProps) => {
    //
    switch (action.type) {
        case types.CLEAR_EXPENSE :
            return {
                ...state,
                expense : ExpensesProps
            }



        case types.CLEAR_MSG:
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                success_msg: '',
                error_msg: ''
            }
        case types.SET_LOADING:
            return {
                //isLoading_
                ...state,
                loading: action.loading?.isLoading,
                loading_: action.loading?.isLoading_,
                loading_t: action.loading?.isLoading_t,
                success_msg: '',
                error_msg: ''
            }

        case types.SET_EXPENSE_FILTER:
            return {
                ...state,
                filter: {
                    fromDate: action.payload?.fromDate,
                    toDate: action.payload?.toDate,
                    status: action.payload?.status
                }
            }
        //
        case types.RESET_TRANSACTION_BOT:
            return {
                ...state,
                newDataBot: false
            }
        case types.SET_TRANSACTION_EXPENSES_REF:
            return {
                ...state,
                transaction_ref : action.payload?.transaction_ref,
                transaction_id: action.payload?.trans_id
            }
        case types.SET_ERROR:
            return {
                ...state,
                loading: false,
                loading_: false,
                success_msg: '',
                error_msg: action.payload
            }
        case types.GET_EXPENSES:
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                success_msg: '',
                error_msg: '',
                expenses: action.payload?.data,
                newDataBot: true,//newDataBot
                income_total: action?.payload?.income_total,
                expense_total: action?.payload?.expense_total
            }

        case types.VERIFY_EXPENSE:
            return {
                ...state,
                success_msg: action.payload?.message,
                transaction_ref: '',
                error_msg: '',
                loading: false,
                loading_: false,
                loading_t: false
            }

        case types.SET_SINGLE_EXPENSE:
            return {
                ...state,
                expense: action.payload
            }


        case types.CREATE_NEW_EXPENSE:
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                expenses: action.payload?.data,
                success_msg: action.payload?.message,
                newDataBot: true,
                income_total: action?.payload?.income_total,
                expense_total: action?.payload?.expense_total,
                expense : action?.payload?.expense

            }


        case types.LOG_OUT:
            return {
                ...state,
                loading: false,
                loading_: false,
                success_msg: '',
                error_msg: '',
                newDataBot: false,
                expenses: [],
                filter: {
                    fromDate: '',
                    toDate: '',
                    status: '1'
                }
            }

        default:
            return state;
    }

}

export default tmsExpenseReducer