import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest, setGeneralAction } from '../../../../config/redux/actions';
import { AppState } from '../../../../config/redux/reducers';
import { AuthState } from '../../../../config/redux/reducers/tmsAuthReducer';
import { SavingsReducerState } from '../../../../config/redux/reducers/tmsSavingsReducer';
import { types } from '../../../../config/redux/types';
import { remote_url } from '../../../../config/urls/remote';
//
import {useLocation} from "react-router-dom";
import React, {useEffect} from "react";

interface Props {
    _user_id : string   | number
}

type FormValues ={
    fromDate : string,
    toDate : string,
    status : number,
}

const SavingsFilter:React.FC<Props> = ({_user_id}) => {
    const search = useLocation().search;
    const status = new URLSearchParams(search).get('status');

    const { register, handleSubmit } = useForm<FormValues>()    
     const {token,account_key,user,role_id} = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
     const {savingsFilter} = useSelector<AppState,SavingsReducerState>((state)=> state.tmsSavingsReducer)
     const dispatch = useDispatch()


     const searchData = (data:FormValues) => {
        
          dispatch(setGeneralAction(types.SET_SAVINGS_FILTER,{
            fromDate :data?.fromDate,
            toDate: data?.toDate,
            status : data.status
        }))
        // console.log(`${remote_url.savings}?fromDate=${data.fromDate}&toDate=${data.toDate}&status=${data.status}`);
         const getData = {
            token,
            url: `${remote_url.savings}?fromDate=${data.fromDate}&toDate=${data.toDate}&status=${data.status}&user_id=${_user_id}`,
            actionType : types.GET_SAVINGS,
            postData : [],
             secretData : {
                 email:user.email,
                 mac_id :user.mac_id,
                 r_id: role_id
             },
            errorAction : types.SET_ERROR,
            accountKey: account_key,
            loading :{
                isLoading :false,
                isLoading_ : false,
                isLoading_t: true
            }
        }

        dispatch(getRequest(getData))

    }

    useEffect(() => {
        if(status){
            searchData({fromDate:'',toDate:'',status:Number(status)??'all'})
        }
        else {
            searchData({fromDate:savingsFilter.fromDate,toDate:savingsFilter.toDate,status:savingsFilter.status??'all'})
        }
    }, []);



    return <>
  
    {/*  */}
    <form  onSubmit={handleSubmit(searchData)}>

      <div className='card px-3 py-1'>
            <div className='row'>
                {/* from Date */}
                <div className='col-lg-3'>
                    <div className="mb-3">
                    <label htmlFor="example-date" className="form-label">From Date</label>
                    <input className="form-control" type="date"  {...register('fromDate')} 
                    defaultValue={savingsFilter.fromDate}
                    />
                </div>
                </div>
                {/* to Date */}
                <div className='col-lg-3'>
                    <div className="mb-3">
                    <label htmlFor="example-date" className="form-label">To Date</label>
                    <input className="form-control" type="date"  {...register('toDate')} defaultValue={savingsFilter.toDate}/>
                </div>
                </div>
                {/* transaction status  */}
                <div className='col-lg-3'>
                    <div className="mb-3">
                        <label htmlFor="example-date" className="form-label">Transaction Status</label>
                        <select className="form-select mb-3"  {...register('status')} defaultValue={savingsFilter.status}>
                            <option value="all">All</option>
                            <option value="1">Paid</option>
                            <option value="0">Unpaid</option>
                        </select>
                    </div>
                </div>
                {/* filter button */}
                <div className='col-lg-3'>
                    <div className="mb-3">
                        <label htmlFor="example-date" className="form-label">-</label>
                        <input className="form-control btn btn-primary" type="submit"  value="Filter"/>
                    </div>
                </div>
            </div>
        </div>

    </form>
        </>
}


export default SavingsFilter