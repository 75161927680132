import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../../../../config/redux/reducers'
import { AnalyticState } from '../../../../config/redux/reducers/tmsAnalyticsReducer'

const RightHandSideCard = () => {
    const {dashboard} = useSelector<AppState,AnalyticState>((state) => state.tmsAnalyticsReducer)
  return (
    <>
      <div className="col-xl-4 col-lg-6">
          {
              dashboard && dashboard?.ads && dashboard?.ads?.map((item,i=0) =>
                            
                            <div key={i+1} className={`card cta-box ${item.bg} ${item.text_color} `}>
                                    <div className="card-body">
                                        <div className="d-flex align-items-start align-items-center">
                                            <div className="w-100 overflow-hidden">
                                                <h2 className="mt-0">
                                                    <i className={`mdi ${item.icon}`}>
                                                </i>&nbsp;
                                                </h2>
                                                <h3 className="m-0 fw-normal cta-box-title">
                                                    {item.text}
                                                    <i  className="mdi mdi-arrow-right"></i></h3>
                                            </div>
                                            <img className="ms-3" src={item.img_url} width="120" alt="Generic placeholder"/>
                                        </div>
                                    </div>

                                </div>)}
                            </div>
                            </>
  )
}

export default RightHandSideCard