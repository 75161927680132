import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AppState } from "../../../config/redux/reducers";
import { AnalyticState } from "../../../config/redux/reducers/tmsAnalyticsReducer";
import { appTxt } from "../../../config/strings";
import { url } from "../../../config/urls";
import DashboardWrapper from '../DashboardWrapper'

import { LoansDashboardFilter } from "./components";
import { loansReturnVsDisbursed, potentialVsActionLoan } from "./graph/chartInit";


const LoansDashboard = () => {
    const {loansDashboard} = useSelector<AppState,AnalyticState>((state)=> state.tmsAnalyticsReducer)
  return (
    <DashboardWrapper>
        <div className="container-fluid">
            <LoansDashboardFilter/>
              <div className="row">
                    <div className="col-xl-5 col-lg-6">

                        <div className="row">
                            <div className="col-lg-6">
                                <div className="card widget-flat">
                                    <div className="card-body">
                                        <div className="float-end">
                                            <i className="mdi mdi-account-multiple widget-icon"></i>
                                        </div>
                                        <h5 className="text-muted fw-normal mt-0" title="Number of Customers">
                                            Total Disbursed
                                        </h5>

                                        <h3 className="mt-3 mb-3">{loansDashboard?.total_amount_disbursed_current_month?.toLocaleString()}</h3>
                                        <p className="mb-0 text-muted">
                                            {/* <span className="text-success me-2">
                                                <i className="mdi mdi-arrow-up-bold"></i> 
                                                5.27%
                                            </span> */}
                                            <span className="text-nowrap badge bg-warning">Month</span>
                                        </p>
                                    </div> 
                                </div> 
                            </div> 

                            {/*  */}
                            <div className="col-lg-6">
                                <div className="card widget-flat">
                                    <div className="card-body">
                                        <div className="float-end">
                                            <i className="mdi mdi-cart-plus widget-icon"></i>
                                        </div>
                                        <h5 className="text-muted fw-normal mt-0" title="Number of Orders">
                                           Total Returned

                                        </h5>
                                        <h3 className="mt-3 mb-3">{loansDashboard?.interest_from_loan_disbursed_current_month?.toLocaleString()}</h3>
                                        <p className="mb-0 text-muted">
                                            {/* <span className="text-danger me-2"><i className="mdi mdi-arrow-down-bold"></i> 1.08%</span> */}
                                            <span className="text-nowrap badge bg-info">Month</span>
                                        </p>
                                    </div> 
                                </div> 
                            </div> 
                        </div>

                        <div className="row">
                            <div className="col-lg-6">
                                <div className="card widget-flat">
                                    <div className="card-body">
                                        <div className="float-end">
                                            <i className="mdi mdi-currency-usd widget-icon"></i>
                                        </div>
                                        <h5 className="text-muted fw-normal mt-0" title="Average Revenue">Total Disbursed</h5>
                                        <h3 className="mt-3 mb-3">{loansDashboard?.total_amount_disbursed_current_year?.toLocaleString()}</h3>
                                        <p className="mb-0 text-muted">
                                            {/* <span className="text-danger me-2"><i className="mdi mdi-arrow-down-bold"></i> 7.00%</span> */}
                                            <span className="text-nowrap badge bg-warning">Year</span>
                                        </p>
                                    </div> 
                                </div> 
                            </div> 

                            <div className="col-lg-6">
                                <div className="card widget-flat">
                                    <div className="card-body">
                                        <div className="float-end">
                                            <i className="mdi mdi-pulse widget-icon"></i>
                                        </div>
                                        <h5 className="text-muted fw-normal mt-0" title="Growth">Total Returned</h5>
                                        <h3 className="mt-3 mb-3">{loansDashboard?.total_amount_return_current_year}</h3>
                                        <p className="mb-0 text-muted">
                                            {/* <span className="text-success me-2"><i className="mdi mdi-arrow-up-bold"></i> 4.87%</span> */}
                                            <span className="text-nowrap badge bg-info">Year</span>
                                        </p>
                                    </div> 
                                </div> 
                            </div> 
                        </div>

                    </div> 

                    <div className="col-xl-7 col-lg-6">
                        <div className="card card-h-100">
                            <div className="card-body">
                                <h4 className="header-title mb-3">Actuals Vs Potential </h4>
                                <div dir="ltr">
                                    <Chart
                                        type="bar"
                                        options={{...potentialVsActionLoan,labels:loansDashboard.labels}}
                                        series={
                                                    [
                                                        { name: loansDashboard?.actual_interest?.name, data: loansDashboard?.actual_interest?.data },
                                                        {name: loansDashboard?.potential_interest?.name,data: loansDashboard?.potential_interest?.data}
                                                    ]
                                                 }
                                        className="apex-charts"
                                        id="high-performing-product"
                                        height= "257px"
                                    /> 
                                </div>

                            </div> 
                        </div> 

                    </div> 
                </div>
               
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="header-title mb-3">Loan Revenue</h4>
                                <div className="chart-content-bg">
                                    <div className="row text-center">
                                        <div className="col-md-6">
                                            <p className="text-muted mb-0 mt-3">Amount Disbursed(plus interest)</p>
                                            <h2 className="fw-normal mb-3">
                                                <small className="mdi mdi-checkbox-blank-circle text-primary align-middle me-1">

                                                </small>
                                                <span>{`${appTxt.naira_}${loansDashboard?.total_amount_disbursed_current_year?.toLocaleString()}`}</span>
                                            </h2>
                                        </div>
                                        <div className="col-md-6">
                                            <p className="text-muted mb-0 mt-3">Amount Returned(plus interest)</p>
                                            <h2 className="fw-normal mb-3">
                                                <small className="mdi mdi-checkbox-blank-circle text-success align-middle me-1">
                                                </small>
                                                <span>{`${appTxt.naira_}${loansDashboard?.total_amount_return_current_year?.toLocaleString()}`}</span>
                                            </h2>
                                        </div>
                                    </div>
                                </div>

                                <div className="dash-item-overlay d-none d-md-block" dir="ltr">
                                    <h5>
                                        balance + interest :  
                                        {loansDashboard.interest_from_loan > 0 ?
                                        
                                        <span className='badge bg-success'>{appTxt.naira_} {loansDashboard?.interest_from_loan?.toLocaleString()}  </span>
                                        
                                        :  <span className='badge bg-danger'>{appTxt.naira_} {loansDashboard?.interest_from_loan?.toLocaleString()}  </span>}
                                    </h5>
                                    <p className="text-muted font-13 mb-3 mt-2">

                                    </p>
                                    <Link to={url.loansApp} className="btn btn-outline-primary">View Loans
                                        <i className="mdi mdi-arrow-right ms-2"></i>
                                    </Link>
                                </div>
                                <div dir="ltr">
                                    {/* loansGraphTwo */}
                                    <div id="revenue-chart" className="apex-charts mt-3" data-colors="#727cf5,#0acf97"></div>
                                        <Chart
                                            type="line"
                                            options={{...loansReturnVsDisbursed,labels:loansDashboard?.labels}}
                                            series={
                                                [
                                                    {   name: loansDashboard?.disbursed?.name,
                                                        data:loansDashboard?.disbursed?.data
                                                    },
                                                    {   name: loansDashboard?.returned?.name,
                                                        data: loansDashboard?.returned?.data
                                                    },
                                                ]
                                            }
                                            className="apex-charts mt-3"
                                            id="revenue-chart"
                                            height= "257px"
                                    /> 
                                </div>
                            </div> 
                        </div> 
                    </div> 

                   
                </div>
               


                <div className="row">
                    <div className="col-xl-12 col-lg-12 order-lg-2 order-xl-1">
                        <div className="card">
                            <div className="card-body">
                                {/* <a href="" className="btn btn-sm btn-link float-end">Export
                                    <i className="mdi mdi-download ms-1"></i>
                                </a> */}
                                <h4 className="header-title mt-2 mb-3">Pending Loans</h4>

                                <div className="table-responsive">
                                    <table className="table table-centered table-nowrap table-hover mb-0">
                                        <tbody>
                                            { loansDashboard &&
                                                loansDashboard?.user_pending_loans?.map((item) =>
                                            <tr key={item.id}>
                                            <td>
                                                {/* 07 April 2018 */}
                                                <h5 className="font-14 my-1 fw-normal">{item.user.name}</h5>
                                                <span className="text-muted font-13">{item.disbursed_date}</span>
                                            </td>
                                            <td>
                                                <h5 className="font-14 my-1 fw-normal">
                                                    {Number(item.amount).toLocaleString()}
                                                </h5>
                                                <span className="text-muted font-13">Amount Borrowed</span>
                                            </td>
                                            <td>
                                                <h5 className="font-14 my-1 fw-normal">
                                                    {Number(item?.expected_amount).toLocaleString()}
                                                </h5>
                                                <span className="text-muted font-13">Amount to be Returned</span>
                                            </td>
                                            <td>
                                                <h5 className="font-14 my-1 fw-normal">
                                                    {Number(item?.loans_transaction_sum_amount).toLocaleString()}
                                                </h5>
                                                <span className="text-muted font-13">Amount Returned</span>
                                            </td>
                                            <td>
                                                <h5 className="font-14 my-1 fw-normal">{
                                                (item.expected_amount - item?.loans_transaction_sum_amount).toLocaleString()}
                                                </h5>
                                                <span className="text-muted font-13">Amount Left</span>
                                            </td>
                                        </tr> )}
                                       
                                        </tbody>
                                    </table>
                                </div> 
                            </div> 
                        </div> 
                    </div> 
                   
                </div>
        </div>
        
    </DashboardWrapper>
  )
}

export default LoansDashboard