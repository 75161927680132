import React, {useEffect} from "react";
import DashboardWrapper from "../../pages/admin/DashboardWrapper";
import BreadCrumb from "../../pages/admin/layouts/BreadCrumb";
import {ordersDatatableConfig, productsDatatableConfig} from "../_config/dataTableConfig";

interface Props{
    children :  JSX.Element
    currentPage ?:  string
    current :  string
    main :  string
    second : string
    showAddNewForm : any
}


const orderData = [
    {
        id:1,
        name:1,
        date:'',
        status:1,
        amount:0,
        action:1,
        teest: 2,
        e:1
    },
    {
        id:1,
        name:2,
        date:'',
        status:1,
        amount:0,
        action:1,
        teest: 2,
        e:1
    },
];

const  OrderWrapper:React.FC<Props>  =  ({children,current,currentPage,main,second,showAddNewForm}) =>   {


    const initializeDatatable = () => {
        if(currentPage==='Products'){
            productsDatatableConfig(orderData,'product_table',[1, 'desc'],['Add New Products'],showAddNewForm,'')
        }
        else if(currentPage==='Orders'){
            ordersDatatableConfig(orderData,'order_table',[1, 'desc'],['New Acc','New Data'],'','')
        }
    }

    useEffect(() => {
        initializeDatatable()

    }, []);


    return(
        <DashboardWrapper key={'orders'}>
            <BreadCrumb pageTitle={current} main={main}  second={second} current={current}/>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                {children}
                            </div>
                        </div>
                        {/* end card*/}
                    </div>
                    {/* end col */}
                </div>
                {/* end row */}
            </div>
            {/* container */}
        </DashboardWrapper>
    )
}

export default OrderWrapper