import DashboardWrapper from "../../DashboardWrapper";
import BreadCrumb from "../../layouts/BreadCrumb";
import {EmailLeftSmsSidebar, NewEmailSmsForm} from "./components";

const EmailSms = () => {
    return (
        <DashboardWrapper showWarning={false} keys={'email-chat'} >
            <BreadCrumb pageTitle={'Email/SMS'} main={'Dashboard'} current={'Communication'}/>
            <NewEmailSmsForm/>
            <div className="container-fluid">

                <div className="row">
                {/* Right Sidebar */}
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <EmailLeftSmsSidebar/>

                            <div className="page-aside-right">
                                <h5 className={'card-header'}> </h5>
                                <div className="mt-3">
                                    <ul className="email-list">
                                        <li className="unread">
                                            <div className="email-sender-info">
                                                <div className="checkbox-wrapper-mail">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="mail1" />
                                                        <label className="form-check-label" htmlFor="mail1" />
                                                    </div>
                                                </div>
                                                <span className="star-toggle mdi mdi-star-outline text-warning" />
                                                <a href="#0" className="email-title">Lucas Kriebel (via Twitter)</a>
                                            </div>
                                            <div className="email-content">
                                                <a href="javascript: void(0);" className="email-subject">Lucas Kriebel (@LucasKriebel) has sent
                                                    you a direct message on Twitter! &nbsp;–&nbsp;
                                                    <span>@LucasKriebel - Very cool :) Nicklas, You have a new direct message.</span>
                                                </a>
                                                <div className="email-date">11:49 am</div>
                                            </div>
                                            <div className="email-action-icons">
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-archive email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-delete email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-email-open email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-clock email-action-icons-item" /></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="email-sender-info">
                                                <div className="checkbox-wrapper-mail">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="mail2" />
                                                        <label className="form-check-label" htmlFor="mail2" />
                                                    </div>
                                                </div>
                                                <span className="star-toggle mdi mdi-star-outline" />
                                                <a href="javascript: void(0);" className="email-title">Randy, me (5)</a>
                                            </div>
                                            <div className="email-content">
                                                <a href="javascript: void(0);" className="email-subject">Last pic over my village &nbsp;–&nbsp;
                                                    <span>Yeah i'd like that! Do you remember the video you showed me of your train ride between Colombo and Kandy? The one with the mountain view? I would love to see that one again!</span>
                                                </a>
                                                <div className="email-date">5:01 am</div>
                                            </div>
                                            <div className="email-action-icons">
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-archive email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-delete email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-email-mark-as-unread email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-clock email-action-icons-item" /></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="email-sender-info">
                                                <div className="checkbox-wrapper-mail">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="mail3" />
                                                        <label className="form-check-label" htmlFor="mail3" />
                                                    </div>
                                                </div>
                                                <span className="star-toggle mdi mdi-star-outline text-warning" />
                                                <a href="javascript: void(0);" className="email-title">Andrew Zimmer</a>
                                            </div>
                                            <div className="email-content">
                                                <a href="javascript: void(0);" className="email-subject">Mochila Beta: Subscription Confirmed
                                                    &nbsp;–&nbsp; <span>You've been confirmed! Welcome to the ruling class of the inbox. For your records, here is a copy of the information you submitted to us...</span>
                                                </a>
                                                <div className="email-date">Mar 8</div>
                                            </div>
                                            <div className="email-action-icons">
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-archive email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-delete email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-email-mark-as-unread email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-clock email-action-icons-item" /></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="unread">
                                            <div className="email-sender-info">
                                                <div className="checkbox-wrapper-mail">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="mail4" />
                                                        <label className="form-check-label" htmlFor="mail4" />
                                                    </div>
                                                </div>
                                                <span className="star-toggle mdi mdi-star-outline" />
                                                <a href="javascript: void(0);" className="email-title">Infinity HR</a>
                                            </div>
                                            <div className="email-content">
                                                <a href="javascript: void(0);" className="email-subject">Sveriges Hetaste sommarjobb &nbsp;–&nbsp;
                                                    <span>Hej Nicklas Sandell! Vi vill bjuda in dig till "First tour 2014", ett rekryteringsevent som erbjuder jobb på 16 semesterorter iSverige.</span>
                                                </a>
                                                <div className="email-date">Mar 8</div>
                                            </div>
                                            <div className="email-action-icons">
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-archive email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-delete email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-email-open email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-clock email-action-icons-item" /></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="email-sender-info">
                                                <div className="checkbox-wrapper-mail">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="mail5" />
                                                        <label className="form-check-label" htmlFor="mail5" />
                                                    </div>
                                                </div>
                                                <span className="star-toggle mdi mdi-star-outline" />
                                                <a href="javascript: void(0);" className="email-title">Web Support Dennis</a>
                                            </div>
                                            <div className="email-content">
                                                <a href="javascript: void(0);" className="email-subject">Re: New mail settings &nbsp;–&nbsp;
                                                    <span>Will you answer him asap?</span>
                                                </a>
                                                <div className="email-date">Mar 7</div>
                                            </div>
                                            <div className="email-action-icons">
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-archive email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-delete email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-email-mark-as-unread email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-clock email-action-icons-item" /></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="email-sender-info">
                                                <div className="checkbox-wrapper-mail">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="mail6" />
                                                        <label className="form-check-label" htmlFor="mail6" />
                                                    </div>
                                                </div>
                                                <span className="star-toggle mdi mdi-star-outline text-warning" />
                                                <a href="javascript: void(0);" className="email-title">me, Peter (2)</a>
                                            </div>
                                            <div className="email-content">
                                                <a href="javascript: void(0);" className="email-subject">Off on Thursday &nbsp;–&nbsp;
                                                    <span>Eff that place, you might as well stay here with us instead! Sent from my iPhone 4 &gt; 4 mar 2014 at 5:55 pm</span>
                                                </a>
                                                <div className="email-date">Mar 4</div>
                                            </div>
                                            <div className="email-action-icons">
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-archive email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-delete email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-email-mark-as-unread email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-clock email-action-icons-item" /></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="email-sender-info">
                                                <div className="checkbox-wrapper-mail">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="mail7" />
                                                        <label className="form-check-label" htmlFor="mail7" />
                                                    </div>
                                                </div>
                                                <span className="star-toggle mdi mdi-star-outline" />
                                                <a href="javascript: void(0);" className="email-title">Medium</a>
                                            </div>
                                            <div className="email-content">
                                                <a href="javascript: void(0);" className="email-subject">This Week's Top Stories &nbsp;–&nbsp;
                                                    <span>Our top pick for you on Medium this week The Man Who Destroyed America’s Ego</span>
                                                </a>
                                                <div className="email-date">Feb 28</div>
                                            </div>
                                            <div className="email-action-icons">
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-archive email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-delete email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-email-mark-as-unread email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-clock email-action-icons-item" /></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="email-sender-info">
                                                <div className="checkbox-wrapper-mail">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="mail8" />
                                                        <label className="form-check-label" htmlFor="mail8" />
                                                    </div>
                                                </div>
                                                <span className="star-toggle mdi mdi-star-outline text-warning" />
                                                <a href="javascript: void(0);" className="email-title">Death to Stock</a>
                                            </div>
                                            <div className="email-content">
                                                <a href="javascript: void(0);" className="email-subject">Montly High-Res Photos &nbsp;–&nbsp;
                                                    <span>To create this month's pack, we hosted a party with local musician Jared Mahone here in Columbus, Ohio.</span>
                                                </a>
                                                <div className="email-date">Feb 28</div>
                                            </div>
                                            <div className="email-action-icons">
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-archive email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-delete email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-email-mark-as-unread email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-clock email-action-icons-item" /></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="email-sender-info">
                                                <div className="checkbox-wrapper-mail">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="mail9" />
                                                        <label className="form-check-label" htmlFor="mail9" />
                                                    </div>
                                                </div>
                                                <span className="star-toggle mdi mdi-star-outline" />
                                                <a href="javascript: void(0);" className="email-title">Revibe</a>
                                            </div>
                                            <div className="email-content">
                                                <a href="javascript: void(0);" className="email-subject">Weekend on Revibe &nbsp;–&nbsp;
                                                    <span>Today's Friday and we thought maybe you want some music inspiration for the weekend. Here are some trending tracks and playlists we think you should give a listen!</span>
                                                </a>
                                                <div className="email-date">Feb 27</div>
                                            </div>
                                            <div className="email-action-icons">
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-archive email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-delete email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-email-mark-as-unread email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-clock email-action-icons-item" /></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="email-sender-info">
                                                <div className="checkbox-wrapper-mail">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="mail10" />
                                                        <label className="form-check-label" htmlFor="mail10" />
                                                    </div>
                                                </div>
                                                <span className="star-toggle mdi mdi-star-outline" />
                                                <a href="javascript: void(0);" className="email-title">Erik, me (5)</a>
                                            </div>
                                            <div className="email-content">
                                                <a href="javascript: void(0);" className="email-subject">Regarding our meeting &nbsp;–&nbsp;
                                                    <span>That's great, see you on Thursday!</span>
                                                </a>
                                                <div className="email-date">Feb 24</div>
                                            </div>
                                            <div className="email-action-icons">
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-archive email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-delete email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-email-mark-as-unread email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-clock email-action-icons-item" /></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="email-sender-info">
                                                <div className="checkbox-wrapper-mail">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="mail11" />
                                                        <label className="form-check-label" htmlFor="mail11" />
                                                    </div>
                                                </div>
                                                <span className="star-toggle mdi mdi-star-outline text-warning" />
                                                <a href="javascript: void(0);" className="email-title">KanbanFlow</a>
                                            </div>
                                            <div className="email-content">
                                                <a href="javascript: void(0);" className="email-subject">Task assigned: Clone ARP's website
                                                    &nbsp;–&nbsp; <span>You have been assigned a task by Alex@Work on the board Web.</span>
                                                </a>
                                                <div className="email-date">Feb 24</div>
                                            </div>
                                            <div className="email-action-icons">
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-archive email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-delete email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-email-mark-as-unread email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-clock email-action-icons-item" /></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="email-sender-info">
                                                <div className="checkbox-wrapper-mail">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="mail12" />
                                                        <label className="form-check-label" htmlFor="mail12" />
                                                    </div>
                                                </div>
                                                <span className="star-toggle mdi mdi-star-outline" />
                                                <a href="javascript: void(0);" className="email-title">Tobias Berggren</a>
                                            </div>
                                            <div className="email-content">
                                                <a href="javascript: void(0);" className="email-subject">Let's go fishing! &nbsp;–&nbsp;
                                                    <span>Hey, You wanna join me and Fred at the lake tomorrow? It'll be awesome.</span>
                                                </a>
                                                <div className="email-date">Feb 23</div>
                                            </div>
                                            <div className="email-action-icons">
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-archive email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-delete email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-email-mark-as-unread email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-clock email-action-icons-item" /></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="email-sender-info">
                                                <div className="checkbox-wrapper-mail">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="mail13" />
                                                        <label className="form-check-label" htmlFor="mail13" />
                                                    </div>
                                                </div>
                                                <span className="star-toggle mdi mdi-star-outline text-warning" />
                                                <a href="javascript: void(0);" className="email-title">Charukaw, me (7)</a>
                                            </div>
                                            <div className="email-content">
                                                <a href="javascript: void(0);" className="email-subject">Hey man &nbsp;–&nbsp; <span>Nah man sorry i don't. Should i get it?</span>
                                                </a>
                                                <div className="email-date">Feb 23</div>
                                            </div>
                                            <div className="email-action-icons">
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-archive email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-delete email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-email-mark-as-unread email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-clock email-action-icons-item" /></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="unread">
                                            <div className="email-sender-info">
                                                <div className="checkbox-wrapper-mail">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="mail14" />
                                                        <label className="form-check-label" htmlFor="mail14" />
                                                    </div>
                                                </div>
                                                <span className="star-toggle mdi mdi-star-outline text-warning" />
                                                <a href="javascript: void(0);" className="email-title">me, Peter (5)</a>
                                            </div>
                                            <div className="email-content">
                                                <a href="javascript: void(0);" className="email-subject">Home again! &nbsp;–&nbsp; <span>That's just perfect! See you tomorrow.</span>
                                                </a>
                                                <div className="email-date">Feb 21</div>
                                            </div>
                                            <div className="email-action-icons">
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-archive email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-delete email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-email-open email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-clock email-action-icons-item" /></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="email-sender-info">
                                                <div className="checkbox-wrapper-mail">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="mail15" />
                                                        <label className="form-check-label" htmlFor="mail15" />
                                                    </div>
                                                </div>
                                                <span className="star-toggle mdi mdi-star-outline" />
                                                <a href="javascript: void(0);" className="email-title">Stack Exchange</a>
                                            </div>
                                            <div className="email-content">
                                                <a href="javascript: void(0);" className="email-subject">1 new items in your Stackexchange inbox
                                                    &nbsp;–&nbsp; <span>The following items were added to your Stack Exchange global inbox since you last checked it.</span>
                                                </a>
                                                <div className="email-date">Feb 21</div>
                                            </div>
                                            <div className="email-action-icons">
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-archive email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-delete email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-email-mark-as-unread email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-clock email-action-icons-item" /></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="email-sender-info">
                                                <div className="checkbox-wrapper-mail">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="mail16" />
                                                        <label className="form-check-label" htmlFor="mail16" />
                                                    </div>
                                                </div>
                                                <span className="star-toggle mdi mdi-star-outline text-warning" />
                                                <a href="javascript: void(0);" className="email-title">Google Drive Team</a>
                                            </div>
                                            <div className="email-content">
                                                <a href="javascript: void(0);" className="email-subject">You can now use your storage in Google
                                                    Drive &nbsp;–&nbsp; <span>Hey Nicklas Sandell! Thank you for purchasing extra storage space in Google Drive.</span>
                                                </a>
                                                <div className="email-date">Feb 20</div>
                                            </div>
                                            <div className="email-action-icons">
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-archive email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-delete email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-email-mark-as-unread email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-clock email-action-icons-item" /></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="unread">
                                            <div className="email-sender-info">
                                                <div className="checkbox-wrapper-mail">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="mail17" />
                                                        <label className="form-check-label" htmlFor="mail17" />
                                                    </div>
                                                </div>
                                                <span className="star-toggle mdi mdi-star-outline" />
                                                <a href="javascript: void(0);" className="email-title">me, Susanna (11)</a>
                                            </div>
                                            <div className="email-content">
                                                <a href="javascript: void(0);" className="email-subject">Train/Bus &nbsp;–&nbsp; <span>Yes ok, great! I'm not stuck in Stockholm anymore, we're making progress.</span>
                                                </a>
                                                <div className="email-date">Feb 19</div>
                                            </div>
                                            <div className="email-action-icons">
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-archive email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-delete email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-email-open email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-clock email-action-icons-item" /></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="email-sender-info">
                                                <div className="checkbox-wrapper-mail">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="mail18" />
                                                        <label className="form-check-label" htmlFor="mail18" />
                                                    </div>
                                                </div>
                                                <span className="star-toggle mdi mdi-star-outline" />
                                                <a href="javascript: void(0);" className="email-title">Peter, me (3)</a>
                                            </div>
                                            <div className="email-content">
                                                <a href="javascript: void(0);" className="email-subject">Hello &nbsp;–&nbsp; <span>Trip home from Colombo has been arranged, then Jenna will come get me from Stockholm. :)</span>
                                                </a>
                                                <div className="email-date">Jan 28</div>
                                            </div>
                                            <div className="email-action-icons">
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-archive email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-delete email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-email-mark-as-unread email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-clock email-action-icons-item" /></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="email-sender-info">
                                                <div className="checkbox-wrapper-mail">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="mail19" />
                                                        <label className="form-check-label" htmlFor="mail19" />
                                                    </div>
                                                </div>
                                                <span className="star-toggle mdi mdi-star-outline" />
                                                <a href="javascript: void(0);" className="email-title">me, Susanna (7)</a>
                                            </div>
                                            <div className="email-content">
                                                <a href="javascript: void(0);" className="email-subject">Since you asked... and i'm
                                                    inconceivably bored at the train station &nbsp;–&nbsp;
                                                    <span>Alright thanks. I'll have to re-book that somehow, i'll get back to you.</span>
                                                </a>
                                                <div className="email-date">Jan 25</div>
                                            </div>
                                            <div className="email-action-icons">
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-archive email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-delete email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-email-mark-as-unread email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-clock email-action-icons-item" /></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="email-sender-info">
                                                <div className="checkbox-wrapper-mail">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="mail20" />
                                                        <label className="form-check-label" htmlFor="mail20" />
                                                    </div>
                                                </div>
                                                <span className="star-toggle mdi mdi-star-outline" />
                                                <a href="javascript: void(0);" className="email-title">Randy, me (5)</a>
                                            </div>
                                            <div className="email-content">
                                                <a href="javascript: void(0);" className="email-subject">Last pic over my village &nbsp;–&nbsp;
                                                    <span>Yeah i'd like that! Do you remember the video you showed me of your train ride between Colombo and Kandy? The one with the mountain view? I would love to see that one again!</span>
                                                </a>
                                                <div className="email-date">Jan 22</div>
                                            </div>
                                            <div className="email-action-icons">
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-archive email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-delete email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-email-mark-as-unread email-action-icons-item" /></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="javascript: void(0);"><i className="mdi mdi-clock email-action-icons-item" /></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                {/* end .mt-4 */}
                                <div className="row">
                                    <div className="col-7 mt-1">
                                        Showing 1 - 20 of 289
                                    </div> {/* end col*/}
                                    <div className="col-5">
                                        <div className="btn-group float-end">
                                            <button type="button" className="btn btn-light btn-sm"><i className="mdi mdi-chevron-left" /></button>
                                            <button type="button" className="btn btn-info btn-sm"><i className="mdi mdi-chevron-right" /></button>
                                        </div>
                                    </div> {/* end col*/}
                                </div>
                                {/* end row*/}
                            </div>
                            {/* end inbox-rightbar*/}
                        </div>
                        {/* end card-body */}
                        <div className="clearfix" />
                    </div> {/* end card-box */}
                </div> {/* end Col */}
            </div>

            </div>
    </DashboardWrapper>
    )
}


export default  EmailSms