import Chart from "react-apexcharts";
import { SavingDashboardAnalyticModel } from "../../../../config/model/authModel";
import {savingsMonthlyAreaGraphConfig } from "./init";
import React from "react";


interface Props {
    data: SavingDashboardAnalyticModel
}

const SavingsMonthlyReport:React.FC<Props> = ({data}) => {
   
    return <>
         <Chart
            type="area"
            options={{...savingsMonthlyAreaGraphConfig,
                ...{labels:data?.savingsDashboard?.labels??[]}}}
            series={[
				{
					name: 'Savings',
					data: data?.savingsDashboard?.series??[],
				},
			]}
            id="sessions-overview"
            className="apex-charts mt-3" 
            height= "309px"
            /> 
    </>

}


export default SavingsMonthlyReport