const CryptoJS = require('crypto-js');

export const removeBackDropModal = () => {
    const elements = document.getElementsByClassName('modal-backdrop');

    // Check if any elements with the specified class name were found
    if (elements.length > 0) {
        if (elements[0].parentNode) {
            elements[0].parentNode.removeChild(elements[0]);
            document.body.style.overflow = 'auto';
        }
    }

}

export const decryptedData = (encryptedData) => {
    // const encryptedData = '...'; // Received from Laravel
    const parameters = JSON.parse('...'); // Received from Laravel

    const decryptedData = CryptoJS.AES.decrypt(
                    encryptedData,
        CryptoJS.enc.Base64.parse(parameters.key),
        { iv: CryptoJS.enc.Base64.parse(parameters.iv) }
    ).toString(CryptoJS.enc.Utf8);

    console.log(decryptedData);
}



export const removeClass = (id,className,removeBy) => {
    if(removeBy==='class'){

        document.getElementsByClassName(className)[0].style.display = "none"
    }
    else{
        document.getElementById(id).classList.remove(`${className}`)
    }

}