import {useDispatch, useSelector} from "react-redux"
import {useForm} from "react-hook-form"
import {Link} from "react-router-dom"
import {AppState} from "../../../../config/redux/reducers"
import {LoansReducerState} from "../../../../config/redux/reducers/tmsLoansReducer"
import {appTxt} from "../../../../config/strings"
import {AuthState} from "../../../../config/redux/reducers/tmsAuthReducer"
import {remote_url} from "../../../../config/urls/remote"
import {types} from "../../../../config/redux/types"
import {postRequest, setGeneralAction} from "../../../../config/redux/actions"
import {Spinner} from "../../../../components"
import {useState} from "react"
import {LoanRepaymentModel, LoanRepaymentProps} from "../../../../config/model/authModel"
import {ProfileDetailCard} from "../../_components";

type LoanDetailsProps = {
    show?: string,
    backDrop?: string,
    closeModal?: any
    // userD  : UsersModel
}

interface FormValues {
    status: number,
    loan_reference: string,
    user_id: number,
    channel: string
    gateway: string
}

const sampleLoanRepayment: LoanRepaymentModel = {
    id: 0,
    amount: 0,
    status: "0",
    application_reference: "",
    channel: "",
    initialized_url: ""
}


const LoanRepaymentDetails: React.FC<LoanDetailsProps> = ({show = "", closeModal,backDrop = "true"}) => {
    const {register, handleSubmit, formState: {errors}, reset} = useForm<FormValues>()
    const {token, account_key, user, role_id,payment_channels,gateways} = useSelector<AppState, AuthState>((state) => state.tmsAuthReducer)
    const [showPaymentGateway,setPaymentGateway] = useState(false)

    const {
        success_msg,
        loan,
        loading_,
        loanPayments,
        loanPayment,
        loanFilter
    } = useSelector<AppState, LoansReducerState>((state) => state.tmsLoansReducer)
    //keeps track of the laon repayment that was selected
    // const [selectedLoan, setSelectedLoan] = useState<LoanRepaymentModel>()


    const dispatch = useDispatch()

    //
    const clearData = () => {
        dispatch(setGeneralAction(types.CLEAR_LOAN_MSG, []))

    }
    //
    const updateStatus = (data: FormValues) => {
        // e.preveven
        const postData = {
            token,
            url: remote_url.updateStatus,
            actionType: types.UPDATE_LOAN_APP_STATUS,
            postData: {
                loan_reference: loan.application_reference_no,
                user_id: loan.user.id,
                status: data.status,
                fromDate: loanFilter.fromDate,
                toDate: loanFilter.toDate,
                filter_status: loanFilter.status

            },
            secretData: {
                email: user.email,
                mac_id: user.mac_id,
                r_id: role_id
            },
            errorAction: types.SET_ERROR,
            accountKey: account_key,
            loading: {
                isLoading: false,
                isLoading_: true,
                isLoading_t: false
            }
            // reset
        }
        //
        dispatch(postRequest(postData))

    }

    const handlePaymentGateway = (item:string) => {
        if(item==='card'){
            setPaymentGateway(true)
        }else{
            setPaymentGateway(false)
        }
    }

    if(success_msg){
        closeModal('')
    }
    //
    const getLoanSavingsBreakdown = () => {
        //
        const postData = {
            token,
            url: remote_url.getLoanRepaymentBreakdown,
            actionType: types.GET_LOAN_REPAYMENT_BREAKDOWN,
            postData: {
                loan_app_id: loan.id,
                user_id: loan.user.id
            },
            secretData: {
                email: user.email,
                mac_id: user.mac_id,
                r_id: role_id
            },
            errorAction: types.SET_ERROR,
            accountKey: account_key,
            loading: {
                loading: false,
                isLoading_: true,
                isLoading_t: false
            }
            //     // reset
        }
        //
        dispatch(postRequest(postData))
    }

    //
    const completeLoanRepaymentTransaction = (data: FormValues) => {
        console.log(data)
        const postData = {
            token,
            url: remote_url.loanRepayment,
            actionType: types.REPAY_LOAN,
            postData: {
                loan_repayment_id: loanPayment?.id,
                loan_app_id: loan.id,
                user_id: loan.user.id,
                channel: data.channel,
                gateway: data.gateway,
                amount: loanPayment?.amount,
                fromDate: loanFilter.fromDate,
                toDate: loanFilter.toDate,
                filter_status: loanFilter.status
            },
            secretData: {
                email: user.email,
                mac_id: user.mac_id,
                r_id: role_id
            },
            errorAction: types.SET_ERROR,
            accountKey: account_key,
            loading: {
                isLoading: false,
                isLoading_: true,
                isLoading_t: false
            }
            //     // reset
        }
        //
        dispatch(postRequest(postData))
        //
    }


    const makePayment = (url: string) => {
        window.location.href = url
    }



    ///
    return <>
        <div
            className={`offcanvas offcanvas-end ${show}`}
            tabIndex={-1}
            id="loansSavingDetails"
            // data-bs-backdrop="true" 
            aria-labelledby="offcanvasRightLabel">
            <div className="offcanvas-header">
                <h5 id="offcanvasRightLabel">
                    {loan?.application_reference_no} | Loan Application
                </h5>
                <Link to="#0" onClick={() => clearData()} className="btn-close text-reset" data-bs-dismiss="offcanvas"
                      aria-label="Close"></Link>
            </div>

            <div className="offcanvas-body">
                <ProfileDetailCard
                    profile_url={loan.user?.profile_image ==="" ? loan.user?.alternate_profile_image : loan.user?.profile_image}
                    email={''}
                    name={loan.user?.name}
                    summaryText={'Pending Loan Bal'}
                    summaryValue={'0'}
                    />


                {/*  */}
                <table className="table table-centered table-striped">
                    <tbody>
                    <tr>
                        <td>Loan Status</td>
                        <td>
                            {/*Pending,Approved,Disapproved,Disbursed,Paid */}
                            {
                                loan.status === 'Pending' ?
                                    <span className="badge bg-warning">{loan?.status}</span>
                                    :
                                    loan.status === 'Approved' ?
                                        <span className="badge bg-primary">{loan?.status}</span>
                                        :
                                        loan.status === 'Disapproved' ?
                                            <span className="badge bg-danger">{loan?.status}</span> :
                                            loan.status === 'Disbursed' ?
                                                <span className="badge bg-info">{loan?.status}</span>
                                                :
                                                loan.status === 'Payment Completed' ?
                                                    <span className="badge bg-success">{loan?.status}</span>
                                                    :
                                                    ''
                            }
                        </td>
                    </tr>

                    <tr>
                        <td>Loan Type</td>
                        <td>
                            <span className="badge bg-info">{loan?.loan_type?.name}</span>
                        </td>
                    </tr>

                    <tr>
                        <td>Application Date</td>
                        <td>{loan?.application_date}</td>
                    </tr>

                    <tr>
                        <td>Approval/Disapproval Date</td>
                        <td>{loan?.approval_disapproval_date}</td>
                    </tr>

                    <tr>
                        <td>Loan Disbursement Date</td>
                        <td>{loan.disbursed_date ? loan.disbursed_date : 'N/A'}</td>
                    </tr>

                    <tr>
                        <td> Loan Completed Date</td>
                        <td> {loan?.status === 'Payment Completed' ? loan?.updated_at : 'N/A'} </td>
                    </tr>

                    <tr>
                        <td>Submitted By</td>
                        <td>{loan?.creator?.name}</td>
                    </tr>


                    <tr>
                        <td>Repayment Status(%)</td>
                        <td>{Math.round((loan?.loan_repayment_count / loan.duration) * 100)} %</td>
                    </tr>
                    <tr>
                        <td>Loan Interest</td>
                        <td>{loan.expected_interest * 100}%</td>
                    </tr>
                    <tr>
                        <td>Minimum Savings Balance for <span className="badge bg-info">{loan?.loan_type?.name}</span>
                        </td>
                        <td>{appTxt.naira_}{Number(loan.minimum_savings_bal).toLocaleString()} <span
                            className="badge bg-info"></span></td>
                    </tr>
                    <tr>
                        <td>Applicant can request for a maximum of {loan.multiples_of_savings} times his/her
                            savings {loan.savings_transaction_sum_thrift_amount}
                        </td>
                        {/* <td>{loan.multiples_of_savings} * {loan.savings_transaction_sum_thrift_amount}</td> */}
                    </tr>
                    <tr>
                        <td>Applicant Savings Balance</td>
                        <td>{appTxt.naira_}{Number(loan.savings_transaction_sum_thrift_amount).toLocaleString()}</td>
                    </tr>

                    <tr>
                        <td> Amount Borrowed</td>
                        <td>
                            {Number(loan.multiples_of_savings) * Number(loan.savings_transaction_sum_thrift_amount) === Number(loan.amount) ?
                                <span className="badge bg-success">
                                    {appTxt.naira_}
                                    {Number(loan?.amount).toLocaleString()}</span> :
                                <span className="badge bg-danger">
                                    {appTxt.naira_}
                                    {Number(loan?.amount).toLocaleString()}</span>
                            }

                        </td>
                    </tr>
                    <tr>
                        <td>Amount to be Returned</td>
                        <td>{appTxt.naira_}
                            {Number(loan?.expected_amount).toLocaleString()}</td>
                    </tr>
                    <tr>
                        <td>Amount Returned</td>
                        <td>{appTxt.naira_}{Number(loan.loans_transaction_sum_amount).toLocaleString()}</td>
                    </tr>
                    <tr>
                        <td>Duration</td>
                        <td>{loan?.duration} month(s)</td>
                    </tr>
                    </tbody>
                </table>
                <hr/>
                {/* Shows form When Approval is Still Pending */}
                {loan.status === 'Pending' && Number(role_id)===1 &&
                    <form onSubmit={handleSubmit(updateStatus)}>
                        <select className="form-select mb-2" {...register('status')}>
                            <option> Select Loan Status</option>
                            <option value={1}>Approve Loan</option>
                            <option value={2}>Disapproved Loan</option>
                        </select>
                        <div className="form-group">
                            {loading_ ? <Spinner color="primary"/> :
                                <input type='submit' value='Update' className="btn btn-primary form-control"/>
                            }
                        </div>
                    </form>
                }
                {/* Shows Up when Loan is Approved */}
                {loan.status === 'Approved' && Number(role_id)===1 &&
                    <form onSubmit={handleSubmit(updateStatus)} className="px-5 py-3">
                        <select defaultValue={''} className="form-select mb-2" {...register('status')}>
                            <option value={''}>Select loan Status</option>
                            <option value={3}>Disbursed Loan</option>
                        </select>
                        <div className="form-group">
                            {loading_ ? <Spinner color="primary"/> :

                                <input type='submit' value='Update' className="btn btn-primary form-control"/>
                            }
                        </div>
                    </form>
                }

                {/* Button to fetch payment  breakdown */}
                {((loan.status === 'Disbursed' && !loanPayment) || loan.status === 'Payment Completed') &&
                    <div className="my-3">
                        <Link to="#0" className="btn btn-outline-primary" onClick={() => getLoanSavingsBreakdown()}>
                            Check For Loan Repayment Breakdown ....
                        </Link>
                        {loading_ && <Spinner color="info"/>}
                    </div>
                }
                {/*  */}
                {
                    loanPayment
                        ?
                        <div className="card shadow px-4 py-3">
                            {/* <h5>Payment Form</h5> */}
                            <form onSubmit={handleSubmit(completeLoanRepaymentTransaction)}>
                                <div className={'form-group'}>
                                    <small className="helper-text">Payment channel</small>
                                    <select className="form-select mb-2"
                                            {...register('channel',{
                                                required:true,
                                                onChange: (e) => handlePaymentGateway(e.target.value)
                                            })}>
                                        <option value={''}>Select your Payment Channel</option>
                                        {payment_channels.map((channels,key=0) => <option key={channels.key} value={channels.key}>{channels.name}</option>)}
                                    </select>
                                </div>

                                {
                                    showPaymentGateway &&
                                    <div className={'form-group'}>
                                        <small className="helper-text">Payment Gateway</small>
                                        <select className="form-select mb-2"   {...register('gateway',{required:true})}>
                                            <option value={''}>Select your Payment Gateway</option>
                                            {gateways.map((gateway,key=0) => <option key={gateway.key} value={gateway.key}>{gateway.name}</option>)}
                                        </select>
                                    </div>
                                }

                                <div className="btn-group">
                                    <span className="btn btn-outline-dark"
                                          onClick={() => dispatch(setGeneralAction(types.SET_LOAN_PAYMENT_DATA, sampleLoanRepayment))}>
                                        Close
                                    </span>
                                    <span>&nbsp;</span>
                                    {
                                        loading_ ?
                                            <Spinner color="primary"/> :
                                            <button className="btn btn-primary form-control">
                                                Repay a sum
                                                of {appTxt.naira_} {Number(loanPayment?.amount).toLocaleString()}
                                            </button>
                                    }

                                </div>
                            </form>
                            <hr/>
                        </div>
                        :
                        loanPayments?.length > 0 &&
                        <table className="table table-centered table-striped table-responsive">
                            <thead>
                            <tr>
                                <th>Amount</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>

                            {loanPayments.map((item) =>
                                <tr key={item.id}>
                                    <td>
                                        <small>
                                            {appTxt.naira_}{Number(item.amount).toLocaleString()} |
                                            {item.application_reference} |{item.paid_at ?? 'N/A'} | {item?.channel ?? 'N/A'}
                                        </small>
                                    </td>
                                    <td>
                                        {
                                            item.status === "0" && item.channel === 'card' && item.initialized_url ?
                                                <Link to="#0"
                                                      onClick={() => makePayment(item.initialized_url)}
                                                      className="btn btn-outline-dark">continue to payment gateway
                                                </Link>
                                                : item.status === "0" ?
                                                    <Link to="#0"
                                                          onClick={() => dispatch(setGeneralAction(types.SET_LOAN_PAYMENT_DATA, item))}
                                                          className="btn btn-outline-info"> pay
                                                    </Link>
                                                    :
                                                    <span className="badge bg-success">paid</span>
                                        }
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                }

            </div>
        </div>
        {/*{show==="false" &&    <div className="modal-backdrop fade show"></div>}*/}

    </>
}


export default LoanRepaymentDetails