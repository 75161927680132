import Chart from "react-apexcharts";

import { ApexOptions } from "apexcharts";
import { Link } from "react-router-dom";
import { revenueChartOptions } from "../graph/init";
import { DashboardAnalyticModel } from "../../../../config/model/authModel";
import { appTxt } from "../../../../config/strings";
import {formatCash} from "../../../../config/redux/actions";



interface Props {
 data : DashboardAnalyticModel,
 id : string,
 col?: string
}

const LineChartCard: React.FC<Props> = ({data,id,col='7'}) => {
  return (
    <>
      <div className={`col-lg-${col}`}>
            <div className="card">
                <div className="card-body">
                    {/* 
                        <div className="dropdown float-end">
                        <Link to="#0" className="dropdown-toggle arrow-none card-drop"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="mdi mdi-dots-vertical"></i>
                        </Link>
                        <div className="dropdown-menu dropdown-menu-end">
                            <Link to="#0" className="dropdown-item">Today</Link>

                            <Link to="#0" className="dropdown-item">Yesterday</Link>

                            <Link to="#0" className="dropdown-item">Last Week</Link>

                            <Link to="#0" className="dropdown-item">Last Month</Link>
                        </div>
                        </div> 
                    */}

                    <h4 className="header-title mb-3">Revenue</h4>

                    <div className="chart-content-bg">
                        <div className="row text-center">
                            <div className="col-md-4">
                                <p className="text-muted mb-0 mt-3">Overall Savings </p>
                                <h2 className="fw-normal mb-3">
                                    <span>{ formatCash(data?.overall_savings) }</span>
                                </h2>
                            </div>
                            <div className="col-md-4">
                                <p className="text-muted mb-0 mt-3">Loan Disbursed</p>
                                <h2 className="fw-normal mb-3">
                                    <span>{formatCash(data?.overall_loan)}</span>
                                </h2>
                            </div>
                            <div className="col-md-4">
                                <p className="text-muted mb-0 mt-3">Expenses</p>
                                <h2 className="fw-normal mb-3">
                                    <span>{formatCash(data?.overall_expense)}</span>
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div dir="ltr">
                        <Chart
                            type="line"
                            options={{...revenueChartOptions,...{labels : data?.savings?.labels}}}
                            series={ [
                                    {
                                        name: 'Savings',
                                        type: 'line',
                                        data: data?.savings?.series,
                                    },
                                    {
                                        name: 'Loans',
                                        type: 'line',
                                        data: data?.loans?.series,
                                    },
                                    {
                                        name: 'Expenses',
                                        type: 'line',
                                        data:data?.inventories?.series,
                                    },
                                ]}
                            height="321"
                            className="apex-charts"
                            id="dash-revenue-chart"
                        />
                    </div>

                </div>
            </div>
       </div>
    </>
  )
}

export default LineChartCard