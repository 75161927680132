import {remote_url} from "../../../../config/urls/remote";
import {types} from "../../../../config/redux/types";
import {postRequest} from "../../../../config/redux/actions";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../config/redux/reducers";
import {AuthState} from "../../../../config/redux/reducers/tmsAuthReducer";
import { useForm } from 'react-hook-form';

type FormValues ={
    message : string,
    title : string,
}
const NewSupportForm = () => {
    const {token,account_key,user,role_id} = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
    const { register, handleSubmit, getValues,setValue,formState: { errors },reset,watch } = useForm<FormValues>()
    const dispatch = useDispatch()

    const createSupportTicket = async(data:FormValues) => {
        const postData = {
            token,
            url: remote_url.createSupportTicketsConversation,
            actionType : types.CREATE_SUPPORT_TICKET_CONVERSATION,
            postData : data,
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            errorAction : types.SET_ERROR,
            accountKey: account_key,
            loading :{
                isLoading :false,
                isLoading_ : true,
                isLoading_t : false
            }
            // reset
        }
        dispatch(postRequest(postData))
    }
    return <>
            <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header">
                    <h5 id="offcanvasRightLabel">Off canvas right</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
                </div>
                <div className="offcanvas-body">
                    <form className="px-2 " onSubmit={handleSubmit(createSupportTicket)}>
                        {/**/}
                        <div className={'mb-3'}>
                            <label className="form-label ">subject/title</label>
                            <input type={'text'} className={'form-control'}
                                   {...register('title')}
                            />
                        </div>
                        {/*    */}
                        <div className="mb-2">
                            <textarea className={'form-control'} rows={5}  {...register('message')}>

                            </textarea>
                        </div>

                        <button className={'btn btn-outline-primary'}>Submit Ticket</button>

                    </form>
                </div>
            </div>


    </>
}

export default NewSupportForm