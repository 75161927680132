
import { Link,Navigate } from "react-router-dom"
import { useForm } from 'react-hook-form';

//@1
import {useDispatch, useSelector} from 'react-redux'


//
import { url } from "../../config/urls"
import AuthWrapper from "./AuthWrapper"
import { remote_url } from "../../config/urls/remote"

//@2
// import * as actionsCreator from "../../config/redux/actions"
// import {State} from '../../config/redux/reducers/index'
import { postRequest, setGeneralAction } from "../../config/redux/actions";
import { AuthState } from "../../config/redux/reducers/tmsAuthReducer";

import { Spinner } from "../../components";
import { types } from "../../config/redux/types";
import { ErrorAlert, SuccessAlert } from "../../components/alert";
import { AppState } from "../../config/redux/reducers";
import { useEffect, useState } from "react";
import {fetchToken} from "../../firebase";
import {useNotifications} from "reapop";
import {Footer, Header} from "./components";
// import { State } from "../../config/redux/reducers";


type FormValues ={
    email : string,
    password : string,
    device_id :  string
}
//TODO new account showing multiple login
const Login = () => {
    const { register, handleSubmit, getValues ,formState: { errors },reset } = useForm<FormValues>()
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [deviceId,setDeviceId] = useState('')



    //@3
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setGeneralAction(types.CLEAR_MSG,[]))
        fetchToken().then(r => setDeviceId(r))
    },[])

    useEffect(() => {

    },[])

    //@4
    const {loading,success_msg,token,error_msg,isMultipleDevice}= useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
   

    //:: login function
    const login = async (data:FormValues) => {
        data.device_id = deviceId;
        // console.log(data)
        const postData = {
            token : '',
            url:remote_url.signIn,
            actionType: types.SIGN_IN,
            postData : data,
            secretData : {
                email:'',
                mac_id :'',
                r_id:0
            },
            // reset,
            errorAction : types.SET_ERROR,
            loading :{
                isLoading :true,
                isLoading_ : false,
                isLoading_t: false
            },
            accountKey :''
        }
        //
        dispatch(postRequest(postData))
       // if(success_msg){
       //    reset()
       //  }
    }



    const logoutFromOtherDevices = () => {
        const email = getValues('email')
        const password = getValues('password')
        const postData = {
            token : '',
            url:remote_url.logoutFromAllDevices,
            actionType: types.LOGOUT_FROM_ALL_DEVICE,
            postData : {
                email,
                password
            },
            secretData : {
                email:'',
                mac_id :'',
                r_id:0
            },
            // reset,
            errorAction : types.SET_ERROR,
            loading :{
                isLoading :true,
                isLoading_ : false,
                isLoading_t: false
            },
            accountKey :''
        }
        //
        dispatch(postRequest(postData))
    }


    //
    if(token !== ''){
        return <Navigate to={url.accounts}/>
    }

    // login()
  return (
    <AuthWrapper>
        <div className="card shadow-lg">
          <Header/>

            <div className="card-body p-4">
                <div className="text-center w-75 m-auto mb-3">
                    <h4 className="text-dark-50 text-center pb-0 fw-bold">Sign In</h4>
                </div>
                {!loading && success_msg && <SuccessAlert msg={success_msg}/>}
                {!loading && error_msg && <ErrorAlert msg={error_msg}/> }

                <form onSubmit={handleSubmit(login)} >

                    <div className="mb-3">
                        <label htmlFor="emailaddress" className="form-label">Email address</label>
                        <input 
                            className={`form-control ${errors.email && 'is-invalid'}`}
                            type="text" 
                            placeholder="Enter your email"
                            {...register('email',{
                                required: 'email address is required',
                                pattern: {
                                     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                     message: "invalid email address"
                                }
                            })}
                        />
                         {errors.email  && (<div className="invalid-feedback">{errors.email.message}</div>)}
                    </div>

                    <div className="mb-3">
                        <Link to={url.forgotPass} className="text-muted float-end">
                            <small>Forgot your password ?</small>
                        </Link>
                        <label htmlFor="password" className="form-label">Password</label>
                        <div className="input-group input-group-merge">
                            <input type={`${showPassword ? 'text':'password'}`}
                                className={`form-control ${errors.password && 'is-invalid'}`}
                                placeholder="*******"
                                {...register('password',{
                                    required: '*Password is Required',
                                    minLength: { value: 6, message: 'Your Password is too Short' },
                                })}
                            />
                            <div className="input-group-text" onClick={()=>setShowPassword(!showPassword)}>
                                <span className="password-eye"></span>
                            </div>
                            {errors.password && <small className='invalid-feedback'>{errors.password.message}</small>}
                        </div>
                    </div>

                    <div className="mb-0 text-center">
                        {
                            loading 
                            ?  
                            <Spinner color='primary'/> 
                            :
                                isMultipleDevice ? <Link to={'#'} className={'btn btn-info'} onClick={()=>logoutFromOtherDevices()}>Logout from other devices</Link>:
                            <button className="btn btn-primary" type="submit"> Log In </button>
                        }
                    </div>

                </form>
            </div>
        </div>


        <Footer url={url.accSignUp} linkText={'Sign up'} callToActionText={'New to ThriftMS?'}/>

    </AuthWrapper>
  )
}

export default Login