import DashboardWrapper from "../DashboardWrapper"
import BreadCrumb from "../layouts/BreadCrumb"




const SavingsSettings = () => {
    return <DashboardWrapper>
        <BreadCrumb current="Settings" pageTitle="Savings Settings" main="Savings"/>
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">

                            <div className="row">
                                <div className="col-xl-6">
                                    <div className="mb-3">
                                        <label htmlFor="projectname" className="form-label">Name</label>
                                        <input type="text" id="projectname" className="form-control" placeholder="Enter project name"/>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="project-overview" className="form-label">Overview</label>
                                        <textarea className="form-control" id="project-overview" rows={5} placeholder="Enter some brief about project.."></textarea>
                                    </div>

                                    
                                    <div className="mb-3 position-relative" id="datepicker1">
                                        <label className="form-label">Start Date</label>
                                        <input type="text" className="form-control" data-provide="datepicker" data-date-container="#datepicker1" data-date-format="d-M-yyyy" data-date-autoclose="true"/>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="project-budget" className="form-label">Budget</label>
                                        <input type="text" id="project-budget" className="form-control" placeholder="Enter project budget"/>
                                    </div>

                                    <div className="mb-0">
                                        <label htmlFor="project-overview" className="form-label">Team Members</label>
                                        
                                        <select className="form-control select2" data-toggle="select2">
                                            <option>Select</option>
                                            <option value="AZ">Mary Scott</option>
                                            <option value="CO">Holly Campbell</option>
                                            <option value="ID">Beatrice Mills</option>
                                            <option value="MT">Melinda Gills</option>
                                            <option value="NE">Linda Garza</option>
                                            <option value="NM">Randy Ortez</option>
                                            <option value="ND">Lorene Block</option>
                                            <option value="UT">Mike Baker</option>
                                        </select>

                                        <div className="mt-2" id="tooltip-container">
                                            <a href="javascript:void(0);" data-bs-container="#tooltip-container" data-bs-toggle="tooltip" data-bs-placement="top" title="Mat Helme" className="d-inline-block">
                                                <img src="assets/images/users/avatar-6.jpg" className="rounded-circle avatar-xs" alt="friend"/>
                                            </a>
    
                                            <a href="javascript:void(0);" data-bs-container="#tooltip-container" data-bs-toggle="tooltip" data-bs-placement="top" title="Michael Zenaty" className="d-inline-block">
                                                <img src="assets/images/users/avatar-7.jpg" className="rounded-circle avatar-xs" alt="friend"/>
                                            </a>
    
                                            <a href="javascript:void(0);" data-bs-container="#tooltip-container" data-bs-toggle="tooltip" data-bs-placement="top" title="James Anderson" className="d-inline-block">
                                                <img src="assets/images/users/avatar-8.jpg" className="rounded-circle avatar-xs" alt="friend"/>
                                            </a>

                                            <a href="javascript:void(0);" data-bs-container="#tooltip-container" data-bs-toggle="tooltip" data-bs-placement="top" title="Lorene Block" className="d-inline-block">
                                                <img src="assets/images/users/avatar-4.jpg" className="rounded-circle avatar-xs" alt="friend"/>
                                            </a>
    
                                            <a href="javascript:void(0);" data-bs-container="#tooltip-container" data-bs-toggle="tooltip" data-bs-placement="top" title="Mike Baker" className="d-inline-block">
                                                <img src="assets/images/users/avatar-5.jpg" className="rounded-circle avatar-xs" alt="friend"/>
                                            </a>
                                        </div>

                                    </div>

                                </div> 

                                <div className="col-xl-6">
                                    <div className="mb-3 mt-3 mt-xl-0">
                                        <label htmlFor="projectname" className="mb-0">Avatar</label>
                                        <p className="text-muted font-14">Recommended thumbnail size 800x400 (px).</p>

                                        <form action="/" method="post" className="dropzone" id="myAwesomeDropzone" data-plugin="dropzone" data-previews-container="#file-previews" data-upload-preview-template="#uploadPreviewTemplate">
                                            <div className="fallback">
                                                <input name="file" type="file"/>
                                            </div>

                                            <div className="dz-message needsclick">
                                                <i className="h3 text-muted dripicons-cloud-upload"></i>
                                                <h4>Drop files here or click to upload.</h4>
                                            </div>
                                        </form>

                                        
                                        <div className="dropzone-previews mt-3" id="file-previews"></div>

                                        
                                        <div className="d-none" id="uploadPreviewTemplate">
                                            <div className="card mt-1 mb-0 shadow-none border">
                                                <div className="p-2">
                                                    <div className="row align-items-center">
                                                        <div className="col-auto">
                                                            <img data-dz-thumbnail="" src="#" className="avatar-sm rounded bg-light" alt=""/>
                                                        </div>
                                                        <div className="col ps-0">
                                                            <a href="javascript:void(0);" className="text-muted fw-bold" data-dz-name=""></a>
                                                            <p className="mb-0" data-dz-size=""></p>
                                                        </div>
                                                        <div className="col-auto">
                                                            
                                                            <a href="" className="btn btn-link btn-lg text-muted" data-dz-remove="">
                                                                <i className="dripicons-cross"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>

                                    
                                    <div className="mb-3 position-relative" id="datepicker2">
                                        <label className="form-label">Due Date</label>
                                        <input type="text" className="form-control" data-provide="datepicker" data-date-container="#datepicker2" data-date-format="d-M-yyyy" data-date-autoclose="true"/>
                                    </div>
                                </div> 
                            </div>
                            

                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </DashboardWrapper>
}


export default SavingsSettings