import React from "react";

interface  ComponentProps {
    img : string
}

const ImagePreviewContainer:React.FC<ComponentProps> = ({img}) => {
    return <>
    <div className="dropzone-previews mt-3" id="file-previews"></div>
    <div
        id="uploadPreviewTemplate">
        <div className="card mt-1 mb-0 shadow-none border">
            <div className="p-2">
                <div className="row align-items-center">
                    <div className="col-auto">
                        <a href={img} target={'_blank'}>
                        <img data-dz-thumbnail="" src={img}
                             className="avatar-sm rounded bg-light" alt=""/>
                        </a>
                    </div>
                    <div className="col ps-0">
                        <a href={img} target={'_blank'} className="text-muted fw-bold" data-dz-name=""> </a>
                        <p className="mb-0" data-dz-size=""></p>
                    </div>
                    <div className="col-auto">
                        <a href={img} target={'_blank'} className="btn btn-link btn-lg text-muted" data-dz-remove="">
                            <i className="dripicons-cross"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    </>
}


export  default  ImagePreviewContainer