import {Spinner} from "../../../components";
import {SuccessAlert} from "../../../components/alert";
import {useForm} from "react-hook-form";
import React, {useEffect} from "react";
import {calculateTime, postRequest} from "../../../config/redux/actions";
import {DEFAULT_INACTIVE_TIME} from "../../../config/config";
import {remote_url} from "../../../config/urls/remote";
import {types} from "../../../config/redux/types";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../config/redux/reducers";
import {AuthState} from "../../../config/redux/reducers/tmsAuthReducer";
import {LoansReducerState} from "../../../config/redux/reducers/tmsLoansReducer";
import {Link} from "react-router-dom";
import {ViewSavings} from "../savings/_components";
import {GeneralFormInput} from "../../../components/form";
import {CustomModalWrapper} from "../_components";

type FormValues ={
    name : string,
    percent_interest : string,
    duration : string,
    minimum_savings_balance : string,
    membership_period : string,
    multiples_of_savings : number
}
type LoanFormProps = {
    onClickFunc? : any,
    screen ? : string
}

const LoanTypeForm:React.FC<LoanFormProps> = ({onClickFunc,screen='add'}) => {
    const dispatch = useDispatch()
    const { register, handleSubmit, formState: { errors },watch,setError } = useForm<FormValues>()
    const {
        token,
        account_key,
        user,
        role_id
    } = useSelector<AppState, AuthState>((state) => state.tmsAuthReducer)
    const {loading_,success_msg} = useSelector<AppState, LoansReducerState>((state) => state.tmsLoansReducer)

    const temp_percentage = watch('percent_interest')
    //

    //
    const onSubmit = (data:FormValues) => {
        if(Number(data.percent_interest)){
            const postData = {
                token,
                accountKey:account_key,
                url:remote_url.createLoanTypes,
                actionType: types.CREATE_LOANS_TYPES,
                postData : data,
                secretData : {
                    email:user.email,
                    mac_id :user.mac_id,
                    r_id: role_id
                },
                errorAction : types.SET_ERROR,
                loading :{
                    isLoading :false,
                    isLoading_ : true,
                    isLoading_t : false
                }
            }
            dispatch(postRequest(postData))
        }
        else{
            setError('percent_interest',{message:'invalid percentage interest value entered'})
        }

    }

    const clearData = () => {
            onClickFunc(false)

    }

    if(success_msg&& screen==='add'){
      clearData()
    }

    return <>
        <CustomModalWrapper onClickFunc={onClickFunc} modalTitle={'New Loan Type'}>
            <div className="offcanvas-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/*  */}
                    <div className="mb-3">
                        <label htmlFor="projectname" className="form-label">Loan Type </label>
                        <input
                            type="text"
                            placeholder="e.g emergency loan"
                            className={`form-control ${errors.name && 'is-invalid'}`}
                            {...register('name',{required: '*loan category name is required'})}
                        />

                        {errors.name  && (<div className="invalid-feedback">{errors.name.message}</div>)}
                    </div>
                    {/*Loan Interest  */}
                    <div className="mb-3">
                        <label htmlFor="projectname" className="form-label">Loan Interest(%)</label>
                        <input
                            type="text"
                            size={3}
                            placeholder="e.g: 0.05 or 20"
                            className={`form-control ${errors.percent_interest && 'is-invalid'}`}
                            {...register('percent_interest',{
                                required: '*loan percentage interest is Required',
                                pattern: {
                                    value: /^[0-9]+(\.[0-9]{1,2})?$/,
                                    message: 'Invalid percentage interest entered'
                                }
                            }
                            )}
                        />
                        <small className={'helper-text'}>{temp_percentage} % </small>
                        {errors.percent_interest  && (<div className="invalid-feedback">{errors.percent_interest.message}</div>)}
                    </div>
                    {/* duration */}
                    <div className="mb-3">
                        <label htmlFor="projectname" className="form-label">Repayment Period(month)</label>
                        <input
                            type="number"
                            placeholder="e.g 3"
                            className={`form-control ${errors.duration && 'is-invalid'}`}
                            {...register('duration',{
                                required: '*loan repayment period is required',
                                min:{value:1,message:'You have exceed the minimum month range'},
                                pattern: {
                                    value: /^[0-9]+(\.[0-9]{1,2})?$/,
                                    message: 'Invalid duration entered'
                                }
                            })}
                        />
                        {errors.duration  && (<div className="invalid-feedback">{errors.duration.message}</div>)}
                    </div>


                    {/*  minimum_savings_balance*/}
                    <div className="mb-3">
                        <label htmlFor="projectname" className="form-label">Minimum Savings Balance</label>
                        <input
                            type="number"
                            placeholder="Enter amount"
                            className={`form-control ${errors.minimum_savings_balance && 'is-invalid'}`}
                            {...register('minimum_savings_balance',{
                                required: '*minimum savings balance is required',
                                min:{value:0,message:'negative value  is not allowed/value less than zero '},
                                // minLength: { value: 6, message: 'Your Password is too Short' },
                            })}
                        />
                        {errors.minimum_savings_balance  && (<div className="invalid-feedback">{errors.minimum_savings_balance.message}</div>)}
                    </div>


                    {/*multiples_of_savings*/}
                    <div className="mb-3">
                        <label htmlFor="projectname" className="form-label">Multiple of Savings a User can request For</label>
                        <input
                            type="number"
                            placeholder="e.g 3"
                            className={`form-control ${errors.multiples_of_savings && 'is-invalid'}`}
                            {...register('multiples_of_savings',{
                                required: '*loan repayment period is required',
                                // max:{value:12,message:''},
                                min:{value:1,message:'Invalid multiples of savings entered'},
                                pattern: {
                                    value: /^[0-9]+(\.[0-9]{1,2})?$/,
                                    message: 'Invalid multiples of savings entered'
                                }
                            })}
                        />
                            {errors.multiples_of_savings  && (<div className="invalid-feedback">{errors.multiples_of_savings.message}</div>)}
                    </div>
                    {/* membership_period */}
                    <div className="mb-3">
                        <label htmlFor="projectname" className="form-label">Membership Period(months)</label>
                        <input
                            type="text"
                            placeholder="how many month(s)  of membership before a user can apply for loan"
                            className={`form-control ${errors.membership_period && 'is-invalid'}`}
                            {...register('membership_period',{
                                required: '*membership  is required',
                                pattern: {
                                    value: /^[0-9]+(\.[0-9]{1,2})?$/,
                                    message: 'Invalid membership period entered'
                                }
                            })}
                        />
                        {errors.membership_period  && (<div className="invalid-feedback">{errors.membership_period.message}</div>)}
                    </div>

                    {/**/}
                    <div className="mb-3">
                        {loading_ ?
                            <Spinner color='primary'/> :
                            <button className='btn btn-primary' type='submit'>Add new Loans Setting</button>
                        }
                    </div>

                </form>
            </div>

            {loading_ && <div className="modal-backdrop fade show"></div>}
        </CustomModalWrapper>
    </>
}

export default  LoanTypeForm