import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Spinner } from "../../../../components"
import { ErrorAlert, SuccessAlert } from "../../../../components/alert"
import { TestResponse, UsersModel } from "../../../../config/model/authModel"
import { postRequest, validateLoanBreakDown } from "../../../../config/redux/actions"
import { AppState } from "../../../../config/redux/reducers"
import { AuthState } from "../../../../config/redux/reducers/tmsAuthReducer"
import { LoansReducerState } from "../../../../config/redux/reducers/tmsLoansReducer"
import { types } from "../../../../config/redux/types"
import { appTxt } from "../../../../config/strings"
import { remote_url } from "../../../../config/urls/remote"
import {MemebersState} from "../../../../config/redux/reducers/tmsMemeberReducerState";
import {CustomModalWrapper} from "../../_components";


type LoanFormProps = {
    show : boolean,
    backDrop? : string,
    userD  ?: UsersModel,
    handleFormModal : any
}

type FormValues = {
    amount : number,
    loan_type : string,
    user_id : number,
    isAdmin ? : boolean,
    //
    route : string,
    repayment_module : string,
    amount_in_string : string,
    expected_amount : number,
    duration : number
}

const LoanApplicationForm:React.FC<LoanFormProps> = ({handleFormModal}) => {
    //
     const [loanCat, setLoanCat] = useState<number>(0)
     const [repaymentModule , setRepaymentModule] = useState<boolean>(false)
     const [msg , setMsg] = useState<string>('')
     const { register, handleSubmit, watch,formState: { errors },reset } = useForm<FormValues>()
     //
     const {token,account_key,user,role_id} = useSelector<AppState, AuthState>((state) => state.tmsAuthReducer)
     const {loan_types,isLoading_t,error_msg,success_msg} = useSelector<AppState,LoansReducerState>((state)=> state.tmsLoansReducer)
    const {userDetails} = useSelector<AppState,MemebersState>((state) => state.tmsMemeberReducerState)
     const dispatch = useDispatch()
     //
     const amountInputed = watch('amount') 
    //
     useEffect(() => {
        getLoanTypes()
     },[])

    //get LoanTypes 
    const getLoanTypes = () => {
        const postData = {
            token,
            accountKey:account_key,
            url:remote_url.getLoanTypes,
            actionType: types.GET_LOANS_TYPES,
            postData : [],
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            errorAction : types.SET_ERROR,
            loading :{
                isLoading :false,
                isLoading_ : false,
                isLoading_t : true
            }
    }
    dispatch(postRequest(postData)) 
    }
    //getting the % interest from the selected loan type
    const setSelectedType = (id:string) => {
        const item = loan_types.filter((item) => item.id === Number(id))[0]?.percent_interest


        // console.log(id))
        // console.log(loan_types)
        setLoanCat(item)
    }
    //clear data 
    const clearData = () => {
        //
    }

    const updateRepaymentModel = (val:string) => {
        //
        if(val==='auto'){
            setRepaymentModule(false)
        }else{
            setRepaymentModule(true)
        }
    }
   
    //
    const submitLoanApplication = (data:FormValues) => {
        setMsg('')
        let check:any;
        
        const item = loan_types.filter((item) => item.id === Number(data.loan_type))[0]
        if(data.repayment_module==='auto'){
            check = validateLoanBreakDown('auto',data.amount,'',item.percent_interest,item.duration)
        }else{
           check = validateLoanBreakDown('manual',data.amount,data.amount_in_string,item.percent_interest,item.duration)
        }

        if(check?.status){
            setMsg('')

            data.isAdmin = role_id === 1;
            //manual , auto
            data.user_id = userDetails.user.id
            data.amount_in_string = check.msg
            data.expected_amount = data.repayment_module==='auto' ? item.duration * check.msg : ((Number(item.percent_interest)* Number(data.amount))/100) + Number(data.amount)
            data.route = 'web'
            data.duration = check.duration
            const postData = {
               token,
               url: remote_url.submitLoanApplication,
               actionType : types.CREATE_LOAN_APPLICATION,
               postData : data,
                secretData : {
                    email:user.email,
                    mac_id :user.mac_id,
                    r_id: role_id
                },
                errorAction : types.SET_ERROR,
                accountKey: account_key,
                loading :{
                    loading: false,
                    loading_ :false,
                    isLoading_t : true
                }
                // reset
            }
            //
            dispatch(postRequest(postData))
            if(success_msg){
                reset()
            }
        }else{
            setMsg(check.msg)
        }
    }

    const handleCloseModal = ()=> {
        handleFormModal(false)
    }


    //
       return <>
            <CustomModalWrapper onClickFunc={handleCloseModal} modalTitle={`Loan Application Form | ${userDetails?.user?.name}`}>
                    {/* data-bs-backdrop="true" */}


                <div className="card-body">
                    {/* // singleSavings  */}
                    {/* // <ViewSavings  saving={singleSavings} /> */}
                    
                    <form  onSubmit={handleSubmit(submitLoanApplication)}>
                        {error_msg && <ErrorAlert msg={error_msg}/> }
                        {msg && <ErrorAlert msg={msg}/> }
                        {success_msg && <SuccessAlert msg={success_msg}/>}
                        {/*  */}
                        <div>
                            {/* loan type */}
                            <div className="mb-3">
                                <label htmlFor="simpleinput" className="form-label">Loan  Type </label>
                                <select
                                 className={`form-select ${errors.loan_type ? 'is-invalid':''}`}
                                // defaultValue={0}
                                {...register('loan_type',{
                                            required: '*loan type is required',
                                    })}
                                    onChange = {(e)=>setSelectedType(e.target.value) }
                                >
                                    <option value=''>Select Loan Type</option>
                                    {
                                        loan_types?.length>0 ? loan_types.map((item) => 
                                            <option value={item.id} key={item.id} >
                                                {item.name} | {item.percent_interest}
                                            </option> 
                                        )
                                        : 'no loans setting please to proceed'
                                    }
                                </select>
                                 {errors.loan_type  && (<div className="invalid-feedback">{errors.loan_type.message}</div>)}
                            </div>
                            {/* repayment module */}
                            <div className="mb-3">
                                <label htmlFor="simpleinput" className="form-label"> Repayment Module </label>
                                <select
                                    className={`form-select ${errors.repayment_module && 'is-invalid'}`} 
                                    {...register('repayment_module',{required: '*repayment module is required',})}
                                    defaultValue={'auto'}
                                    onChange={(e) => updateRepaymentModel(e.target.value)}
                                >
                                    <option value='manual'>Let me  decide</option>
                                    <option value='auto'>Decide for me</option>
                                </select>
                                 {errors.repayment_module  && (<div className="invalid-feedback">{errors.repayment_module.message}</div>)}
                            </div>
                            {/* amount */}
                            <div className="mb-3">
                                <label htmlFor="simpleinput" className="form-label">Amount</label>
                                <input type="text" 
                                     className={`form-control ${errors.amount && 'is-invalid'}`} 
                                    {...register('amount',{required: 'amount is Required'})}/>
                                {errors.amount  && (<div className="invalid-feedback">{errors.amount.message}</div>)}

                                <span className={'mt-2 text-sm'}>interest rate:  {loanCat}%  of {Number(amountInputed).toLocaleString()} = {Number(loanCat/100) * Number(amountInputed)}</span> <br/>
                                <span>amount to borrow: {appTxt.naira_} {Number(amountInputed).toLocaleString()}</span>
                                <br/>
                                <span>estimated amount to return:&nbsp;&nbsp;
                                    {appTxt.naira_} {(Number(amountInputed) + (Number(loanCat/100) * Number(amountInputed))).toLocaleString()}
                                </span>
                            </div>



                            {/*  */}
                            {repaymentModule && 
                                <div className="mb-3">
                                    <label htmlFor="simpleinput" className="form-label">Repayment Breakdown</label>
                                    <input type="text" 
                                    placeholder="eg. 2000,5000,6000,7000"
                                        className={`form-control ${errors.amount && 'is-invalid'}`} 
                                        {...register('amount_in_string',{required: 'amount is Required'})}/>
                                    {errors.amount_in_string  && (<div className="invalid-feedback">{errors.amount_in_string.message}</div>)}
                                </div>
                            }
                         
                    
                            

                            {
                                isLoading_t
                                ? 
                                <Spinner color='primary'/> 
                                :
                                <>
                                <button type="submit"  className="btn btn-primary mr-2">
                                    Submit Loan Application
                                </button>
                                <span> </span>
                                <span className="btn btn-outline-dark" onClick={()=>reset()}>Clear form</span>
                                
                                </>
                            }
                        </div>
                        
                    </form>
                    {/* //  display expected loans returns  */}
                {isLoading_t && <div className="modal-backdrop fade show"></div>}
                </div>

            </CustomModalWrapper>
            </>
}
export default LoanApplicationForm