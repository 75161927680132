
import {ActionProps} from "../actions";
import {types} from "../types";
import {
    SupportMessagesModel,
    SupportTicketConversationModel,
    SupportTicketConversationProp
} from "../../model/authModel";

export interface SupportTicketReducerState {
    loading: boolean,
    loading_: boolean,
    loading_t: boolean,
    success_msg: string,
    error_msg: string,
    filter: {
        fromDate: string,
        toDate: string,
        status: string
    },
    supportsConversations: SupportTicketConversationModel[],
    supportsConversation: SupportTicketConversationModel,
    supportMessages : SupportMessagesModel[],
    isNew : boolean
}

//
const initialState = {
    loading: false,
    loading_: false,
    loading_t: false,
    success_msg: '',
    error_msg: '',
    filter: {
        fromDate: '',
        toDate: '',
        status: '1'
    },
    supportsConversations : [],
    supportsConversation :  SupportTicketConversationProp,
    supportMessages : [],
    isNew : false

}


const tmsSupportTicketReducer = (state: SupportTicketReducerState = initialState, action: ActionProps) => {
    //
    switch (action.type) {
        case types.LOG_OUT:
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t : false,
                success_msg: '',
                error_msg: '',
                filter: {
                    fromDate: '',
                    toDate: '',
                    status: '1'
                },
                supportsConversations : [],
                supportsConversation :  SupportTicketConversationProp,
                supportMessages : [],
                isNew : false,
            }

        case types.ADD_SUPPORT_TICKET_MESSAGE:
            return {
                ...state,
                supportMessages: action.payload?.data,
                isNew:  false
            }

        case types.UPDATE_MSG_TEMP :
            // this will add new message to the chat list while we try to submit to the backend
            return{
                ...state,
                supportMessages: action.payload,
                isNew:  false
            }

        case types.GET_SUPPORT_TICKET_MESSAGE:
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                error_msg: '',
                success_msg:  '',
                supportMessages: action.payload?.data,
                isNew:  false
            }

        case types.SET_CONVERSATION_PAGE:
            return{
                ...state,
                supportsConversation : action.payload,
                isNew : true
            }

        case types.CREATE_SUPPORT_TICKET_CONVERSATION:
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                error_msg: '',
                success_msg:  '',
                supportsConversations : action.payload?.data,
                isNew:  false
            }

        case types.GET_SUPPORT_TICKET_CONVERSATION:
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                error_msg: '',
                success_msg:  '',
                supportsConversations : action.payload?.data,
                isNew:  false
            }

        case types.CLEAR_MSG:
            return {
                ...state,
                success_msg: '',
                error_msg: '',
                isNew:  false
            }
        case types.SET_LOADING:
            return {
                //isLoading_
                ...state,
                loading: action.loading?.isLoading,
                loading_: action.loading?.isLoading_,
                loading_t: action.loading?.isLoading_t,
                success_msg: '',
                error_msg: ''
            }

        // case types.SET_EXPENSE_FILTER:
        //     return {
        //         ...state,
        //         filter: {
        //             fromDate: action.payload?.fromDate,
        //             toDate: action.payload?.toDate,
        //             status: action.payload?.status
        //         }
        //     }
        //


        case types.SET_ERROR:
            return {
                ...state,
                loading: false,
                loading_: false,
                success_msg: '',
                error_msg: action.payload
            }



        default:
            return state;
    }

}

export default tmsSupportTicketReducer