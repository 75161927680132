/**
 * mac_id ,
 * email
 * r_id
 */


import axios from 'axios'
import {Dispatch} from "redux"
import {
	APP_VERSION, appConfig,
	errorResponseCheck,
	generateValidRoleIdKey,
	hmac_512,
	hmac_512_non_token,
	MsgProps,
	validateAppVersionResponse
} from '../config'
import {
	AccountTypeModel,
	AllThriftSavingsModel, AllUserRoles,
	AuditLogModel,
	BussinessRegTypesModel,
	CountryMOdel, LoanAppSummaryModel, PaymentChannel,
	savingDashboardModel,
	SubscriptionPlanModel,
	UsersModel
} from '../model/authModel'
import {types} from './types'
import LoanAppSummaryCard from "../../pages/admin/loans/components/LoanAppSummaryCard";

interface LoadingType {
		isLoading : boolean,
		isLoading_: boolean,
		isLoading_t : boolean,
		searchLoading ? : boolean
}

interface RequestProps {
	token:string, 
	url:string, 
	actionType:types,
	errorAction:types,
	secretData :MsgProps,
	postData ?: object,
	// reset : any,
	accountKey : string,
	loading : any
	returnData?: boolean
}



export type GeneralResponse  = {
	message : string
}

//
type LoginResponse = {
	message : string,
	data : {
		isLinkAccount ?: boolean,
		subscription_plans : SubscriptionPlanModel[]
		user : object, //user object that handles login payload
		token :string,
		accounts : string[],
		countries : CountryMOdel[],
		business_registration_types : BussinessRegTypesModel[],
		documents_types : BussinessRegTypesModel[],
		// has_loan_settings : number,
		singleSavings: object,
		savings : AllThriftSavingsModel[] // 
		audits : AuditLogModel[],
		users : UsersModel[] //users that handle auditLog user listing
		data : [] // case of getting transactions
		savingsSummary : object // savings Summary
		savingsDashboard: savingDashboardModel[] // savings Dashboard  //SavingDashboardAnalytic
		summary : [],
		loanApps : [],
		loanApp : object,
		thrift_account : [],
		account_doucments : [],
		payment_channels : PaymentChannel[],
		gateways : PaymentChannel[],
		subscription_transaction : object,
		all_roles : AllUserRoles[],
		user_membership_status : AllUserRoles[],
	},
	expense : object,
	expense_total : [],
	income_total : [],
	account_branch_key : string ,
	role_id : string,
	role_text:string,
	has_account_number : boolean,
	thrift_account: AccountTypeModel,
	fromDate:string,
	toDate:string,
	status:number,
	left_sidebar_default: boolean,
	left_sidebar_light: boolean,
	left_sidebar_dark: boolean,
	left_sidebar_fixed : boolean,
	left_sidebar_condensed : boolean,
	left_sidebar_scrollable : boolean,
	adminMode : boolean,
	//
	data_layout_mode_boxed : boolean,
	data_layout_mode_fluid : boolean,
	//
	darkMode : boolean,
	lightMode : boolean,
	loanAppSummary : LoanAppSummaryModel[]
	transaction_ref : string //used on saving verification page
	trans_id : string //used on saving verification page
}


// type ResponseData = LoginResponse 


// const data = message?.status?.data;
export interface ActionProps {
	type : types,
	payload?:  LoginResponse,

	loading ? : LoadingType,
	isAppOutdatedAction ? : {
		isAppOutdated : boolean,
		action : string
	}
}




export const setLoading = (data:any) => (dispatch:Dispatch<ActionProps>) => {
		dispatch({
		type: types.SET_LOADING,
		loading:{
			isLoading : data.isLoading,
			isLoading_ : data.isLoading_,
			isLoading_t : data.isLoading_t
		}
	})
}
//::::::
export const setGeneralAction = (actionType:types,data:any) => (dispatch: Dispatch<ActionProps>) => {
	dispatch({
		type: actionType, 
		payload: data,
	})
}






//normal request
export const getRequest = ({token, url, actionType,secretData,errorAction,accountKey,loading}:RequestProps) => async (dispatch: Dispatch<ActionProps>) => {
	const key = token  ? hmac_512(secretData, token) : hmac_512_non_token()
	const options = {
		headers: {
			'Content-type': 'application/json',
			CoopAgent: key,
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
			TmsAccType : `${accountKey}`,
			IdValidate : generateValidRoleIdKey(token,secretData.r_id,secretData.mac_id,accountKey),
			AppVersion : APP_VERSION
		},
	}
	//
	dispatch({
		type: types.SET_LOADING,
		loading:{
			isLoading : loading.isLoading,
			isLoading_ : loading.isLoading_,
			isLoading_t : loading.isLoading_t,
			searchLoading : loading?.searchLoading ?? false
		}
	})
	//
	try {
		const res = await axios.get(`${url}`, options)
		if (res.data.status === 'success') {
			validateAppVersionResponse(res.headers,dispatch)
			dispatch({
				type: actionType, 
				payload: res.data,
			})
		}
	}
	catch (error:any) {
		errorResponseCheck(error,dispatch)
		console.log(error)
		dispatch({
			type: errorAction,
			payload:
				error.response === undefined
					? 'Network Error'
					: error.response.data.errors === undefined
					? error.response.data.message
					: error.response.data.errors,
		})
	}
}
//token:string, url:string, actionType:string, postData:object, secretData:object, reset:object
export const postRequest = ({token, url, actionType, postData, secretData,errorAction,accountKey,loading,returnData}:RequestProps) => async (dispatch:Dispatch<ActionProps>) => {
		//
		const key = token  ? hmac_512(secretData, token) : hmac_512_non_token()
		//
		const options = {
			headers: {
				'Content-type': 'application/json',
				CoopAgent: key,
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			    TmsAccType :`${accountKey}` ,
				IdValidate : generateValidRoleIdKey(token,secretData.r_id,secretData.mac_id,accountKey),
				AppVersion : APP_VERSION
			},
		}
		//
		dispatch({
			type: types.SET_LOADING,
			loading:{
				isLoading : loading.isLoading,
				isLoading_ : loading.isLoading_,
				isLoading_t : loading.isLoading_t,
				searchLoading : loading?.searchLoading ?? false
			}
		})
		//
		try {
			const res = await axios.post(url, postData, options)

			if (res.status >= 200 && res.status <= 299) {
				validateAppVersionResponse(res.headers,dispatch)
				dispatch({
					type: types.SET_LOADING,
					loading:{
						isLoading : false,
						isLoading_ : false,
						isLoading_t : false,
						searchLoading : false
					}
				})
				dispatch({
					type: actionType,
					payload: res.data,
				})
			}

		}
		catch (error:any) {
			errorResponseCheck(error,dispatch)
			dispatch({
				type:errorAction,
				payload:
					error.response?.data === undefined
						? 'Network Error'
						: error.response.data.errors === undefined
						? error.response.data.message
						: error.response.data.errors,
			})
		}
}
//
export const postRequestFile = ({token, url, actionType, postData, secretData,errorAction,accountKey,loading}:RequestProps) => async (dispatch:Dispatch<ActionProps>) => {
	
		const key = token  ? hmac_512(secretData, token) : hmac_512_non_token()
		
		const options = {
			headers: {
				'Content-type': 'multipart/form-data',
				CoopAgent: key,
				Authorization: `Bearer ${token}`,
			    TmsAccType : accountKey,
				IdValidate: generateValidRoleIdKey(token,secretData.r_id,secretData.mac_id,accountKey),
				AppVersion : APP_VERSION
			},
		}
		//
		dispatch({
			type: types.SET_LOADING,
			loading:{
				isLoading : loading.isLoading,
				isLoading_ : loading.isLoading_,
				isLoading_t : loading.isLoading_t,
				searchLoading : loading?.searchLoading ?? false
			}
		})
		//
		try {
			const res = await axios.post(url, postData, options)
			if (res.status >= 200 && res.status <= 299) {
				validateAppVersionResponse(res.headers,dispatch)
				// reset && reset()
				dispatch({
					type: actionType,
					payload: res.data,
				})
			}
		}
		//
		catch (error:any) {
			errorResponseCheck(error,dispatch)
			dispatch({
				type:errorAction,
				payload:
					error.response.data === undefined
						? 'Network Error'
						: error.response.data.errors === undefined
						? error.response.data.message
						: error.response.data.errors,
			})
		}
}
















export const validateLoanBreakDown= (category:string,requestedAmount:number,amountBreakDown:string,interest:number,duration:number) => {
	if(category==='manual'){
		return validateMannualAmount(requestedAmount,interest,amountBreakDown)
	}else{
		return computeLoanBreakDown(requestedAmount,interest,duration)
	}

}




const  validateMannualAmount = (amountBorrowed:number,interest:number,amountInString:string) => {
	let expectedRefund = Number(amountBorrowed) + (( Number(amountBorrowed) * Number(interest))/100) 



    let tempSum = 0
	let duration = 0

    String(amountInString).split(',').map((str) => {		
		tempSum += Number(str)
		duration +=1
		return ''
	});
	


    if(tempSum>expectedRefund){
        let greaterWith = Number(tempSum) - Number(expectedRefund);
        return {
		  msg:	'amount split is great than expected return with ' + greaterWith,
		  status : false,
		  duration :duration
		}
    }
    //
    else if(tempSum===expectedRefund){
       return {
		   msg :amountInString,
		   status  : true ,
		   duration :duration
	   }
    }
    //
    else{
        let lessWith = Number(expectedRefund) - Number(tempSum)  ;
        return {
			status:false,
			msg :'repayment breakdown is less than expected return with ' + lessWith,
			duration :duration
		} 
		
    }

}



const computeLoanBreakDown = (amount:number,interest:number, duration:number) => {

	 let  letAmountWithInterest = Number(amount) + ((Number(amount) * Number(interest))/100 )


    let number = Math.round(letAmountWithInterest/duration)

    let x=0;
    const arr = String(number)
      .split('')
      .map(str => Number(str));
    


     let  lastItem = arr[arr.length-1]

     let secondTotheLastitem = arr[arr.length-2]
     
    
     if(lastItem<=4){
        x += 0;
     }else{
        x+=5;
        arr[arr.length-2] = secondTotheLastitem + 1
     }
    
     arr[arr.length-1]= x;
	
	 return {
		   msg :arr.join(''),
		   status  : true ,
		   duration:duration
	   }

}


export const makeRequest = async ({token, url, postData, secretData,accountKey}:RequestProps) => {
		const key = token  ? hmac_512(secretData, token) : hmac_512_non_token()
		const options = {
			headers: {
				'Content-type': 'application/json',
				CoopAgent: key,
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			    TmsAccType :`${accountKey}` 
			},
		}
		//
		//
		try {
			const res = await axios.post(url, postData, options)
			if (res.status ===200) {
				
				return res.data
			
			}
		} catch (error:any) {
		
			// return response
		}
}


export const calculateTime = (loginTime:number) => {
	let t1 = new Date();
	let dif = t1.getTime()- loginTime;
	let sec = Math.round(dif/1000)
	return Math.round(sec / 60)
}
/*
* Converts a number to Nigerian currency format.
* @param amount - The number to be converted.
* @returns The number formatted as Nigerian currency.
*/
export function toNigerianCurrency(amount: number): string {
	return amount.toLocaleString("en-NG", {
		style: "currency",
		currency: "NGN",
		maximumFractionDigits: 2,
		minimumFractionDigits: 2,
	});
}

export const formatStatusText = (status:any) => {
	let text = 'Pending'
	let  color;
	switch (Number(status)) {
		case 0:
			text ='pending'
			color ='warning'
			break
		case 1:
			text ='paid';
			color ='success'
			break
		case 2:
			text = "deleted"
			color ='danger'
			break
		case 4 :
			text ='expired'
			color ='danger'
			break
		case 3 :
			text ='amount mismatch'
			color ='info'
			break
	}
	return {text,color}
}

export const copyYourLink = (url:any,dispatch:any,text:string) => {
	navigator.clipboard.writeText(url)
	dispatch(setGeneralAction(types.SET_SUCCESS_MSG,text))
}


export const formatCash = (n:number) => {
	if (n < 1e3) return n;
	if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
	if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
	if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
	if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";

};


