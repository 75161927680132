import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../config/redux/reducers";
import {AuthState} from "../../../config/redux/reducers/tmsAuthReducer";
import {setGeneralAction} from "../../../config/redux/actions";
import {types} from "../../../config/redux/types";
import React, {useEffect, useState} from "react";
import {PushNotificationOld, PushNotificationOne} from "./index";

export interface PushNotificationProps{
    clearPushNotification : any,
    pushNotification:any,
    modalStateText?:any,
}

const PushNotification = () => {

    const [modalStateText , setModalStateText] = useState('show')
    const {pushNotification} = useSelector<AppState, AuthState>((state) => state.tmsAuthReducer)
    const dispatch = useDispatch()

    const clearPushNotification = () => {
        dispatch(setGeneralAction(types.CLEAR_PUSH_NOTIFICATION, []))
    }

    const clearNotificationTwo = () => {
        setModalStateText('')
        dispatch(setGeneralAction(types.CLEAR_PUSH_NOTIFICATION, []))
    }

    useEffect(() => {
        // setTimeout(() => clearPushNotification(),7000)
    }, [])

    return <>
        {/*
         Logic to determine
         */}
        {/*<PushNotificationOld clearPushNotification={clearPushNotification} pushNotification={pushNotification}/>*/}
        <PushNotificationOne clearPushNotification={clearNotificationTwo} pushNotification={pushNotification} modalStateText={modalStateText}/>

    </>
}

export default PushNotification