import {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {calculateTime, postRequest, setGeneralAction} from "../../../config/redux/actions"
import {useForm} from 'react-hook-form';

import {AppState} from "../../../config/redux/reducers"
import {AuthState} from "../../../config/redux/reducers/tmsAuthReducer"
import {MemebersState} from "../../../config/redux/reducers/tmsMemeberReducerState"
import {types} from "../../../config/redux/types"
import {remote_url} from "../../../config/urls/remote"


import DashboardWrapper from "../DashboardWrapper"
import BreadCrumb from "../layouts/BreadCrumb"
import {CustomModalWrapper, ExpandCollapse, Loader, NumberCardSummary} from "../_components"
import {Link, useNavigate} from "react-router-dom";
import AddNewMember from "./AddNewMember";

import {SavingsForm} from "../savings/form";
import {DEFAULT_INACTIVE_TIME} from "../../../config/config";
import {membersInitDatatable} from "../../../config/datatableConfig";
import {MembersProps, UsersProps} from "../../../config/model/authModel";
import {LoanApplicationForm} from "../loans/components";
import {ErrorAlert, SuccessAlert} from "../../../components/alert";
import UserBulkImport from "./UserBulkImport";
import {TableWrapper} from "../../../_versionTwo/pages/_component";
import {EditUserForm} from "./index";



type FormValues = {
    branch: string,
    role: string,
    status: string,
}


const UserDashboard = () => {
    //
    const buttonTexts = ['Add Member', 'Import Data']
    const navigate = useNavigate()
    const {register, handleSubmit} = useForm<FormValues>()
    const [showSavingsForm, setShowSavingsForm] = useState(false)

    const [showBulkUserForm, setBulkUserForm] = useState(false)
    const [showEditUserForm, setEditUserForm] = useState(false)

    const [showLoanForm, setShowLoanForm] = useState(false)
    const [showAddNewMemberForm, setShowAddNewMemberForm] = useState(false)
    //
    const dispatch = useDispatch()
    const {
        users,
        memberFilter,
        newDataBot,
        summary,
        loading_t,
        success_msg,
        error_msg
    } = useSelector<AppState, MemebersState>((state) => state.tmsMemeberReducerState)
    const {
        token,
        account_key,
        loginTime,
        user,
        role_id

    } = useSelector<AppState, AuthState>((state) => state.tmsAuthReducer)


 const handleUserForm = ()=> {
        setShowSavingsForm(false)
 }

    const setUserDetails = (x: any, id: any) => {
        dispatch(setGeneralAction(types.GET_USER, x))
        navigate(`/user/${id}`, {replace: true});
    }
    //
    const saveNow = (x: any, id: any) => {
        setShowSavingsForm(true)
        dispatch(setGeneralAction(types.CLEAR_SAVING_DATA,[]))
        dispatch(setGeneralAction(types.GET_USER, x))
    }
    //
    const applyForLoan = (x:any,id:any) => {
        setShowLoanForm(true)
        dispatch(setGeneralAction(types.GET_USER, x))
    }

    const editUserDetails = (x: any, id: any) => {
        setEditUserForm(true)
        dispatch(setGeneralAction(types.GET_USER, x))
    }
    //
    useEffect(() => {
        let time_diff = calculateTime(loginTime)
        if (time_diff <= DEFAULT_INACTIVE_TIME) {
            getMembers(memberFilter)
        }
    }, [])


    const getMembers = (data:FormValues) => {
        dispatch(setGeneralAction(types.SET_MEMBER_FILTER,data))
        // console.log(data)
        const postData = {
            token,
            url: remote_url.thriftMemebers,
            actionType: types.GET_USERS,
            postData: {
                role:data?.role,
                status:data?.status,
                branch : data?.branch
            },
            secretData: {
                email: user?.email,
                mac_id: user?.mac_id,
                r_id: role_id
            },
            errorAction: types.SET_ERROR,
            accountKey: account_key,
            loading: {
                isLoading: false,
                isLoading_: false,
                isLoading_t: true
            }
        }
        dispatch(postRequest(postData))
        handleDataTable()
    }

    const handleAddMemberForm = ()=>{
        setShowAddNewMemberForm(true)
    }
    const handleBulkImport = (data:boolean) => {
        setBulkUserForm(data)
    }


    const handleDataTable = () => {
        membersInitDatatable(users, setUserDetails, saveNow,applyForLoan,
            buttonTexts,handleAddMemberForm,handleBulkImport,editUserDetails)
    }


    if (newDataBot) {
        handleDataTable()
        dispatch(setGeneralAction(types.RESET_NEW_USERS, []))
    }



    return <DashboardWrapper>

        { success_msg && <SuccessAlert msg={ success_msg}/> }
        { error_msg && <ErrorAlert msg={error_msg}/> }
        {showEditUserForm &&   <EditUserForm   closeFunc={setEditUserForm}/> }


        <BreadCrumb current="Members Listing" main="Dashboard" pageTitle="Members"/>
        {showAddNewMemberForm && <AddNewMember show={showAddNewMemberForm} setShowAddNewMemberForm={setShowAddNewMemberForm}/> }

        {/*Bulk Form ::::::::*/}
        {showBulkUserForm && <UserBulkImport show={showBulkUserForm} handleImportModal={handleBulkImport}/> }
        {/* */}
        {showSavingsForm && <SavingsForm show={showSavingsForm}  showSavingFormFunc={handleUserForm}/>}
        {showLoanForm && <LoanApplicationForm show={showLoanForm}  handleFormModal={setShowLoanForm} />}

        <ExpandCollapse name={'Summary Card'} id={'UserFilterD'} id_={'UserFilter_'} id_1={'UserFilter'}>
            <div className="row">
                {
                    summary.map(
                        (
                            item, i = 0) => (<div key={i + 1} className="col-lg-4">
                             <NumberCardSummary bg={item.bg} leadText={item.text} text={item.val} iconName={item.iconName}/>
                           </div>
                        )
                    )
                }
            </div>
        </ExpandCollapse>
        {/* filter goes her */}
        <ExpandCollapse name={'Filter'} id={'UserFilter'} id_={'UserFilterD'} id_1={'UserFilterD_'}>
            <div className='card px-3 py-4'>
                <form onSubmit={handleSubmit(getMembers)}>
                    <div className='row'>
                        {/* transaction status  */}
                        <div className='col-lg-3'>
                            <div className="mb-3">
                                <label htmlFor="example-date" className="form-label">Members Status</label>
                                <select className="form-select mb-3" defaultValue={memberFilter?.status}  {...register('status')}>
                                    <option value='all'>All</option>
                                    <option value="1">Verified</option>
                                    <option value="0">Unverified</option>
                                    <option value="2">Blocked</option>
                                </select>
                            </div>
                        </div>
                        {/* t  */}
                        <div className='col-lg-3'>
                            <div className="mb-3">
                                <label htmlFor="example-date" className="form-label"  >Members Role</label>
                                <select className="form-select mb-3" {...register('role')} defaultValue={memberFilter?.role}>
                                    <option value={'all'}>All</option>
                                    <option value="3">Member</option>
                                    <option value="2">Admin</option>
                                    <option value="1">Super Admin</option>
                                </select>
                            </div>
                        </div>
                        {/* filter button */}
                        <div className='col-lg-3'>
                            <div className="mb-3">
                                <label htmlFor="example-date" className="form-label">-</label>
                                <input className="form-control btn btn-primary" type="submit"  value="Filter"/>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </ExpandCollapse>

        {/*end of filter*/}
        <ExpandCollapse name={'Members Management'} id={'UserData'} id_={'UserData_'} id_1={'UserDataS'}>
            {loading_t && <div className="my-2"><Loader/></div>}
            {/*"Loan Bal"*/}
            <TableWrapper
                id={'products-datatable'}
                tableHeaders={["Image", "Name", "Email", "Phone no", "Savings Bal", "Role", "Action"]}
                // filterOptionsOne = {
                //     {
                //         select_label : 'Members Role',
                //         lead_option_text : '',
                //         data : [
                //             {
                //                 name : 'All',
                //                 value : 'all'
                //             }
                //         ]
                //     }
                // }
            >
                <>
                </>
            </TableWrapper>

        </ExpandCollapse>



    </DashboardWrapper>
}


export default UserDashboard