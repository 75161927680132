import {createSlice} from "@reduxjs/toolkit";
import {SettlementFilterProps, SettlementReconciliationModel} from "./settlementReconcilationModel";
import {getSettlementRequests,} from "./settlementReconcillationActions";



const initialState:SettlementReconciliationModel = {
    current_gateway : '3',
    settlements_pay_stack:[],
    settlements_flutter_wave:[],
    settlement_filter : SettlementFilterProps,
    settlements_bank:[],
    settlement_loading : false,
    isNewSettlementData: false
}


const settlementReconciliationSlice = createSlice({
    name:'settlements',
    initialState,
    reducers:{
        setSettlementLoading:(state,action) => {
            state.settlement_loading = action.payload;
        },
        setSettlementFilter:(state,action) => {
            state.settlement_filter = action.payload;
        },
        setCurrentSettlementTab :(state,action)=>{
            state.current_gateway = action.payload
        },
        resetDatatable:(state,action)=>{
            state.isNewSettlementData = false
        },
    },
    //
    extraReducers:(builder)=>{
        //action:PayloadAction<GetAllAgentsResponse>
        builder
            .addCase(getSettlementRequests.pending,(state)=>{
                state.settlement_loading = true
            })
            .addCase(getSettlementRequests.fulfilled,(state,action)=>{
              let current_gateway = Number(state.current_gateway)
                console.log(current_gateway)
               if(current_gateway=== 3){
                   state.settlements_bank = action.payload.data
               }else if(current_gateway === 2){
                   state.settlements_pay_stack = action.payload.data
               }else {
                   state.settlements_flutter_wave = action.payload.data
               }
                state.isNewSettlementData = true
                state.settlement_loading = false

            })
            .addCase(getSettlementRequests.rejected,(state)=>{
                state.settlement_loading = false
            })
        //end of getSettlements


    }
})


export default settlementReconciliationSlice.reducer
export const {
    setSettlementFilter,
    resetDatatable,
    setSettlementLoading,
    setCurrentSettlementTab} = settlementReconciliationSlice.actions;
