import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { SubscriptionTransactionModel } from "../../../../config/model/authModel"
import {calculateTime, getRequest, setGeneralAction} from "../../../../config/redux/actions"
import { AppState } from "../../../../config/redux/reducers"
import { AuthState } from "../../../../config/redux/reducers/tmsAuthReducer"
import { types } from "../../../../config/redux/types"
import { appTxt } from "../../../../config/strings"
import { remote_url } from "../../../../config/urls/remote"
import DashboardWrapper from "../../DashboardWrapper"
import BreadCrumb from "../../layouts/BreadCrumb"
import ViewSubTransactionDetails from "./ViewSubTransactionDetails"
import {DEFAULT_INACTIVE_TIME} from "../../../../config/config";
import {subInitDatatable} from "../../../../config/datatableConfig";
import {ErrorAlert, SuccessAlert} from "../../../../components/alert";

// const $ = require('jquery')
// $.DataTable = require('datatables.net')
// $.DataTable  = require( 'datatables.net-bs5')


const ThriftSubscription = () => {

    const {token, account_key,subscription_transactions,loginTime,newData,user,role_id,error_msg,success_msg} = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
    const dispatch = useDispatch()

    useEffect(() => {
        let time_diff = calculateTime(loginTime)
        if(time_diff <= DEFAULT_INACTIVE_TIME){
            getSubTransactions()
        }
    },[])


    //
    const getSubTransactions =  () => {
        const getData = {
            token,
            url: remote_url.subscriptionTransactions,
            actionType : types.GET_SUBSCRIPTION_TRANSACTIONS,
            postData : [],
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            errorAction : types.SET_ERROR,
            accountKey: account_key,
            loading :{
                isLoading :false,
                isLoading_ : false,
                isLoading_t : true
            }
        }
        dispatch(getRequest(getData))
        subInitDatatable(subscription_transactions,setSubTransaction)
    }

    const setSubTransaction = (x:any) => {
        dispatch( setGeneralAction(types.SET_SINGLE_SUBSCRIPTION_TRANS_DETAILS,x))
    }

    if(newData){
        subInitDatatable(subscription_transactions,setSubTransaction)
        dispatch(setGeneralAction(types.RESET_NEW_DATA_BOT,[]))
    }

    return <DashboardWrapper  errorNsg={error_msg} >
        {error_msg && <ErrorAlert msg={error_msg}/> }
        {success_msg && <SuccessAlert msg={success_msg}/> }
        <BreadCrumb pageTitle="" main="" current=""/>
        <ViewSubTransactionDetails/>
        <div className="col-sm-12">
            <div className="table-responsive">
                 <table className="table table-centered table-responsive w-100 dt-responsive nowrap dataTable no-footer dtr-inline collapsed" role={'grid'}
                        id="products-datatable">
                    <thead className="table-light">
                        <tr>

                            <th>Plan Name</th>
                            <th>Reference</th>
                            <th>Amount Paid</th>
                            <th>Created At Date</th>
                            <th>Payment Date</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>

                    </tbody>
                </table>
            </div>
        </div>  
    </DashboardWrapper>
}


export default  ThriftSubscription