import Chart from "react-apexcharts";
import { SavingDashboardAnalyticModel } from "../../../../config/model/authModel";
import {savingsMonthlyBarGraphConfig } from "./init";


interface Props {
    data: SavingDashboardAnalyticModel
}
//
const SavingsMonthlyReportBarGraph:React.FC<Props> = ({data}) => {
    return <> 
         <Chart
            type="bar"
            options={{...savingsMonthlyBarGraphConfig,...{labels:data?.total_no_of_savings_year?.labels??[]}}}
            // options={savingsMonthlyBarGraphConfig}
            series={[
				{
					name: 'Savings',
					data: data?.total_no_of_savings_year?.series??[],
				},
			]}
            className="apex-charts mt-3 mb-3"
            id="country-chart"
            height= "400px"
        />
    </>
}


export default SavingsMonthlyReportBarGraph