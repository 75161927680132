import { AllThriftSavingsModel } from "../../../config/model/authModel"

import { useDispatch, useSelector } from "react-redux"
import {copyYourLink, postRequest, setGeneralAction, toNigerianCurrency} from "../../../config/redux/actions"
import { types } from "../../../config/redux/types"
import { Link } from "react-router-dom"
import React, { useState } from "react"
import { appTxt } from "../../../config/strings"
import { remote_url } from "../../../config/urls/remote"
import { AppState } from "../../../config/redux/reducers"
import { AuthState } from "../../../config/redux/reducers/tmsAuthReducer"
import { SavingsReducerState } from "../../../config/redux/reducers/tmsSavingsReducer"
import { Spinner } from "../../../components"
import {SuccessAlert, WarningAlert} from "../../../components/alert";
import {BankDetailsWrapperComponent, ProfileDetailCard} from "../_components";

type ViewSavingsProps = {
    saving : AllThriftSavingsModel,
    show ?: string,
    backdrop? :boolean
}

const SavingsDetails:React.FC<ViewSavingsProps> = ({saving , show="true",backdrop=false}) => {
    const {account_key,token,user,role_id,success_msg} = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
    const {singleSavings,isLoading_t,requery,savingsFilter} = useSelector<AppState,SavingsReducerState>((state) => state.tmsSavingsReducer)
    //
    const editedEmails = singleSavings.edited_by_email ? singleSavings.edited_by_email.split(',') : []
    const editedDateTime =singleSavings.edited_date_time ? singleSavings.edited_date_time.split(',') : []
    // const [backDrop, setBackDrop] = useState("show")

    const dispatch = useDispatch()

    const clearData = () => {
        // setBackDrop("")
        dispatch(setGeneralAction(types.CLEAR_SAVING_DATA,[]))
        // setBackDrop("show")
    }

    const requeryNow = () => {

        if(singleSavings.payment_gateway_used===1 && !singleSavings.transaction_id){
            window.location.href = singleSavings.initialized_url
        }
          const ref = saving.transaction_reference
        if(ref){
            //  dispatch(setGeneralAction(types.SET_TRANS_REF,ref))
              const postData = {
                token,
                url: remote_url.verifySavings,
                actionType : types.REQUERY_SAVINGS, //GET_SAVINGS
                postData : {
                    tranxRef:ref,
                    _requery : 1,
                    gateway_id : saving.payment_gateway_used,
                    transaction_id : saving.transaction_id,
                    fromDate: savingsFilter.fromDate,
                    toDate : savingsFilter.toDate,
                    status:savingsFilter.status
                },
                  secretData : {
                      email:user.email,
                      mac_id :user.mac_id,
                      r_id: role_id
                  },
                errorAction : types.SET_ERROR,
                accountKey: account_key,
                loading :{
                    isLoading : false,
                    isLoading_ : false,
                    isLoading_t : true
                }
            }
            dispatch(postRequest(postData))
            if(requery){
                if(singleSavings.payment_status ==='0' && singleSavings.saving_transaction_status=== 0){
                    window.location.href = singleSavings.initialized_url
                }
                // console.log(singleSavings);
                // dispatch(setGeneralAction(types.CLEAR_SAVING_DATA,[]))
            }
        }
    }

    if(requery){
        if(singleSavings.payment_status ==='0' && singleSavings.payment_channel==='card'){
            window.location.href = singleSavings.initialized_url
        }
    }
    //
    return <>
   
       <div 
            className={`offcanvas offcanvas-end  ${show} bg-white`}
            tabIndex={-1}  id="savingsDetails"
            aria-labelledby="offcanvasRightLabel"
            >
           {success_msg && <SuccessAlert  msg={success_msg}/>}
                    <div className="offcanvas-header">
                        <h5 id="offcanvasRightLabel"> 
                        {
                            singleSavings?.payment_status ==='1' && singleSavings?.saving_transaction_status=== 1 ?
                                <span className="badge bg-success">Paid</span> :
                                singleSavings?.payment_status ==='0' && singleSavings?.saving_transaction_status=== 0 ?
                                    <span className="badge bg-info">waiting for approval</span>
                                    :
                                 <span className="badge bg-warning">Unpaid</span>
                            }
                        </h5> 
                        <Link 
                        to="#0" 
                        onClick={() => clearData()}  
                        className="btn-close text-reset" 
                        data-bs-dismiss="offcanvas" 
                        aria-label="Close"
                        >
                    </Link> 
                      
                    </div>

                    <div className="offcanvas-body">
                        <ProfileDetailCard
                            profile_url={user.profile_image!=='' ? user.profile_image : user.alternate_profile_image }
                            email={user.email}
                            name={user.name}
                            summaryText={''}
                            summaryValue={''}
                        />
                        <div className={'table-responsive'}>
                            <table className="table   table-responsive   table-striped mb-0">
                                <thead className="">
                                <tr>
                                    <th></th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td> Reference </td>
                                        <td> {singleSavings?.transaction_reference}</td>
                                    </tr>
                                    {
                                        singleSavings?.payment_status === '1' &&
                                        <>


                                            <tr>
                                                <td> Amount </td>
                                                <td> {toNigerianCurrency(Number(singleSavings?.thrift_amount))} </td>
                                            </tr>
                                            <tr>
                                                <td> Payment  Channel</td>
                                                <td> {singleSavings?.payment_channel}</td>
                                            </tr>
                                            <tr>
                                                <td> Bank</td>
                                                <td>
                                                    {['bank','card'].includes(singleSavings?.payment_channel) ? singleSavings?.bank_name :'N/A'}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td> Description </td>
                                                <td>
                                                    {['bank',''].includes(singleSavings?.payment_channel) ? singleSavings?.description :'N/A'}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td> Amount Paid By Member </td>
                                                <td> ₦{Number(singleSavings?.amount_paid).toLocaleString()}</td>
                                            </tr>
                                            <tr>
                                                <td> Payment  Date </td>
                                                <td> {singleSavings?.paid_at}</td>
                                            </tr>
                                            <tr>
                                                <td> Amount settled to cooperative society</td>
                                                <td> ₦{Number(singleSavings?.thrift_amount).toLocaleString()}</td>
                                            </tr>

                                            <tr>
                                                <td> Transaction Fee</td>
                                                <td> {appTxt.naira_}{Number(singleSavings?.gateway_fee) + Number(singleSavings?.service_charge)}</td>
                                            </tr>
                                        </>
                                    }

                                    { singleSavings?.payment_channel==='card' && Number(singleSavings?.payment_status)===1
                                        &&
                                        <tr>
                                            <td> Transaction Fee Bearer</td>
                                            <td> {singleSavings?.transaction_fee_bearer}</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>

                            { singleSavings?.payment_channel==='bank' && Number(singleSavings?.payment_status)===0
                                && <>
                                    <BankDetailsWrapperComponent
                                        bank_name={singleSavings.bank_name}
                                        account_number={singleSavings.account_number}
                                        amount_paid={singleSavings.amount_paid}
                                        account_name={singleSavings.account_name}
                                        expires_at={singleSavings.expires_at}
                                        dispatch ={dispatch}
                                        loading={isLoading_t}
                                        requeryNow={requeryNow}
                                        showBtn={true}
                                    />
                                </>
                            }

                            {
                                singleSavings?.payment_status === '0' && singleSavings?.payment_channel==='card' &&
                                <>
                                    <div className={'pull-right float-right text-right mt-3 alert alert-info py-2'}>
                                        <p className={'text-danger'}>N.B: payment gateway will add transaction fee:
                                            If the payment is yet to be processed, we will redirect you to the payment gateway
                                        </p>
                                        <p className={'text-dark'}>Transaction Reference : {singleSavings?.transaction_reference}</p>
                                        <hr/>
                                                {isLoading_t ?
                                                    <Spinner color="primary"/>
                                                    :
                                                    <Link to="#0"
                                                          onClick={() => requeryNow()}
                                                          className="btn btn-outline-primary">
                                                        Requery &nbsp; {appTxt.naira_}{Number(singleSavings?.actual_amount).toLocaleString()}
                                                    </Link>
                                                }
                                            </div>



                                </>
                            }
                            {/*edit history*/}



                            {
                               [1,2].includes(role_id)  && singleSavings?.is_edit === 1 &&
                                <>
                                    <br/>
                                    <h6 className={'py-1 text-info'}>Edit/Update History</h6>
                                    <table className={'table table-condensed savingEditHistory'} id={"savingEditHistory"}>
                                        <thead>
                                        <tr>
                                            <th>Email</th>
                                            <th>DateTime</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            editedEmails.map((item,i=0) =>
                                                <tr key={i}>
                                                    <td>{item}</td>
                                                    <td>{editedDateTime[i]}</td>
                                                </tr>)
                                        }
                                        </tbody>
                                    </table>
                                </>
                            }

                        </div>
                    </div>
                   
                   
            </div>
    
    
       
                    {/* {loading_ && <div className="modal-backdrop fade show"></div>} */}{/* </div> */}
            </>
}


export default SavingsDetails