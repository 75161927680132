import { useEffect } from "react"
import { useForm } from 'react-hook-form';
import DashboardWrapper from "../DashboardWrapper"
import BreadCrumb from "../layouts/BreadCrumb"

import "../../../assets/css/buttons.bootstrap5.css"
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "../../../config/redux/reducers"
import { SavingsReducerState } from "../../../config/redux/reducers/tmsSavingsReducer"
import { AuthState } from "../../../config/redux/reducers/tmsAuthReducer"
import { remote_url } from "../../../config/urls/remote"
import { types } from "../../../config/redux/types"
import { Loader } from "../_components";
import {calculateTime, postRequest, setGeneralAction} from "../../../config/redux/actions";
import { AuditLogModel } from "../../../config/model/authModel";
import {ViewDetails} from "./_components";
import {auditLogInitDatable} from "../../../config/datatableConfig";

// const $ = require('jquery')
// $.DataTable = require('datatables.net')
// $.DataTable  = require('datatables.net-bs5')
// $.DataTable  = require('datatables.net-buttons')


type FormValues ={
    fromDate : string,
    toDate : string,
    action_type : string,
    user_id : string | null
}

const AuditLog = () => {

    const { register, handleSubmit } = useForm<FormValues>()
    const {token,auditLogs,loading_t,account_key,newData,users,loginTime,user,role_id} = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
    const dispatch =  useDispatch()


    // initial render
    useEffect(() => {
        let time_diff = calculateTime(loginTime)
        if(time_diff <= 6){
            getAuditLog({
                fromDate:'',
                toDate:'',
                action_type:'create',
                user_id : 'all'
            })
        }
    },[])

    //
    const getAuditLog =  (data:FormValues) => {
        const postData = {
            token,
            url: remote_url.auditLog,
            actionType : types.GET_AUDIT_LOG,
            postData : data,
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            errorAction : types.SET_ERROR,
            accountKey: account_key,
            loading :{
                isLoading :false,
                isLoading_ : false,
                isLoading_t : true
            }
        }
        dispatch(postRequest(postData))
        auditLogInitDatable(auditLogs,setAuditLog)
    }
    //
    const setAuditLog = (x:any) => {
        dispatch( setGeneralAction(types.SET_AUDIT_LOG,x))
    }

    //checking if new data was fetched and reset the bot back to false
    if(newData){
        auditLogInitDatable(auditLogs,setAuditLog)
        dispatch(setGeneralAction(types.RESET_NEW_DATA_BOT,[]))
    }
   
    return <DashboardWrapper>
        <BreadCrumb pageTitle="Audit Log" main="Dashboard" current="Audit Log" showToggle={false}/>

        {/*<ViewDetails/>*/}
          {/* filter */}
        <div className='card px-3 py-4'>
            <form  onSubmit={handleSubmit(getAuditLog)}>
                <div className='row'>
                    {/* from Date */}
                    <div className='col-lg-2'>
                        <div className="mb-3">
                        <label htmlFor="example-date" className="form-label">From Date</label>
                        <input className="form-control" type="date" {...register('fromDate')} />
                    </div>
                    </div>
                    {/* to Date */}
                    <div className='col-lg-2'>
                        <div className="mb-3">
                            <label htmlFor="example-date" className="form-label">To Date</label>
                            <input className="form-control" type="date"  {...register('toDate')}/>
                        </div>
                    </div>
                    {/* transaction status  */}
                    <div className='col-lg-3'>
                        <div className="mb-3">
                            <label htmlFor="example-date" className="form-label">Actions</label>
                            <select className="form-select mb-3"  {...register('action_type')}
                                    defaultValue={"create"}>
                                <option value="create">Create Action</option>
                                <option value="read"> Accessing of Data Actions</option>
                                <option value="update">Update Actions</option>
                                <option value="delete">Delete Actions</option>
                            </select>
                        </div>
                    </div>
                    {/*  */}
                    <div className='col-lg-3'>
                        <div className="mb-3">
                            <label htmlFor="example-date" className="form-label">Members</label>
                            <select className="form-select mb-3"  {...register('user_id')} defaultValue={'all'}>
                                <option value="all">All</option>
                                {users.map((item,i=0) =><option key={i}  value={item.id}>{item.name}</option> )}
                            </select>
                        </div>
                    </div>
                    {/* filter button */}
                    <div className='col-lg-2'>
                        <div className="mb-3">
                            <label htmlFor="example-date" className="form-label">-</label>
                            <input className="form-control btn btn-primary" type="submit" value="Filter"/>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        {/*  */}
        <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                           
                            <div>
                            {loading_t && <Loader/>}

                            </div>
                            <table id="auditLogDataTable" className="table table-striped dt-responsive nowrap w-100">
                                <thead>
                                    <tr>
                                        <th>Action Performed by: </th>
                                        <th>Branch</th>
                                        <th style={{width:'20px'}}>Description</th>
                                        <th>Date Performed</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                            </table>
                        </div> 
                    </div>
                </div>
            </div>
        {/*  */}
    </DashboardWrapper>
}

export default AuditLog