import {url} from "../urls";
import {appConfig} from "../config";

const isNew = appConfig.appMode !== 'local'

export const adminRoute = [
    {
        'title': 'Dashboards',
        'icon_name': 'uil-home-alt',
        'route': url.dashboard,
        'component': '',
        'has_children': false,
        'children': [],
        'has_pre_title': true,
        'pre_title_text': 'Navigation',
        'has_badge': false,
        'badge_color': '',
        'badge_text': '',
        'role_id': [1, 2, 3],
        'is_active': true,
        'is_new' : false
    },
    //! @Savings 
    {
        'title': 'Savings',
        'icon_name': 'uil-wallet',
        'route': '',
        'component': '',
        'has_children': true,
        'children': [
            {
                'title': 'Savings Dashboard',
                'route': url.savingsDashboard,
                'role_id' :  [1,2,3]

            },
            {
                'title': 'My Savings',
                'route': url.savings,
                'role_id' :  [1,2,3]
            },
            {
                'title': 'All Savings',
                'route': url.all_savings,
                'role_id' :  [1,2]
            },
        ],
        'has_pre_title': true,
        'pre_title_text': 'Apps',
        'id': 'sidebarSavings',
        'role_id': [1, 2, 3],
        'is_active': true,
        'is_new' : false
    },
    //! @Loans
    {
        'title': 'Loans',
        'icon_name': 'uil-mobey-bill-slash',
        'route': '',
        'component': '',
        'has_children': true,
        'role_id': [1, 2, 3],
        'children': [
            {
                'title': 'Loans Dashboard',
                'route': url.loansDashboard,
                'role_id' :  [1,2,3]
            },
            {
                'title': 'Loans Applications',
                'route': url.loansApp,
                'role_id' :  [1,2,3]
            },
            {
                'title': 'Loans Settings',
                'route': url.loansSettings,
                'role_id' :  [1,2]
            }
        ],
        'has_pre_title': false,
        'pre_title_text': '',
        'id': 'sidebarLoans',
        'is_active': true,
        'is_new' : false
    },
    //! Expenses
    {
        'title': 'Expenses',
        'icon_name': 'uil-card-atm',
        'route': '',
        'component': '',
        'has_children': true,
        'role_id': [1, 2, 3],
        'children': [
            {
                'title': 'Expenses Dashboard',
                'route': url.expensesDashboard,
                'role_id' :  [1,2]
            },
            {
                'title': 'Expenses',
                'route': url.expenses,
                'role_id' :  [1,2,3]
            }
        ],
        'has_pre_title': false,
        'pre_title_text': '',
        'id': 'sidebarTransactions',
        'is_active': true,
        'is_new' : false
    },
    //Settlements

    {
        'title': 'Settlements',
        'icon_name': 'uil-compress-arrows',
        'route': url.settlements,
        'component': '',
        'has_children': false,
        'children': [],
        'has_pre_title': true,
        'pre_title_text': 'Settlement/Reconciliation',
        'id': 'settlements',
        'role_id': [1, 2],
        'is_active': true,
        'is_new' : false,
        'isDisable':false
    },


    // ? Another Section
    //! @Customer :: Done
    {
        'title': 'Customers',
        'icon_name': ' uil-users-alt',
        'route': url.users,
        'component': '',
        'has_children': false,
        'children': [],
        'has_pre_title': true,
        'pre_title_text': 'User Management',
        'id': 'sidebarCustomers',
        'role_id': [1, 2],
        'is_active': true,
        'is_new' : false,
        'isDisable':false
    },

    // @SuperAdmin Coop Branch Management :: Done
    {
        'title': 'Branch',
        'icon_name': 'uil-gold',
        'route': url.branch,
        'component': '',
        'has_children': false,
        'children': [],
        'has_pre_title': false,
        'pre_title_text': '',
        'id': '',
        'role_id': [1],
        'is_active': true,
        'is_new' : false,
        'isDisable':false
    },
    // ? Another Section
    //! Marketing
    {
        'title': 'Marketing',
        'icon_name': 'uil-rainbow',
        'route': '',
        'component': '',
        'has_children': true,
        'isDisable':true,
        'children': [
            {
                'title': 'Ads',
                'route': url.adsHome,
                'role_id' :  [1,2,3],
                'isDisable':true,
            },
            {
                'title': 'Blog/News',
                'route': url.blogHome,
                'role_id' :  [1,2],
                'isDisable':true,
            }
        ],
        'has_pre_title': true,
        'pre_title_text': 'Marketing  / Communication',
        'id': 'sidebarMarkerting',
        'role_id': [1, 2, 3],
        'is_active': false,
        'is_new' : false,
    },
    //! StoreFront
    {
        'title': 'Store Front',
        'icon_name': 'uil-card-atm',
        'route': '',
        'component': '',
        'has_children': true,
        'isDisable':isNew,
        'role_id': [1, 2, 3],
        'children': [
            {
                'title': 'Category',
                'route': url.expensesDashboard,
                'role_id' :  [1],
                'isDisable':isNew,
            },
            {
                'title': 'All Products',
                'route': url.allProducts,
                'role_id' :  [1],
                'isDisable':isNew,
            },
            {
                'title': 'Orders',
                'route': url.allOrders,
                'role_id' :  [1,2],
                'isDisable':isNew,
            },
            {
                'title': 'My Orders',
                'route': url.allOrders,
                'role_id' :  [3],
                'isDisable':isNew,
            },
        ],
        'has_pre_title': false,
        'pre_title_text': '',
        'id': 'sidebarStoreFronts',
        'is_active': false,
        'is_new' : false,
    },
    //Communication
    {
        'title': 'Communication',
        'icon_name': ' uil-rss-alt',
        'route': '',
        'component': '',
        'has_children': true,
        'isDisable':true,
        'children': [
            {
                'title': 'Notifications',
                'route': url.pushNotification,
                'role_id' :  [1,2],
                'isDisable':true,
            },
            {
                'title': 'Email/SMS',
                'route': url.emailSms,
                'role_id' :  [1,2],
                'isDisable':true,
            },
            // {
            //      'title' : 'Messages/Chat',
            //      'route' :''
            // },
        ],
        'has_pre_title': false,
        'pre_title_text': '',
        'id': 'sidebarCommunications',
        'role_id': [1, 2],
        'is_active': false,
        'is_new' : false
    },
    // ! Referral System :: DOne
    {
        'title': 'Referral',
        'icon_name': 'uil-coins',
        'route': url.referralHome,
        'component': '',
        'has_children': false,
        'children': [],
        'has_pre_title': false,
        'pre_title_text': '',
        'has_badge': false,
        'badge_color': '',
        'badge_text': '',
        'role_id': [1, 2, 3],
        'is_active': false,
        'is_new' : false,
        'isDisable':true,
    },
    // ? Another Section
    //! Super Admin Only Thrifts Settings :: Done
    {
        'title': 'Thrift Settings',
        'icon_name': ' uil-wrench',
        'route': '',
        'component': '',
        'has_children': true,
        'isDisable':false,
        'children': [
            {
                'title': 'Settings',
                'route': url.thriftAccountSettings,
                'role_id' :  [1]
            },
            // {
            //     'title': 'Documents',
            //     'route': url.thriftAccountDocuments,
            //     'role_id' :  [1]
            // },
            {
                'title': 'Bank Accounts',
                'route': url.thriftBankListing,
                'role_id' :  [1]
            },
            // {
            //      'title' : 'Payment Channel',
            //      'route' :''
            // },
            // {
            //      'title' : 'Application Module',
            //      'route' :''
            // },
        ],
        'has_pre_title': true,
        'pre_title_text': 'Settings',
        'id': 'sidebarThriftsAccSettings',
        'role_id': [1],
        'is_active': true,
        'is_new' : false
    },
    //! My Personal  :: Done
    {
        'title': 'Profile Settings',
        'icon_name': 'uil-user-check',
        'route': '',
        'component': '',
        'has_children': true,
        'children': [
            // {
            //     'title': 'My Profile',
            //     'route': url.myProfile,
            //     'role_id' :  [1,2,3]
            // },
            {
                'title': 'Profile Settings',
                'route': url.myAccount,
                'role_id' :  [1,2,3]
            },
            {
                'title': 'Change Password',
                'route': url.changePassword,
                'role_id' :  [1,2,3]
            },
        ],
        'has_pre_title': false,
        'pre_title_text': '',
        'id': 'sidebarSettings',
        'role_id': [1, 2, 3],
        'is_active': true,
        'is_new' : false
    },
    //! Subscription Management :: Done
    {

        'title': 'Subscription Settings',
        'icon_name': 'uil-user-check',
        'route': '',
        'component': '',
        'has_children': true,
        'isDisable':false,
        'children': [
            {
                'title': 'Pricing Table',
                'route': url.pricing,
                'role_id' :  [1]
            },
            {
                'title': 'My Subscription',
                'route': url.thriftSubscriptions,
                'role_id' :  [1]
            },

        ],
        'has_pre_title': false,
        'pre_title_text': '',
        'id': 'sidebarSettingsSub',
        'role_id': [1],
        'is_active': true,
        'is_new' : false
    },
    //! Audit Log :: Done
    {
        'title': 'Audit Log',
        'icon_name': ' uil-circuit',
        'route': url.auditLogDashboard,
        'component': '',
        'has_children': false,
        'children': [],
        'has_pre_title': false,
        'pre_title_text': '',
        'has_badge': false,
        'badge_color': '',
        'badge_text': '',
        'role_id': [1],
        'is_active': true,
        'is_new' : false
    },
    //support ticket :: Done
    {
        'title': 'Support Tickets',
        'icon_name': 'uil-chat-bubble-user',
        'route': url.supportsHome,
        'component': '',
        'has_children': false,
        'children': [],
        'has_pre_title': false,
        'pre_title_text': '',
        'has_badge': false,
        'badge_color': '',
        'badge_text': '',
        'role_id': [1, 2, 3],
        'is_active': true,
        'is_new' : false,
        'isDisable':true,
    },
]
