import { Link } from "react-router-dom"
import { useForm } from "react-hook-form"
import React, { useState } from "react"
import { Spinner } from "../../../components"
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "../../../config/redux/reducers"
import { AuthState } from "../../../config/redux/reducers/tmsAuthReducer"
import { types } from "../../../config/redux/types"
import {postRequest, setGeneralAction} from "../../../config/redux/actions"
import { remote_url } from "../../../config/urls/remote"

import {appConfig} from "../../../config/config";
import {ExpenseReducerState} from "../../../config/redux/reducers/tmsExpenseReducer";
import {ErrorAlert, SuccessAlert} from "../../../components/alert";
import {ViewExpense} from "./components";
import {CustomModalWrapper} from "../_components";

interface FormValues {
    purpose : string,
    phone_number : string,
    route : string,
    amount : string,
    category : number,
    transaction_type : string,
    desc : string,
    channel : string,
    gateway : string,
    frontend_url : string,
    paid_at : string,
    fromDate : string,
    toDate : string,
    status : string
}
interface  Props{
    handleModalFunc : any
    requery ?: any
}
const AddNewExpense:React.FC<Props> = ({handleModalFunc,requery}) => {
    const {token ,account_key,user,role_id,gateways,payment_channels } = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
    const {loading_,filter,success_msg,error_msg,expense} = useSelector<AppState,ExpenseReducerState>((state) => state.tmsExpenseReducer)
    const { register, handleSubmit, formState: { errors },reset } = useForm<FormValues>()
    const [showPhone , setPhoneNo] = useState(false)
    const [showChannel , setShowChannel] = useState(false)
    const [showTransactionDate , setTransactionDate ] = useState(false)
    const [showPaymentGateway , setPaymentGateway ] = useState(false)

    const dispatch = useDispatch()

    const clearData = () => {
            dispatch(setGeneralAction(types.CLEAR_MSG,[]));
            dispatch(setGeneralAction(types.CLEAR_EXPENSE,[]));
            reset()
        handleModalFunc(false)
    }

    const addNewTransaction = (data:FormValues) => {
        data.route = 'web'
        data.frontend_url = `${appConfig.frontend_url}/expenses/verification`
        data.fromDate = filter?.fromDate
        data.toDate = filter?.toDate
        data.status = filter?.status
        // console.log(data)
        const postData = {
            token,
            url: remote_url.createExpenses,
            actionType : types.CREATE_NEW_EXPENSE,
            postData :data,
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            errorAction : types.SET_ERROR,
            accountKey: account_key,
            loading :{
                isLoading :false,
                isLoading_ : true,
                isLoading_t:false
            }
        }
        //
        console.log(postData)
        dispatch(postRequest(postData))
    }

    const onChangeTransactionType = (val:string) => {
        if(val==='income'){
            setShowChannel(true)
            setTransactionDate(false)
        }else{
            setShowChannel(false)
            setTransactionDate(true)
        }
    }
    //::::
    const onChangeWhoOwnsExpense = (val:string) => {
        if(val==='1'){
            setPhoneNo(true)
        }else{
            setPhoneNo(false)
        }
    }

    const onChangeChannel = (val:string)=>{
        if(val==='card'){
            setPaymentGateway(true)
        }
    }



    //
    return <>
        <CustomModalWrapper onClickFunc={clearData} modalTitle={expense ?  'Expense Details' :  'Add New Expense'}>

            <div className="offcanvas-body">
                {expense.id !==0 ?
                    <ViewExpense dispatch={dispatch} isLoading={loading_} requery={requery}/>
                    :
                    <form onSubmit={handleSubmit(addNewTransaction)}>
                        {success_msg && <SuccessAlert msg={success_msg}/>}
                        {error_msg && <ErrorAlert msg={error_msg}/> }
                        {/* purpose */}
                        <div className="mb-2">
                            <label>Purpose</label>
                            <input
                                type='text'
                                className={`form-control ${errors.purpose && 'is-invalid'}`}
                                placeholder="eg: lateness fee"
                                {...register('purpose',{
                                    required: 'purpose field is required',
                                    minLength: { value: 4, message: 'purpose should be more descriptive' },
                                })}
                            />
                            {errors.purpose  && (<div className="invalid-feedback">{errors.purpose.message}</div>)}
                        </div>

                        {/* transaction type */}
                        <div className="mb-2">
                            <label>Transaction Type</label>
                            <select
                                className={`form-select ${errors.transaction_type && 'is-invalid'}`}
                                {...register('transaction_type',{
                                    required: 'transaction type field is required',
                                })}
                                onChange= {(e) => onChangeTransactionType(e.target.value)}
                            >
                                <option value={''}>Transaction Type</option>
                                <option value={'income'}>Income</option>
                                <option value={'expense'}>Expense</option>


                            </select>
                            {errors.transaction_type  && (<div className="invalid-feedback">{errors.transaction_type.message}</div>)}
                        </div>
                        <div className="alert alert-primary text-primary" role="alert">
                            <strong>Income:</strong> means transaction that is going into the cooperative society purse while <br/>
                            <strong> Expenditure: </strong> means transaction that is going out of the cooperative  purse
                        </div>
                        {/*    */}


                        {/* Category */}
                        <div className="mb-2">
                            <label>Who owns this Transaction</label>
                            <select
                                className={`form-select ${errors.category && 'is-invalid'}`}
                                {...register('category',{
                                    required: 'who owns the transaction field is required',
                                })}
                                onChange={ (e)=>onChangeWhoOwnsExpense(e.target.value) }
                            >
                                <option value={''}> Select Who is Completing/Initiating this Transaction </option>
                                <option value={0}> Cooperative / Thrift Account</option>
                                <option value={1}> Member</option>
                            </select>
                            {errors.category  && (<div className="invalid-feedback">{errors.category.message}</div>)}
                        </div>
                        {/* phone no */}
                        { showPhone &&
                            <div className="mb-2">
                                <label>Phone Number</label>
                                <input type='text'
                                    //  className="form-control"
                                       className={`form-control ${errors.phone_number && 'is-invalid'}`}
                                       placeholder="phone number"
                                       {...register('phone_number',{
                                           required: 'phone number field is required',
                                           minLength: { value: 11, message: 'Your Phone number is incorrect' },
                                           maxLength: { value: 12, message: 'Your Phone number is incorrect' },
                                       })}
                                />
                                {errors.phone_number  && (<div className="invalid-feedback">{errors.phone_number.message}</div>)}
                            </div>
                        }


                        {/* Channel */}
                        {showChannel && <div className="mb-2">
                            <label>Payment Channel</label>
                            <select
                                className={`form-select ${errors.channel && 'is-invalid'}`}
                                {...register('channel',{
                                    required: 'payment channel is required',
                                    onChange:(e)=>onChangeChannel(e.target.value)
                                })}>
                                <option value={''}>Payment Channel</option>
                                {payment_channels.map((item,i=0) =>
                                   item.role.includes(role_id) &&  <option key={i+1} value={item.key} >{item.name}</option>
                                )}
                            </select>
                            {errors.channel  && (<div className="invalid-feedback">{errors.channel.message}</div>)}
                        </div> }

                        {showPaymentGateway && <div className="mb-2">
                            <label>Payment Channel</label>
                            <select
                                className={`form-select ${errors.channel && 'is-invalid'}`}
                                {...register('gateway',{
                                    required: 'payment gateway is required',
                                    onChange:(e)=>onChangeChannel(e.target.value)
                                })}>
                                {gateways.map((item)=>
                                        <option key={item.key} value={item.key}> {item.name}</option>
                                    )
                                }
                            </select>
                            {errors.gateway  && (<div className="invalid-feedback">{errors.gateway.message}</div>)}
                        </div> }



                        {/* expense date*/}
                        {showTransactionDate &&
                            <div className="mb-2">
                            <label>Transaction Date</label>
                            <input type='date'
                                   className={`form-control ${errors.paid_at && 'is-invalid'}`}
                                   placeholder="dd-mm-yyyy"
                                   {...register('paid_at',{required: 'transaction_date'})}
                            />
                            {errors.paid_at  && (<div className="invalid-feedback">{errors.paid_at.message}</div>)}
                        </div> }


                        {/* Description */}
                        <div className="mb-2">
                            <label>Description</label>
                            <textarea
                                className={`form-control ${errors.desc && 'is-invalid'}`}
                                rows={3}
                                {...register('desc',{
                                    required: 'description field is required',
                                    minLength: { value: 12, message: 'description field is too Short' },
                                })}>
                                </textarea>
                            {errors.desc  && (<div className="invalid-feedback">{errors.desc.message}</div>)}
                        </div>
                        {/*  */}
                        <div className="mb-2">
                            <label>Amount</label>
                            <input type='text'
                                   className={`form-control ${errors.amount && 'is-invalid'}`}
                                   placeholder="amount"
                                   {...register('amount',{
                                       required: 'amount field is required',
                                   })}
                            />
                            {errors.amount  && (<div className="invalid-feedback">{errors.amount.message}</div>)}
                        </div>

                        <div>
                            {success_msg && <SuccessAlert msg={success_msg}/>}
                            {error_msg && <ErrorAlert msg={error_msg}/> }
                        </div>

                        {
                            loading_
                                ?
                                <Spinner color="primary"/>
                                :
                                <div className="d-flex justify-content-center mt-2">
                                    <hr/>
                                    <input type="submit" className="btn btn-primary rounded-pill br-2" value={'Submit Transactions'}/>
                                </div>
                        }


                    </form>

                }

            </div>

            {/* {loading_ && <div className="modal-backdrop fade show"></div>} */}
        </CustomModalWrapper>
    </>
}

export default AddNewExpense