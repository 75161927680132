import DashboardWrapper from "../../DashboardWrapper";
import BreadCrumb from "../../layouts/BreadCrumb";
import {ComingSoon} from "../../../../components";

const AdsHome = () => {
    return <DashboardWrapper>
        <BreadCrumb pageTitle={'Ads'} main={'Marketing'} current={'Ads'}/>
        <ComingSoon imgName={'coming_soon_2'}/>
    </DashboardWrapper>
}

export default  AdsHome