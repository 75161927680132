import React from "react";

interface  FilterOptions{
    name: string,
    value : string
}
interface Props{
    id:  string
    tableHeaders:string[],
    onChangeFilter ?: any,
    filterOptionsOne  ?:{
        select_label : string,
        lead_option_text : string
        data : FilterOptions[]
    },
    children: React.ReactNode
}

const TableWrapper:React.FC<Props> = ({id,tableHeaders,children}) => {
    return(
        <div className={'py-1 card shadow'}>
            <div className={'card-header py-4'}>
                {children}
            </div>


            {/*  */}
            <div className="table-responsive card-body">
                <table className={`table table-centered w-100 dt-responsive nowrap`} id={`${id}`}>
                    <thead className="table-light">
                    <tr>
                        {tableHeaders.map((header,i=0)=><th key={i}>{header}</th>)}
                    </tr>
                    </thead>

                    <tbody>

                    </tbody>
                </table>
            </div>
        </div>

    )
}
export default TableWrapper