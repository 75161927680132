import DashboardWrapper from "../../../pages/admin/DashboardWrapper";
import React, {useEffect} from "react";
import BreadCrumb from "../../../pages/admin/layouts/BreadCrumb";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../_config/redux/store";
import {AppState} from "../../../config/redux/reducers";
import {AuthState} from "../../../config/redux/reducers/tmsAuthReducer";
import {RequestPropsNew} from "../../_config/generalAction";
import {
    getSettlementRequests
} from "../../_config/redux/feacture/settlementsReconcilations/settlementReconcillationActions";
import {remote_url} from "../../../config/urls/remote";


interface Props{
    children  :  React.ReactNode
}
const SettlementReconciliationWrapper:React.FC<Props> = ({children}) => {
    const dispatch = useDispatch()
    const {role_id,token,account_key,user} = useSelector<AppState, AuthState>((state)=> state.tmsAuthReducer)
    //
    const {settlement_filter, current_gateway} = useSelector((state:RootState)=>state.settlementReconciliation)

    const getSettlementsData = () => {
        let postData: RequestPropsNew = {
            accountKey: account_key,
            secretData: {
                r_id: role_id,
                mac_id: user.mac_id,
                email: user.email
            },
            token,
            postData : {
                gateway : current_gateway,
                ...settlement_filter
            },
            url: remote_url.getSettlement
        }
        // :: ::
        dispatch(getSettlementRequests(postData));
    }

    //
    useEffect(() => {
        getSettlementsData()
    },[current_gateway,settlement_filter])





    return(
        <DashboardWrapper>
            <>
                <BreadCrumb pageTitle={''} main={''} current={'Settlement Reconciliation'} />
                {children}
            </>
        </DashboardWrapper>
    )
}

export default SettlementReconciliationWrapper