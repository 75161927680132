import {useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Link, Navigate} from "react-router-dom"
import {UserBranchModel, UserBranchProps} from "../../config/model/authModel"
import {postRequest, setGeneralAction, setLoading} from "../../config/redux/actions"
import {AppState} from "../../config/redux/reducers"

import {AuthState} from "../../config/redux/reducers/tmsAuthReducer"
import {types} from "../../config/redux/types"
import {url} from "../../config/urls"
import AuthWrapper from "./AuthWrapper"
import {Spinner} from "../../components";
import {ErrorAlert} from "../../components/alert";
import {remote_url} from "../../config/urls/remote";
import {Header} from "./components";

//

const Accounts = () => {
    const [item, setItem] = useState<UserBranchModel>(UserBranchProps)
    const dispatch = useDispatch()

    const {thrift_accounts,token,account_key,role_id,loading,error_msg,user} = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
    const [tempLoading , setTempLoading] = useState(false)
    const [accountDetails,setAccountDetails] = useState(thrift_accounts[0])
    

    const handleSetKey = async (accountDetails:UserBranchModel) => {
        dispatch(setGeneralAction(types.SET_ACCOUNT_KEY,{
            'account_branch_key':item.account_branch.account_branch_key,
            'role_id': item.role_id,
            'role_text':item.role_text,
            'has_account_number' : item.account_branch.has_account_number,
            'thrift_account' : item
        }))
    }

    const setAccountKey = async () => {
        setTempLoading(true)
        if(item.account_branch_id !== 0){
            if(Number(item.status)===1){
                // activating loading spinner
                dispatch(setLoading({
                    isLoading : true,
                    isLoading_ : false,
                    isLoading_t : false
                }))
                //
                await handleSetKey(item)
            }else {
                setTempLoading(false)
                //0:  1:Activate, 2:Deactivate/Blocked,
                let x = Number(item.status) === 0 ? 'is yet to be  Unapproved' : 'has been Blocked'
                dispatch(setGeneralAction(types.SET_ERROR, `your account ${x}, please contact your cooperative admin/executive`))
            }
        }
        else{
            setTempLoading(false)
            dispatch(setGeneralAction(types.SET_ERROR,
              'You must select one of your Accounts before you can proceed'
            ))
        }
    }

    const handleLogout = async () => {
        setTempLoading(true)
        const postData = {
            token,
            accountKey:accountDetails.account_branch.account_branch_key,
            url:remote_url.logout,
            actionType: types.LOG_OUT,
            postData : [],
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            errorAction : types.LOG_OUT,
            loading :{
                isLoading :true,
                isLoading_ : false,
                loading_t : false
            }
        }
        dispatch(postRequest(postData))
    }



    //! checking if account key is not empty and token is not empty
    if(account_key !=='' && token!==''){
        //TODO normal user are not allowed to login
       if([1,2,3].includes(role_id)){

        //    return <Navigate to={url.dashboard}/>
        window.location.href = url.dashboard
       }
       else{
           dispatch(setGeneralAction(types.LOG_OUT, []))
         return  <Navigate to={url.signIn}/>
       }
    }



    //check if token is not empty
    if(!token){
        return  <Navigate to={url.signIn}/>
    }



    return( <AuthWrapper>
             <div className="card">
               <Header/>
                <div className="card-body">
                    {!loading && error_msg && <ErrorAlert msg={error_msg}/> }
                    <p className={'mb-3 text-dark text-center mx-auto'}>Please Select An Account</p>
                    <div className="row">

                        {
                        thrift_accounts.map((account:UserBranchModel) =>
                         <div className={`col-lg-3 col-sm-3 my-1`}
                              style={{cursor:'pointer'}}
                            key={account.id} 
                            onClick={()=> setItem(account)}
                            >
                             <div className={`text-center 
                                  ${item?.account_branch?.id===account?.account_branch?.id 
                                 ? 
                                 'text-primary border py-2 border-primary shadow'
                                 :
                                 'text-primary border py-2'} 
                                 `}
                                  style={{borderRadius:'1rem'}}
                             >

                                 <img src={account.account_branch.logo} alt="" height='60' width={'60'}
                                      className={`rounded-circle ${item?.account_branch?.id===account?.account_branch?.id ? 'rounded-circle':'rounded-circle'}`}
                                 />
                                 <p className="mb-0" title={account?.account_branch?.branch_name}>
                                     <code>{account?.account_branch?.branch_name.substring(0, 10) }</code>
                                 </p>
                             </div>

                        </div> )}
                     
                    </div>
                    {/*  */}
                </div>
                 <div className={'card-footer'}>
                     <div className="mb-3 text-center">
                         {
                             tempLoading
                                 ?
                                 <Spinner color={'primary'}/>
                                 :
                                 <button disabled={false} className="btn btn-primary" onClick={() => setAccountKey()}> Continue </button>
                         }

                     </div>
                 </div>
            </div>
        <div className="row mt-3">
            <div className="col-12 text-center">
                <p className="text-muted">Not you?
                    <Link to={'#0'} className="text-muted ms-1 "><b className={'text-muted'} onClick={()=>handleLogout()}><u>Logout</u></b></Link>
                </p>
            </div>
        </div>
                      
    </AuthWrapper>
    )

}


export default Accounts