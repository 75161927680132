import {
    AllThriftSavingsModel,
    AuditLogModel,
    ExpensesModel, LoanApplicationModel, LoanTypesModel, MembersModel,
    SubscriptionTransactionModel, ThriftBankAccModel, UserBranchModel
} from "./model/authModel";
import {formatStatusText, toNigerianCurrency} from "./redux/actions";


const $ = require('jquery')
// $.DataTable  = require( 'datatables.net')
$.DataTable = require('datatables.net-dt')
$.DataTable = require('datatables.net-responsive-dt')
$.Database = require('datatables.net-responsive-dt/css/responsive.dataTables.css')
//:::: start of buttons file
$.DataTable = require("datatables.net-buttons/js/buttons.html5.js") //loads the copy,pdf,csv buttons and makes it works
$.DataTable = require("datatables.net-buttons/js/buttons.print.js") //loads the print btn and makes it work
//::: end of button file
//datatables.net-bs5
$.DataTable = require('datatables.net-bs5');
//sidebar-enable
// $.DataTable  = require('datatables.net-buttons/js/buttons.colVis.js') //csv
// $.DataTable  = require( 'datatables.net-buttons/js/dataTables.buttons.js')

//loanSettingsDatatable
export const loansSettingsInitDatatable = (allLoansSettings: LoanTypesModel[], setLoanSetting: any,buttonText:string[],functionOne:any,functionTwo:any) => {
    const cols = [
        {data: 'name'}, //0
        {data: (a:LoanTypesModel) =>{
            return a.percent_interest + ' %'
            }}, //1
        {data: (a:LoanTypesModel)=>{
            return a.duration + ' month(s)'
            }}, //2
        {
            data: (a:LoanTypesModel)=>{
                return toNigerianCurrency(Number(a.minimum_savings_balance))
            }
        },
        {data:(a:LoanTypesModel)=>{
                return a.membership_period + ' month(s)'
            }},

        {data: 'multiples_of_savings'},


        {
            data: (a: LoanTypesModel) => {
                return (`
                            <a href="#0"
                               data-id="${a.id}"
                                class="action-icon test"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#loanSettingsEdit"
                                aria-controls="loanSettingsEdit">
                                <i class="mdi mdi-pencil"></i>
                            </a>`
                )
            }
        }
    ]

    generalDatatableConfigWithCustomButtons(allLoansSettings, 'loan-settings',
        cols,[1,'desc'],buttonText,functionOne,functionTwo)

    $(document).on("click", ".test", function (this: any) {
        const r = $(this).attr("data-id")
        let x = allLoansSettings.filter((item) => Number(item.id) === Number(r))

        if (x.length > 0) {
            setLoanSetting(x[0])
        }
    })
}

//savingsInitDatable
export const savingsInitDatable = (allThriftSavings: AllThriftSavingsModel[], setSavings: any, role_id: number,extraButtons:string[],extraBtnFunc:any,extraBtnTwoFunc:any,ordering:any) =>  {
    const cols = [
        // {data: 'user.name'},
        //0
        {data: (a:AllThriftSavingsModel)=> {
            return a.payment_channel==='card' ?
                a.payment_gateway_used === 1  ?
                    `<span class="text-primary">${a.transaction_reference}</span>` :
                `<span class="text-info">${a.transaction_reference}</span>`
                :
                    `<span class="text-dark">${a.transaction_reference}</span>`
            }

        },
        //1
        // {
        //     data: (a: AllThriftSavingsModel) => {
        //         return toNigerianCurrency(Number(a.actual_amount))
        //     }
        // },
        {
            data: (a: AllThriftSavingsModel) => {
                return  toNigerianCurrency(Number(a.thrift_amount))
            }
        },
        {data: 'payment_channel'}, //3

        {
            data: (a: AllThriftSavingsModel) => {
                return  a.created_at
            }
        },
        {
            data: (a: AllThriftSavingsModel) => {
                return a.paid_at ? a.paid_at : 'N/A'
            }
        },

        //4
        {
            data: function (a: AllThriftSavingsModel) {
                return a.payment_status === "1" && a.saving_transaction_status=== 1 ? `<span class="badge badge-outline-success">Paid</span>`
                    :
                    a.payment_channel === 'card' && a.payment_status==="0" && a.saving_transaction_status ===1 ?
                        `<a href="#0" data-id="${a.id}" class="badge badge-outline-info test" data-bs-toggle="offcanvas" data-bs-target="#savingsDetails"  aria-controls="savingsDetails"> requery </a>`
                        :
                        a.payment_status==='0' && a.saving_transaction_status===0 ? `<span class="badge badge-outline-warning">waiting for approval</span>`:
                        `<span class="badge badge-outline-danger">unpaid</span>`
            },
        },
        {
            data: function (a: AllThriftSavingsModel) {
                return (`
                    <a href="#0"
                       data-id="${a.id}"
                        class="action-icon test"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#savingsDetails"
                        aria-controls="savingsDetails">
                        <i class="mdi mdi-eye"></i>
                    </a>
                    <!-- edit  -->
                    ${a.payment_channel === 'card' ? ''
                    :
                    `
                    ${[1, 2].includes(role_id) && a.saving_transaction_status === 0 ?
                        `<a href="#0"
                        data-id="${a.id}"
                        class="action-icon test-edit"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#savingsEdit"
                        aria-controls="savingsEdit"
                    >
                        <i class="dripicons-document-edit"></i>
                    </a>`
                        :
                        ''
                    }
                    `}
                    <!--Delete  -->
                      ${a.payment_channel === 'card' ? ''
                    :
                    `
                    ${[1, 2].includes(role_id) && a.saving_transaction_status === 0 ?
                        `<a 
                            href="#0"
                            data-id="${a.id}"
                            class="action-icon text-danger test-delete"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#savingsDelete"
                            aria-controls="savingsDelete"
                        >
                            <i class="dripicons-trash"></i>
                        </a>`
                        :
                        ''
                    }
                    `}
                  
                `)
            },
        }
    ]
    generalDatatableConfigWithCustomButtons(
        allThriftSavings, 'products-datatable',
        cols,ordering,extraButtons,extraBtnFunc,extraBtnTwoFunc)

    $(document).on("click", ".test", function (this: any) {
        const r = $(this).attr("data-id")
        let x = allThriftSavings.filter((item) => item.id == r)
        if (x.length > 0) {
            setSavings(x[0], 'view_detail')
        }
    })
    //
    $(document).on("click", ".test-edit", function (this: any) {
        const r = $(this).attr("data-id")
        let x = allThriftSavings.filter((item) => item.id == r)
        if (x.length > 0) {
            setSavings(x[0], 'view_edit')
        }
    })

    $(document).on("click", ".test-delete", function (this: any) {
        const r = $(this).attr("data-id")
        let x = allThriftSavings.filter((item) => item.id == r)
        if (x.length > 0) {
            setSavings(x[0], 'delete')
        }
    })

    // $(document).on("click", ".text-btn", function (this: any) {
    //     extraBtnFunc()
    // })
}

//Expense
export const expensesInitDatatable = (expenses: ExpensesModel[], setExpenses: any,extraButtons:string[],extraFunctionOne:any,extraFunctionTwo:any) => {
    const cols = [
        {data: 'user.name'}, //0
        {data: 'transaction_reference'}, //1
        {
            data: function (a: ExpensesModel) {
                return a.transaction_type === 'income' ? '<span class="text-success">income</span>'
                    :
                    '<span class="text-danger">expense</span>'
            }
        },
        //2
        {data: (a:ExpensesModel) => {
            return toNigerianCurrency(a.thrift_amount)
            }}, //4
        {'data': 'date_created'},
        {
            data: function (a: ExpensesModel) {
                return a.payment_status === 1 ?
                    '<span class="badge bg-success">Paid</span>'
                    :
                    a.transaction_type==='expense'?'<span class="badge bg-danger">debit</span>':
                    ` <a href="#0" 
                        data-id="${a.id}" 
                        class="badge bg-warning test" 
                        data-bs-toggle="offcanvas" 
                        data-bs-target="#expenseDetails"  
                        aria-controls="expenseDetails">
                        requery 
                        </a>
                    `
            },
        },
        {
            data: function (a: ExpensesModel) {
                return (`
                         <a href="#0"
                            data-id="${a.id}"
                            class="badge bg-info test text-white"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#expenseDetails"
                            aria-controls="expenseDetails">
                            <i class="mdi mdi-eye"></i>
                         </a>`
                )
            },
        }
    ]

    generalDatatableConfigWithCustomButtons(expenses, 'products-datatable', cols,[1, 'desc'],extraButtons,extraFunctionOne,'')
    //
    $(document).on("click", ".test", function (this: any) {
        const r = $(this).attr("data-id")
        let x = expenses.filter((item) => Number(item.id) === Number(r))
        console.log(x);
        if (x.length > 0) {
            setExpenses(x[0])
        }
    })

}

//Audit Log
export const auditLogInitDatable = (auditLogs: AuditLogModel[], setAuditLog: any) => {
    const cols = [
        {
            data: (a:AuditLogModel) => {
                return a.user?.name??''
            }
        },

        //0
        {data: 'branch.branch_name'}, //1
        {
            data: function (a: AuditLogModel) {
                return a.message.substring(0, 55)
            }
        }, //2
        {data: 'created_at'}, //3
        {
            data: function (a: AllThriftSavingsModel) {
                return a.payment_status === "1" ? '<span class="badge bg-success">Paid</span>'
                    :
                    `<a 
                        href="#0" 
                        data-id="${a.id}" 
                        class="badge bg-warning test" 
                        data-bs-toggle="offcanvas"
                         data-bs-target="#auditLogDetails"  
                         aria-controls="auditLogDetails">
                         <i class="mdi mdi-eye"></i>
                    </a>`
            },
        },

    ]
    generalDatatableConfig(auditLogs, 'auditLogDataTable', cols)

    $(document).on("click", ".test", function (this: any) {
        const r = $(this).attr("data-id")
        let x = auditLogs.filter((item) => item.id == r)
        if (x.length > 0) {
            setAuditLog(x[0])
        }
    })
}

//Subscription Payment table
export const subInitDatatable = (subsTrans: SubscriptionTransactionModel[], setSubTransaction: any) => {
    const cols = [
        {data: 'subscription.sub_name'}, //0
        {data: 'transaction_reference'}, //1
        {data: (a: SubscriptionTransactionModel)=>{
            return toNigerianCurrency(Number(a.amount))
            }},
        {data: 'created_at'},
        {data: 'paid_at'},
        {
            data: function (a: SubscriptionTransactionModel) {
                   let status = formatStatusText(a.status)
                   return  `<span class="badge bg-${status.color}"> ${status.text} </span>`
                }
        },
        {
            data: function (a: SubscriptionTransactionModel) {
                return (`
                         <a href="#0"
                            data-id="${a.id}"
                            class="badge bg-info test text-white"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#subTransDetails"
                            aria-controls="subTransDetails">
                            <i class="mdi mdi-eye"></i>
                         </a>`
                )
            },
        }
    ]
    generalDatatableConfig(subsTrans, 'products-datatable', cols)
    //
    $(document).on("click", ".test", function (this: any) {
        const r = $(this).attr("data-id")
        let x = subsTrans.filter((item) => Number(item.id) === Number(r))
        if (x.length > 0) {
            setSubTransaction(x[0])
        }
    })
}

// BankListing
export const bankInitDatable = (banks: ThriftBankAccModel[], setBankDetail: any) => {
    //
    const purposeArray = [
        'All',
        'Savings only',
        'Loans only',
        'Expenses only',
        'Savings and Loans only',
        'Savings and Expenses only',
        'Loans and Expenses only'
    ]
    const cols = [
        {data: 'account_name'}, //0
        {data: 'account_number'}, //1
        {data: 'bank.name'},
        {
            data: (a: ThriftBankAccModel) => {
                return a.is_verified === 1 ? "<span class='badge bg-success'>verified</span>" : "<span class='badge bg-danger'>unverified</span>"
            }
        },
        {
            data: (a: ThriftBankAccModel) => {
                return a.status === 1 ? "<span class='badge bg-success'>active</span>" : "<span class='badge bg-danger'>inactive</span>"
            }
        },
        {
            data: (a: ThriftBankAccModel) => {
                return purposeArray[a.account_for]
            }
        },
        {
            data: (a: ThriftBankAccModel) => {
                return `<a href=''   
                        class="action-icon bankDetailsTest"
                        data-id="${a.id}"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#bankDetails"
                        aria-controls="bankDetails" >
                        <i class="mdi mdi-eye" title='view thrift bank details'></i>
                        </a>`
            }
        },
    ]

    generalDatatableConfig(banks, 'thriftsBankAccountListingDataTable', cols)
    //
    $(document).on("click", ".bankDetailsTest", function (this: any) {
        const r = $(this).attr("data-id")
        let x = banks.filter((item) => Number(item.id) === Number(r))
        if (x.length > 0) {
            setBankDetail(x[0])
        }
    })
}

//Thrift Members
export const membersInitDatatable = (users: MembersModel[], setUserDetails: any, saveNow: any,
                                     applyForLoan: any,buttonTexts:string[],functionOne:any,functionTwo:any,editUserDetails:any) => {
    const cols = [
        {
            data: function (a: MembersModel) {
                return `<img src=${a.user.profile_image !== '' ? a.user.profile_image : a.user.alternate_profile_image} 
                    alt="table-user" class="me-2 rounded-circle" height="40" width="40"/>`
            }
        },
        {
            data: function (a: MembersModel) {
                return `${a?.user?.name}`
            }
        },
        {data: 'user.email'},
        {data: "user.phone_no"},
        {
            data: function (a: MembersModel) {
                return a.savings_sum_thrift_amount
                    ?
                    toNigerianCurrency(Number(a.savings_sum_thrift_amount))
                    :
                    'N/A'
            }
        },
        {'data': 'role_text'},
        {
            data: function (a: MembersModel) {
                return `
                <div class="dropdown">
                    <a href="#" class="dropdown-toggle arrow-none card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="mdi mdi-dots-horizontal"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-end" style="">
                        <a href="#"
                          title = 'Add new Savings'
                          class='dropdown-item saveNow text-primary'
                          data-id="${a.id}"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#savingsForm"
                          aria-controls="savingsForm"
                        >
                            Add new Savings
                        </a>
                        <div class="dropdown-divider"></div>
                        <a
                            href="#loansForm"
                            class="dropdown-item loan loanNow text-info"
                            title = 'Apply for Loans'
                            data-id="${a.id}"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#loansForm"
                            aria-controls="loansForm"
                        >
                            Apply for Loans
                        </a>
                        <div class="dropdown-divider"></div>
                        
                         <a
                            href="#editUser"
                            class="dropdown-item editUser  text-info"
                            title = 'Edit User Details'
                            data-id="${a.id}"
                        >
                            Edit User Status
                        </a>
                    </div>
                </div>
               `
            }
        }
    ]
/*
<a
    href="#detailsForm"
    class="dropdown-item test text-info"
    title = 'View User Details'
    data-id="${a.id}"
    data-bs-toggle="offcanvas"
    data-bs-target="#detailsForm"
    aria-controls="detailsForm"
>
    View User Details
</a>
 */
    generalDatatableConfigWithCustomButtons(users, 'products-datatable', cols,[1,'desc'],buttonTexts,functionOne,functionTwo)
    //
    $(document).on("click", ".test", function (this: any) {
        const r = $(this).attr("data-id")
        let x = users.filter((item) => Number(item.id) === Number(r))
        if (x.length > 0) {
            setUserDetails(x[0], r)
        }
    })
    //
    $(document).on("click", ".saveNow", function (this: any) {
        const r = $(this).attr("data-id")
        let x = users.filter((item) => Number(item.id) === Number(r))
        if (x.length > 0) {
            saveNow(x[0], r)
        }
    })
    //
    $(document).on("click", ".loanNow", function (this: any) {
        const r = $(this).attr("data-id")
        let x = users.filter((item) => Number(item.id) === Number(r))
        if (x.length > 0) {
            applyForLoan(x[0], r)
        }
    })
    //Edit User Status
    $(document).on("click", ".editUser", function (this: any) {
        const r = $(this).attr("data-id")
        let x = users.filter((item) => Number(item.id) === Number(r))
        if (x.length > 0) {
            editUserDetails(x[0], r)
        }
    })
}

//Loan Application
export const loanApplicationInitDatatable = (loans_app: LoanApplicationModel[], setLoanAppDetails: any,btnNames:string[],funcOne:any,funcTwo:any) => {
    const cols = [
        {
            data: function (a: LoanApplicationModel) {
                return `<img src=${a.user.profile_image} alt="table-user" class="me-2 rounded-circle" height="48"/>
                     <a href="#" class="text-body fw-semibold">${a?.user?.name}</a>`
            }
        },
        {data: 'application_reference_no'},
        {
            data: function (a: LoanApplicationModel) {
                return Number(a.amount).toLocaleString()
            }
        },
        {
            data: function (a: LoanApplicationModel) {
                return Number(a.expected_amount).toLocaleString()
            }
        },
        {
            data: function (a: LoanApplicationModel) {
                return a.loans_transaction_sum_amount ? a.loans_transaction_sum_amount.toLocaleString() : 0
            }
        },
        {data: 'application_date'},
        {
            data: function (a: LoanApplicationModel) {
                return `
                    <a href="#0"
                        class="test btn btn-outline-dark btn-sm "
                        data-id="${a.id}"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#loansSavingDetails"
                        aria-controls="loansSavingDetails"
                    >
<!--                        <i class="mdi mdi-eye" title='view loan repayment details'></i>-->
                        view 
                    </a>
                `
            },
        },
    ]
    generalDatatableConfigWithCustomButtons(loans_app, 'products-datatable', cols,[1,'desc'],btnNames,funcOne,funcTwo)
    $(document).on("click", ".test", function (this: any) {
        const r = $(this).attr("data-id")
        let x = loans_app.filter((item) => Number(item.id) === Number(r))
        if (x.length > 0) {
            setLoanAppDetails(x[0])
        }
    })
}

//
export const branchInitDatatable = (branches: UserBranchModel[], setBranchDetail: any,btnTexts:string[],functionOne:any,functionTwo:any) => {
    const cols = [
        {data: "account_branch.branch_name"},
        {
            data: function (x: UserBranchModel) {
                return x.account_branch.main_branch === 1 ? 'Main' : "-"
            }
        },
        {
            data: function (x: UserBranchModel) {
                return x.account_branch.email ? x.account_branch.email : 'N/A'
            }
        },
        {
            data: function (x: UserBranchModel) {
                return x.account_branch.phone_no ? x.account_branch.phone_no : 'N/A'
            }
        },
        {
            data: function (x: UserBranchModel) {
                return x.account_branch.business_registration_no ? x.account_branch.business_registration_no : 'N/A'
            }
        },
        {
            data: function (x: UserBranchModel) {
                return x.account_branch.is_profile_complete === 1 ? 'completed' : 'incomplete'
            }
        },
    ]

    generalDatatableConfigWithCustomButtons(branches, 'products-datatable', cols,[1,'desc'],btnTexts,functionOne,functionTwo)

    // $(document).on("click",".test",function(this:any){
    //     const r =  $(this).attr("data-id")
    //     let x =   loans_app.filter((item) => Number(item.id) === Number(r))
    //     if(x.length>0){
    //         setLoanAppDetails(x[0])
    //     }
    // })
}


// const dispatch = useDispatch()
const generalDatatableConfig = (data: any, table_id: string, cols: any,orderBy=[1, 'desc']) => {
    let isLandScape = true
    let exportOptionsIndex: number[] = [0, 1, 2, 3, 4, 5]
    let exportTitle = ''
    let fromDate = ''
    let toDate = ''
    let total = 0
    let other_title = ''

    $(document).ready(function () {
        let idInit = $(`#${table_id}`)
        idInit.DataTable().clear().destroy()
        idInit.DataTable({
            language: {
                paginate: {
                    previous: "<i class='mdi mdi-chevron-left'>",
                    next: "<i class='mdi mdi-chevron-right'>",
                    // first : "1",
                    // last: "2"
                },
                info: 'Showing item _START_ to _END_ of _TOTAL_',
                lengthMenu:
                    `Display <select class=\'form-select form-select-sm ms-1 me-1\'>
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="-1">All</option>
                    </select> 
                    savings
                    `,
            },
            pageLength: 10,
            data: data,
            // dom: 'Bfrtip',
            dom: '<"container-fluid py-2"<"row"<"col-8"B><"col-4"f>>>rtip',
            buttons: [
                /*
                 {
                    extend: "copy",
                    text: 'COPY',
                    footer: true,
                    orientation: isLandScape ? "landscape" : "portrait",
                    pageSize: isLandScape ? "LEGAL" : "A4",
                    exportOptions: {
                        columns: exportOptionsIndex,
                    },
                    title: exportTitle,
                    className: "btn btn-primary btn-sm  mx-1  my-2",
                    header: true,
                    messageTop: `Filter Result : ${fromDate} -  ${toDate}`,
                    messageBottom: `\n\n Total :₦${Number(total).toLocaleString()} \n\n ${exportTitle}`,
                    filename: `${other_title}_data_for_${fromDate}_${toDate}_`,
                },
                 */
                //
                {
                    extend: "excel",
                    footer: true,
                    orientation: isLandScape ? "landscape" : "portrait",
                    pageSize: isLandScape ? "LEGAL" : "A4",
                    exportOptions: {
                        columns: exportOptionsIndex,
                    },
                    title: exportTitle,
                    className: "btn-sm btn-info mx-1  my-2",
                    header: true,
                    messageTop: `Filter Result : ${fromDate} -  ${toDate}`,
                    messageBottom: `\n\n Total :₦${Number(total).toLocaleString()} \n\n ${exportTitle}`,
                    filename: `${other_title}_data_for_${fromDate}_${toDate}_`,
                },

                /*
                    {
                        extend: "pdf",
                        footer: true,
                        orientation: isLandScape ? "landscape" : "portrait",
                        pageSize: isLandScape ? "LEGAL" : "A4",
                        exportOptions: {
                            columns: exportOptionsIndex,
                        },
                        title: exportTitle,
                        className: "btn-sm btn-primary btn-sm mr-1  my-2",
                        header: true,
                        messageTop: `Filter Result : ${fromDate} -  ${toDate}`,
                        messageBottom: `\n\n Total :₦${Number(total).toLocaleString()} \n\n ${exportTitle}`,
                        filename: `${other_title}_data_for_${fromDate}_${toDate}_`,
                    },

                 */

                {
                    extend: "csv",
                    footer: true,
                    orientation: isLandScape ? "landscape" : "portrait",
                    pageSize: isLandScape ? "LEGAL" : "A4",
                    exportOptions: {
                        columns: exportOptionsIndex,
                    },
                    title: exportTitle,
                    className: "btn btn-info btn-sm mx-1  my-2",
                    header: true,
                    messageTop: `Filter Result : ${fromDate} -  ${toDate}`,
                    messageBottom: `\n\n Total :₦${Number(total).toLocaleString()} \n\n ${exportTitle}`,
                    filename: `${other_title}_data_for_${fromDate}_${toDate}_`,
                },
                //
                {
                    extend: "print",
                    text: "PRINT",
                    footer: true,
                    orientation: isLandScape ? "landscape" : "portrait",
                    pageSize: isLandScape ? "LEGAL" : "A4",
                    exportOptions: {
                        columns: exportOptionsIndex,
                    },
                    title: exportTitle,
                    className: "btn btn-light btn-sm  my-2",
                    header: true,
                    messageTop: `Filter Result : ${fromDate} -  ${toDate}`,
                    messageBottom: `\n\n Total :₦${Number(total).toLocaleString()} \n\n ${exportTitle}`,
                    filename: `${other_title}_data_for_${fromDate}_${toDate}_`,
                },//
            ],
            columns: cols,
            select: {style: 'multi'},
            order: [[1, 'desc']],
            responsivePriority: 1,
            responsive: true,
            drawCallback: () => (
                $('.dataTables_paginate > .pagination').addClass('pagination-rounded'),
                    $('#products-datatable_length label').addClass('form-label')
            ),
        })
        //
    })
}





export const generalDatatableConfigWithCustomButtons = (data: any, table_id: string, cols: any,orderBy=[1, 'desc'],btnName:string[],innerBtnFuncOne:any,innerBtnFuncTwo:any) => {
    let isLandScape = true
    let exportOptionsIndex: number[] = [0, 1, 2, 3, 4, 5]
    let exportTitle = ''
    let fromDate = ''
    let toDate = ''
    let total = 0
    let other_title = ''

    $(document).ready(function () {
        let idInit = $(`#${table_id}`)
        idInit.DataTable().clear().destroy()
        idInit.DataTable({
            language: {
                paginate: {
                    previous: "<i class='mdi mdi-chevron-left'>",
                    next: "<i class='mdi mdi-chevron-right'>",
                    // previous: "<i class='fa fa-arrow-left'>",
                    // next: "<i class='fa fa-arrow-right'>",
                },
                info: 'Showing item _START_ to _END_ of _TOTAL_',
                // info: `Showing ${table_name} _START_ to _END_ of _TOTAL_`,
                lengthMenu:
                    `Display <select class='form-select form-select-sm ms-1 me-1'>
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="-1">All</option>
                    </select> 
                    savings
                    `,
            },
            pageLength: 10,
            data: data,
            dom: '<"container-fluid py-2"<"row"<"col-md-8 col-sm-12"B><"col-md-4 col-sm-12"f>>>rtip',
            buttons: [
                {
                    text: btnName[0]??"",
                    className:btnName[0] ? "btn btn-outline-primary  btn-sm  mx-1 my-2":'d-none',
                    action: (e:any, dt:any, node:any, config:any) => {
                        btnName[0] && innerBtnFuncOne(true)
                    },
                },

                {
                    text: btnName[1]??"",
                    className:btnName[1] ? "btn btn-outline-dark  btn-sm  mx-1 my-2":'d-none',
                    action: (e:any, dt:any, node:any, config:any) => {
                        btnName[1] && innerBtnFuncTwo(true)
                    }
                },
                {
                    extend: "csv",
                    text: 'Export Data',
                    footer: true,
                    orientation: isLandScape ? "landscape" : "portrait",
                    pageSize: isLandScape ? "LEGAL" : "A4",
                    exportOptions: {
                        columns: exportOptionsIndex,
                    },
                    title: exportTitle,
                    className: "btn btn-primary btn-sm mx-1  my-2",
                    header: true,
                    messageTop: `Filter Result : ${fromDate} -  ${toDate}`,
                    messageBottom: `\n\n Total :₦${Number(total).toLocaleString()} \n\n ${exportTitle}`,
                    filename: `${other_title}_data_for_${fromDate}_${toDate}_`,
                },
                {
                    extend: "print",
                    text: "Print Data",
                    footer: true,
                    orientation: isLandScape ? "landscape" : "portrait",
                    pageSize: isLandScape ? "LEGAL" : "A4",
                    exportOptions: {
                        columns: exportOptionsIndex,
                    },
                    title: exportTitle,
                    className: "btn btn-dark btn-sm  my-2",
                    header: true,
                    messageTop: `Filter Result : ${fromDate} -  ${toDate}`,
                    messageBottom: `\n\n Total :₦${Number(total).toLocaleString()} \n\n ${exportTitle}`,
                    filename: `${other_title}_data_for_${fromDate}_${toDate}_`,
                },

            ],
            columns: cols,
            select: {style: 'multi'},
            order: [orderBy],
            responsivePriority: 1,
            responsive: true,
            drawCallback: () => (
                $('.dataTables_paginate > .pagination').addClass('pagination-rounded'),
                    $('#products-datatable_length label').addClass('form-label')
            ),
        })
        //
    })
}


