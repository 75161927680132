import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../../config/redux/reducers";
import {AuthState} from "../../../../../config/redux/reducers/tmsAuthReducer";
import React, {useEffect, useState} from "react";
import {remote_url} from "../../../../../config/urls/remote";
import {types} from "../../../../../config/redux/types";
import {copyYourLink, getRequest, postRequestFile} from "../../../../../config/redux/actions";

import ConfirmApproval from "../ConfirmApproval";
import {ErrorAlert, SuccessAlert, WarningAlert} from "../../../../../components/alert";
import {GeneralFormInput, HelperText, ImagePreviewContainer, LabelWrapper} from "../../../../../components/form";
import {Link} from "react-router-dom";
import {CustomModalWrapper} from "../../../_components";
import {appConfig} from "../../../../../config/config";


interface TabProps {
    // children : React.ReactNode
    setNextTab : any
}

type FormValues ={
    logo : string,
    id_type_id : string,
    id_number : string,
    business_registration_no : string,
    transaction_fee_bearer : string,
    //
    branch_name : string,
    email : string,
    phone_no : string,
    country_id : string,
    state_id : string,
    city : string,
    address : string,
    business_registration_type:string,
    thrift_document : string,
    payment_gateway : string,

}
const GeneralDetails:React.FC<TabProps> = ({setNextTab}) => {

    const { register, handleSubmit, formState: { errors },
        reset } = useForm<FormValues>()
    const {gateways,thrift_account,loading_,token,account_key,success_msg,error_msg,countries,allStates,business_registration_types,user,role_id} = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)

    const [image, setImage] = useState({preview:thrift_account?.account_branch?.logo})
    const [imgError, setImgError] = useState('')

    const dispatch  = useDispatch()
    //
    useEffect(() => {
        getThriftAccountDetails();
        if(thrift_account?.account_branch?.country_id){
            getStatesUnderACountry(thrift_account?.account_branch?.country_id)
        }
    },[])

    //
    const onSubmit = (data:FormValues) => {

        let finalLogoFile = data.logo===undefined ? 'no_logo_attached' : data.logo[0]
        // let finalDocFile = data.thrift_document[0]=== undefined ? 'no_document_attached' : data.thrift_document[0]

        const reqData = new FormData()
        reqData.append('address',data.address)
        //branch_name
        reqData.append('city',data.city)
        reqData.append('country_id',data.country_id)
        reqData.append('email',data.email)
        reqData.append('logo',finalLogoFile)
        // reqData.append('thrift_document',finalDocFile)
        reqData.append('transaction_fee_bearer',data.transaction_fee_bearer)
        reqData.append('phone_no',data.phone_no)
        reqData.append('state_id',data.state_id)
        reqData.append('bussiness_registration_no',data.business_registration_no)
        reqData.append('bussiness_registration_type',data.business_registration_type)
        reqData.append('payment_gateway',data.payment_gateway)

        const postData = {
            token,
            url: remote_url.updateAccountDetails,
            actionType : types.UPDATE_ACC_DETAILS,
            postData : reqData,
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            errorAction : types.SET_ERROR,
            accountKey: account_key,
            loading :{
                isLoading :true,
                isLoading_ : false,
                isLoading_t : false
            }
            // reset
        }
        //
        dispatch(postRequestFile(postData))

    }
    //:::
    const getStatesUnderACountry = (id:string) => {
        if(id !==''){
            const postData = {
                token,
                url: remote_url.getStates + id,
                actionType : types.GET_STATES,
                postData : [],
                secretData : {
                    email:user.email,
                    mac_id :user.mac_id,
                    r_id: role_id
                },
                errorAction : types.SET_ERROR,
                accountKey: account_key,
                loading :{
                    isLoading :false,
                    isLoading_ : true,
                    isLoading_t : false
                }
            }
            dispatch(getRequest(postData))
        }
        //
        else{

        }
    }
    //::

    // ::
    const handleChange = (e:any) => {
        const size = e.target.files[0]['size']
        const type = e.target.files[0]['type'] //image/jpeg @image/png
        // console.log(size)
        // console.log(type);
        if (size <= 15000000) {
            if (type === 'image/jpeg' || type === 'image/png') {
                setImgError('')
                setImage({preview: URL.createObjectURL(e.target.files[0])})
            } else {
                setImgError('this file type is not allowed')
            }
        } else {
            setImgError('image is too large')
        }
    }
    //thrift_document
    //:::
    const getThriftAccountDetails = () => {
        const postData = {
            token,
            url: remote_url.getThriftAccountDetails,
            actionType : types.UPDATE_ACC_DETAILS,
            postData : [],
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            errorAction : types.SET_ERROR,
            accountKey: account_key,
            loading :{
                isLoading :false,
                isLoading_ : false,
                isLoading_t : false
            }
        }
        dispatch(getRequest(postData))

    }

    return (
        <>
            <div className="container-fluid">


                {
                    thrift_account?.account_branch?.is_profile_complete === 1 && thrift_account?.account_branch?.business_reg_document !== null &&
                    thrift_account?.status === 2 && <WarningAlert type={'danger'} icon={'warning'}
                                                                  msg={'You are yet to Submit your Profile for Approval, Kindly make use of the button below'}/>
                }

                {success_msg && <SuccessAlert  msg={success_msg}/>}
                {error_msg && <ErrorAlert msg={error_msg}/>}

                <div className="row">
                    <div className="col-12">
                        <div className="">

                            <div className="">
                                <form onSubmit={handleSubmit(onSubmit)}>

                                    <div className="row">
                                        <div className="col-xl-6">
                                            <div className="mb-3">
                                                <LabelWrapper label_title={'Members Registration Link'} html_for={'MembersRegistrationLink'}/>
                                                <HelperText text={'Click on the copy icon to copy the members  registration link '} fontSize={'14'}/>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        type="text"
                                                        id="password"
                                                        readOnly={true}
                                                        value={`${appConfig.frontend_url}/create-account/${btoa(account_key)}/${btoa(Date())}`}
                                                        className="form-control"/>
                                                        <div
                                                            className="input-group-text"
                                                             style={{cursor:'pointer'}}
                                                             data-password="false"
                                                             onClick={()=>copyYourLink(`${appConfig.frontend_url}/create-account/${btoa(account_key)}/${btoa(Date())}`,dispatch,'Members Registration Link Copied')}
                                                             title='click me to copy registration link for new member'>
                                                            <span className="uil uil-copy-alt"></span>
                                                        </div>
                                                </div>
                                            </div>

                                            {/*  */}
                                            <div className="mb-3">
                                                <label htmlFor="projectname" className="form-label">Account Name</label>
                                                <input type="text"  className="form-control"
                                                       defaultValue={thrift_account?.account_branch?.branch_name}
                                                       placeholder="Enter Thrift Account name"
                                                       readOnly = {true}
                                                       {...register('branch_name',{
                                                           required: '*Branch name is Required',
                                                           minLength: { value: 3, message: 'Your Thrift name  is too Short' },
                                                       })}
                                                />
                                            </div>
                                            {/* Email Address */}
                                            <div className="mb-3">
                                                <label htmlFor="projectname" className="form-label">Email Address</label>
                                                <input
                                                    type="email"
                                                    readOnly= {thrift_account?.account_branch?.can_edit_email !== 1}
                                                    defaultValue={thrift_account?.account_branch?.email}
                                                    className={`form-control ${errors.email && 'is-invalid'}`}
                                                    placeholder="Enter Thrift Email Address"
                                                    {...register('email',{
                                                        required: '*Email Address is Required',
                                                    })}
                                                />
                                                {errors.email  && (<div className="invalid-feedback">{errors.email.message}</div>)}
                                            </div>
                                            {/* phone_no */}
                                            <div className="mb-3">
                                                <label htmlFor="projectname" className="form-label">Phone Number</label>
                                                <input type="text"
                                                       defaultValue={thrift_account?.account_branch?.phone_no}
                                                       readOnly= {thrift_account?.account_branch?.can_edit_phone_no !== 1}
                                                       className={`form-control ${errors.phone_no && 'is-invalid'}`}
                                                       placeholder="Enter Thrift Account name"
                                                       {...register('phone_no',{
                                                           required: '*Phone Number is Required',
                                                           minLength: { value: 11, message: 'Phone  is too Short' },
                                                           maxLength: { value: 12, message: 'Phone  is too Long' },
                                                       })}
                                                />
                                                {errors.phone_no  && (<div className="invalid-feedback">{errors.phone_no.message}</div>)}
                                            </div>
                                            {/*country  */}
                                            <div className="mb-3">
                                                <label htmlFor="project-overview" className="form-label">Country</label>
                                                <select
                                                    defaultValue={thrift_account?.account_branch?.country_id}
                                                    aria-readonly={thrift_account?.account_branch?.can_edit_country === 0 || thrift_account.account_branch.can_edit===0}
                                                    className={`form-select select2 ${errors.country_id && 'is-invalid'}`}
                                                    {...register('country_id',{
                                                        required: '*Country field is Required',
                                                        onChange : (e) => getStatesUnderACountry(e.target.value)
                                                    })}
                                                >
                                                    <option value={''}>Select Country</option>
                                                    {countries?.map((country) => <option
                                                        {...Number(thrift_account.account_branch.country_id)=== Number(country.id) ? 'selected':''}
                                                        key={country.id} value={country.id}>
                                                        {country?.country_name}</option> )}
                                                </select>
                                                {errors.country_id  && (<div className="invalid-feedback">{errors.country_id.message}</div>)}
                                            </div>
                                            {/* state */}
                                            <div className="mb-3">
                                                <label htmlFor="project-overview" className="form-label">State
                                                    {loading_ && <small className={'text-success'}>fetching state, please wait...</small> }
                                                </label>
                                                <select
                                                    // disabled= {thrift_account?.account_branch?.can_edit_country !== 1}
                                                    aria-readonly={thrift_account?.account_branch?.can_edit_country === 0 || thrift_account.account_branch.can_edit===0}
                                                    defaultValue={thrift_account?.account_branch?.state_id}
                                                    className={`form-select ${errors.state_id && 'is-invalid'}`}
                                                    data-toggle="select2"
                                                    {...register('state_id',{required: '*state field is required'})}>
                                                    <option value={''}>Select State </option>
                                                    {
                                                        allStates?.map((state)=>
                                                            <option value={state.id} key={state.id}>
                                                                {state.state_name}
                                                            </option>
                                                        )
                                                    }
                                                </select>
                                                {errors.state_id  && (<div className="invalid-feedback">{errors.state_id.message}</div>)}
                                            </div>
                                            {/*city  */}
                                            <div className="mb-3">
                                                <label htmlFor="projectname" className="form-label">City</label>
                                                <input
                                                    type="text"
                                                    readOnly= {thrift_account?.account_branch?.can_edit_city !== 1}
                                                    className={`form-control ${errors.city && 'is-invalid'}`}
                                                    placeholder="enter thrift account city"
                                                    defaultValue={thrift_account?.account_branch?.city}
                                                    {...register('city',{
                                                        required: '*City is Required',
                                                        minLength: { value: 2, message: 'City is too Short' },
                                                    })}
                                                />
                                                {errors.city  && (<div className="invalid-feedback">{errors.city.message}</div>)}
                                            </div>
                                            {/* Address  */}
                                            <div className="mb-3">
                                                <label htmlFor="project-overview" className="form-label">Street Address</label>
                                                <textarea
                                                    readOnly= {thrift_account?.account_branch?.can_edit_address !== 1}
                                                    className={`form-control ${errors.address && 'is-invalid'}`}
                                                    rows={5}
                                                    placeholder="Enter address.."
                                                    defaultValue={thrift_account?.account_branch?.address}
                                                    {...register('address',{
                                                        required: '*address is required',
                                                        minLength: { value: 6, message: 'the address entered looks short/invalid' },
                                                    })}
                                                >
                                          </textarea>
                                                {errors.address  && (<div className="invalid-feedback">{errors.address.message}</div>)}
                                            </div>
                                        </div>
                                        {/* Right has Side */}

                                        <div className="col-xl-6">
                                            {/* logo */}
                                            <div className="mb-3 mt-3 mt-xl-0">
                                                <LabelWrapper label_title={'Cooperative  Logo'} html_for={'Account Logo'}/>
                                                <HelperText text={'Recommended account logo size 400x400 (px).'} fontSize={'14'}/>
                                                {imgError  && (<div className="invalid-feedback">{imgError}</div>)}
                                                <div className="fallback">
                                                    <input
                                                        type="file"
                                                        className={`form-control ${imgError && 'is-invalid'}`}
                                                        {...register('logo',{
                                                            onChange : (e) => handleChange(e)
                                                        })}
                                                        disabled= {thrift_account?.account_branch?.can_edit_phone_no !== 1}
                                                    />
                                                </div>
                                                <ImagePreviewContainer img={image.preview}/>
                                            </div>
                                            {/* end of logo */}

                                            <div className="mb-3">
                                                <label htmlFor="project-overview" className="form-label">Thrift Registration Document Type</label>
                                                <select
                                                    disabled= {thrift_account?.account_branch?.can_edit !== 1}
                                                    defaultValue={thrift_account?.account_branch?.business_registration_type}
                                                    className={`form-select ${errors.business_registration_type && 'is-invalid'}`}
                                                    data-toggle="select2"
                                                    {...register('business_registration_type',{
                                                        required: '*business registration type is required',
                                                    })}>
                                                    <option value={''}>Select Cooperative Registration Type</option>
                                                    {business_registration_types?.map((item)=>
                                                        <option key={item.key} value={item.key}>{item.name}</option>)}
                                                </select>
                                                {errors.business_registration_type  && (<div className="invalid-feedback">{errors.business_registration_type.message}</div>)}
                                            </div>
                                            {/* business registration number  :: business_registration_no*/}
                                            <div className='mb-2'>
                                                <label>Thrift Registration Document ID No</label>
                                                <input
                                                    type="text"
                                                    readOnly= {thrift_account?.account_branch?.can_edit_phone_no !== 1}
                                                    defaultValue={thrift_account?.account_branch?.business_registration_no}
                                                    className={`form-control ${errors.business_registration_no && 'is-invalid'}`}
                                                    placeholder='registration no/ c.a.c registration number'
                                                    {...register('business_registration_no',{
                                                        required: '*business registration no is required',
                                                    })}
                                                />
                                                {errors.business_registration_no  && (<div className="invalid-feedback">{errors.business_registration_no.message}</div>)}
                                            </div>



                                            <GeneralFormInput
                                                type={'select'}
                                                register={register}
                                                label={"Who Bear's the Transaction fee"}
                                                fieldName={'transaction_fee_bearer'}
                                                errors={errors.transaction_fee_bearer?.message}
                                                defaultVal={thrift_account?.account_branch?.transaction_fee_bearer}
                                                disabled= {Number(thrift_account?.account_branch?.can_edit) === 0}
                                            >
                                                <>
                                                    <option value={''}>Select transaction bearer</option>
                                                    <option value={'member'}>Member</option>
                                                    <option value={'thrift'}>Thrift Society</option>
                                                </>

                                            </GeneralFormInput>



                                            <GeneralFormInput
                                                type={'select'}
                                                register={register}
                                                label={"Payment Gateway"}
                                                fieldName={'payment_gateway'}
                                                errors={errors.payment_gateway?.message}
                                                defaultVal={thrift_account?.account_branch?.payment_gateway}
                                                disabled= {thrift_account?.account_branch?.can_edit === 0}
                                            >
                                                <>
                                                    <option value={''}>Select your preferred Payment Gateway</option>
                                                    {gateways.map((item,i=0)=>
                                                        <option  key={i+1} value={item.key}>{item.name}</option>)
                                                    }
                                                </>
                                            </GeneralFormInput>



                                            {/*|| imgError !=='' || docError !==''*/}
                                            <div className='mt-3'>
                                                <button
                                                    className='btn btn-primary' type='submit'
                                                    disabled={(thrift_account.account_branch.can_edit===0) ?? ([1,2].includes(thrift_account?.account_branch?.status)) }>
                                                    Update Details
                                                </button>
                                                {
                                                    thrift_account.account_branch.business_registration_no &&
                                                    <Link to={''}  onClick={()=>setNextTab('documents')} className='btn btn-outline-primary ms-2'>
                                                        Next
                                                    </Link>
                                                }




                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}



export default GeneralDetails