import {useForm} from "react-hook-form"
import {remote_url} from "../../../../../config/urls/remote";
import {types} from "../../../../../config/redux/types";
import {postRequest, postRequestFile} from "../../../../../config/redux/actions";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../../config/redux/reducers";
import {AuthState} from "../../../../../config/redux/reducers/tmsAuthReducer";
import {ErrorAlert, SuccessAlert} from "../../../../../components/alert";
import String from "../../../../../config/strings/string";
import {MemebersState} from "../../../../../config/redux/reducers/tmsMemeberReducerState";
import {useEffect, useState} from "react";

type FormValues = {
    label: string,
    message: string,
    receiver: string,
    title: string,
    type: string, //0: sms , 1:email
    attachment: string,
    total_no_of_receiver: string
}
const NewEmailSmsForm = () => {
    const {register, handleSubmit, formState: {errors}, reset} = useForm<FormValues>()
    const {users} = useSelector<AppState, MemebersState>((state) => state.tmsMemeberReducerState)


    const {
        token,
        account_key,
        success_msg,
        error_msg,
        user,
        role_id
    } = useSelector<AppState, AuthState>((state) => state.tmsAuthReducer)

    const dispatch = useDispatch();


    useEffect(() => {
        getMembers()
    }, [])


    const onSubmit = (data: FormValues) => {
        //CREATE_EMAIL_SMS
        console.log(data)
        const reqData = new FormData()
        reqData.append('label', data.label)
        reqData.append('message', data.message)
        reqData.append('receiver', data.receiver)
        reqData.append('title', data.title);
        reqData.append('type', data.type)
        reqData.append('total_no_of_receiver', (data?.receiver?.length).toString());

        const postData = {
            token,
            url: remote_url.sendEmailOrSms,
            actionType: types.CREATE_EMAIL_SMS,
            postData: reqData,
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            errorAction: types.SET_ERROR,
            accountKey: account_key,
            loading: {
                isLoading: true,
                isLoading_: true,
                isLoading_t: false
            }
        }
        //
        // dispatch(postRequestFile(postData))
    }

    const getMembers = () => {
        //
        const postData = {
            token,
            url: remote_url.thriftMemebers,
            actionType: types.GET_USERS,
            postData: {},
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            errorAction: types.SET_ERROR,
            accountKey: account_key,
            loading: {
                isLoading: false,
                isLoading_: false,
                isLoading_t: true
            }
        }
        dispatch(postRequest(postData))
    }


    return (
        <div id="compose-modal" className="modal fade" tabIndex={-1} role="dialog"
             aria-labelledby="compose-header-modalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header modal-colored-header bg-primary">
                        <h4 className="modal-title" id="compose-header-modalLabel">Send Email to Members</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="p-1">



                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="modal-body px-3 pt-3 pb-0">
                                {success_msg && <SuccessAlert msg={success_msg}/>}
                                {error_msg && <ErrorAlert msg={error_msg}/>}
                                <div className="mb-2">
                                    <label htmlFor="type of notification" className="form-label">Email</label>
                                    <select
                                            // disabled={true}
                                            className = {`form-select ${errors.type && 'is-invalid'}`}
                                            {...register('type' )}
                                            defaultValue={'1'}>
                                        <option  value={"1"}> Email </option>
                                    </select>
                                    {errors.type && <div className={'invalid-feedback'}>{errors.type.message}</div>}
                                </div>

                                {/*receiver */}
                                <div className="mb-2">
                                        <label htmlFor="msgto" className="form-label">Receiver</label>
                                            <select
                                                multiple
                                                className = {`form-select select2`}
                                                defaultValue={[]}
                                                {...register('receiver')}

                                                    // onChange={(e) => console.log(e.target.value) }
                                            >
                                                <option value={''}>Select Users</option>
                                                {users.map((user) =>
                                                    <option key={user.id} value={user.user.email}>
                                                        {` ${user?.user?.name} | ${user?.user?.email} `}
                                                    </option>
                                                )}
                                            </select>
                                    {errors.receiver && <div className={'invalid-feedback'}>{errors.receiver.message}</div>}
                                </div>

                                <div className="mb-2">
                                    <label htmlFor="msgto" className="form-label">Label</label>
                                    <select
                                        className={`form-select ${errors.label  &&  'is-invalid' }`}
                                        {...register('label', {
                                            required: '*receiver is Required',
                                        })}
                                    >
                                        <option value={'savings'}>Savings</option>
                                        <option value={''}></option>
                                        <option value={''}></option>
                                        <option value={''}></option>
                                    </select>
                                    {errors.label && <div className={'invalid-feedback'}>{errors.label.message}</div>}
                                </div>
                                {/*subject*/}
                                <div className="mb-2">
                                    <label htmlFor="mailsubject" className="form-label">Subject</label>
                                    <input type="text"
                                           className={`form-control && ${errors.title && 'is-invalid'}`}
                                           placeholder="Your subject"
                                           {...register('title', {
                                               required: '*receiver is Required',
                                           })}
                                    />
                                    {errors.title && <div className={'invalid-feedback'}>{errors.title.message}</div>}
                                </div>
                                {/* message body */}
                                <div className="write-mdg-box mb-3">
                                    <label className="form-label">Message</label>
                                    <textarea
                                        rows={5}
                                        className={`form-control && ${errors.message && 'is-invalid'}`}
                                        defaultValue={""}
                                        {...register(
                                            'message',
                                            {
                                                required: '*receiver is Required',
                                            })}
                                    />
                                </div>
                            </div>
                            <div className="px-3 pb-3">
                                <button type="submit" className="btn btn-primary">
                                    <i className="mdi mdi-send me-1"/> Send Message
                                </button>
                                <button type="button" className="btn btn-light" data-bs-dismiss="modal">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
                {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
        </div>
    );

}

export default NewEmailSmsForm