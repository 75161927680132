import {configureStore,combineReducers} from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore,  FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER, } from 'redux-persist';

import virtualAccountSlice from "./feacture/virtual_accounts/virtualAccountSlice";
import settlementReconciliationSlice from "./feacture/settlementsReconcilations/settlementReconciliationSlice";
import tmsAuthReducer from "../../../config/redux/reducers/tmsAuthReducer";
import tmsSavingsReducer from "../../../config/redux/reducers/tmsSavingsReducer";
import tmsMemeberReducerState from "../../../config/redux/reducers/tmsMemeberReducerState";
import tmsLayoutSettingsReducer from "../../../config/redux/reducers/tmsLayoutSettingsReducer";
import tmsLoansReducer from "../../../config/redux/reducers/tmsLoansReducer";
import tmsBranchReducer from "../../../config/redux/reducers/tmsBranchReducer";
import tmsExpenseReducer from "../../../config/redux/reducers/tmsExpenseReducer";
import tmsSupportTicketReducer from "../../../config/redux/reducers/tmsSupportTicketReducer";
import tmsAnalyticsReducer from "../../../config/redux/reducers/tmsAnalyticsReducer";




const rootPersistConfig = {
    key: 'root',
    storage:storage,
    // whitelist: ["auth","mda","agent","program","beneficiary","landing"]
}

const appRootReducer = combineReducers({
    // virtualAccount:virtualAccountSlice ,
    settlementReconciliation : settlementReconciliationSlice,
    tmsAuthReducer,
    tmsSavingsReducer,
    tmsMemeberReducerState,
    tmsLayoutSettingsReducer,
    tmsLoansReducer,
    tmsBranchReducer,
    tmsExpenseReducer,
    tmsSupportTicketReducer,
    //non cache
    tmsAnalyticsReducer,
})

const persistedReducer = persistReducer(rootPersistConfig, appRootReducer)

export const store = configureStore({
    reducer : persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
})

export type  RootState  =  ReturnType<typeof store.getState>;
export type  AppDispatch =  typeof  store.dispatch
//
export const persist = persistStore(store)







