import React, {useEffect, useState} from "react"
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux"
import { Spinner } from "../../../../../components";
import { ErrorAlert, SuccessAlert } from "../../../../../components/alert";
import { BankTypeModel } from "../../../../../config/model/authModel"
import {getRequest, postRequest, setGeneralAction} from "../../../../../config/redux/actions"
import { AppState } from "../../../../../config/redux/reducers"
import { AuthState } from "../../../../../config/redux/reducers/tmsAuthReducer"
import { types } from "../../../../../config/redux/types"
import { remote_url } from "../../../../../config/urls/remote"
import axios from "axios";
import {bankAccountPurposeArray, PAYSTACK_KEY} from "../../../../../config/config";
import {removeClass} from "../../../../../config/action";
import {CustomModalWrapper} from "../../../_components";

type FormValues ={
    account_name : string,
    bank : string,
    account_number : string,
    account_for : string
}

interface Props {
    onClickFunc: any
}
const page = 'banK_form';
const AddNewBankForm:React.FC<Props> = ({onClickFunc}) => {
    const dispatch = useDispatch()
    const {banks,token,account_key,loading_,success_msg,error_msg,user,role_id} = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
    const { register, handleSubmit, getValues,setValue,formState: { errors },reset,watch } = useForm<FormValues>()
    //
    const [successMsg, setSuccessMsg] = useState('')
    const [errorMsg, setErrorMsg] = useState('')



    const [tempLoading, setTempLoading] = useState(false)
    const [isVerified, setIsVerified] = useState(false)
    // const [disableInput, setDisableInput] = useState(false)
    const [disableButton, setDisableButton] = useState(true)



    //
    useEffect(() => {
        //modal-backdrop fade show
        if(banks.length===0){
            getAllBanks()
        }
    },[])

    //::::: 
    const createBank = async (data:FormValues) => {
        data.account_for ="0";
       const postData = {
           token,
           url: remote_url.coopBankAccs,
           actionType : types.CREATE_BANKS,
           postData : {
               account_name:data.account_name,
               bank : data.bank.split('_')[0],
               account_number : data.account_number,
               status : isVerified? 1 : 0,
               account_for : data.account_for
           },
           secretData : {
               email:user.email,
               mac_id :user.mac_id,
               r_id: role_id
           },
            errorAction : types.SET_ERROR,
            accountKey: account_key,
            loading :{
                isLoading :false,
                isLoading_ : true,
                isLoading_t : false
            }
            // reset
        }
        dispatch(postRequest(postData))
    }
    //:::::
    const getAllBanks = () => {
         const getData = {
           token,
           url: remote_url.banks,
           actionType : types.GET_BANKS,
           postData : [],
             secretData : {
                 email:user.email,
                 mac_id :user.mac_id,
                 r_id: role_id
             },
            errorAction : types.SET_ERROR,
            accountKey: account_key,
            loading :{
                isLoading :false,
                isLoading_ : true
            }
            // reset
        }
        dispatch(getRequest(getData))
    }

    //
    const handleAccountNumber = async (acc_no:string) => {
       await verifyBankAccount(acc_no)
    }
    //
    const handleBankSelected = async (bank_key:string) => {
      await verifyBankAccount('',bank_key)
    }
    //
    const verifyBankAccount = async  (account_number='',tempBank='') => {
        setSuccessMsg('')
        setErrorMsg('')
        account_number = account_number ? account_number : getValues('account_number');
        tempBank = tempBank ? tempBank : getValues('bank');
        let  bank_code = tempBank.split('_')[1]
        if(account_number.length === 10 && bank_code){
            setTempLoading(true)
            let url  = `https://api.paystack.co/bank/resolve?account_number=${account_number}&bank_code=${bank_code}`
            try{
                const res = await axios.get(`${url}`, {headers:{
                        Authorization : `Bearer ${PAYSTACK_KEY}`,
                        Accept : 'application/json',
                        'Content-type' : 'application/json'
                }})
                setValue('account_name',res.data.data.account_name)
                // setDisableInput(true)
                setDisableButton(false)
                setTempLoading(false)
                setIsVerified(true)
                setSuccessMsg('account verified')
            }catch (e:any) {
                setTempLoading(false)
                setIsVerified(false)
                setDisableButton(true)
                setErrorMsg('invalid account number / bank selected')
            }
        }
    }
    //
    const clearData = () => {
        // setValue('account_name','')
        // setDisableButton(true)
        // setIsVerified(false)
        // setValue('account_number','')
        // setValue('bank','')
        // dispatch(setGeneralAction(types.CLEAR_MSG,[]))
        onClickFunc(false)
    }



    if(success_msg){
        // onSuccessMessage()
        clearData()

        //CLEAR_MSG
        //
        // setSuccessMsg('')
        // setErrorMsg('')
    }

    //
    return <> 
            <CustomModalWrapper key="addNewBankForm" onClickFunc={onClickFunc} modalTitle={'Add New Bank Account'} >
                <div className="card-body">
                    <form className="px-2" onSubmit={handleSubmit(createBank)}>
                       {success_msg && <SuccessAlert msg={success_msg}/> }
                       {error_msg  && <ErrorAlert msg={error_msg}/>}

                       <div className="mb-3">
                            <label htmlFor="simp">Account Name</label>
                           <input
                               type='text'
                               readOnly={true}
                               className={`form-control ${errors.account_name && 'is-invalid'}`}
                               {...register('account_name')}
                            />
                             {errors.account_name  && (<div className="invalid-feedback">{errors.account_name.message}</div>)}
                        </div>
                        {/*  */}
                         <div className="mb-3">
                            <label htmlFor="simpleinput" className="form-label">Account number
                                <i className={`
                                    ${ isVerified
                                    ?
                                    'mdi mdi-check-decagram text-success':
                                    'uil uil-times-circle text-danger'}`}>
                                </i>
                                {tempLoading && <span className={'text-success'}>loading...</span>}
                            </label>
                            <input
                                type="number"
                                size={10}
                                // disabled={disableInput}
                                className={`form-control ${errors.account_number && 'is-invalid'} ${successMsg && 'is-valid'} ${errorMsg && 'is-invalid'}`}
                                {...register('account_number',{
                                        required: '*account number is Required',
                                        minLength: { value: 10, message: 'account number Must be 10 digit' },
                                        maxLength: { value: 10, message: 'account number Must be 10 digit' },
                                        onChange: (e) => handleAccountNumber(e.target.value)
                                })}
                            />
                                 {errors.account_number  && (<div className="invalid-feedback">{errors.account_number.message}</div>)}
                                 {successMsg  && (<div className="valid-feedback">{successMsg}</div>)}
                                 {errorMsg  && (<div className="invalid-feedback">{errorMsg}</div>)}
                        </div>
                        {/* Bank Name */}
                         <div className="mb-3">
                            <label htmlFor="Bank Name" className="form-label">Bank Name</label>
                            <select
                                className={`form-select mb-3 ${errors?.bank && 'is-invalid'}`}
                                {...register('bank',
                                    {
                                        required: 'please select your bank',
                                        onChange : (e) => handleBankSelected(e.target.value)
                                    }
                                )}
                            >
                                <option value=''>Select bank</option>
                                {banks.map((item:BankTypeModel) => <option key={item.bank_key} value={`${item.bank_key}_${item.code}`}>
                                    {item.name}
                                </option>
                                )}
                            </select>
                             {errors.bank  && (<div className="invalid-feedback">{errors.bank.message}</div>)}
                        </div>
                        {/**/}
                        {/*<div className={'mb-3'}>*/}
                        {/*    <select className={'form-select'} {...register('account_for',{*/}
                        {/*        required:'this field is required'*/}
                        {/*    })}>*/}
                        {/*        <option value={''}>Select Bank Account purpose</option>*/}
                        {/*        {bankAccountPurposeArray.map((item, i = 0) =>*/}
                        {/*            <option value={i} key={i}> {item}</option>*/}
                        {/*        )*/}
                        {/*        }*/}
                        {/*    </select>*/}

                        {/*</div>*/}

                        {
                            loading_
                                ?
                                <Spinner color="primary"/>
                                :
                                <input type="submit" className="btn btn-primary" value="Add Bank" disabled={disableButton}/>
                         }
                    </form>
                </div>
            </CustomModalWrapper>

    </>
}


export default AddNewBankForm