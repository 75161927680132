import {calculateTime, postRequest, setGeneralAction} from "../../../../config/redux/actions";
import {types} from "../../../../config/redux/types";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../config/redux/reducers";
import {AnalyticState} from "../../../../config/redux/reducers/tmsAnalyticsReducer";
import {useEffect} from "react";
import {remote_url} from "../../../../config/urls/remote";
import {AuthState} from "../../../../config/redux/reducers/tmsAuthReducer";

const DashboardFilter = () => {
    const {
        token,
        account_key,
        loginTime,
        user,
        role_id,isAdminMode
    } = useSelector<AppState, AuthState>((state) => state.tmsAuthReducer)
    const {dashboardYear} = useSelector<AppState, AnalyticState>((state) => state.tmsAnalyticsReducer)

    const dispatch = useDispatch()
    let current_time = calculateTime(loginTime)

    // ::::: ::::: :::
    const getDashboardAnalytics = (year:number) => {

        dispatch(setGeneralAction(types.SET_DASHBOARD_FILTER, year))

        const postData = {
            token,
            url: remote_url.dashboardAnaltics,
            actionType: types.GET_DASHBOARD_DATA,
            postData: {
                year,
                user_id: isAdminMode ? 'all' : user.id
            },
            secretData: {
                email: user.email,
                mac_id: user.mac_id,
                r_id: role_id
            },
            errorAction: types.SET_ERROR,
            accountKey: account_key,
            loading: {
                isLoading: false,
                isLoading_: false,
                isLoading_t: true
            },
        }
        dispatch(postRequest(postData))
    }

    useEffect(() => {
        if (current_time <= 6) {
            getDashboardAnalytics(dashboardYear)
        }
    }, [])


    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box">
                        <div className="page-title-right">
                            <form className="d-flex">
                                {/* fromDate */}
                                <div className="input-group">
                                    <select className="form-control form-control-light form-select"
                                            defaultValue={dashboardYear}
                                            onChange={
                                                // (e) => dispatch(setGeneralAction(types.SET_DASHBOARD_FILTER, e.target.value))
                                                (e)=> getDashboardAnalytics(Number(e.target.value))
                                    }

                                    >
                                        <option value={2021}>2018</option>
                                        <option value={2021}>2019</option>
                                        <option value={2021}>2020</option>
                                        <option value={2021}>2021</option>
                                        <option value={2022}>2022</option>
                                        <option value={2023}>2023</option>
                                        <option value={2024}>2024</option>
                                    </select>
                                {/*    <span title={'Filter'} className="input-group-text bg-primary border-primary text-white"*/}
                                {/*          onClick={() => getDashboardAnalytics()}>*/}
                                {/*    <i className="mdi mdi-arrow-right font-13"></i>*/}
                                {/*</span>*/}
                                </div>
                            </form>
                        </div>
                        <h4 className="page-title">Dashboard</h4>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashboardFilter