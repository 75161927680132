

import { Action } from "history";
import {
    DashboardAnalyticModel,
    DashboardAnalyticProps,
    ExpenseDashboardModel, ExpenseDashboardProps,
    LoanDashboardAnalyticModel,
    LoanDashboardAnalyticProps,
    SavingDashboardAnalyticModel,
    SavingDashboardAnalyticProps
} from "../../model/authModel";
import { ActionProps } from "../actions";
import { types } from "../types";



export interface AnalyticState{
    loading : boolean,
    loading_ : boolean,
    loading_t ? :boolean,
    success_msg  : string ,
    error_msg : string,
    dashboard : DashboardAnalyticModel,
    newData : boolean,
    savingsDashboard : SavingDashboardAnalyticModel,
    loansDashboard: LoanDashboardAnalyticModel,
    expenseDashboard : ExpenseDashboardModel,
    dashboardYear : number,
    savingsDashboardYear : number,
    loansDashboardYear : number,
    expensesDashboardYear : number

}

const initialState = {
    loading:false,
    loading_: false,
    loading_t : false,
    success_msg  : '',
    error_msg : '',
    savingsDashboard :SavingDashboardAnalyticProps,
    loansDashboard : LoanDashboardAnalyticProps,
    expenseDashboard : ExpenseDashboardProps,

    //
    dashboard : DashboardAnalyticProps,
    newData : false,
    dashboardYear : new Date().getFullYear(),
    savingsDashboardYear : new Date().getFullYear(),
    loansDashboardYear : new Date().getFullYear(),
    expensesDashboardYear : new Date().getFullYear()
}


const tmsAnalyticsReducer = (state:AnalyticState = initialState , action: ActionProps ) => {
    switch (action.type) {

        case types.SET_DASHBOARD_FILTER:
            return {
                ...state,
                dashboardYear : action.payload
            }

        case types.GET_EXPENSE_ANALYTICS:
            // console.log(action.payload?.data)
            return {
                ...state,
                expenseDashboard : action.payload?.data
            }

        case types.SET_SAVINGS_DASHBOARD_FILTER : 
            return {
                ...state,
                savingsDashboardYear:action.payload
            }
        
        case types.SET_LOANS_DASHBOARD_FILTER:
            return {
                ...state,
                loansDashboardYear:action.payload
            }
        
        case types.CLEAR_MSG:
            return{
                ...state,
                loading:false,
                loading_:false,
                loading_t:false,
                success_msg : '',
                error_msg : ''
            }
        //
        case types.SET_LOADING:
            return {
                ...state,
                loading:action.loading?.isLoading,
                loading_ :action.loading?.isLoading_,
                loading_t :action.loading?.isLoading_t,
                success_msg : '',
                error_msg : '',
                newData: false
            }
        //
        case types.SET_ERROR:
            return {
                ...state,
                loading:false,
                loading_:false,
                loading_t:false,
                success_msg : '',
                error_msg : action.payload,
                 newData: false
            }
        case types.GET_DASHBOARD_DATA:
            return {
                ...state,
                loading:false,
                loading_:false,
                loading_t:false,
                success_msg : '',
                error_msg : '',
                dashboard : action.payload?.data,
                newData : true
            }

        case types.RESET_DASHBOARD_BOT :
            return {
                ...state,
                newData: false,
            }

        case types.GET_SAVINGS_DASHBOARD_DATA:
            return {
                ...state,
                loading:false,
                loading_:false,
                loading_t:false,
                savingsDashboard: action.payload?.data,
                newData : true
            }

        case types.GET_LOANS_DASHBOARD_DATA:
            return{
                ...state,
                 loading:false,
                loading_:false,
                loading_t:false,
                loansDashboard : action.payload?.data
            }
        //
        case types.LOG_OUT:
            return {
                ...state,
                loading : false,
                loading_ : false,
                loading_t : false,
                success_msg : '',
                error_msg : '',
                dashboard : [],
                newData : false,
                savingsDashboard : [],
                loansDashboard:[],
                savingsDashboardYear : ''
            }
        //
        default:
            return state;
    }
}


export default tmsAnalyticsReducer;