import {Spinner} from "../../../../components";
import {Link} from "react-router-dom";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../config/redux/reducers";
import {AuthState} from "../../../../config/redux/reducers/tmsAuthReducer";
import {remote_url} from "../../../../config/urls/remote";
import {types} from "../../../../config/redux/types";
import {postRequest} from "../../../../config/redux/actions";

type FormValues ={
    search: string,
}

const TopNavBarSearch = () => {
    const { register, handleSubmit} = useForm<FormValues>()
    const {role_id,user,token,account_key,searchLoading,searchData} = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
    const dispatch = useDispatch()

    const handleSearch = (value:string) => {
        search({search:value})
    }
    const search = (data:FormValues) => {
        const postData = {
            token : token,
            url:remote_url.search,
            actionType: types.SEARCH_DATA,
            postData : {
                search :  data
            },
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id:role_id
            },
            // reset,
            errorAction : types.SET_ERROR,
            loading :{
                isLoading :false,
                isLoading_ : false,
                isLoading_t: false,
                searchLoading : true
            },
            accountKey :account_key
        }
        //
        dispatch(postRequest(postData))
    }

    return <>
        <div className="app-search dropdown d-none d-lg-block">
            <form onSubmit={handleSubmit(search)}>
                <div className="input-group">
                    <input type="text" className="form-control dropdown-toggle" placeholder="Search..."
                           id="top-search" {...register('search',{
                        onChange:(e) => e.target.value.length >=5 && handleSearch(e.target.value)
                    })}
                    />
                    <span className="mdi mdi-magnify search-icon"></span>
                    {searchLoading ? <Spinner  color={'primary'} /> :  <button className="input-group-text btn-primary" type="submit">Search</button>}
                </div>
            </form>

            <div className="dropdown-menu dropdown-menu-animated dropdown-lg" id="search-dropdown">
                <div className="dropdown-header noti-title">
                    {/*{loading_  && <Spinner color={'primary'} />}*/}
                    <h5 className="text-overflow mb-2">Found <span className="text-danger">17</span> results</h5>
                </div>


                <Link to="#0" className="dropdown-item notify-item">
                    <i className="uil-notes font-16 me-1"></i>
                    <span>Savings <span className="text-danger">{searchData?.savings?.length}</span> result(s) found </span>
                </Link>


                <Link to="#0" className="dropdown-item notify-item">
                    <i className="uil-life-ring font-16 me-1"></i>
                    <span>Loans <span className="text-danger">{searchData?.loans?.length}</span> result(s) found </span>
                </Link>


                <Link to="#0" className="dropdown-item notify-item">
                    <i className="uil-cog font-16 me-1"></i>
                    <span>Expenses <span className="text-danger">{searchData?.expenses?.length}</span> result(s) found </span>
                </Link>

                <div className="dropdown-header noti-title">
                    <h6 className="text-overflow mb-2 text-uppercase">Users</h6>
                </div>
                <div className="notification-list">
                    <Link to="#0" className="dropdown-item notify-item">
                        <div className="d-flex">
                            <img className="d-flex me-2 rounded-circle" src="/assets/images/users/avatar-2.jpg"
                                 alt="Generic placeholder" height="32"/>
                            <div className="w-100">
                                <h5 className="m-0 font-14">Erwin Brown</h5>
                                <span className="font-12 mb-0">UI Designer</span>
                            </div>
                        </div>
                    </Link>


                    <Link to="#0" className="dropdown-item notify-item">
                        <div className="d-flex">
                            <img className="d-flex me-2 rounded-circle" src="/assets/images/users/avatar-5.jpg"
                                 alt="Generic placeholder" height="32"/>
                            <div className="w-100">
                                <h5 className="m-0 font-14">Jacob Deo</h5>
                                <span className="font-12 mb-0">Developer</span>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    </>
}


export  default TopNavBarSearch