import { useDispatch, useSelector } from "react-redux"
import { postRequestFile } from "../../../../config/redux/actions"
import { AppState } from "../../../../config/redux/reducers"
import { AuthState } from "../../../../config/redux/reducers/tmsAuthReducer"
import { types } from "../../../../config/redux/types"
import { remote_url } from "../../../../config/urls/remote"
import { useForm } from 'react-hook-form';
import ToastAlert from "../../_components/ToastAlert"

interface FormValues{
    document : string
    request_type : string 
    document_type : string 
}
//
const DocumentUploadForm = () => {
        const { register, handleSubmit, formState: { errors },reset } = useForm<FormValues>()
        const {documents_types,token, account_key,success_msg,error_msg,user,role_id} = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
        const dispatch = useDispatch()
    //
        const onSubmit = (data:FormValues) => {
            const reqData = new FormData()
            reqData.append('request_type','create')
            reqData.append('document_type',data.document_type)
            reqData.append('document',data.document[0])
            const postData = {
                token,
                url: remote_url.updateAccountDocUpload,
                actionType : types.ADD_DOCUMENT,
                postData : reqData,
                secretData : {
                    email:user.email,
                    mac_id :user.mac_id,
                    r_id: role_id
                },
                errorAction : types.SET_ERROR,
                accountKey: account_key,
                loading :{
                    isLoading :true,
                    isLoading_ : false,
                    isLoading_t : false
                }
            }
            //
        dispatch(postRequestFile(postData))
    
    }

    return <>
    
    <div>
   
    
    {/* <div className="offcanvas offcanvas-end" tabIndex={-1} id="documentForm" aria-labelledby="documentFormLabel">
        <div className="offcanvas-header">
            <h5 id="documentFormLabel">Offcanvas right</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            Content for the offcanvas goes here. You can place just about any Bootstrap component or custom elements here.
        </div>
    </div> */}

    {/*  */}
                
                
    <div className="offcanvas offcanvas-bottom" tabIndex={-1} id="documentForm" aria-labelledby="documentFormLabel">
        {success_msg && <ToastAlert msg={success_msg}/>}
        <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="documentFormLabel">Document Upload</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body small">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-4">
                        <select  {...register('document_type')}  className="form-select">
                            <option value={''}>Select Document Type</option>
                            {documents_types.map((doc) => <option value={doc.key} key={doc.key}>{doc.name}</option>  )}
                        </select>
                    </div>
                    <div className="col-4">
                        <input type='file' {...register('document',{
                            required: '*business document is required',
                        })} className="form-control" />
                    </div>
                    <div className="col-4">
                        <button className="btn btn-outline-primary"> Upload Document </button>
                    </div>

                </div>

            </form>
        </div>
    </div>
    {/*  */}


</div>
    
    
    </>
}




export default DocumentUploadForm