import {generalDatatableConfigWithCustomButtons} from "../../config/datatableConfig";
import {
    FlutterWaveSettlementModel,
    InAppSettlementModel, SettlementModel
} from "./redux/feacture/settlementsReconcilations/settlementReconcilationModel";


export const inAppSettlementDatatableConfig = (data: any, table_id: string, orderBy: any, btnName: string[]) => {
    const cols = [
        {
            data: (item: InAppSettlementModel) => {
                return (`
                   
                        <a href="" class="text-body fw-bold">#${item.settlement_reference}</a> 
                    
                 `)
            }
        },
        {
            data: (item: InAppSettlementModel) => {
                return (`${item.settlement_account_number} `)
            }
        },
        {
            data: (item: InAppSettlementModel) => {
                return (`${item.settlement_bank_name}  `)
            }
        },
        {
            data: (item: InAppSettlementModel) => {
                return (` ${item.settlement_account_name}`
                )
            }
        },
        {
            data: (item: InAppSettlementModel) => {
                return (`${item.amount}`)
            }
        },
        {
            data: (item: InAppSettlementModel) => {
                return (``)
            }
        },

        {
            data: (item: InAppSettlementModel) => {
                return (`
                        <a href="" class="action-icon"> <i class="mdi mdi-eye"></i></a>
                `)
            }

        }

    ]

    generalDatatableConfigWithCustomButtons(data, table_id, cols, orderBy, btnName,null,null)
}



export const flutterWaveSettlementDatatableConfig = (data: any, table_id: string, orderBy: any, btnName: string[]) => {
    const cols = [
        {
            data: (item: FlutterWaveSettlementModel) => {
                return (`
                 <a href="" class="text-body fw-bold">#${item.processor_ref}</a> 
                 `)
            }
        },
        {
            data: (item: FlutterWaveSettlementModel) => {
                return (`${item.settlement_account} `)
            }
        },
        {
            data: (item: FlutterWaveSettlementModel) => {
                return (`${item.bank_code}`)
            }
        },
        {
            data: (item: FlutterWaveSettlementModel) => {
                return (` ${item.merchant_name}`
                )
            }
        },
        {
            data: (item: FlutterWaveSettlementModel) => {
                return (`${item.gross_amount}`)
            }
        },
        {
            data: (item: FlutterWaveSettlementModel) => {
                return (``)
            }
        },

        {
            data: (item: FlutterWaveSettlementModel) => {
                return (`
                        <a href="" class="action-icon"> <i class="mdi mdi-eye"></i></a>
                `)
            }

        }

    ]

    generalDatatableConfigWithCustomButtons(data, table_id, cols, orderBy, btnName,null,null)
}

export const payStackSettlementDatatableConfig = (data: any, table_id: string, orderBy: any, btnName: string[]) => {
    const cols = [
        {
            data: (item: SettlementModel) => {
                return (`
                 <a href="" class="text-body fw-bold">#${item.id}</a> 
                 `)
            }
        },
        {
            data: (item: SettlementModel) => {
                return (`${item.subaccount.account_number} `)
            }
        },
        {
            data: (item: SettlementModel) => {
                return (`${item.subaccount.settlement_bank}`)
            }
        },
        {
            data: (item: SettlementModel) => {
                return (` ${item.subaccount.business_name}`
                )
            }
        },
        {
            data: (item: SettlementModel) => {
                return (`${item.effective_amount}`)
            }
        },
        {
            data: (item: SettlementModel) => {
                return (``)
            }
        },

        {
            data: (item: SettlementModel) => {
                return (`
                        <a href="" class="action-icon"> <i class="mdi mdi-eye"></i></a>
                `)
            }

        }

    ]

    generalDatatableConfigWithCustomButtons(data, table_id, cols, orderBy, btnName,null,null)
}










export const ordersDatatableConfig = (data: any, table_id: string, orderBy: any, btnName: string[], innerBtnFunc: any, innerBtnFuncTwo: any) => {
    const cols = [
        {
            data: () => {
                return (
                    `  <div class="form-check">
                         <input type="checkbox" class="form-check-input" id="customCheck4" />
                         <label class="form-check-label" for="customCheck4">&nbsp;</label>
                     </div>
              
                `)
            }
        },
        {
            data: () => {
                return (`
                   
                        <a href="" class="text-body fw-bold">#BM9706</a> 
                    
                 `)
            }
        },
        {
            data: () => {
                return (
                    `
                      August 04 2018 <small class="text-muted">10:29 PM</small>     
                    `
                )
            }
        },
        {
            data: () => {
                return (`
               
                    <h5><span class="badge badge-success-lighten"><i class="mdi mdi-coin" /> Paid</span></h5>
                 
                `)
            }
        },
        {
            data: () => {
                return (` $801.99 `
                )
            }
        },
        {
            data: () => {
                return (`
                   
                             Credit Card
                `)
            }
        },
        {
            data: () => {
                return (` <h5><span class="badge badge-warning-lighten">Processing</span></h5>`)
            }
        },

        {
            data: () => {
                return (`
                 
                                 <a href="" class="action-icon"> <i class="mdi mdi-eye"></i></a>
                                 <a href="" class="action-icon"> <i class="mdi mdi-square-edit-outline"></i></a>
                                 <a href="" class="action-icon"> <i class="mdi mdi-delete"></i></a>
                             
                `)
            }

        }

    ]

    generalDatatableConfigWithCustomButtons(data, table_id, cols, orderBy, btnName,
        innerBtnFunc, innerBtnFuncTwo)
}


export const productsDatatableConfig = (data: any, table_id: string, orderBy: any, btnName: string[], innerBtnFunc: any, innerBtnFuncTwo: any) => {
    const cols = [
        {
            data: () => {
                return (
                    `  <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="customCheck2" />
                        <label class="form-check-label" for="customCheck2">&nbsp;</label>
                  </div>
                `)
            }
        },
        {
            data: () => {
                return (`
                   
                           <img src="assets/images/products/product-1.jpg" alt="contact-img" title="contact-img" class="rounded me-3" height={48} />
                                    <p class="m-0 d-inline-block align-middle font-16">
                                        <a href="" class="text-body">Amazing Modern Chair</a>
                                        <br />
                                        <span class="text-warning mdi mdi-star"></span>
                                        <span class="text-warning mdi mdi-star"></span>
                                        <span class="text-warning mdi mdi-star"></span>
                                        <span class="text-warning mdi mdi-star"></span>
                                        <span class="text-warning mdi mdi-star"></span>
                                    </p>
                    
                 `)
            }
        },
        {
            data: () => {
                return (
                    `
                       Aeron Chairs  
                    `
                )
            }
        },
        {
            data: () => {
                return (`09/12/2018`)
            }
        },
        {
            data: () => {
                return (
                    `$148.66`
                )
            }
        },
        {
            data: () => {
                return (`
                   
                             254
                `)
            }
        },
        {
            data: () => {
                return (`<span class="badge bg-success">Active</span>`)
            }
        },
        {
            data: () => {
                return (`
                 <a href="" class="action-icon"> <i class="mdi mdi-eye"></i></a>
                 <a href="" class="action-icon"> <i class="mdi mdi-square-edit-outline"></i></a>
                <a href="" class="action-icon"> <i class="mdi mdi-delete"></i></a>
                        
                `)
            }

        }
    ]

    generalDatatableConfigWithCustomButtons(data, table_id, cols, orderBy, btnName,
        innerBtnFunc, innerBtnFuncTwo)
}



