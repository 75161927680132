import {useSelector} from "react-redux";
import {AppState} from "../../config/redux/reducers";
import {AuthState} from "../../config/redux/reducers/tmsAuthReducer";
import {AuthNotFoundPage, NonAuthErrorPage} from "./index";

const NotFoundPage = () => {
    const {token} = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
    if(token) {
        return <AuthNotFoundPage/>
    }else{
        return <NonAuthErrorPage/>
    }
}


export  default  NotFoundPage