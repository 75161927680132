import { useDispatch, useSelector } from "react-redux"
import { postRequest, postRequestFile } from "../../../../config/redux/actions"
import { AppState } from "../../../../config/redux/reducers"
import { AuthState } from "../../../../config/redux/reducers/tmsAuthReducer"
import { types } from "../../../../config/redux/types"
import { remote_url } from "../../../../config/urls/remote"
import { useForm } from 'react-hook-form';
import { Spinner } from "../../../../components"
import { SavingsReducerState } from "../../../../config/redux/reducers/tmsSavingsReducer"
import React from "react";
import BulkImportWrapper from "../../_components/BulkImportWrapper";

interface FormProps {
  file : any
}

interface ComponentProps {
    show : boolean
    handleImportModal : any
}

const SavingsImportForm :React.FC<ComponentProps> = ({show,handleImportModal}) => {
    const page ='saving_Import'
  const { register, handleSubmit, formState: { errors },reset } = useForm<FormProps>()
  const {token,account_key,user,role_id} = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
  const {success_msg,error_msg,loading,isLoading_t} = useSelector<AppState,SavingsReducerState>((state) => state.tmsSavingsReducer)
  const dispatch = useDispatch()


  const insertRecord = async (data:FormProps) => {
         const postData = {
                token,
                url: `${remote_url.savings}/bulk-import`,
                actionType: types.GET_SAVINGS,
                postData :{
                  file :  data.file[0]
                },
             secretData : {
                 email:user.email,
                 mac_id :user.mac_id,
                 r_id: role_id
             },
             errorAction : types.SET_ERROR,
             accountKey: account_key,
            loading :{
                isLoading :false,
                isLoading_ : false,
                isLoading_t : true
            }
        }
        dispatch(postRequestFile(postData))
  }

  if(success_msg && page ==='saving_Import'){
      handleImportModal(false)
  }



  //
  return  (
      <BulkImportWrapper show={show} handleImportModal={handleImportModal}  link={'savings_import.xlsx'}>
          <form className="ps-3 pe-3" onSubmit={handleSubmit(insertRecord)}>
              <div className="mb-3">
                  <label htmlFor="username" className="form-label">Savings File(CSV/EXCEL)</label>
                  <input className="form-control" type="file"
                         {...register('file',{required: 'Please Select an Excel File'})}
                  />
              </div>


              <div className="mb-3 text-center">
                  {
                      isLoading_t ? <Spinner color="primary"/> :
                          <button className="btn btn-primary" type="submit">Upload Data</button>
                  }
              </div>
          </form>
        </BulkImportWrapper>
  )

}
export default SavingsImportForm