import {url} from "../urls";


export interface AllUserRoles{
    id : number,
    name : string
}
export const  AllUserRolesProps ={
    id : 0,
    name : ''
}

export interface PaymentChannel{
    key : string,
    name : string,
    role : number[],
}
export interface SavingsBankAccountsModel{
    id : number,
    account_number:string
}
interface  QuickMenu {
    iconName: string,
    name : string,
    link: string
}
export interface QuickMenuModel {
    links : QuickMenu[],
    links_two : QuickMenu[],
}
export const QuickMenuLinksProps = {
    links : [
        //1
        {
            iconName : 'uil uil-wallet',
            name: 'Savings',
            link : url.savings
        },
        //2
        {
            iconName : 'uil uil-mobey-bill-slash',
            name: 'Loans',
            link : url.loansApp
        },
        //3
        {
            iconName : 'uil uil-card-atm',
            name: 'Expenses',
            link : url.expenses
        },
    ],
    links_two :  [
        //4
        {
            iconName : 'uil uil-rainbow',
            name: 'Ads',
            link : url.adsHome
        },
        //5
        {
            iconName : 'uil uil-chat-bubble-user',
            name: 'Support Tickets',
            link : url.supportsHome
        },
        //
        {
            iconName : 'uil uil-user-check',
            name: 'My Profile',
            link : url.myProfile
        },
    ]
}


//Search Model starts here
export interface  SearchSavingsModel {
    id : number,
    transaction_reference: string
    thrift_amount : string
}
export interface SearchLoansModel{
    id : number,
    application_reference: string
    amount : string
}
export interface  SearchExpensesModel {
    id : number,
    transaction_reference: string
    amount : string
}

export interface  SearchModel{
    savings : SearchSavingsModel[],
    loans : SearchLoansModel[],
    expenses : SearchExpensesModel[]
}
export const SearchProps = {
    savings : [],
    loans : [],
    expenses : []
}
//end of search model

//My Profile Model
export interface UsersSavingsTransactionModel{
    transaction_reference : string,
    thrift_amount : number,
    status : number
}

export interface  UsersProfileSupportTickets {
    title : string,
    message : string,
    status : number
}

export const UsersSavingsTransactionProps = {
    transaction_reference : '',
    thrift_amount : 0,
    status : 0
}
//
export interface  FullUserDetails {
    overall_total_savings : number,
    overall_total_loans : number,
    overall_total_expenses : number,
    overall_total_savings_years : number,
    overall_total_loans_years : number,
    overall_total_expenses_years : number,
    savings_amount_chart : {
        series : number[],
        labels : string[]
    },
    savings_count_chart : {
        series : number[],
        labels : string[]
    },
    unpaid_transactions : UsersSavingsTransactionModel[],
    support_tickets : UsersProfileSupportTickets[]
}

export const  FullUserDetailsProps  = {
    overall_total_savings : 0,
    overall_total_loans : 0,
    overall_total_expenses : 0,
    overall_total_savings_years : 0,
    overall_total_loans_years : 0,
    overall_total_expenses_years : 0,
    savings_amount_chart : {
        series : [0,0,0,0,0,0,0,0,0,0,0,0],
        labels : ['Jan','Feb','Mar','Apr','May','Jun','July','Aug','Sep','Oct','Nov','Dec']
    },
    savings_count_chart : {
        series : [0,0,0,0,0,0,0,0,0,0,0,0],
        labels : ['Jan','Feb','Mar','Apr','May','Jun','July','Aug','Sep','Oct','Nov','Dec']
    },
    unpaid_transactions : [],
    support_tickets : []
}
//end of user profile
//
export interface SupportMessagesModel {
    id: number,
    support_conversation_id : number,
    sender_id : number,
    support_admin_id : number,
    messages : string,
    title : string,
    has_attachment : number,
    attachment_url : string,
    is_read_by_receiver : number,
    is_deleted_by_sender: number,
    is_deleted_by_receiver: number,
    created_at : string,
    user: {
        name : string,
        profile_image : string
    }
}



export interface SupportTicketConversationModel {
    id :number,
    last_message: {
        title : string,
        messages : string,
        created_at : string,
    },
    total_no_of_unread_message : number
}

export const SupportTicketConversationProp= {
    id: 0,
    last_message: {
        title : '',
        messages : '',
        created_at : ''
    },
    total_no_of_unread_message: 0
}

export interface PushNotificationDesignModel {
    iconText : string,
    modalContentBg : string,
    id : string,
    btn : string,
}

export interface  PushNotificationPayloadModel {
    body : string,
    image : string,
    title: string,
    //
    btn : string,
    iconText : string,
    id : string,
    modalContentBg : string,
    link  : string,

    icon : string,
    color : string,
    sound : string,
}

export const PushNotificationPayloadProps = {
    body : '',
    image :'',
    title: '',
    //
    btn : '',
    iconText : '',
    id:'',
    modalContentBg : '',
    link : '',
    // not used in my notification component
    icon : '',
    color : '',
    sound : ''
}

export interface ExpenseDashboardModel {
    incomes: {
        "name" : string,
        "labels": string[],
        "series": number[]
    },
    expenses : {
        "name" : string,
        "labels": string[],
        "series": number[]
    }
}

export const ExpenseDashboardProps = {
    incomes: {
        "name" : '',
        "labels":['',''],
        "series": [0,0]
    },
    expenses : {
        "name" : '',
        "labels":['',''],
        "series": [0,0]
    }
}


//State
export interface StatesModel {
    id: number,
    state_name: string
}

export interface SubscriptionTransactionModel {
    id: number,
    subscription_id: number,
    account_branch_id: number,
    amount: number,
    amount_paid: number,
    transaction_reference: string,
    payment_channel: string,
    initial_url: string,
    status: string,
    paid_at: string,
    created_at: string,
    gateway_response: string,
    subscription: {
        id: number,
        sub_name: string
    },
    duration: number,
    sms: number,
    branch: number,
    email: number,
    members: number,
    push_notification: number,
    bank_name :string,
    account_number:string,
    account_name : string
    expires_at :string,
    subscription_data  : string[]

}

export const SubscriptionTransactionProp = {
    subscription_data  : [],
    id: 0,
    subscription_id: 0,
    account_branch_id: 0,
    amount: 0,
    amount_paid: 0,
    transaction_reference: '',
    payment_channel: '',
    initial_url: '',
    status: '',
    paid_at: '',
    created_at: '',
    gateway_response: '',
    subscription: {
        id: 0,
        sub_name: ''
    },
    duration: 0,
    sms: 0,
    branch: 0,
    email: 0,
    members: 0,
    push_notification: 0,
    bank_name :'',
    account_number:'',
    account_name : '',
    expires_at :''

}
interface SubscriptionDetailsModel {
    name :string,
    value :string,
    label :string

}
export interface SubscriptionPlanModel {
    id: number,
    sub_name: string,
    amount: number,
    duration: number,
    sms: number,
    branch: number,
    email: number,
    members: number,
    push_notification: number,
    icon_name: string,
    subscription_details  : SubscriptionDetailsModel[]
}

export const SubscriptionPlanProps = {
    id: 0,
    subscription_details:[],
    sub_name: '',
    amount: 0,
    duration: 0,
    sms: 0,
    branch: 0,
    email: 0,
    members: 0,
    push_notification: 0,
    icon_name: ''
}

//Branch
export interface BranchModel {
    id: string,
    status: number,
    account_branch_key: string,
    branch_name: string,
    has_account_number: boolean,
    has_loan_settings: number,
    city: string,
    address: string,
    state_id: string
    country_id: string
    logo: string
    id_type_id: string
    id_number: string
    bussiness_registration_type: string
    bussiness_registration_no: string
    email: string
    phone_no: string
    role_text: string,
    total_number_of_members: number,
    members: [
        {
            image: string
        }
    ]
}

export const BranchObject = {
    id: '',
    status: 0,
    account_branch_key: '',
    branch_name: '',
    has_account_number: false,
    has_loan_settings: 0,
    city: '',
    address: '',
    state_id: '',
    country_id: '',
    logo: '',
    id_type_id: '',
    id_number: '',
    bussiness_registration_type: '',
    bussiness_registration_no: '',
    email: '',
    phone_no: '',
    role_text: '',
    total_number_of_members: 0,
    members: [
        {
            image: ''
        },
        {
            image: ''
        },
        {
            image: ''
        }
    ]
}

export interface CountryMOdel {
    id: number,
    country_name: string
}

export interface BussinessRegTypesModel {
    name: string,
    key: string
}

export interface NotificationModel {
    id: number,
    title: string,
    message: string,
    status: number,
    created_at: string,
    icon_name: string,
    icon_bg_color: string
}


export interface AccountDocument {
    id: number,
    document_type: string,
    document: string

}


// used in Loan App
export interface UserBranchModel {
    id: number,
    user_id: number,
    account_branch_id: number,
    date_joined: string,
    role_id: number,
    status: number,
    secret_key: string,
    savings_balance: number,
    amount_left_to_be_returned: number,
    no_of_incomplete_loans_repayment: number,
    created_at: string,
    updated_at: string,
    role_text: string,
    account_branch: {
        id: string,
        account_id: number,
        main_branch: number
        account_branch_key: string
        approval_reason: string
        branch_name: string
        email: string
        phone_no: string
        city: string
        address: string
        logo: string
        country_id: string
        state_id: string
        // 
        has_account_number: number
        has_loan_settings: number
        is_profile_complete: number,
        is_document_approved: number,
        account_branch_subscription_id: number,
        transaction_fee_bearer :string,
        // 
        business_registration_type: string
        business_registration_no: string
        business_reg_document: string
        proof_of_address: string
        //
        can_edit: number
        can_edit_branch_name: number,
        can_edit_email: number
        can_edit_phone_no: number
        can_edit_city: number
        can_edit_address: number
        can_edit_logo: number
        can_edit_country: number
        // 
        status: number,
        payment_gateway : number,
        account_branch_subscription: {
            id: number,
            subscription_id: number,
            account_branch_id: number,
            start_date: string,
            end_date: string,
            amount: number,
            duration: number,
            sms: number,
            branch: number,
            email: number,
            members: number,
            push_notification: number,
            status: number,
            subscription: {
                sub_name: string
            }
        }
    },
    total_number_of_users: number,
    members: object[]
}

export const UserBranchProps = {
    id: 0,
    user_id: 0,
    account_branch_id: 0,
    date_joined: '',
    role_id: 0,
    status: 0,
    secret_key: '',
    savings_balance: 0,
    amount_left_to_be_returned: 0,
    no_of_incomplete_loans_repayment: 0,
    created_at: '',
    updated_at: '',
    role_text: '',
    account_branch: {
        id: '',
        account_id: 0,
        main_branch: 0,
        account_branch_key: '',
        approval_reason: '',
        branch_name: '',
        email: '',
        phone_no: '',
        city: '',
        address: '',
        logo: '',
        country_id: '',
        state_id: '',
        transaction_fee_bearer :'',
        payment_gateway : 0,
        // 
        has_account_number: 0,
        has_loan_settings: 0,
        is_profile_complete: 0,
        is_document_approved: 0,
        account_branch_subscription_id: 0,
        // 
        business_registration_type: '',
        business_registration_no: '',
        business_reg_document: '',
        proof_of_address: '',
        //
        can_edit: 0,
        can_edit_branch_name: 0,
        can_edit_email: 0,
        can_edit_phone_no: 0,
        can_edit_city: 0,
        can_edit_address: 0,
        can_edit_logo: 0,
        can_edit_country: 0,
        // 
        status: 0,
        account_branch_subscription: {
            id: 0,
            subscription_id: 0,
            account_branch_id: 0,
            start_date: '',
            end_date: '',
            amount: 0,
            duration: 0,
            sms: 0,
            branch: 0,
            email: 0,
            members: 0,
            push_notification: 0,
            status: 0,
            subscription: {
                sub_name: ''
            }
        }
    },
    total_number_of_users: 0,
    members: []
}


export interface TestResponse {
    status: boolean,
    msg: string,
    duration: number
}


//used on Branch Component
export interface AccountTypeModel {
    id: string,
    // 
    main_branch: number
    account_branch_key: string
    branch_name: string
    // 
    email: string
    phone_no: string
    // 
    city: string
    address: string
    logo: string
    country_id: string
    state_id: string
    // 
    has_account_number: number
    has_loan_settings: number
    is_profile_complete: number,
    is_document_approved: number,

    account_branch_subscription_id: number,

    // 
    bussiness_registration_type: string
    bussiness_registration_no: string
    bussiness_reg_document: string
    // 
    can_edit: number
    can_edit_branch_name: number,
    can_edit_email: number
    can_edit_phone_no: number
    can_edit_city: number
    can_edit_address: number
    can_edit_logo: number
    can_edit_country: number
    // 
    status: number,
    //    little details about the user that fetch the account details
    role_id: string,
    user_status: number,
    role_text: string,
    //
    account_branch_subscription: {
        id: number,
        subscription_id: number,
        account_branch_id: number,
        start_date: string,
        end_date: string,
        amount: number,
        duration: number,
        sms: number,
        branch: number,
        email: number,
        members: number,
        push_notification: number,
        status: number,
        subscription: {
            sub_name: string
        }
    }

}


export const AccountTypeObject = {
    id: '',
    //
    main_branch: 0,
    account_branch_key: '',
    branch_name: '',
    //
    email: '',
    phone_no: '',
    //
    city: '',
    address: '',
    logo: '',
    country_id: '',
    state_id: '',
    //
    has_account_number: 0,
    has_loan_settings: 0,
    is_profile_complete: 0,
    is_document_approved: 0,
    //
    bussiness_registration_type: '',
    bussiness_registration_no: '',
    bussiness_reg_document: '',

    account_branch_subscription_id: 0,

    //
    can_edit: 0,
    can_edit_branch_name: 0,
    can_edit_email: 0,
    can_edit_phone_no: 0,
    can_edit_city: 0,
    can_edit_address: 0,
    can_edit_logo: 0,
    can_edit_country: 0,
    //
    status: 0,
    //
    role_id: '',
    user_status: 0,
    role_text: '',
    //
    account_branch_subscription: {
        id: 0,
        subscription_id: 0,
        account_branch_id: 0,
        start_date: '',
        end_date: '',
        amount: 0,
        duration: 0,
        sms: 0,
        branch: 0,
        email: 0,
        members: 0,
        push_notification: 0,
        status: 0,
        subscription: {
            sub_name: ''
        }
    }
}

//
export interface BankTypeModel {
    id:number,
    bank_key: string,
    name: string,
    code: string
}

export const BankTypeObject = {
    id:0,
    bank_key: '',
    name: '',
    code: ''
}

export interface ThriftBankAccModel {
    id : number,
    account_name: string,
    bank_name: string,
    account_number: string,
    status: number,
    is_verified : number,
    account_for : number,
    bank: {
        name: string
    }
}

export const ThriftBankAccProps = {
    id : 0,
    account_name: '',
    bank_name: '',
    account_number: '',
    status: 0,
    is_verified : 0,
    account_for : 0,
    bank: {
        name: ''
    }
}

//:::
export interface UserSummaryModel {
    val: string,
    text: string,
    iconName: string,
    bg: string
}


//SAVINGS
export interface UsersModel {
    id: number,
    name: string,
    email: string,
    dob: string,
    gender: string,
    bvn: string,
    status: number,
    approval_status: number,
    state_id: number,
    country_id: number,
    address: string,
    phone_no: string,
    profile_image: string,
    alternate_profile_image : string,
    email_verified_at : string,
    auth_key: string,
    mac_id: string,
    device_id: string,

    account_name: string,
    bank_key: number,
    account_number: string,

    id_document: string,
    id_number: string,


    id_type_id: string,
    created_at: string,
    is_profile_complete: number,
    can_edit: number
    // savings_sum_thrift_amount: number
}

export const UsersProps = {
    id: 0,
    name: '',
    email: '',
    status: 0,
    approval_status: 0,
    id_type_id: '',
    bvn:'',
    dob: '',
    gender: '',
    state_id: 0,
    country_id: 0,
    address: '',
    phone_no: '',
    profile_image: '',
    alternate_profile_image : '',
    auth_key: '',
    mac_id: '',
    email_verified_at:"",
    created_at: '',
    account_name: '',
    bank_key: 0,
    account_number: '',
    id_number: '',
    id_document: '',
    device_id: '',
    is_profile_complete: 0,
    can_edit: 1
    // savings_sum_thrift_amount : 0,
}


// ? :::Model create for Memeber Lsiting
export interface MembersModel {
    id: string,
    user_id: number,
    account_branch_id: number,
    role_id: number,
    status: number,
    registration_route:  string,
    savings_sum_thrift_amount: number,
    role_text: string,
    user: UsersModel
}

export const MembersProps = {
    id: '',
    user_id: 0,
    registration_route: '',
    account_branch_id: 0,
    role_id: 0,
    status: 0,
    savings_sum_thrift_amount: 0,
    role_text: '',
    user: UsersProps
}


// ? ends here
//::::::::::::::::::::::::::::::::::::::::::
//
export interface AuditLogModel {
    id: string,
    // user_id
    action_type: string,
    message: string,

    old_new_data : string,
    category : string,
    user: {
        name: string
    },
    branch: {
        branch_name: string
    },
    created_at: string
}

export const AuditLogProps = {
    id: '',
    // user_id
    action_type: '',
    message: '',
    old_new_data: '',
    category: '',
    user: {
        name: ''
    },
    branch: {
        branch_name: ''
    },
    created_at: ''
}
//:::::::::::::::::::::::::::::::


//Savings
export interface AllThriftSavingsModel {
    id: string,
    transaction_reference: string,
    transaction_id: string,
    initialized_url: string,
    amount_paid: string,
    service_charge: string,
    gateway_fee: string,
    thrift_amount: string,
    actual_amount : number,
    payment_status: string,
    paid_at: string,
    created_at: string,
    payment_channel: string,
    bank_name: string,
    description : string,
    gateway_response: string,
    transaction_fee_bearer:string,
    payment_gateway_used : number,
    user: {
        id: string,
        name: string,
        profile_image: string
    },
    is_edit :number,
    edited_date_time: string,
    edited_by_email : string,
    saving_transaction_status : number,
    account_name: string,
    account_number: string,
    expires_at: string,
}

export const SavingsProps = {
    id: '',
    transaction_reference: '',
    transaction_id : '',
    initialized_url: '',
    amount_paid: '',
    service_charge: '',
    gateway_fee: '',
    thrift_amount: '',
    actual_amount: 0,
    payment_status: '',
    paid_at: '',
    created_at: '',
    payment_channel: '',
    bank_name: '',
    description : '',
    gateway_response: '',
    transaction_fee_bearer:'',
    payment_gateway_used:0,
    user: {
        id: '',
        name: '',
        profile_image: ''
    },
    is_edit :0,
    edited_date_time: '',
    edited_by_email : '',
    saving_transaction_status : 0,
    account_name: '',
    account_number: '',
    expires_at: '',
}

export interface SavingsSummary {
    bg: string,
    badgeBg: string,
    iconBg: string,
    iconColor: string,
    icon: string,
    textColor: string,
    amount: number,
    total_count: number,
    text: string,
    bgTextColor: string,
}

export const SavingsSummaryProps = {
    bg: "",
    bgTextColor: "",
    badgeBg: "",
    iconBg: "",
    iconColor: "",
    icon: "",
    textColor: "",
    amount: 0,
    total_count: 0,
    text: ""
}


//LOANS 
export interface LoanTypesModel {
    id: number,
    name: string,
    percent_interest: number,
    duration: number,
    minimum_savings_balance: number,
    multiples_of_savings: number,
    membership_period: number,
}

export const LoanTypesProps = {
    id: 0,
    name: '',
    percent_interest: 0,
    duration: 0,
    minimum_savings_balance: 0,
    multiples_of_savings: 0,
    membership_period: 0,
}

export interface LoanApplicationModel {
    id: number,
    duration: number,
    status: string,
    minimum_savings_bal: number,
    amount: number,
    expected_amount: number,
    expected_interest: number,
    application_reference_no: string,
    loans_transaction_sum_amount: number,
    savings_transaction_sum_thrift_amount: number,
    multiples_of_savings: number,
    application_date: string,
    approval_disapproval_date: string,
    disbursed_date: string,
    loan_repayment_status: number,
    loan_repayment_count : number,
    updated_at : string,
    user: {
        id: number,
        name: string,
        profile_image: string,
        alternate_profile_image : string
    }
    creator: {
        id: number,
        name: string,
    }
    loan_type: {
        name: string
    }
}

//
export const LoanApplicationProps = {
    id: 0,
    duration: 0,
    minimum_savings_bal: 0,
    amount: 0,
    status: '',
    expected_amount: 0,

    expected_interest: 0,
    loans_transaction_sum_amount: 0,
    savings_transaction_sum_thrift_amount: 0,
    multiples_of_savings: 0,
    application_reference_no: '',
    application_date: '',
    approval_disapproval_date: '',
    disbursed_date: '',
    loan_repayment_status: 0,
    loan_repayment_count: 0,
    updated_at: '',
    user: {
        id: 0,
        name: '',
        profile_image: '',
        alternate_profile_image : ''
    },
    creator: {
        id: 0,
        name: '',

    },
    loan_type: {
        name: ''
    }
}

export interface LoanAppSummaryModel  {
    text : string,
    bg : string,
    leadText : string,
    iconName : string,
    size: string
}
export const LoanAppSummaryProps = {
    text : '',
    bg: '',
    leadText : '',
    iconName : '',
    size : 'col-lg-3'
}

export interface LoanRepaymentModel {
    id: number,
    amount: number,
    status: string,
    application_reference: string,
    paid_at?: string,
    channel : string,
    initialized_url : string
}

export const LoanRepaymentProps = {
    id: 0,
    amount: 0,
    status: "0",
    application_reference: '',
    paid_at: '',
    channel : '',
    initialized_url : ''
}


//TransactionsModel
export interface ExpensesModel {
    id:number
    account_branch_id:number
    created_by:{
        name : string
    }
    user_id:number
    transaction_type:string
    category:number
    purpose:string
    desc:string
    date_created:string
    amount:number
    channel:string
    paid_at:string
    amount_paid:number
    gateway_fee:number
    thrift_amount:number
    service_charge:number
    subaccount_code:string
    payment_status:number
    transaction_reference:string
    gateway_used:string,
    initialized_url:string
    bank_name:string
    route:string
    status:number,
    account_name: string,
    account_number: string,
    expires_at: string,
    user : {
        name : string,
        logo : string
    }
}

export const ExpensesProps = {
    id:0,
    account_branch_id:0,
    created_by:{
        name : ''
    },
    user_id:0,
    transaction_type:'',
    category:0,
    purpose:'',
    desc:'',
    date_created:'',
    amount:0,
    channel:'',
    paid_at:'',
    amount_paid:0,
    gateway_fee:0,
    thrift_amount:0,
    service_charge:0,
    subaccount_code:'',
    payment_status:0,
    transaction_reference:'',
    account_name: '',
    account_number: "",
    gateway_used: "",
    expires_at: "",
    initialized_url:'',
    bank_name:'',
    route:'',
    status:0,
    user : {
        name : '',
        logo : ''
    }
}


//DashboardAnalyticModel
export interface seriesDataModel {
    data: string[]
}

// Dashboard
export interface cardSummaryModel {
    "name": string,
    "className" : string,
    "classNameOne" : string,
    "colors": string[],
    "id": string,
    "types": string,
    "title": string,
    "total": string,
    "arrow": string,
    "percentage": string,
    "data": string[],
    "label": string[],
    "series": any
}

export interface adsModel {
    icon: string,
    bg: string,
    text_color: string,
    img_url: string,
    type: string,
    text: string
}

export interface DashboardAnalyticModel {
    cardSummary: cardSummaryModel[],

    savings: {
        labels: string[],
        series: number[]
    },
    loans: {
        labels: string[],
        series: number[],
        returned: number[]
    },
    inventories: {
        labels: string[],
        series: number[]
    },
    topPerformingUsers: {
        labels: string[],
        series: number[]
    },
    overall_savings: number,
    overall_loan: number,
    overall_expense : number,

    ads: adsModel[]

}

//
export const DashboardAnalyticProps = {
    //
    cardSummary: [
        {
            "classNameOne" : "",
            "name": "",
            "id": "",
            "className" : "col-lg-6 col-xl-3",
            "colors": ['#0acf97'],
            "types": "",
            "title": "",
            "total": "0",
            "arrow": "up",
            "percentage": "0",
            "data": [],
            "label": ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            "series": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        {
            "classNameOne" : "",
            "name": "",
            "id": "",
            "className" : "col-lg-6 col-xl-3",
            "colors": ['#fa5c7c'],
            "types": "",
            "title": "",
            "total": "0",
            "arrow": "up",
            "percentage": "0",
            "data": [],
            "label": ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            "series": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        {
            "classNameOne" : "",
            "name": "",
            "id": "",
            "className" : "col-lg-6 col-xl-3",
            "colors": ['#727CF5'],
            "types": "",
            "title": "",
            "total": "0",
            "arrow": "up",
            "percentage": "0",
            "data": [],
            "label": ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            "series": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        {
            "classNameOne" : "",
            "name": "",
            "id": "",
            "className" : "col-lg-6 col-xl-3",
            "colors": ['#57BBD8'],
            "types": "",
            "title": "",
            "total": "0",
            "arrow": "up",
            "percentage": "0",
            "data": [],
            "label": ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            "series": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
    ],
    //
    savings: {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        series: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    },
    loans: {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        series: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        returned: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    },
    inventories: {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        series: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    },
    topPerformingUsers: {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        series: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    },
    overall_savings: 0,
    overall_loan: 0,
    overall_expense : 0,
    ads: [
        {
            icon: '',
            bg: '',
            text_color: '',
            img_url: '',
            type: '',
            text: ''
        },
        {
            icon: '',
            bg: '',
            text_color: '',
            img_url: '',
            type: '',
            text: ''
        }
    ]
}
// End of Dashboard Model


//
export interface savingDashboardModel {
    label: [],
    series: []
    //
}

export interface SavingDashboardAnalyticModel {
    total_no_of_savings_current_month: number,
    total_no_of_savings_prev_month: {
        sign: number,
        value: number
    },
    total_no_of_savings_current_week: number,
    total_no_of_savings_prev_week: {
        sign: number,
        value: number
    },
    savings_ads: {
        title: string,
        url: string,
        location: string,
        img_url: string
    },
    news_ads: {
        "title": string,
        "link": string
    },
    savingsDashboard: {
        labels: string[],
        series: number[]
    },
    total_no_of_savings_year: {
        labels: string[],
        series: number[]
    },
    top_performaing_user: {
        labels: string[],
        series: number[]
    },
    branch_performance: {
        labels: string[],
        series: number[]
    }
}

export const SavingDashboardAnalyticProps = {
    total_no_of_savings_current_month: 0,
    total_no_of_savings_prev_month: {
        sign: 1,
        value: 0
    },
    total_no_of_savings_current_week: 0,
    total_no_of_savings_prev_week: {
        sign: 1,
        value: 0
    },
    savings_ads: {
        title: '',
        url: '',
        location: '',
        img_url: ''
    },
    news_ads: {
        "title": '',
        "link": ''
    },
    savingsDashboard: {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        series: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    },
    total_no_of_savings_year: {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        series: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    },
    top_performaing_user: {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        series: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    },
    branch_performance: {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        series: [1000, 9000, 9000, 0, 0, 0]
    },
}


export interface UserPendingLoansModel {
    id: number,
    user: {
        name: string
    },
    disbursed_date: string,
    amount: number,
    expected_amount: number,
    loans_transaction_sum_amount: number,
    country_id: string,
    state_id: string
}

export interface LoanDashboardAnalyticModel {
    total_amount_disbursed_current_month: number,
    interest_from_loan_disbursed_current_month: number,
    total_amount_return_current_month: number,
    interest_from_loan_returned_current_month: number,
    total_amount_disbursed_current_year: number,
    nterest_from_loan_disbursed_current_year: number,
    total_amount_return_current_year: number,
    interest_from_loan: number,
    potential_interest:
        {
            "name": string,
            "data": number[]
        },
    actual_interest: {
        "name": string,
        "data": number[]
    }
    disbursed:
        {
            "name": string,
            "data": number[]
        },
    returned: {
        "name": string,
        "data": number[]
    }
    labels: string[],
    user_pending_loans: UserPendingLoansModel[]

}

export const LoanDashboardAnalyticProps = {
    total_amount_disbursed_current_month: 0,
    interest_from_loan_disbursed_current_month: 0,
    total_amount_return_current_month: 0,
    interest_from_loan_returned_current_month: 0,
    total_amount_disbursed_current_year: 0,
    nterest_from_loan_disbursed_current_year: 0,
    total_amount_return_current_year: 0,


    interest_from_loan: 0,

    potential_interest: {
        "name": '',
        "data": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    },
    actual_interest: {
        "name": '',
        "data": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    },
    disbursed: {
        "name": '',
        "data": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    },
    returned: {
        "name": '',
        "data": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    },
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    user_pending_loans: []

}