import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setGeneralAction } from '../../../config/redux/actions'
import { types } from '../../../config/redux/types'
import styles from '../styles/toast.module.css'

interface Props {
    msg : string
}
const ToastAlert:React.FC<Props> = ({msg}) => {

  const dispatch = useDispatch()
  
  useEffect(() => {
        window.setTimeout(() => {dispatch(setGeneralAction(types.CLEAR_MSG,[])) }, 4900)

    },[])



    return <>
      <div id={styles.toast} className={styles.show}>
            <div id={styles.img}>Icon</div>
            <div id={styles.desc}>{msg}</div>
      </div>
    </>
}


export default ToastAlert