import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Link, Navigate, useSearchParams, useNavigate, useParams,} from "react-router-dom"
import {Spinner} from "../../../components"
import {ErrorAlert, SuccessAlert} from "../../../components/alert"
import {postRequest, setGeneralAction} from "../../../config/redux/actions"
import {AppState} from "../../../config/redux/reducers"
import {AuthState} from "../../../config/redux/reducers/tmsAuthReducer"
import {SavingsReducerState} from "../../../config/redux/reducers/tmsSavingsReducer"
import {types} from "../../../config/redux/types"
import {url} from "../../../config/urls"
import {remote_url} from "../../../config/urls/remote"
import DashboardWrapper from "../DashboardWrapper"
import BreadCrumb from "../layouts/BreadCrumb"
import {VerificationComponent} from "../_components";


type SavingsCanvasProps = {
    show?: string
}
export type UrlParams = {
    transaction_ref: string,
    gateway_id: string
}

const SavingsVerify: React.FC<SavingsCanvasProps> = ({show = 'show'}) => {
    const {loading_, success_msg, error_msg, transaction_id} = useSelector<AppState, SavingsReducerState>((state) =>
        state.tmsSavingsReducer)
    const {token, account_key, user, role_id} = useSelector<AppState, AuthState>((state) => state.tmsAuthReducer)

    const [searchParams] = useSearchParams();
    let {transaction_ref, gateway_id} = useParams<UrlParams>()
    const dispatch = useDispatch()


    useEffect(() => {
        setSavingsRef()
    }, [])

    //


    const setSavingsRef = () => {
        if (transaction_ref) {
            let trans_id = searchParams.get('transaction_id') ?? '';
            let finalTransId = ''

           //I Dont want this to be replaced with empty data
            if(trans_id !== ''){
                dispatch(setGeneralAction(types.SET_TRANS_REF, {
                    transaction_ref,
                    trans_id
                }))
                finalTransId = trans_id
            }
            else{
                finalTransId = transaction_id
            }

            //:::-:::-:::
            const postData = {
                token,
                url: remote_url.verifySavings,
                actionType: types.VERIFY_SAVINGS,
                postData: {
                    tranxRef: transaction_ref,
                    gateway_id,
                    transaction_id: finalTransId
                },
                secretData: {
                    email: user.email,
                    mac_id: user.mac_id,
                    r_id: role_id
                },
                errorAction: types.SET_ERROR,
                accountKey: account_key,
                loading: {
                    isLoading: false,
                    isLoading_: true
                }
            }
            dispatch(postRequest(postData))
        }
    }

    //
    return <DashboardWrapper showWarning={false}>

        <>
            {
                transaction_ref &&
                <VerificationComponent
                    transaction_ref={transaction_ref}
                    loading_={loading_}
                    success_msg={success_msg}
                    error_msg={error_msg}
                    verificationFunction={setSavingsRef}
                    url={url.savings}
                />
            }
        </>


    </DashboardWrapper>
}


export default SavingsVerify

//
//       <div className="row">
//                             <div className="col-sm-6">
//                                 <div className="float-end mt-3">
//                                     <p><b>Hello, {singleSavings.user?.name}</b></p>
//                                     <p className="text-muted font-13">Please find below a cost-breakdown for the recent work completed.
//                                     Please make payment at your earliest convenience, and do not hesitate to contact me with any questions.</p>
//                                 </div>
//                             </div>
//                             <div className="col-sm-4 offset-sm-2">
//                                 <div className="mt-3 float-sm-end">
//                                     <p className="font-13"><strong>Payment Date: </strong>   <span className="float-end">{singleSavings.paid_at}</span> </p>
//                                     <p className="font-13"><strong>Payment Status: </strong>
//                                      {
//                                         singleSavings.payment_status ==='1'
//                                         ?
//                                         <span className="badge bg-success float-end">Paid</span>
//                                         :
//                                         <span className="badge bg-warning float-end">Unpaid</span>
//                                       }
//                                     </p>
//                                     <p className="font-13">
//                                         <strong>Order ID: </strong>
//                                         <span className="float-end"># {singleSavings.transaction_reference} </span>
//                                     </p>
//                                 </div>
//                             </div>
//                         </div>
//
//
//
//                         <div className="row mt-4">
//                             <div className="col-sm-4"></div>
//                             <div className="col-sm-4"></div>
//                             <div className="col-sm-4">
//                                 <div className="text-sm-end">
//                                    {
//                                         loading_
//                                         ?
//                                         <div className="text-center">
//                                             <div className="modal-backdrop fade show" onClick={()=> goHome()}></div>
//                                             <Spinner color="primary"/>
//                                             <span className="text-center">Verifying Payment......</span>
//                                         </div>
//                                         :
//                                         <img src="/assets/images/barcode.png" alt="barcode" className="img-fluid me-2"/>
//                                     }
//                                 </div>
//                             </div>
//                         </div>
//
//
//                         <div className="row">
//                             <div className="col-12">
//                                 <div className="table-responsive">
//                                     <table className="table mt-4">
//                                         <thead>
//                                             <tr>
//                                                 <th>#</th>
//                                                 <th>Item</th>
//                                                 <th className="text-end">Total</th>
//                                             </tr>
//                                         </thead>
//
//                                         <tbody>
//                                         <tr>
//                                             <td>1</td>
//                                             <td>Savings Amount</td>
//                                             <td className="text-end">₦ {singleSavings.thrift_amount}</td>
//                                         </tr>
//                                         <tr>
//                                             <td>2</td>
//                                             <td>Transaction Fee</td>
//                                             <td className="text-end">₦{singleSavings.gateway_fee}</td>
//                                         </tr>
//                                         <tr>
//                                             <td>3</td>
//                                             <td>Service Charge </td>
//                                             <td className="text-end">₦{singleSavings.service_charge}</td>
//                                         </tr>
//
//                                         </tbody>
//                                     </table>
//                                 </div>
//                             </div>
//                         </div>
//
//
//                         <div className="row">
//                             <div className="col-sm-6">
//                                 <div className="clearfix pt-3">
//                                     <h6 className="text-muted">Notes:</h6>
//                                     <small>          </small>
//                                 </div>
//                             </div>
//                             <div className="col-sm-6">
//                                 <div className="float-end mt-3 mt-sm-0">
//                                     <p><b>Sub-total:</b> <span className="float-end">
//                                         ₦{Number(singleSavings.amount_paid).toLocaleString()}
//                                     </span></p>
//                                     <p><b>VAT (0.0):</b> <span className="float-end">$0.00</span></p>
//                                     <h3>₦{Number(singleSavings.amount_paid).toLocaleString()} NGN</h3>
//                                     {/* <ViewSavings saving={singleSavings}/> */}
// </div>
// <div className="clearfix"></div>
// </div>
// </div>
//
//
// <div className="d-print-none mt-4">
//     <div className="text-end">
//         <Link to="#"  className="btn btn-primary">
//             <i className="mdi mdi-printer"></i> Print
//         </Link>
//         <Link to={url.savings} className="btn btn-info">Close</Link>
//     </div>
// </div>

