
import { useDispatch } from "react-redux"
import { setGeneralAction } from "../../config/redux/actions"
import { types } from "../../config/redux/types"
import React, {useEffect} from "react";

import {useNotifications} from "reapop";



interface Props {
    msg : string
}

const SuccessAlert:React.FC<Props> = ({msg}) => {

    const dispatch = useDispatch()
    const {notify,dismissNotifications} = useNotifications()
    const closeTab = () => {
        dispatch(setGeneralAction(types.CLEAR_MSG,[]))

    }

    useEffect(() => {
        showSuccessNotification()
    },[])

    const showSuccessNotification = () => {
        notify(msg, 'success')
        setTimeout(() => {
            dispatch(setGeneralAction(types.CLEAR_MSG,[]))
            dismissNotifications()
        },5000)
    }

    return <>
        {/*<ToastContainer style={{width:'100%',marginTop:0,top:0}} />*/}
    </>
}

export default SuccessAlert