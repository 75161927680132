import {GeneralModal} from "../../../_versionTwo/pages/_component";
import React, {useState} from "react";



const guarantorFormArrayNew = [
    {
        newSection : true,
        sectionClassName :'gridContainer members_detail_section',
        sectionType:'normal',
        sectionTitle :'Section 1: Members Details',
        sectionSubTitle: "",
        hasChildren : true,
        children : [
            {
                key : 'guarantorFormKey1',
                label : 'A GUARANTEE IN THE SUM OF N',
                value : '200000.00',
                form_type : 'text',
                parentClassName:'gridItem',
                className : 'underlineDotted'
            },
            {
                key : 'guarantorFormKey2',
                label: 'IN FAVOUR OF',
                value: 'Dere Sunday Toluwani',
                form_type : 'text',
                parentClassName:'gridItem',
                className : 'underlineDotted'
            }
        ],
        key:'SectionOne',
        newForm : {
            key : '',
            label : 'Some goes  Here',
            value : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            form_type : 'text',
            parentClassName:'gridItem',
            className : 'underlineDotted'
        },
    },
    //Section 2
    {
        newSection : true,
        sectionClassName :'warning_section py-2',
        sectionTitle :'Section 2 : Introduction',
        sectionSubTitle: "",
        hasChildren : false,
        sectionType:'normal',
        children : [],
        detail : {
            key : 'sectionTwoKey1',
            label : 'I understand that the above named applicant\'s has/have applied to your cooperative for a loan of the above stated amount and as guarantor of the above stated amount furnish you under my signature and necessary information regarding myself',
            value : '',
            form_type : 'text',
        },
        key:'SectionTwo',

    },
    //Section 3
    {
        newSection : true,
        sectionClassName :'gridContainer guarantor_detail_section',
        sectionTitle :'Section 3 : Guarantor Details',
        sectionSubTitle: "",
        hasChildren : true,
        sectionType:'normal',
        children : [
            {
                label : 'Surname',
                value :'John',
                key : 'sectionThree1',
                form_type : 'text',
                parentClassName:'gridItem',
                className : 'underlineDotted'
            },
            {
                label : 'Others name',
                value : 'Doe',
                key : 'sectionThree2',
                form_type : 'text',
                parentClassName:'gridItem',
                className : 'underlineDotted'
            },
            {
                label : 'Title',
                value : 'Mr',
                key : 'sectionThree3',
                form_type : 'text',
                parentClassName:'gridItem',
                className : 'underlineDotted'
            },
            {
                label : 'Home Address',
                value : 'lorem ipsum lorem test street and me is here',
                key : 'sectionThree4',
                form_type : 'text',
                parentClassName:'gridItem',
                className : 'underlineDotted'
            },
            {
                label : 'Phone Number',
                value : '08113765996',
                key : 'sectionThree5',
                form_type : 'text',
                parentClassName:'gridItem',
                className : 'underlineDotted'
            },
            {
                label : 'Sex',
                value : 'Male',
                key : 'sectionThree6',
                form_type : 'text',
                parentClassName:'gridItem',
                className : 'underlineDotted'
            },
            {
                label : 'Marital Status',
                value : 'Married',
                key : 'sectionThree7',
                form_type : 'text',
                parentClassName:'gridItem',
                className : 'underlineDotted'
            },
            {
                label : 'State Origin',
                value : 'Kwara',
                key : 'sectionThree8',
                form_type : 'text',
                parentClassName:'gridItem',
                className : 'underlineDotted'
            },
            {
                label : 'LGA',
                value : 'Oyun',
                key : 'sectionThree9',
                form_type : 'text',
                parentClassName:'gridItem',
                className : 'underlineDotted'
            },
            {
                label : 'Employer',
                value : 'Dream labs Ng Ltd',
                key : 'sectionThree10',
                form_type : 'text',
                parentClassName:'gridItem',
                className : 'underlineDotted'
            },
            {
                label : 'Office Address',
                value : 'Dream labs Ng Ltd',
                key : 'sectionThree11',
                form_type : 'text',
                parentClassName:'gridItem',
                className : 'underlineDotted'
            },
            {
                label : 'Office Phone Number',
                value : '08065854979',
                key : 'sectionThree12',
                form_type : 'text',
                parentClassName:'gridItem',
                className : 'underlineDotted'
            },
            {
                label : 'Department',
                value : 'Social Department',
                key : 'sectionThree13',
                form_type : 'text',
                parentClassName:'gridItem',
                className : 'underlineDotted'
            },
            {
                label : 'Grade',
                value : 'Level 7',
                key : 'sectionThree14',
                form_type : 'text',
                parentClassName:'gridItem',
                className : 'underlineDotted'
            },
            {
                label : 'Position',
                value : 'Assistant Director',
                key : 'sectionThree15',
                form_type : 'text',
                parentClassName:'gridItem',
                className : 'underlineDotted'
            },
        ],
        key:'SectionThree',
        newForm: {
            key : '',
            label : '',
            value : '',
            form_type : 'text',
            parentClassName:'gridItem',
            className : 'underlineDotted',
        }
    },
    //Section 4
    {
        newSection : true,
        sectionClassName :'',
        sectionTitle :'Section 4 : Terms and Condition',
        sectionSubTitle: "",
        sectionType:'list',
        hasChildren : true,
        children : [
            {
                key : 'sectionFour0',
                label : "The Guarantor shall take all reasonable measures to ensure that the Borrower repays the loan in accordance with the terms of the offer letter and to cooperate with the Cooperative in collecting any installment due to repayment together with any interest and penalties accrued under the terms of the loan agreement.",
                value : '200000.00',
                form_type : 'text',
                parentClassName:'gridItem',
                className : 'underlineDotted'
            },
            {
                key : 'sectionFour1',
                label: 'If the Borrower defaults in paying to this Company any installment, then I would indemnify the Cooperative against principal sum with interest including but not limited to all cost expended towards recovering the loan.',
                value: '',
                form_type : 'text',
                parentClassName:'gridItem',
                className : 'underlineDotted'
            },
            {
                key : 'sectionFour2',
                label: 'The guarantor shall not have the right withdraw from this guarantee without first obtain in the company\'s consent in writing provided that there are no outstanding liabilities on the part of the guarantor',
                value: '',
                form_type : 'text',
                parentClassName:'gridItem',
                className : 'underlineDotted'
            },
            {
                key : 'sectionFour3',
                label: 'Notwithstanding the modification to the terms of the loan so as to extend the repayment periods or as to increase or decrease the amount of periodic payments, the Guarantor\'s agreement. This Guarantee shall be continuous and the liability of the guarantor shall remain irrevocable until the present and other incidental cost are fully paid.',
                value: '',
                form_type : 'text',
                parentClassName:'gridItem',
                className : 'underlineDotted'
            },
            {
                key : 'sectionFour4',
                label: 'I declare that all particulars and information given in the above form are true, correct and complete and that they shall form the basis of loan to be availed from TAJO MULTI-PURPOSE COOPERATIVE SOCIETY',
                value: '',
                form_type : 'text',
                parentClassName:'gridItem',
                className : 'underlineDotted'
            },
        ],
        key:'SectionFour'
    },
    //section 5
    {
        newSection : true,
        sectionClassName :' my-3 row',
        id:'',
        sectionTitle :'Section 5: Verification and Signature',
        sectionSubTitle :'I confirm that I have read the content of the offer letter and understood the terms and conditions properly before giving my consent to stand as a guarantor.',
        sectionType:'normal',
        hasChildren : true,
        children : [
            {
                key : 'sectionFive0',
                form_type : 'text',
                label : 'Name',
                value : 'Samuel Ajalekoko',
                parentClassName:'col-4',
                className : 'underlineDotted'
            } ,
            {
                key : 'sectionFive1',
                form_type : 'text',
                label: 'Signature',
                value:  'Jdkakak',
                parentClassName:'col-4',
                className : 'underlineDotted'
            },
            {
                key : 'sectionFive2',
                form_type : 'text',
                label: 'Date',
                value:  '20/01/2030',
                parentClassName:'col-4',
                className : 'underlineDotted'
            }
        ],
        key:'SectionFive',
        newForm: {
            key : '',
            label : '',
            value : '',
            form_type : 'text',
            parentClassName:'gridItem',
            className : 'underlineDotted',
        }
    },
]

interface Props{
    handelCloseModal : any
}


const CreateLoanGuarantorForm:React.FC<Props> = ({handelCloseModal}) => {
    //     const { register, handleSubmit, watch,formState: { errors },reset } = useForm<FormValues>()
    const [guarantorForms,setFormsData] = useState(guarantorFormArrayNew)

    function manipulateArrayObjectsByKey(array: any[], keyToMatch: string, newData: any) {
            return array.map(obj => {
                if(!obj.hasChildren){
                    if (obj.detail.key === keyToMatch) {
                        obj.detail = {
                            ...obj.detail,
                            label: newData
                        };
                    }
                }
                return obj;
            });


    }

    function manipulateArrayObjectsByKeyWithChildren(array: any[], keyToMatch: string, newData: any,index:number) {
        return array.map(obj => {
            if(obj.hasChildren){
                if(obj.children[index]){
                    if (obj.children[index].key === keyToMatch) {
                        console.log()
                        obj.children[index] = {
                            ...obj.children[index],
                            label: newData
                        };
                    }
                }
            }
            return obj;
        });


    }

    function deleteObjectsByKeyWithChildren(array: any[], keyToMatch: string, newData: any, index: number) {
        return array.map(obj => {
            if (obj.hasChildren && obj.children[index]) {
                // Check if the child object's key matches the keyToMatch
                if (obj.children[index].key === keyToMatch) {
                    // If it matches, delete the child object
                    delete obj.children[index];
                }
            }
            return obj;
        });
    }

    function addObjectsByKeyWithChildren(array: any[], keyToMatch:string, newData: any, index: number) {
        return array.map(obj => {
            if (obj.hasChildren && obj.key===keyToMatch) {
                let x = {...obj.newForm,key:`section${keyToMatch}${index*2}`}
              obj.children.push(x)
            }
            return obj;
        });
    }

    const updateFormData = (value: string, key: string, hasChildren: boolean,index=0) => {
        let x;
        if(hasChildren){
            //Wisdom . Strength ,, Grace,  Mercy
           x = manipulateArrayObjectsByKeyWithChildren(guarantorForms, key, value,index);
        }
        else{
            x= manipulateArrayObjectsByKey(guarantorForms, key, value)
        }
        setFormsData(x);
    }

    const handleDeleteWidget = (key:string,hasChildren:boolean,index:number)=>{
        //formData.key,mainFormData.hasChildren,y
        console.log('i was here')
        let x =deleteObjectsByKeyWithChildren(guarantorForms,key,hasChildren,index)
        setFormsData(x)
    }
    const handleAddWidget = (key:string,hasChildren:boolean,index:number)=>{
       let x = addObjectsByKeyWithChildren(guarantorForms,key,'',index)
        setFormsData(x)
    }



    return(
        <GeneralModal closeModal={handelCloseModal} modalSize={'modal-full-width modal-dialogue-scrollable'} modalTitle={'Guarantor Form'} showModal={true} key={'guanrantorForm'} >
            <div className={'row'}>
                <div className={'col-sm-8 p-3'}>
                    <form>
                        <section className={`text-center py-2`} id={'formTitle'}>
                            {/*<h4>{guarantorForm.form_title.value}</h4>*/}
                            <hr/>
                        </section>
                        {
                            guarantorForms.map((form,x=0)=>
                                form.newSection ?
                                    <section className={form.newSection ? form.sectionClassName: ''}>
                                        <div className={'mb-3'}>{form.sectionSubTitle}</div>
                                        {
                                            form.hasChildren ?
                                               <>
                                                   {
                                                       form.sectionType==='normal' ?
                                                           form.children.map((item,key=0)=>
                                                               <div key={key} className={item.parentClassName}>
                                                                   <span>{item.label}:</span>
                                                                   <span className={item.className}> <i> &nbsp; {item.value}</i></span>
                                                               </div>
                                                           )
                                                           :
                                                           <>
                                                               <h6>UNDERTAKING</h6>
                                                               <ul style={{listStyle:'numbers'}}>
                                                                   {
                                                                       form.children.map((item,index)=>{
                                                                           return(
                                                                               <li key={index} className={'text-justify mb-2'}>{item.label}</li>
                                                                           )
                                                                       })
                                                                   }
                                                               </ul>
                                                           </>
                                                   }

                                               </>
                                                :
                                                <div>{form?.detail?.label}</div>
                                        }
                                    </section>
                                    :
                                    <></>
                            )
                        }
                    </form>
                </div>

                <div className={'col-sm-4'}>
                    <h1>Edit Form</h1>
                    {
                        guarantorForms.map((mainFormData,x=0)=>
                            mainFormData.newSection
                                ?
                                <fieldset className={'newSectionDynamicForm'}>
                                    <legend>
                                        {mainFormData.sectionTitle}
                                        <span style={{cursor:'pointer'}} className={'util uil-plus float-end text-primary'}
                                              onClick={()=> handleAddWidget(mainFormData.key,mainFormData.hasChildren,x)}>
                                        </span>
                                    </legend>
                                    {
                                        mainFormData.hasChildren ?
                                            <>
                                                {
                                                    mainFormData.sectionSubTitle!=='' &&
                                                    <div className={'form-group mb-2'}>
                                                        <input
                                                            className={'form-control'}
                                                            type={'text'} value={mainFormData.sectionSubTitle}
                                                        />
                                                    </div>
                                                }

                                                {
                                                    mainFormData.children.map((formData,y=0)=>
                                                        <div className={'form-group mb-2 d-flex'} key={y}>
                                                            <input
                                                                multiple={true}
                                                                className={'form-control'}
                                                                type={'text'}
                                                                value={formData.label}
                                                                name={formData.key}
                                                                onChange={(e)=>updateFormData(e.target.value,formData.key,mainFormData.hasChildren,y)}
                                                            />
                                                            <span className={'mdi mdi-close ms-1'} onClick={()=>handleDeleteWidget(formData.key,mainFormData.hasChildren,y)}></span>
                                                        </div>
                                                    )
                                                }
                                            </>
                                            :
                                            <div className={'form-group mb-2'}>
                                                <input
                                                    className={'form-control'}
                                                    type={'text'}
                                                    value={mainFormData?.detail?.label}
                                                    name={mainFormData?.detail?.key}
                                                    onChange={(e)=>updateFormData(e.target.value,mainFormData?.detail?.key??"0",mainFormData.hasChildren)}
                                                />
                                            </div>
                                    }

                                </fieldset>
                                :
                                <></>
                        )
                    }
                </div>
            </div>
        </GeneralModal>
    )
}

export default CreateLoanGuarantorForm
