import {Link} from "react-router-dom";
import {Spinner} from "../../../../components";
import {appTxt} from "../../../../config/strings";
import { ExpensesModel} from "../../../../config/model/authModel";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../config/redux/reducers";
import {ExpenseReducerState} from "../../../../config/redux/reducers/tmsExpenseReducer";
import {setLoading} from "../../../../config/redux/actions";
import {BankDetailsWrapperComponent} from "../../_components";
import React from "react";
import {ErrorAlert, SuccessAlert} from "../../../../components/alert";
import {AuthState} from "../../../../config/redux/reducers/tmsAuthReducer";

type ViewExpensesProps = {
    expense : ExpensesModel,
    show ?: string,
    backdrop? :boolean,
    dispatch ?:any
    isLoading : boolean
    requery : any
}


const ExpenseDetails:React.FC<ViewExpensesProps> = ({expense,show="true",isLoading,requery}) => {
    const {success_msg,error_msg } = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
    const {loading_t} = useSelector<AppState,ExpenseReducerState>((state) => state.tmsExpenseReducer)
    const dispatch = useDispatch()

    const requeryNow = (item:ExpensesModel) => {
        dispatch(setLoading({
            isLoading : false,
            isLoading_ : false,
            isLoading_t : true
        }))

        if(item.initialized_url){
            window.location.href = item.initialized_url
        }

    }

    return <>
        {success_msg && <SuccessAlert msg={success_msg}/>}
        {error_msg && <ErrorAlert msg={error_msg}/>}
        <div
            className={`offcanvas offcanvas-end ${show}`}
            tabIndex={-1}
            id="expenseDetails"
            aria-labelledby="offcanvasRightLabel"
        >
            <div className="offcanvas-header">
                <h5 id="offcanvasRightLabel">
                   {expense?.transaction_reference}
                </h5>
                <Link
                    to="#0"
                    // onClick={() => clearData()}
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                >
                </Link>

            </div>

            <div className="offcanvas-body">
                <div className="table-responsive">
                    <table className="table mb-0">
                        <thead className="table-light">

                        <tr>
                            <th>Description</th>
                            <th>Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td> Name  </td>
                            <td>  {expense?.user?.name} </td>
                        </tr>
                        <tr>
                            <td> Payment  Channel </td>
                            <td> {expense.channel ?? 'N/A'}</td>
                        </tr>

                        <tr>
                            <td> Payment  Status </td>
                            <td>
                                {Number(expense?.payment_status)===1 || Number(expense.payment_status) === 2 ? <span className="badge bg-success">Paid</span> : <span className="badge bg-warning">Unpaid</span>}
                            </td>
                        </tr>

                        <tr>
                            <td>  Amount </td>
                            <td> ₦{Number(expense?.thrift_amount).toLocaleString()}</td>
                        </tr>
                        <tr>
                            <td>Transaction Type </td>
                            <td> {expense?.transaction_type}</td>
                        </tr>
                        <tr>
                            <td>Category  </td>
                            <td> {expense?.category === 1 ? 'member' : 'thrift'}</td>
                        </tr>
                        <tr>
                            <td>Purpose  </td>
                            <td> {expense?.purpose}</td>
                        </tr>
                        <tr>
                            <td>Description  </td>
                            <td> {expense?.desc}</td>
                        </tr>
                        <tr>
                            <td>Created Date </td>
                            <td> {expense?.date_created}</td>
                        </tr>

                        <tr>
                            <td>Payment Date  </td>
                            <td> {expense?.paid_at}</td>
                        </tr>

                        <tr>
                            <td>Charged to  </td>
                            <td> {expense?.user.name}</td>
                        </tr>

                        <tr>
                            <td> Created by </td>
                            <td> {expense?.created_by?.name}</td>
                        </tr>


                        {
                            (Number(expense?.payment_status) === 0 && expense.transaction_type === 'income' && expense.channel ==='card')
                                &&
                            <tr aria-colspan={2}>
                                <th></th>
                                <th>
                                    {
                                        loading_t ?
                                        <Spinner color="primary"/>
                                        :
                                        <Link
                                            to="#0"
                                            onClick={() => requeryNow(expense)}
                                            className="btn btn-outline-primary">
                                            requery
                                        </Link>
                                    }
                                </th>
                            </tr>
                        }


                        </tbody>
                    </table>
                </div>

                {

                    Number(expense.payment_status)===0 && expense.channel==='bank' &&
                    <BankDetailsWrapperComponent
                        bank_name={expense.bank_name}
                        account_number={expense.account_number}
                        amount_paid={expense.amount_paid}
                        account_name={expense.account_name}
                        expires_at={expense.expires_at}
                        dispatch={dispatch}
                        loading={isLoading}
                        requeryNow={requery}
                        showBtn={true}
                    />
                }
            </div>


        </div>



        {/* {loading_ && <div className="modal-backdrop fade show"></div>} */}{/* </div> */}
    </>
}

export default  ExpenseDetails