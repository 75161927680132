import {ErrorAlert, SuccessAlert} from "../../components/alert";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../config/redux/reducers";
import {AuthState} from "../../config/redux/reducers/tmsAuthReducer";
import {Link, Navigate, useParams, useSearchParams} from "react-router-dom";
import {remote_url} from "../../config/urls/remote";
import {types} from "../../config/redux/types";
import {postRequest} from "../../config/redux/actions";
import AuthWrapper from "./AuthWrapper";
import {Spinner} from "../../components";
import {url} from "../../config/urls";
import axios from "axios";
import {pageKeys} from "./SuccessScreen";



type UrlParams = {
    email ?: string,
    id?: string,
    phone_no ?:string,
    type ?: string
}


const RejectInvite = () => {
    const [verififcationInfo,setVerififcationInfo] = useState<any>(null)
    const {user,loading,error_msg,success_msg} = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
    const dispatch = useDispatch()
    let {email,id,phone_no,type} = useParams<UrlParams>()

    const verifyAccountDetails = async () => {
        try{
            const postData = {
                email,id,phone_no,type
            }
            const resp = await axios.post(
                `${remote_url.getInvitationDetail}`,
                postData,
                {
                        headers: {'Content-type': 'application/json', 'Accept': 'application/json'}
                    }
                )
            if(resp.status===200){
                setVerififcationInfo(resp.data.data)
            }
        }catch (e){
            // setShowErrorScreen(true)
        }
    }

    useEffect(() => {
        verifyAccountDetails().then(()=>'')
    }, []);

    const rejectInvite = (status:string) => {
        const postData = {
            token : '',
            url:remote_url.rejectInvite,
            actionType: types.REJECT_INVITE,
            postData : {
                email ,
                id,
                phone_no,
                type,
                status
            },
            secretData : {
                email:'',
                mac_id :'',
                r_id:0
            },
            // reset,
            errorAction : types.SET_ERROR,
            loading :{
                isLoading :true,
                isLoading_ : false,
                isLoading_t: false
            },
            accountKey :''
        }
        //
        dispatch(postRequest(postData))
    }
    if(success_msg){
        return <Navigate to={`${url.successScreenPre}${pageKeys[2]}?msg=${success_msg}`}/>
    }

    if(error_msg){
        return <Navigate to={`${url.successScreenPre}${pageKeys[3]}?msg=${error_msg}`}/>
    }

    return <>
        <AuthWrapper>
            <div className="card">

                <div className="card-header pt-4 pb-4 text-center bg-primary">
                    <Link to={url.home}>
                        <span><img src="/assets/img/b.png" alt="" height="48"/></span>
                    </Link>
                </div>

                <div className="card-body p-4">
                    <div className="text-center w-75 m-auto">
                        <h4 className="text-dark-50 text-center mt-0 fw-bold">{verififcationInfo?.branch_name??''} Cooperative invite </h4>
                        <p className=" alert alert-warning  mb-4">you have been invited to join {verififcationInfo?.branch_name??''} Thrift Society </p>
                    </div>
                    {success_msg && <SuccessAlert msg={success_msg}/>}
                    { error_msg && <ErrorAlert msg={error_msg}/> }
                    <div className={'text-center py-4'}>
                        {loading ? <Spinner color={'primary'}/> :
                            <>
                                <span  className={'btn btn-outline-danger mr-2'} onClick={() => rejectInvite('decline')}> Decline </span>
                                &nbsp;
                                &nbsp;
                                <span  className={'btn btn-success'} onClick={() => rejectInvite('accept')}> Accept </span>
                            </>
                        }
                    </div>
                </div>
            </div>


            <div className="row mt-3">
                <div className="col-12 text-center">
                    <p className="text-muted">Back to
                        <Link to={url.home} className="text-muted ms-1"><b>Log In</b></Link>
                    </p>
                </div>
            </div>

        </AuthWrapper>
    </>
}
export default  RejectInvite