import {payStackSettlementDatatableConfig} from "../../../_config/dataTableConfig";
import {resetDatatable} from "../../../_config/redux/feacture/settlementsReconcilations/settlementReconciliationSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../_config/redux/store";
import {useEffect} from "react";
import {TableWrapper} from "../../_component";
import SettlementFilter from "../filters/SettlementFilter";
import {Loader} from "../../../../pages/admin/_components";

const PaystackSettlemeent = () => {
    const dispatch = useDispatch()

    const {settlements_pay_stack, current_gateway,isNewSettlementData} = useSelector((state:RootState)=>state.settlementReconciliation)


    const initializeDatatable = () => {
        //&& Number(current_gateway) === 2
        payStackSettlementDatatableConfig(settlements_pay_stack,'pay_stack_settlement_table',[1, 'desc'],[])
        dispatch(resetDatatable({}))
    }

    useEffect(() => {
        initializeDatatable()
    }, []);

    if(isNewSettlementData ){
          initializeDatatable()
    }

    return (<>

        <TableWrapper
            id={'pay_stack_settlement_table'}
            tableHeaders={["Reference", "Acc No", "Bank Name", "Acc Name", "Amount Settled", "Settlement Date", "Action"]}
        >
            <SettlementFilter/>
        </TableWrapper>
    </>)
}

export default PaystackSettlemeent