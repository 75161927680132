import {ApexOptions} from "apexcharts";
import {appTxt} from "../../../../config/strings";

export const incomeGraph : ApexOptions = {
    chart: {
        height: 380,
        type: 'bar',
        stacked: !1,
        toolbar: { show: !1 },
        sparkline:{
            enabled:false
        }
    },
    dataLabels: {
        enabled: false
    },
    plotOptions: { bar: { horizontal: !1 } },
    stroke: { width: 1, colors: ['#fff'] },
    colors: ['#5C65CA'], //,'#57BBD8','#727cf5'
    tooltip: {
        y: {
            formatter: function (e) {
                return  appTxt.naira_ + e.toLocaleString()
            },
        },
    },
    fill: { opacity: 1 },
    legend: { position: 'top', horizontalAlign: 'center', offsetY: 10 },
    grid: { borderColor: '#f1f3fa', padding: { top: 0 } },
}

export const expenseGraph : ApexOptions = {
    chart: {
        height: 380,
        type: 'bar',
        stacked: !1,
        toolbar: { show: !1 },
    },
    dataLabels :{
        enabled:false
    },
    plotOptions: { bar: { horizontal: !1 } },
    stroke: { width: 1, colors: ['#fff'] },
    colors: ['#57BBD8'], //,'#57BBD8','#727cf5'
    tooltip: {
        y: {
            formatter: function (e) {
                return   appTxt.naira_ + e.toLocaleString()
            },
        },
    },
    fill: { opacity: 1 },
    legend: { position: 'top', horizontalAlign: 'center', offsetY: 10 },
    grid: { borderColor: '#f1f3fa', padding: { top: 0 } },
}


export const incomesExpensesGraph: ApexOptions = {
    chart: { height: 309, type: 'area' },
    dataLabels: { enabled: !1 },
    stroke: { curve: 'smooth', width: 4 },
    legend: { show: !1 },
    colors:  ['#727cf5','#57BBD8'],
    xaxis: {
        tooltip: { enabled: !1 },
        axisBorder: { show: !1 },
        labels: {},
    },
    yaxis: {
        labels: {
            formatter: function (e) {
                return appTxt.naira_ +  e.toLocaleString()
            },
            offsetX: -15,
        },
    },
    fill: {
        type: 'gradient',
        gradient: {
            type: 'vertical',
            shadeIntensity: 1,
            inverseColors: !1,
            opacityFrom: 0.45,
            opacityTo: 0.05,
            stops: [45, 100],
        },
    },

}
