import React, {useState} from "react";
import DashboardWrapper from "../../DashboardWrapper";
import BreadCrumb from "../../layouts/BreadCrumb";
import GeneralDetails from "./kyc/GeneralDetails";
import Documents from "./kyc/Documents";
import {appConfig} from "../../../../config/config";
import {copyYourLink} from "../../../../config/redux/actions";
import {Link} from "react-router-dom";
import PreviewData from "./kyc/PreviewData";

interface TabProps {
    // children : React.ReactNode
    name : string //Cooperative Information
    link : string //#home-b2
    aria_expanded : boolean,
    isActive : string
    icon : string
}

const preTabs =[
    {
        name: 'Thrift Account Information',
        link:'first',
        aria_expanded: true,
        isActive:'account_info',
        icon : 'mdi mdi-account-circle font-18 align-middle me-1'
    },
    {
        name: 'Documents',
        link:'second',
        aria_expanded: true,
        isActive:'documents',
        icon : 'uil uil-file-info-alt font-18 align-middle me-1'
    },
    {
        name: 'Account Status',
        link:'third',
        aria_expanded: true,
        isActive:'information',
        icon: 'uil uil-file-check-alt font-18 align-middle me-1'
    },
]

interface Props {
    // children : React.ReactNode,
    tabs:TabProps[]
    isActive : string
}
const ThriftAccountSettings = () => {
    // account_info,documents,information
    const [tabs,setTabs] = useState(preTabs)
    const [isActive,setIsActive] = useState('account_info')

  return (

      <DashboardWrapper showWarning={false}>
          <BreadCrumb pageTitle="Cooperative Complaince" current="Thrift Account Document" main="Thrift Settings"/>


          <div className="col-sm-12 px-sm-4 mt-5 card">
              <div id="progressbarwizard">
                  <ul className="nav nav-pills nav-justified form-wizard-header mb-3" role="tablist">
                      {tabs.map((tab,i=0)=>
                          <li className="nav-item" role="presentation">
                              <a
                                  key={i+1}
                                  href={`#${tab.link}`}
                                  aria-expanded={tab.aria_expanded}
                                  className={`nav-link ${tab.isActive===isActive?'active':''}`}
                                  onClick={()=>setIsActive(tab.isActive)}
                                  data-bs-toggle="tab"
                                  data-toggle="tab"
                                  // className="nav-link rounded-0 py-2 active"
                                  aria-selected="true"
                                  role="tab">
                                  <i className={`${tab.icon}`} />
                                  <span className="d-none d-sm-inline">{tab.name}</span>
                              </a>
                          </li>
                      )}
                  </ul>

                  <div className="tab-content b-0 mb-0">

                      <div className={`tab-pane ${isActive === 'account_info' ? 'show active' : ''}`}
                           id="account-2" role="tabpanel">
                          <GeneralDetails  setNextTab={setIsActive}/>
                      </div>

                      <div className={`tab-pane ${isActive === 'documents' ? 'show active' : ''}`} id="profile-tab-2" role="tabpanel">
                          <Documents setNextTab={setIsActive}/>
                      </div>

                      <div className={`tab-pane ${isActive === 'information' ? 'show active' : ''}`} id="finish-2" role="tabpanel">
                          <div className="row">
                              <div className="col-12">
                                  <PreviewData/>
                              </div>
                              {/* end col */}
                          </div>

                          {/* end row */}
                      </div>
                  </div>





                  {/* tab-content */}
              </div>
              {/* end card*/}
          </div>

      </DashboardWrapper>


  )
}
export default ThriftAccountSettings





{/*
thrift_account?.account_branch?.is_profile_complete === 1  && thrift_account?.account_branch?.bussiness_reg_document === null  &&*
   <div className=" mt-3 alert alert-danger alert-dismissible fade show" role="alert">
       <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>*
         <i className="dripicons-warning me-2"></i> You are yet to upload your thrift account document  <u>
       <Link to={url.thriftAccountDocuments}>fix now</Link> </u>
    </div>
*/}

