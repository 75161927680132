import {Link} from "react-router-dom";


const EmailLeftSmsSidebar = () => {
    return <>
        <div className="page-aside-left">
            <div className="d-grid">
                <button type="button" className="btn btn-danger" data-bs-toggle="modal" data-bs-target="#compose-modal">Compose</button>
            </div>
            <div className="email-menu-list mt-3">
                <Link to={'#0'}  className="text-danger fw-bold">
                    <i className="dripicons-inbox me-2" />
                    Email
                    <span className="badge badge-danger-lighten float-end ms-2">7</span>
                </Link>


                <Link to={'#0'}>
                    <i className="dripicons-document me-2" />
                    SMS
                    <span className="badge badge-info-lighten float-end ms-2">32</span>
                </Link>

                <a href="javascript: void(0);"><i className="dripicons-trash me-2" />Trash</a>

            </div>
            <div className="mt-4">
                <h6 className="text-uppercase">Labels</h6>
                <div className="email-menu-list labels-list mt-2">
                    <a href="javascript: void(0);"><i className="mdi mdi-circle font-13 text-info me-2" />Updates</a>
                    <a href="javascript: void(0);"><i className="mdi mdi-circle font-13 text-warning me-2" />Friends</a>
                    <a href="javascript: void(0);"><i className="mdi mdi-circle font-13 text-success me-2" />Family</a>
                    <a href="javascript: void(0);"><i className="mdi mdi-circle font-13 text-primary me-2" />Social</a>
                    <a href="javascript: void(0);"><i className="mdi mdi-circle font-13 text-danger me-2" />Important</a>
                    <a href="javascript: void(0);"><i className="mdi mdi-circle font-13 text-secondary me-2" />Promotions</a>
                </div>
            </div>

        </div>

    </>
}

// @ts-ignore
export  default  EmailLeftSmsSidebar