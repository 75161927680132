import {Link, Navigate, useParams, useSearchParams} from "react-router-dom"
import { url } from "../../config/urls"
import { useForm } from 'react-hook-form';

import AuthWrapper from "./AuthWrapper"
import {remote_url} from "../../config/urls/remote";
import {types} from "../../config/redux/types";
import {postRequest} from "../../config/redux/actions";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../config/redux/reducers";
import {AuthState} from "../../config/redux/reducers/tmsAuthReducer";
import {Spinner} from "../../components";
import {ErrorAlert} from "../../components/alert";
import {useEffect} from "react";

type FormValues ={
    token : number,
    email : string,
    encoded_email : string
}

type UrlParams = {
    encrypted_email ?: string
}

const VerifyEmail = () => {
    const { register, handleSubmit,setValue, formState: { errors },reset } = useForm<FormValues>()
    const {user,loading,error_msg,success_msg} = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
    const dispatch = useDispatch()
    let {encrypted_email} = useParams<UrlParams>()
    const [searchParams, setSearchParams]  = useSearchParams()



    useEffect(()=> {
        let token = searchParams.get("token")
        setValue('token',Number(token))
    },[])


    const verifyEmail = (data:FormValues) => {
        const postData = {
            token : '',
            url:remote_url.userAccVerify,
            actionType: types.CONFIRM_VERIFY_EMAIL,
            postData : {
                encoded_email : encrypted_email,
                token : data.token
            },
            secretData : {
                email:'',
                mac_id :'',
                r_id:0
            },
            // reset,
            errorAction : types.SET_ERROR,
            loading :{
                isLoading :true,
                isLoading_ : false,
                isLoading_t: false
            },
            accountKey :''
        }
        //
        dispatch(postRequest(postData))
    }
    //
    if(success_msg){
        return <Navigate to={url.verifiedAccount}/>
    }


    return (
        <AuthWrapper>
            <div className="card">

                <div className="card-header pt-4 pb-4 text-center bg-primary">
                    <a href="">
                        <span><img src="/assets/img/b.png" alt="" height="48"/></span>
                    </a>
                </div>

                <div className="card-body p-4">

                    <div className="text-center w-75 m-auto">
                        <h4 className="text-dark-50 text-center mt-0 fw-bold">Verify Email</h4>
                        <p className="text-muted mb-4">Enter your 6 digit token to complete email verification Process.</p>

                    </div>
                    { error_msg && <ErrorAlert msg={error_msg}/> }

                    <form  onSubmit={handleSubmit(verifyEmail)}>
                        <div className="mb-3">
                            <label htmlFor={'token'} className="form-label"> 6 digits token </label>
                            <input className="form-control"
                                   type="number"
                                   id="emailaddress"
                                   placeholder="XXXXXX"
                                   {...register('token',{
                                       required: '* 6 digit  is Required',
                                   })}
                            />
                        </div>

                        <div className="mb-0 text-center">
                            {
                                loading ?
                                <Spinner color={'primary' }/>
                                :
                                <button className="btn btn-primary" type="submit">Verify me</button>
                            }
                        </div>
                    </form>
                </div>
            </div>


            <div className="row mt-3">
                <div className="col-12 text-center">
                    <p className="text-muted">Back to
                        <Link to={url.home} className="text-muted ms-1"><b>Log In</b></Link>
                    </p>
                </div>
            </div>

        </AuthWrapper>
    )
}



export default VerifyEmail