import {useDispatch, useSelector} from "react-redux"
import { Link } from "react-router-dom"
import { AppState } from "../../../../config/redux/reducers"
import { AuthState } from "../../../../config/redux/reducers/tmsAuthReducer"
import { appTxt } from "../../../../config/strings"
import {GeneralFormInput} from "../../../../components/form";
import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {url} from "../../../../config/urls";
import {remote_url} from "../../../../config/urls/remote";
import {types} from "../../../../config/redux/types";
import {formatStatusText, postRequest} from "../../../../config/redux/actions";
import {BankDetailsWrapperComponent} from "../../_components";

interface Props {
    show ? : boolean
}
interface FormValues {
    channel :  string
    gateway :  string
    subscription_id :  number
    transaction_reference :  string
}

const ViewSubTransactionDetails: React.FC<Props> = ({show=false}) => {
    const dispatch = useDispatch()
    const [channel,setChannel] = useState('bank')

    const { register, handleSubmit, formState: { errors },reset } = useForm<FormValues>()
    const {loading,token,user,account_key,subscription_transaction,payment_channels,role_id,gateways,error_msg} = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
    const [status,setStatus] = useState(formatStatusText(subscription_transaction.status))

    //
    const clearData = () => {

    }
    const handleChannelChange =  (item: string) => {
        setChannel(item)
    }


    const reQuery  = () => {
        if(subscription_transaction.initial_url && subscription_transaction.payment_channel==='card'){
            window.location.href = subscription_transaction.initial_url

        }else{
            //go back to backend to generate payment url
        }

    }
    const submitRequest = (data: FormValues) => {
        data.subscription_id = subscription_transaction.subscription_id
        data.transaction_reference = subscription_transaction.transaction_reference
        const postData = {
            token,
            url: remote_url.upgradePlan,
            actionType: types.UPGRADE_PLAN,
            postData: data,
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            errorAction: types.SET_ERROR,
            accountKey: account_key,
            loading: {
                isLoading: true,
                isLoading_: false,
                isLoading_t: false
            }
        }
        //
        dispatch(postRequest(postData))
    }

    const verify = () => {

        const postData = {
            token,
            url: remote_url.verifyPlanSub,
            actionType : types.VERIFY_SUBSCRIPTION,
            postData : {
                transaction_ref : subscription_transaction.transaction_reference
            },
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            errorAction : types.SET_ERROR,
            accountKey: account_key,
            loading :{
                isLoading :true,
                isLoading_ : false,
                isLoading_t : false
            }
            // reset
        }
        //
        dispatch(postRequest(postData))

    }



    return <>
    
      <div className={`offcanvas offcanvas-end ${show}`} tabIndex={-1}  id="subTransDetails"  aria-labelledby="offcanvasRightLabel">
                     {/* data-bs-backdrop="true" */}
                    <div className="offcanvas-header">
                        <h5 id="offcanvasRightLabel"> 
                           <small> {subscription_transaction?.transaction_reference} |   {' '}
                                <span className={`badge bg-${formatStatusText(subscription_transaction.status).color}`}> {formatStatusText(subscription_transaction.status).text} </span>
                           </small>  
                        </h5> 
                       
                        <Link to="#0" onClick={() => clearData()}  className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></Link>
                    </div>
                    {/*Canvas head ends here*/}

                    <div className="offcanvas-body">
                        <div>
                            <table className="table table-centre">
                                <tbody>
                                    <tr>
                                        <td> Plan Name </td>
                                        <td>{subscription_transaction.subscription.sub_name}</td>
                                    </tr>
                                    <tr>
                                        <td>Amount </td>
                                        <td>{appTxt.naira_}
                                            {Number(subscription_transaction.amount_paid).toLocaleString()}</td>
                                    </tr>
                                    <tr>
                                        <td>Duration </td>
                                        <td>{subscription_transaction.duration} Month(s)</td>
                                    </tr>
                                    {/*<tr>*/}
                                    {/*    <td>Email </td>*/}
                                    {/*    <td>*/}
                                    {/*        {subscription_transaction.email ?? 'Unlimited'}</td>*/}
                                    {/*</tr>*/}
                                    {/*<tr>*/}
                                    {/*    <td>Member </td>*/}
                                    {/*    <td>*/}
                                    {/*        {subscription_transaction.members ?? 'Unlimited'}</td>*/}
                                    {/*</tr>*/}
                                    {/*<tr>*/}
                                    {/*    <td>Branch </td>*/}
                                    {/*    <td>*/}
                                    {/*        {subscription_transaction.branch ?? 'Unlimited'}</td>*/}
                                    {/*</tr>*/}
                                    {/*<tr>*/}
                                    {/*    <td>Push Notification </td>*/}
                                    {/*    <td>*/}
                                    {/*        {subscription_transaction.push_notification ?? 'Unlimited'}</td>*/}
                                    {/*</tr>*/}

                                   
                                    <tr>
                                        <td>Created at</td>
                                        <td>{subscription_transaction.created_at}</td>
                                    </tr>
                                    <tr>
                                        <td>Paid at</td>
                                        <td>{subscription_transaction.paid_at}</td>
                                    </tr>
                                </tbody>
                            </table>

                            {/*Payment Section*/}
                            {/*<div onClick={()=>reQuery()} className={'btn btn-outline-primary'}>Proceed to make Payment</div>*/}
                            {
                                subscription_transaction.initial_url && subscription_transaction.payment_channel==='card' && subscription_transaction.status==='0'
                                ?
                                    <>
                                        <div onClick={()=>reQuery()} className={'btn btn-outline-primary'}>Proceed to make Payment</div>
                                    </>
                                    :
                                subscription_transaction.status ==='0' &&

                                <>
                                    <form onSubmit={handleSubmit(submitRequest)}>
                                        <GeneralFormInput
                                            type={'select'}
                                            register={register}
                                            label={'Payment Channel'}
                                            fieldName={'channel'}
                                            isReq={true}
                                            selectFunction={handleChannelChange}
                                        >
                                            <option value={''}>Select your preferred Payment Channel</option>
                                            {
                                                payment_channels?.map((channel)=>{
                                                    return  channel.key !=='cash'  && <option key={channel.key} value={channel.key}>{channel.name}</option>
                                                })
                                            }
                                        </GeneralFormInput>


                                        {
                                            channel === 'card' &&
                                            <GeneralFormInput
                                                type={'select'}
                                                register={register}
                                                label={'Payment Gateway'}
                                                fieldName={'gateway'} isReq={true}>
                                                <option value={''}>Select your preferred Payment Channel</option>
                                                {
                                                    gateways?.map((gateway)=>{
                                                        return  <option key={gateway.key} value={gateway.key}>{gateway.name}</option>
                                                    })
                                                }
                                            </GeneralFormInput>
                                        }

                                        <input type="submit" value="Pay now" className="btn btn-outline-primary" />
                                    </form>

                                    {subscription_transaction?.payment_channel === 'bank' && subscription_transaction.account_name  &&
                                    <BankDetailsWrapperComponent
                                        bank_name={subscription_transaction.bank_name}
                                        account_number={subscription_transaction.account_number}
                                        amount_paid={subscription_transaction.amount_paid}
                                        account_name={subscription_transaction.account_name}
                                        expires_at={subscription_transaction.expires_at}
                                        dispatch={dispatch}
                                        loading={loading}
                                        requeryNow={verify}
                                        showBtn = {Number(subscription_transaction.status) === 0}
                                    />
                                    }
                                </>
                            }

                        </div>
                        {/*  */}
                          
                      
                    </div>
{/*     
                    {loading_ && <div className="modal-backdrop fade show"></div>} */}
                </div>
    </>

}

export default ViewSubTransactionDetails