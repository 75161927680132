import {SubscriptionPlanModel} from "../../../../config/model/authModel";
import {useForm} from 'react-hook-form';
import {url} from "../../../../config/urls";
import {useState} from "react";
import {remote_url} from "../../../../config/urls/remote";
import {types} from "../../../../config/redux/types";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../config/redux/reducers";
import {AuthState} from "../../../../config/redux/reducers/tmsAuthReducer";
import {postRequest} from "../../../../config/redux/actions";
import {appTxt} from "../../../../config/strings";
import {ErrorAlert, SuccessAlert} from "../../../../components/alert";
import {Link} from "react-router-dom";

interface Props {
    // sub_id: number
    plan: SubscriptionPlanModel,
    setShowModalFunc: any,
    modalStatus: boolean
}

type FormValues = {
    no_of_month: number,
    subscription_id: number,
    callback_url: string,
    channel : string
    gateway : string

}

const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];


const UpgradeSubModal: React.FC<Props> = ({plan, setShowModalFunc, modalStatus}) => {
    const {register, handleSubmit, formState: {errors}, reset} = useForm<FormValues>()
    const {subscription_transaction,token, account_key, success_msg,error_msg,user,role_id,payment_channels,gateways} = useSelector<AppState, AuthState>((state) => state.tmsAuthReducer)
    const dispatch = useDispatch()
    const [showGateway,setShowGateway]  = useState(false)



    const [selectedDuration, setSelectedDuration] = useState<number>(1)
    const [backDrop, setBackDrop] = useState(false)

    const submitRequest = (data: FormValues) => {
        data.callback_url = url.subscriptionCallback
        data.subscription_id = plan.id
        // console.log(data)
        //
        const postData = {
            token,
            url: remote_url.upgradePlan,
            actionType: types.UPGRADE_PLAN,
            postData: data,
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            errorAction: types.SET_ERROR,
            accountKey: account_key,
            loading: {
                isLoading: true,
                isLoading_: false,
                isLoading_t: false
            }
        }
        //
        dispatch(postRequest(postData))

    }

    const handleChannelChange  = (selected:string) => {
        if(selected === 'card'){
            setShowGateway(true)
        }else{
            setShowGateway(false)
        }

    }

    const closeModal = () => {
        setShowModalFunc(false)
    }

    const reQuery  = () => {
        if(subscription_transaction.initial_url && subscription_transaction.payment_channel==='card'){
            window.location.href = subscription_transaction.initial_url

        }else{
            //go back to backend to generate payment url
        }

    }

    return <>
        <>
            {/*<div className="modal-backdrop fade show"></div>*/}
            <div className={`offcanvas offcanvas-end  ${modalStatus ? 'show' : ''}`}
                 tabIndex={-1} id="offcanvasExample"
                 aria-labelledby="offcanvasExampleLabel"
                 // data-bs-backdrop={backDrop}
            >

                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasExampleLabel">
                        {plan.sub_name} Pack | {appTxt.naira_}
                        {
                            selectedDuration === 12 ? (plan.amount * 10).toLocaleString()
                                :
                                (plan.amount * selectedDuration).toLocaleString()
                        }
                    </h5>
                    <Link  to={"#"} className="btn-close text-reset"   data-bs-dismiss="offcanvas"  aria-label="Close" onClick={()=>closeModal()}/>
                </div>

                <div className="offcanvas-body">
                    {/*  */}
                    <div className="mt-4">
                        <table className="table table-centered table-striped mb-0">
                            <tbody>

                            <tr>
                                <td>SMS</td>
                                <td>{(plan.sms * selectedDuration).toLocaleString()} </td>
                            </tr>

                            <tr>
                                <td>Email</td>
                                <td>{plan.email ? (plan.email * selectedDuration).toLocaleString() : 'Unlimited'}</td>
                            </tr>

                            <tr>
                                <td>Branch</td>
                                <td>{plan.branch ? (plan.branch * selectedDuration).toLocaleString() : 'Unlimited'}</td>
                            </tr>

                            <tr>
                                <td>Members</td>
                                <td>{plan.members ? (plan.members * selectedDuration).toLocaleString() : 'Unlimited'}</td>
                            </tr>

                            <tr>
                                <td>Notification</td>
                                <td>{
                                    (plan.push_notification || plan.push_notification===0) ?
                                        (plan.push_notification * selectedDuration).toLocaleString()
                                        :
                                        'Unlimited'
                                }
                                </td>
                            </tr>

                            </tbody>

                        </table>


                    </div>
                    {/* */}

                    {
                        subscription_transaction.initial_url && subscription_transaction.payment_channel==='card' && subscription_transaction.status==='0'
                            ?
                            <>
                                <div onClick={()=>reQuery()} className={'btn btn-outline-primary'}>Proceed to make Payment</div>
                            </>
                            :
                            subscription_transaction.status ==='0' &&

                            <form onSubmit={handleSubmit(submitRequest)}>
                                {success_msg && <SuccessAlert msg={success_msg}/>}
                                {error_msg && <ErrorAlert msg={error_msg}/>}

                                <div className="form-group ">
                                    <label htmlFor=""> subscription duration </label>
                                    <select className="form-select"
                                            {...register('no_of_month')}
                                            onChange={(e) => setSelectedDuration(Number(e.target.value))}>
                                        {
                                            months.map((item) =>
                                                <option key={item} value={item}>
                                                    {item} Month(s)
                                                </option>
                                            )
                                        }
                                    </select>
                                </div>
                                {/*channel*/}
                                <div className="form-group mt-2">
                                    <label htmlFor=""> Payment Channel </label>
                                    <select className="form-select"
                                            {...register('channel')}
                                            onChange={(e) => handleChannelChange(e.target.value)}>
                                        <option value={''}>Select Payment Channel </option>
                                        {

                                            payment_channels.map((item) =>
                                                    item.key!=='cash' && <option key={item.key} value={item.key}>
                                                        {item.name}
                                                    </option>
                                            )
                                        }
                                    </select>
                                </div>
                                {/*gateway*/}
                                {
                                    showGateway &&
                                    <div className="form-group mt-2">
                                        <select className="form-select"
                                                {...register('gateway')}
                                                onChange={(e) => setSelectedDuration(Number(e.target.value))}>
                                            <option value={''}>Select Payment Gateway</option>
                                            {
                                                gateways.map((item) =>
                                                    <option key={item.key} value={item.key}>
                                                        {item.name}
                                                    </option>
                                                )
                                            }
                                        </select>
                                    </div>
                                }





                                <div className="pull-right float-right text-right">

                                    <button className="mt-3 btn btn-outline-primary "> Proceed
                                        {' '}
                                        {appTxt.naira_}
                                        {
                                            selectedDuration === 12 ? (plan.amount * 10).toLocaleString()
                                                :
                                                (plan.amount * selectedDuration).toLocaleString()
                                        }
                                    </button>
                                </div>
                                {/* */}
                                <br/>
                                {success_msg && <SuccessAlert msg={success_msg}/>}
                                {error_msg && <ErrorAlert msg={error_msg}/>}

                            </form>



                    }


                {/*    */}

                </div>

            </div>

        </>


    </>
}


export default UpgradeSubModal