import AuthWrapper from "../auth/AuthWrapper";
import {Link} from "react-router-dom";
import {url} from "../../config/urls";

const NonAuthNotFoundPage = () => {
    return <AuthWrapper>
        <div className="card">
            {/* Logo */}
            <div className="card-header pt-4 pb-4 text-center bg-primary">
                <Link to={url.signIn}>
                    <span><img src="/assets/img/b.png" alt="" height="30"/></span>
                </Link>
            </div>
            <div className="card-body p-4">
                <div className="text-center">
                    <h1 className="text-error">4<i className="mdi mdi-emoticon-sad" />4</h1>
                    <h4 className="text-uppercase text-danger mt-3">Page Not Found</h4>
                    <p className="text-muted mt-3">It's looking like you may have taken a wrong turn. Don't worry...
                        it
                        happens to the best of us. Here's a
                        little tip that might help you get back on track.</p>
                    <Link to={url.signIn} className="btn btn-info mt-3" >
                        <i className="mdi mdi-reply" /> Return Home
                    </Link>
                </div>
            </div> {/* end card-body*/}
        </div>
    </AuthWrapper>
}



export default  NonAuthNotFoundPage