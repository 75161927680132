import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../config/redux/reducers";
import {ExpenseReducerState} from "../../../../config/redux/reducers/tmsExpenseReducer";
import {postRequest, setGeneralAction} from "../../../../config/redux/actions";
import {types} from "../../../../config/redux/types";
import {remote_url} from "../../../../config/urls/remote";
import {AuthState} from "../../../../config/redux/reducers/tmsAuthReducer";
import {useEffect} from "react";


type  FormValues = {
    fromDate: string,
    toDate: string,
    status: string
}
const ExpenseFilter = () => {
    const {filter} = useSelector<AppState, ExpenseReducerState>((state) => state.tmsExpenseReducer)
    const {token, account_key,user,role_id} = useSelector<AppState, AuthState>((state) => state.tmsAuthReducer)
    const {register, handleSubmit} = useForm<FormValues>()
    const dispatch = useDispatch()


    const search = (data: FormValues) => {
        //
        dispatch(setGeneralAction(types.SET_EXPENSE_FILTER, {
            fromDate: data?.fromDate,
            toDate: data?.toDate,
            status: data.status
        }))
        const postData = {
            token,
            url: remote_url.getExpenses,
            actionType: types.GET_EXPENSES,
            postData: data,
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            errorAction: types.SET_ERROR,
            accountKey: account_key,
            loading: {
                isLoading: false,
                isLoading_: false,
                isLoading_t: true
            }
        }
        dispatch(postRequest(postData))
    }

    useEffect(() => {
        search(filter)
    },[])

    
    return <>
        <div className='card px-3 py-4'>
            <form onSubmit={handleSubmit(search)}>
                <div className='row'>
                    {/* from Date */}
                    <div className='col-lg-3'>
                        <div className="mb-3">
                            <label htmlFor="example-date" className="form-label">from date</label>
                            <input
                                className="form-control"
                                {...register('fromDate')}
                                type="date"
                                defaultValue={filter?.fromDate}
                            />
                        </div>
                    </div>
                    {/* to Date */}
                    <div className='col-lg-3'>
                        <div className="mb-3">
                            <label htmlFor="example-date" className="form-label">to date</label>
                            <input
                                className="form-control"
                                {...register('toDate')}
                                type="date"
                                defaultValue={filter?.toDate}
                            />
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div className="mb-3">
                            <label htmlFor="example-date" className="form-label">status</label>
                            <select className={'form-select'}  {...register('status')} defaultValue={filter?.status}>
                                <option value={'all'}> All</option>
                                <option value={1}> Paid(income) </option>
                                <option value={0}> Unpaid(income) </option>
                                <option value={2}> Expense Only </option>
                            </select>
                        </div>
                    </div>
                    {/* filter button */}
                    <div className='col-lg-3'>
                        <div className="mb-3">
                            <label htmlFor="example-date" className="form-label">-</label>
                            <input className="form-control btn btn-primary" type="submit" name="date" value="Filter"/>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    </>
}

export default ExpenseFilter