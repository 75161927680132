import { ApexOptions } from "apexcharts";
import { cardSummaryModel } from "../../../../config/model/authModel";
import { appTxt } from "../../../../config/strings";

export const buildCardSummary = (data:cardSummaryModel) => {
        //
        const f:ApexOptions ={
                    chart: { type: 'bar', height: 60, sparkline: { enabled: !0 } },
                    plotOptions: { bar: { columnWidth: '60%' } },
                    colors: data.colors,
                    labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
                    xaxis: { crosshairs: { width: 1 } },
                    tooltip: {
                        fixed: { enabled: !1 },
                        x: { show: !1 },
                        y: {title: {formatter: function (o) {return ''},},},
                        marker: { show: !1 },
                    },
                    series: [{ data: data.series}]
                    
        }
        return f;
    }


//
 export const buildCardSummaryLine = (data:cardSummaryModel) => {
     const f:ApexOptions = {
         chart: { type: 'line', height: 60, sparkline: { enabled: !0 } },
         series: [{ data: data.series }],
         stroke: { width: 2, curve: 'smooth' },
         markers: { size: 0 },
         colors:data.colors,
         tooltip: {
            fixed: { enabled: !1 },
            x: { show: !1 },
            y: {
                title: {
                    formatter: function (o) {
                        return ''
                    },
                },
            },
            marker: { show: !1 },
        },
     }

     return f

}




//
export const revenueChartOptions: ApexOptions =  {
        chart: { height: 321,type: 'line',toolbar: { show: !0 } ,
            id: "dash-revenue-chart"},
        stroke:{curve: "smooth",show: true,width: 2},
        fill: { type: 'solid', opacity: [0.35, 1] },
        markers: { size: 0 },
        colors: ['#0acf97','#fa5c7c','#727CF5'], // check clo
        yaxis: [{ title: { text: 'Revenue (NGN)' }, min: 0 }],
        tooltip: {
                shared: !0,
                intersect: !1,
                y: {
                    formatter: function (o:number) {
                        return void 0 !== o ? appTxt.naira_ + o.toLocaleString()  : o
                    },
                },
	    },
        grid: { borderColor: 'red', padding: { bottom: 5 } },
        legend: { fontSize: '14px', fontFamily: '14px', offsetY: 5 },
        responsive: [
            { breakpoint: 600, options: { yaxis: { show: !1 }, legend: { show: !1 } } },
        ],
        // xaxis: {
        //     categories: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
        // }
}
//
export const topPerformingUsersConfig:ApexOptions ={  
    chart: {
		height: 432,
		type: 'bar',
		stacked: !1,
        sparkline: { enabled: false },
        stackType: '100%',
        toolbar: { show: !0 },
	},
	plotOptions: { bar: { horizontal: !1 } },
	stroke: { width: 0, colors: ['#222'] },
    colors: ['#57BBD8','#57BBD8','#727cf5'],
	tooltip: {
                y: {
                    formatter: function (o:number) {
                        return void 0 !== o ? appTxt.naira_ + o.toLocaleString()  : o
                },
            },
	},
	fill: { opacity: 1 },
    dataLabels: {
        enabled: false
    },
	legend: { position: 'top', horizontalAlign: 'center', offsetY: 10 },
	grid: { borderColor: '#f1f3fa', padding: { top: 0 } },

    // responsive: [
    //     { breakpoint: 600, options: { yaxis: { show: !1 }, legend: { show: !1 } } },
    // ],
    // yaxis: [{ title: { text: 'User Savings (NGN)' }, min: 0 }],
    // states: { hover: { filter: 'none' } },
}
      
//
export const transactionsGraph : ApexOptions = {
	chart: {
		height: 297,
		type: 'bar',
		stacked: !1,
        sparkline: { enabled: false },
		stackType: '100%',
		toolbar: { show: !0 },
	},
	plotOptions: { bar: { horizontal: !1 } },
	stroke: { width: 0, colors: ['#fff'] },
	colors: ['#727CF5','#4250aa'],
	tooltip: {
		y: {
			formatter: function (e) {
				return appTxt.naira_ +  e.toLocaleString() 
			},
		},
	},
    fill: { opacity: 1 },
    dataLabels: {
        enabled: false
    },
	legend: { position: 'top', horizontalAlign: 'center', offsetY: 10 },
	grid: { borderColor: '#f1f3fa', padding: { top: 0 } },
    // states: { hover: { filter: 'none' } },
}
