import { ApexOptions } from "apexcharts";
import { appTxt } from "../../../../config/strings";

export const potentialVsActionLoan : ApexOptions = {
			chart: { height: 300, type: 'bar', stacked: !0 },
			plotOptions: { bar: { horizontal: !1, columnWidth: '30%' } },
			dataLabels: { enabled: !1 },
			stroke: { show: !0, width: 2, colors: ['transparent'] },
			legend: { show: !1 },
			colors: ['#727cf5','#e3eaef'],
			xaxis: {
				axisBorder: { show: !1 },
			},
			yaxis: {
				labels: {
					formatter: function (e) {
						return appTxt.naira_ + e.toLocaleString() 
					},
					offsetX: -15,
				},
			},
			fill: { opacity: 1 },
			tooltip: {
				y: {
					formatter: function (e) {
						return appTxt.naira_ + e.toLocaleString() 
					},
				},
			},
}


export const loansReturnVsDisbursed : ApexOptions = {
	chart: {
				height: 364,
				type: 'line',
				dropShadow: { enabled: !0, opacity: 0.2, blur: 7, left: -7, top: 7 },
			},
			dataLabels: { enabled: !1 },
			stroke: { curve: 'smooth', width: 4 },
			// series: [
			// 	{ name: 'Current Week', data: [10, 20, 15, 25, 20, 30, 20] },
			// 	{ name: 'Previous Week', data: [0, 15, 10, 30, 15, 35, 25] },
			// ],
			colors: ['#727cf5','#0acf97'],
			// zoom: { enabled: !1 },
			legend: { show: !1 },
			xaxis: {
				// type: 'string',
				// categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
				tooltip: { enabled: !1 },
				axisBorder: { show: !1 },
			},
			yaxis: {
				labels: {
					formatter: function (e) {
						return appTxt.naira_ + e.toLocaleString() 
					},
					offsetX: -15,
				},
			},
}