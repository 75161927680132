import {Dispatch} from "redux";
import {
    APP_VERSION,
    generateValidRoleIdKey,
    hmac_512,
    hmac_512_non_token, MsgProps,
    validateAppVersionResponse
} from "../../config/config";

import axios from "axios";
import {types} from "../../config/redux/types";


export interface RequestPropsNew {
    token:string,
    url:string,
    secretData :MsgProps,
    postData ?: object,
    accountKey : string,
}

const errorResponseCheck = (error:any,dispatch:any) => {
    if(error?.response?.data?.message==="multiple devices login"){
        dispatch({
            type : types.MULTIPLE_DEVICE
        })
    }
    if(error?.response?.data?.message==="Unauthenticated."){
        dispatch({
            type : types.LOG_OUT
        })
        window.location.reload()
        return true;
    }
}

export const postRequestNew = async ({token, url, postData, secretData,accountKey}:RequestPropsNew) => {
    //
    const key = token  ? hmac_512(secretData, token) : hmac_512_non_token()
    //
    const options = {
        headers: {
            'Content-type': 'application/json',
            CoopAgent: key,
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            TmsAccType :`${accountKey}` ,
            IdValidate : generateValidRoleIdKey(token,secretData.r_id,secretData.mac_id,accountKey),
            AppVersion : APP_VERSION
        },
    }

    //
    try {
        const res = await axios.post(url, postData, options)

        if (res.status >= 200 && res.status <= 299) {
            //Todo
            // validateAppVersionResponse(res.headers,dispatch)
             return {status:true,data:res.data}
        }
    }
    catch (error:any) {
        //Todo
        // errorResponseCheck(error,dispatch)

        return error.response?.data === undefined
            ? 'Network Error'
            : error.response.data.errors === undefined
                ? error.response.data.message
                : error.response.data.errors
    }
}
//