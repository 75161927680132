import { initializeApp,getApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import {FIREBASE_V_API_KEY} from "./config/config";

//
const firebaseConfig = {
    apiKey: "AIzaSyDf1KhUCn89tROHF5Qu-6q1XEGC5XAbbXY",
    authDomain: "thriftms-push-notification.firebaseapp.com",
    projectId: "thriftms-push-notification",
    storageBucket: "thriftms-push-notification.appspot.com",
    messagingSenderId: "820036924480",
    appId: "1:820036924480:web:2b299009e52cb457bb5ba5"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);

// export default  firebase
export const fetchToken = () => {
    return getToken(messaging,
        {
            vapidKey: `${FIREBASE_V_API_KEY}`
        })
        .then((currentToken) => {
        if (currentToken) {
            console.log('current token for client: ', currentToken);
            return currentToken
        } else {
            return 'no_key'
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        return 'error'
    });
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });