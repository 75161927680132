export const url = {
    'home' : '/',
    'signIn' : '/sign-in',
    'successScreen' : '/ms/:key',
    'successScreenPre' : '/ms/',
    'logout' :'/logout',
    'accounts' : '/accounts',
    'verifyEmail' : '/verify-email/:mobile/:encrypted_email',
    'rejectInvite': '/reject/:email/:id/:phone_no/:type',
    //
    'accSignUp' : '/create-account',
    'accSignUpMember' : '/create-account/:accountToken/:randomDate',
    'confirmAccPage' : '/account-creation-confirm', //for showing verified account
    'verifiedAccount' : '/account-verified', //for showing verified account
    'accLock' : '/account-locked',
    //
    'forgotPass' : '/forgot-password',
    'resetPass' : '/reset-password/:t/:e',


    'dashboard' : '/dashboard',
    //SAVINGS
    'savingsDashboard' : '/savings/dashboard',
    'savings' : '/savings',
    'all_savings' : '/savings/all-cooperative-savings',
    'savings_' : '/savings/#0',
    'savingsSettings': '/savings/settings',
    //TODO
    // 'savingsNew': '/savings/new',
    "savingsCallback": "/savings/verify/:transaction_ref/:gateway_id",
    //LOANS
    'loansDashboard' : '/loans/dashboard',
    'loans' : '/loans',
    'loansApp' : '/loans/applications',
    'loansSettings' : '/loans/settings',
    'loanVerify' : '/loans/verify/:transaction_reference/:gateway_id',
    //
    'expensesDashboard' : '/expenses/dashboard',
    'expenses' : '/expenses',
    'expensesVerification' : '/expenses/verification/:transaction_ref/:gateway_id',
    //http://127.0.0.1:3000/expenses/verification?trxref=TMS631DE3AC35821427EXP&reference=TMS631DE3AC35821427EXP
    //
    'auditLogDashboard'  : '/audit-log', 
    //
    'users' : '/users',
    'userDetailsPre':'/user',
    'userDetails' : '/user/:id',
    'branch' : '/branch',
    'thriftAccountSettings' : '/settings/thrift-account',
    'thriftAccountDocuments' : '/settings/thrift-account/documents',
    'thriftAccountDetails' : '/settings/thrift/profile',
    'thriftBankListing' : '/settings/thrift/bank-accounts',
    'myAccount' : '/settings/my-account',
    'changePassword' : '/settings/change-password',
    'myProfile' : '/settings/my-profile',

    'pricing' : '/settings/subscription/pricing',
    'thriftSubscriptions': '/settings/subscription',
    'subscriptionCallback' : '/settings/subscription/callback/:transaction_reference/:gateway_id',

    'supportsHome' : '/supports',
    'supportDetail' : '/support/:id',
    'preLogOut': '/account/logout',

    'emailSms' : '/communication/email-sms',
    'pushNotification' : '/communication/notifications',

    //Referral
    'referralHome' : '/communication/referral',
    'adsHome' : '/marketing/ads',
    'blogHome' : '/marketing/blog',

    //StoreFront
    'allOrders':'/store-front/all-orders',
    'allProducts':'/store-front/all-products',
    //reconciliation  and  settlement'
    'settlements':  '/settlement-reconciliation/settlement'


}
