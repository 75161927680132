import {setGeneralAction} from "../../../../config/redux/actions";
import {types} from "../../../../config/redux/types";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../config/redux/reducers";
import {AnalyticState} from "../../../../config/redux/reducers/tmsAnalyticsReducer";

const ExpenseDashboardFilter = () => {
    const {expensesDashboardYear} = useSelector<AppState,AnalyticState>((state) => state.tmsAnalyticsReducer)
    const dispatch = useDispatch()
    const getExpenseDashboard = (year:number) => {
        dispatch(setGeneralAction(types.SET_SAVINGS_DASHBOARD_FILTER,year))

    }

    return <>
        <div className="row">
            <div className="col-12">
                <div className="page-title-box">
                    <div className="page-title-right">
                        <form className="d-flex">
                            {/* fromDate */}
                            <div className="input-group">
                                <select className="form-control form-control-light form-select"
                                        id="dash-daterange"
                                        defaultValue={expensesDashboardYear}
                                        onChange={(e) => getExpenseDashboard(Number(e.target.value))
                                }>
                                    <option value={2021}>2018</option>
                                    <option value={2021}>2019</option>
                                    <option value={2021}>2020</option>
                                    <option value={2021}>2021</option>
                                    <option value={2022}>2022</option>
                                    <option value={2023}>2023</option>
                                    <option value={2024}>2024</option>
                                </select>

                                {/*<span className="input-group-text bg-primary border-primary text-white"*/}
                                {/*      onClick={()=>getExpenseDashboard()} >*/}
                                {/*    <i className="mdi mdi-arrow-right font-13"></i>*/}
                                {/*</span>*/}
                            </div>
                            {/* button */}
                            {/* <Link to="" className="btn btn-primary ms-2">
                                <i className="mdi mdi-autorenew"></i>
                            </Link> */}
                        </form>
                    </div>
                    <h4 className="page-title">Expenses Dashboard</h4>
                </div>
            </div>
        </div>
    </>
}

export default  ExpenseDashboardFilter