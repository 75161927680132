import {SavingsFilter} from "../savings/_components";
import React from "react";
interface Props {
    children: React.ReactNode,
    name : string,
    id: string,
    id_ : string,
    id_1 : string
    // showWarning?: boolean,
    // keys?: string

}
const ExpandCollapse: React.FC<Props>  = ({children,name,id,id_,id_1}) => {
    return (
        <div className="accordion custom-accordion shadow" id={id_}>
        <div className="card">
            <div className="card-header bg-white" id={id}>
                <h5 className="m-0">
                    <a className="custom-accordion-title d-block py-1  text-muted text-success"
                       data-bs-toggle="collapse"
                       href={`#${id_1}`}
                       aria-expanded="true"
                       aria-controls={id_1}>
                        {name}
                        <i className="mdi mdi-chevron-down accordion-arrow"></i>
                    </a>
                </h5>
            </div>

            <div id={id_1} className="collapse show" aria-labelledby={id} data-bs-parent={id_}>
                <div className="card-body">
                    {children}
                </div>
            </div>
        </div>
    </div>
    )
}


export  default  ExpandCollapse