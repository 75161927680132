import React, {useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Spinner } from '../../../../components';
import { ErrorAlert, SuccessAlert } from '../../../../components/alert';
import {appConfig, PAYSTACK_KEY} from '../../../../config/config';
import {calculateTime, getRequest, postRequestFile} from '../../../../config/redux/actions';
import { AppState } from '../../../../config/redux/reducers';
import { AuthState } from '../../../../config/redux/reducers/tmsAuthReducer';
import { types } from '../../../../config/redux/types';
import { url } from '../../../../config/urls';
import { remote_url } from '../../../../config/urls/remote';
import DashboardWrapper from "../../DashboardWrapper"
import BreadCrumb from "../../layouts/BreadCrumb"
import ToastAlert from '../../_components/ToastAlert';
import axios from "axios";
import {GeneralFormInput, ImagePreviewContainer} from "../../../../components/form";


type FormValues ={
    name : string,
    email : string,
    state_id : string,
    country_id : string,
    address : string,
    phone_no : string,
    profile_image : string,
    account_name : string,
    bank_key : string,
    account_number : string,
    id_document : string,
    id_number : string,
    dob : string,
    gender : string,
    bvn : string,
}

const MyAccountSettings = () => {
    const { register, handleSubmit,watch,setValue,getValues, setError,formState: { errors },reset } = useForm<FormValues>()
    const {user,token,account_key,success_msg,error_msg,countries,allStates,banks,loginTime,role_id,loading_} = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
      
    const [image, setImage] = useState({preview:user?.profile_image})
    const [imgError, setImgError] = useState('')
      
    const [imageDoc, setImageDoc] = useState({preview:user?.id_document})
    const [imgDocError, setImgDocError] = useState('')

    const [showAccountNo, setShowAccountNo] = useState(!!user.account_number)
    const [isVerified, setIsVerified] = useState(!!user.account_number)
    const [isLoading, setIsLoading] = useState(false)

    const [errMsg, setErrorMsg] = useState('')


    const dispatch  = useDispatch()


    useEffect(() => {
        let time_diff = calculateTime(loginTime)
        if(time_diff <=6){
            getAllBanks();
            getUserDetails()
            if(user.country_id){
                getStatesUnderACountry(user.country_id)
            }
        }
    },[])
    //
    const getUserDetails = () => {
        const postData = {
            token,
            url: remote_url.getProfile,
            actionType : types.UPDATE_PROFILE,
            postData : [],
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            errorAction : types.SET_ERROR,
            accountKey: account_key,
            loading :{
                isLoading : false,
                isLoading_ : false,
                isLoading_t : false
            }
        }
        dispatch(getRequest(postData))
    }
    //
    const onSubmit = (data:FormValues) => {
        // console.log(data)
        // data.id_document[0]
        let finalProfileImage = data?.profile_image  === undefined ? 'no_logo_attached' : data.profile_image[0]
        let id_document = data.id_document === undefined ? 'no_logo_attached' : data.id_document[0]
        // let bank_id = banks.filter((bank) => bank.bank_key === data.bank_key)[0].bank_key
        let bvn = data.bvn === 'XXXXX' ? user.bvn : data.bvn
       
        const reqData = new FormData()
        reqData.append('email',data.email)
        reqData.append('state_id',data.state_id)
        reqData.append('country_id',data.country_id)
        reqData.append('address',data.address)
        reqData.append('phone_no',data.phone_no)
        reqData.append('profile_image',finalProfileImage)
        reqData.append('account_name',data.account_name)
        reqData.append('bank_key',data.bank_key)
        reqData.append('account_number',data.account_number)

        reqData.append('gender',data.gender)
        reqData.append('dob',data.dob)
        reqData.append('bvn',data.bvn)
        //branch_name
    
        reqData.append('id_document',id_document)
        reqData.append('id_number',data.id_number)

        const postData = {
                token,
                url: remote_url.updateProfile,
                actionType : types.UPDATE_PROFILE,
                postData : reqData,

            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },

                errorAction : types.SET_ERROR,
                accountKey: account_key,
                loading :{
                    isLoading :true,
                    isLoading_ : false,
                    isLoading_t : false
                }
            }
            //
        dispatch(postRequestFile(postData))
    }
    //
    const getStatesUnderACountry = (id:string|number) => {
     if(id !==''){

         const postData = {
                token,
                url: remote_url.getStates + id,
                actionType : types.GET_STATES,
                postData : [],
             secretData : {
                 email:user.email,
                 mac_id :user.mac_id,
                 r_id: role_id
             },
                errorAction : types.SET_ERROR,
                accountKey: account_key,
                loading :{
                    isLoading :false,
                    isLoading_ : true,
                    isLoading_t : false
                }
            }
         dispatch(getRequest(postData))
     }
     //
     else{
         
     }
    }
    //get all banks
    const getAllBanks = () => {
        const getData = {
            token,
            url: remote_url.banks,
            actionType : types.GET_BANKS,
            postData : [],
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            errorAction : types.SET_ERROR,
            accountKey: account_key,
            loading :{
                isLoading :false,
                isLoading_ : true
            }
            // reset
        }
        dispatch(getRequest(getData))
    }
    //
    const handleChange = (e:any) => {
		const size = e.target.files[0]['size']
		const type = e.target.files[0]['type'] //image/jpeg @image/png
		// console.log(size)
        // console.log(type);
		if (size <= 5000000) {
			if (type === 'image/jpeg' || type === 'image/png' || type==="image/jpg") {
                setImgError('')
				setImage({preview: URL.createObjectURL(e.target.files[0])})
			} else {
                setImgError('this file type is not allowed')
			}
		} else {
            setImgError('image is too large')
		}
	}
    //
    const handleChangeDoc = (e:any) => {
        const size = e.target.files[0]['size']
		const type = e.target.files[0]['type'] //image/jpeg @image/png
		
		if (size <= 15000000) {
			if (type === 'image/jpeg' || type === 'image/png' || type==="image/jpg") {
                setImgDocError('')
				setImageDoc({preview: URL.createObjectURL(e.target.files[0])})
			} else {
                setImgDocError('this file type is not allowed')
			}
		} else {
            setImgDocError('image is too large')
		}

    }
    ///
    const verifyEmail = () => {
        const postData = {
            token,
            url: remote_url.verifyEmail,
            actionType : types.VERIFY_EMAIL,
            postData : {
                frontend_url : `${appConfig.frontend_url}/verify-email/${window.btoa(user.phone_no)}/${window.btoa(user.email)}`
            },
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            errorAction : types.SET_ERROR,
            accountKey: account_key,
            loading :{
                isLoading :true,
                isLoading_ : false,
                isLoading_t : false
            }
            // reset
        }
        //
        dispatch(postRequestFile(postData))

    }
    //
    const handleSelectedBank = async (bank_key:string) => {
        if(bank_key){
            // setTempBank(data)
            setShowAccountNo(true)
            let acc_no = getValues('account_number')
            await verifyBankAccount(acc_no, bank_key)
        }else{
            setShowAccountNo(false)
        }
    }
    //verify account number if it is valid
    const verifyBankAccount = async  (temp_account_number:string,bank_key='') => {
        setErrorMsg('')
        bank_key = bank_key === ''? getValues('bank_key') : bank_key
        if(temp_account_number.length  === 10){
            setIsLoading(true)
            let bank_code = banks.filter((bank) => bank.id === Number(bank_key))[0].code
            // setTempLoading(true)
            let url  = `https://api.paystack.co/bank/resolve?account_number=${temp_account_number}&bank_code=${bank_code}`
            try{
                const res = await axios.get(
                    `${url}`,
                    {
                        headers:{
                            Authorization : `Bearer ${PAYSTACK_KEY}`,
                            Accept : 'application/json',
                            'Content-type' : 'application/json'
                        }
                    }
                )
                // setAccountName(res.data.data.account_name)
                setValue('account_name', res.data.data.account_name, { shouldValidate: true })
                setIsVerified(true)

                // setSuccessMsg('account verified')
            }
            catch (e:any) {
                setIsLoading(false)
                setIsVerified(false)
                if(e.code==='ERR_NETWORK'){
                    setErrorMsg('no internet: we are unable to verify your account details,Try checking the network cables, modem, and router')
                }else{
                    setErrorMsg(e.response.data.message)
                    // setValue('account_name', '', { shouldValidate: true })
                }
            }

        }

    }


  return <DashboardWrapper>
      <BreadCrumb pageTitle="Profile Settings" current="Profile" main="Settings"/>
      <div className="container-fluid">
        {/*  */}
        {success_msg && <SuccessAlert  msg={success_msg}/>}
          {error_msg && <ErrorAlert msg={error_msg}/>}
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            {user.status === 0 && <small className={'text-danger'}>N.B: you are yet to verify your email</small>}
                            <hr/>
                            <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-xl-6">
                                    {/*  */}
                                    <div className="mb-3">
                                        <label htmlFor="projectname" className="form-label">Name</label>
                                        <input type="text"  
                                            className="form-control" 
                                            defaultValue={user.name}
                                            placeholder="enter your full name"
                                            readOnly = {true}
                                            {...register('name',{
                                                    required: 'name is required',
                                                    minLength: { value: 3, message: 'your  name  is too Short' },
                                            })}
                                        />
                                    </div>
                                    {/* Email Address */}
                                    <div className="mb-3">
                                        <label htmlFor="projectname" className="form-label">Email Address
                                            {user.status===1  ? <i className={'mdi mdi-check-decagram text-success'}></i>:
                                                <small className={"text-danger text-sm"} onClick={ ()=>verifyEmail() }>
                                                    <u> verify email</u>
                                                </small>
                                            }

                                        </label>
                                        <input 
                                            type="email"
                                            readOnly= {true}
                                            defaultValue={user.email}
                                            className={`form-control ${errors.email && 'is-invalid'}`}
                                            placeholder="Enter Email Address"
                                            {...register('email',{
                                                    required: '*Email Address is Required',
                                            })}
                                        />
                                        {errors.email  && (<div className="invalid-feedback">{errors.email.message}</div>)}
                                    </div>
                                    {/* phone_no */}
                                    <div className="mb-3">
                                        <label htmlFor="phone number" className="form-label">Phone Number</label>
                                        <input type="text"
                                            readOnly={user.can_edit ===0}
                                            defaultValue={user.phone_no}
                                            className={`form-control ${errors.phone_no && 'is-invalid'}`}
                                            placeholder="Enter Phone No"
                                            {...register('phone_no',{
                                                    required: '*Phone Number is Required',
                                                    minLength: { value: 11, message: 'Phone  is too Short' },
                                                    maxLength: { value: 12, message: 'Phone  is too Long' },
                                            })}
                                        />
                                         {errors.phone_no  && (<div className="invalid-feedback">{errors.phone_no.message}</div>)}
                                    </div>
                                    {/*country  */}
                                    <div className="mb-3">
                                        <label htmlFor="project-overview" className="form-label">Country</label>
                                        <select
                                            disabled={user.can_edit === 0}
                                            defaultValue={user.country_id}
                                            className={`form-select  ${errors.country_id && 'is-invalid'}`}
                                            // data-toggle="select2"
                                                {...register('country_id',{
                                                    required: '*country field is required',
                                                    onChange : (e) => getStatesUnderACountry(e.target.value)
                                                    }
                                                )}
                                          >
                                             <option value={''}>Select your Country</option>
                                            {countries?.map((country) => <option key={country.id} value={country.id}>{country.country_name}</option> )}
                                        </select>
                                         {errors.country_id  && (<div className="invalid-feedback">{errors.country_id.message}</div>)}
                                    </div>
                                    {/* state */}
                                    <div className="mb-3">
                                        <label htmlFor="project-overview" className="form-label">State {loading_ && <small className={'text-success'}>fetching state, please wait...</small> } </label>
                                        <select
                                            disabled={user.can_edit ===0}
                                            defaultValue={user.state_id}
                                                className={`form-select ${errors.state_id && 'is-invalid'}`} 
                                                // data-toggle="select2"
                                                {...register('state_id',
                                                    {required: '*state field is required'})}>
                                                    <option value={''}>Select State</option>
                                                    {
                                                        allStates?.map((state)=> 
                                                        <option value={state.id} key={state.id}>
                                                            {state.state_name}
                                                        </option>
                                                        )
                                                    }
                                        </select>
                                        {errors.state_id  && (<div className="invalid-feedback">{errors.state_id.message}</div>)}
                                    </div>
                                    
                                    {/* Address  */}
                                      <div className="mb-3">
                                            <label htmlFor="project-overview" className="form-label">Address</label>
                                            <textarea
                                                readOnly={user.can_edit === 0}
                                                className={`form-control ${errors.address && 'is-invalid'}`}
                                                rows={5}
                                                placeholder="Enter address.."
                                                defaultValue={user.address}
                                                {...register('address',{
                                                    required: '*address is required',
                                                    minLength: { value: 6, message: 'the address entered looks short/invalid' },
                                                })}
                                            >
                                            </textarea>
                                            {errors.address  && (<div className="invalid-feedback">{errors?.address?.message}</div>)}
                                       </div>
                                    {/*Account  */}
                                    <div className=''>
                                        <div className="mb-3">
                                            <label htmlFor="Bank Name" className="form-label">Bank Name</label>
                                                <select
                                                    disabled={user.can_edit === 0}
                                                    defaultValue={user.bank_key}
                                                    className={`form-select ${errors.bank_key && 'is-invalid'}`}
                                                    data-toggle="select2"
                                                    {...register('bank_key', {
                                                        required: '*bank name is required',
                                                        onChange :(e) => handleSelectedBank(e.target.value)
                                                    })}
                                                >
                                                    <option value={''}>Select your Bank</option>
                                                    {banks?.map((bank) =>
                                                        <option key={bank.id} value={bank.id}>{bank.name}</option>)}
                                                </select>
                                                {errors.bank_key  && (<div className="invalid-feedback">{errors.bank_key.message}</div>)}

                                        </div>
                                        {/*  */}
                                        <div  className='mb-2' style={{ display: showAccountNo ? '' :'none'}}>
                                             <label htmlFor="project-overview" className="form-label">Account Number
                                                 <>
                                                  <i
                                                      className={`
                                                      ${ isVerified 
                                                      ? 
                                                      'mdi mdi-check-decagram text-success':
                                                      'uil uil-times-circle text-danger'}`}>
                                                  </i>
                                                     {isLoading && <small className={'text-success'}>verifying account....</small>}
                                                 </>
                                             </label>
                                            <input
                                                   type='text'
                                                   readOnly={user.can_edit ===0}
                                                   className={`form-control ${errors.account_number && 'is-invalid'}`}
                                                   autoCorrect={'false'}
                                                   autoComplete={'false'}
                                                   placeholder='account number'
                                                   defaultValue={user.account_number}
                                                    {...register(
                                                        'account_number',
                                                        {
                                                            required: '*account number is required',
                                                            minLength: { value: 10, message: 'account number is invalid' },
                                                            onChange: (e)=>verifyBankAccount(e.target.value)
                                                        }
                                                    )}
                                            />
                                             {errors.account_number  && (<div className="invalid-feedback">{errors.account_number.message}</div>)}
                                        </div>
                                        {/*  */}
                                        <div  className='mb-2'>
                                            <label htmlFor="project-overview" className="form-label">Account Name</label>
                                            <input type='text'
                                                   readOnly={true}
                                                   className={`form-control ${errors.account_name && 'is-invalid'}`}
                                                   placeholder='account name'
                                                   defaultValue={user.account_name}
                                                   {...register('account_name',{required: '*account name is required',
                                            })}
                                            />
                                             {errors.account_name  && (<div className="invalid-feedback">{errors.account_name.message}</div>)}
                                            {errMsg && <small className={'text-danger'}> {errMsg} </small>}
                                        </div>
                                        {/*  */}

                                    </div>
                                </div>
                                {/*::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/}
                                {/* Right has Side */}

                                <div className="col-xl-6">
                                    {/*DOB*/}
                                    <div  className='mb-2'>
                                        <label htmlFor="project-overview" className="form-label">Date of Birth</label>
                                        <input
                                            type='date'
                                            className={`form-control ${errors.dob && 'is-invalid'}`}
                                            defaultValue={user.dob}
                                            disabled={user.can_edit === 0}
                                               {...register('dob', {required: '*date of birth is required'})}
                                        />
                                        {errors.dob  && (<div className="invalid-feedback">{errors.dob.message}</div>)}
                                    </div>

                                    {/*Gender*/}
                                    <div className="mb-3">
                                        <label htmlFor="Gender" className="form-label">Gender</label>
                                        <select
                                            disabled={user.can_edit === 0}
                                            defaultValue={user.gender}
                                            className={`form-select ${errors.gender && 'is-invalid'}`}
                                            data-toggle="select2"
                                            {...register('gender', {required: '*gender is required'})}
                                        >
                                            <option value={''}>Select your Gender</option>
                                            <option value={1}>Male</option>
                                            <option value={2}>Female</option>
                                        </select>
                                        {errors.gender  && (<div className="invalid-feedback">{errors.gender.message}</div>)}
                                    </div>

                                    {/*BVN*/}
                                    {user.bvn  && Number(user.can_edit)  === 0? ''
                                        :

                                        <div  className='mb-2'>
                                            <label htmlFor="project-overview" className="form-label">BVN</label>
                                            <input
                                                type='text'
                                                className={`form-control ${errors.bvn && 'is-invalid'}`}
                                                placeholder='Enter BVN'
                                                defaultValue={user.bvn?'XXXXX':""}
                                                {...register('bvn',{required: '*bvn is required',
                                                })}
                                            />
                                            {errors.bvn  && (<div className="invalid-feedback">{errors.bvn.message}</div>)}
                                        </div>

                                    }



                                    {/* Profile Image */}
                                    <div className="mb-3 mt-3 mt-xl-0">
                                        <label htmlFor="projectname" className="mb-0">Profile Image</label>
                                        <p className="text-muted font-14">Recommended profile image size 800x400 (px).</p>
                                        { imgError &&   <ErrorAlert msg={imgError}/>}
                                        <div className="fallback">
                                            {user.profile_image==="" ?
                                                <>
                                                    <input type="file"
                                                           className={`form-control ${errors.profile_image && 'is-invalid'}`}
                                                           {...register('profile_image',
                                                               {required: '*profile image is required'
                                                               }
                                                           )}
                                                           onChange={handleChange}
                                                    />
                                                    {errors.profile_image  && (<div className="invalid-feedback">{errors.profile_image.message}</div>)}
                                                </>
                                                :
                                                <input type="file"
                                                       className={`form-control ${errors.profile_image && 'is-invalid'}`}
                                                       {...register('profile_image')}
                                                       onChange={handleChange}
                                                    disabled={user.can_edit === 0}
                                                />
                                            }
                                        </div>
                                        <ImagePreviewContainer img={image.preview}/>
                                    </div>
                                    {/* end oif logo */}
                                 
                                 
                                    <hr/>

                                    <div className='mb-2'> 
                                        <label className={'form-label'}> ID Number | user.id_number </label>
                                        <input 
                                            type="text"
                                            readOnly={user.can_edit ===0}
                                            defaultValue={user.id_number}
                                            className={`form-control ${errors.id_number && 'is-invalid'}`} 
                                            placeholder='nin no/ international passport no/ driver license'
                                            {...register('id_number',{
                                                required: '*nin no is required',
                                            })}
                                            />
                                        <small className={'text-info'}>PS: Voter's Card/National ID/Driver's License /International Passport/NIMC Slip(No)</small>
                                            {errors.id_number  && (<div className="invalid-feedback">{errors.id_number.message}</div>)}
                                    </div>

                                    {/* document upload */}
                                    
                                    <div className='mt-3'> 
                                        <div className="mb-3 mt-3 mt-xl-0">
                                            <label htmlFor="projectname" className="mb-0 form-label"> ID Upload</label>
                                            {imgDocError &&   <ErrorAlert msg={imgDocError}/>}
                                            <div className="fallback">
                                                <input type="file"
                                                       className={`form-control ${errors.id_document && 'is-invalid'}`}
                                                       {...register('id_document',{
                                                           required:'kindly upload appropriate document '
                                                       })}
                                                       onChange={handleChangeDoc}
                                                       disabled={ Number(user.can_edit) ===0 &&  user.id_document !==null}
                                                />
                                                <small className={'font-12 helper-text text-info'}>upload JPG/JPEG/PNG file and no more than 5M</small>
                                                {errors.id_document  && (<div className="invalid-feedback">{errors.id_document.message}</div>)}
                                            </div>
                                            <ImagePreviewContainer img={imageDoc.preview}/>
                                        </div>
                                        {/* end oif logo */}
                                      
                                    </div>

                                    
                                    



                                    <div className='mt-3'>
                                        <button
                                            className='btn btn-primary'
                                            type='submit'
                                            disabled={(user.can_edit ===0&& user.id_document !==null && user.profile_image!==null)}>
                                            Update Details
                                        </button>
                                      
                                    </div>
                                </div> 
                            </div>
                        </form>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
   </DashboardWrapper>
}


export default MyAccountSettings