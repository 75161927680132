import {Link} from "react-router-dom";
import {url} from "../../../config/urls";
import React from "react";

interface Props {
    transaction_ref : string | undefined,
    loading_ : boolean,
    success_msg : string
    error_msg : string,
    verificationFunction : any,
    url : string
}
const  VerificationComponent:React.FC<Props> = ({transaction_ref,loading_,success_msg, error_msg,verificationFunction,url}) => {
    return (
        <>
            <div className={'container-fluid  pt-sm-5 pt-md-2 h-100'}>
                <div className={'row  pt-sm-5 pt-md-0'}>
                    {/*col-sm-12 col-md-6 col-lg-12 offset-md-3 offset-lg-1*/}
                    <div className={'col-sm-12 col-md-10 col-lg-8  offset-md-1 offset-lg-2'}>
                        <div className="card ">
                            <div className="card-header pt-2 pb-2 text-center bg-white">
                                <div className="clearfix">
                                    <div className="float-start mb-3">
                                        {/*<img src="/assets/images/logo-light.png" alt="" height="18"/>*/}
                                    </div>
                                    <div className="float-end">
                                        <h4 className="m-0 d-print-none">  </h4>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body p-5">
                                <div className="mb-1 text-center">
                                    {
                                        !transaction_ref
                                        &&
                                        <>
                                            <img src="/assets/img/not_found.gif" height={200} alt={'loading'}/>
                                            <h4 className={'text-center text-warning'}>Transaction Reference not found ! </h4>
                                        </>
                                    }

                                    {
                                        loading_
                                        &&
                                        <>
                                            <img src="/assets/img/loading.gif" height={200} alt={'loading'}/>
                                            <h4 className={'text-center text-danger'}>Please wait... </h4>
                                        </>
                                    }

                                    {
                                        success_msg
                                        &&
                                        <>
                                            <img src="/assets/img/success.gif" height={170} alt={'loading'}/>
                                            <h4 className={'text-center text-success'}> Payment Verified </h4>
                                            <Link to={url} className={'btn btn-outline-success mt-3'}>Take me Home</Link>
                                        </>
                                    }

                                    {
                                        error_msg &&
                                        <>
                                            <img src="/assets/img/failed.gif" height={200} alt={'loading'}/>
                                            <h6 className={'text-center text-danger'}>Payment Verification Failed </h6>
                                            <h6 onClick={() => verificationFunction()} className={'btn btn-danger'}>
                                                Try Again
                                            </h6>
                                        </>
                                    }


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )

}

export default  VerificationComponent