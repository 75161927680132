import {useDispatch, useSelector} from "react-redux"
import {Link, Navigate} from "react-router-dom"
import {AllThriftSavingsModel} from "../../../../config/model/authModel"
import {copyYourLink, postRequest, toNigerianCurrency} from "../../../../config/redux/actions"
import {AppState} from "../../../../config/redux/reducers"
import {AuthState} from "../../../../config/redux/reducers/tmsAuthReducer"
import {SavingsReducerState} from "../../../../config/redux/reducers/tmsSavingsReducer"
import {types} from "../../../../config/redux/types"
import {appTxt} from "../../../../config/strings"
import {url} from "../../../../config/urls"
import {remote_url} from "../../../../config/urls/remote"
import { WarningAlert} from "../../../../components/alert";
import React from "react";
import {Spinner} from "../../../../components";
import {BankDetailsWrapperComponent} from "../../_components";


type ViewSavingsProps = {
    show?: string
}

const ViewSavings: React.FC<ViewSavingsProps> = ({show}) => {
    const dispatch= useDispatch()
    const {token,account_key,user,role_id} = useSelector<AppState,AuthState>((state)=> state.tmsAuthReducer)
    const {singleSavings,savingsFilter,isLoading_t} = useSelector<AppState, SavingsReducerState>((state) => state.tmsSavingsReducer)
    //
    const payNow = () => {
        window.location.href = singleSavings.initialized_url
    }

    const requeryNow = () => {

        if(singleSavings.transaction_reference){
            const postData = {
                token,
                url: remote_url.verifySavings,
                actionType : types.GET_SAVINGS,
                postData : {
                    tranxRef:singleSavings.transaction_reference,
                    _requery : 1,
                    gateway_id : singleSavings.payment_gateway_used,
                    transaction_id : singleSavings.transaction_id,
                    fromDate: savingsFilter.fromDate,
                    toDate : savingsFilter.toDate,
                    status:savingsFilter.status
                },
                secretData : {
                    email:user.email,
                    mac_id :user.mac_id,
                    r_id: role_id
                },
                errorAction : types.SET_ERROR,
                accountKey: account_key,
                loading :{
                    isLoading : false,
                    isLoading_ : false,
                    isLoading_t : true
                }
            }
            dispatch(postRequest(postData))
        }
    }

    return <>

        <div className="">
            {
                singleSavings.payment_status === "0" && ['card'].includes(singleSavings.payment_channel) &&
                <WarningAlert
                    type={'warning'}
                    icon={''}
                    msg={`Transaction/Integration fee will be added when on the payment gateway page`}
                />
            }


            <table className="table mb-0">
                <thead className="table-light">
                <tr>
                    <th>Description</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Reference :</td>
                    <td>{singleSavings.transaction_reference}</td>
                </tr>
                <tr>
                    <td>Status:</td>
                    <td>
                        {
                            Number(singleSavings.payment_status) === 1 && Number(singleSavings.saving_transaction_status)===1 ?
                                <span className="badge bg-success">Paid</span>
                                :
                                singleSavings.payment_status ==='0' && singleSavings.saving_transaction_status === 0 ?
                                <span className="badge bg-info">waiting for approval</span>
                                    :
                                    <span className="badge bg-warning">Unpaid</span>
                        }
                    </td>
                </tr>

                {Number(singleSavings.payment_status) === 0 && singleSavings.payment_channel==='card' &&
                    <>
                        <tr aria-colspan={2}>
                            <td></td>
                            <td>
                                <Link to="#0" onClick={() => payNow()} className="btn btn-outline-primary"> Pay Now
                                    {' '} {appTxt.naira_} {Number(singleSavings.actual_amount).toLocaleString()}
                                </Link>
                            </td>
                        </tr>
                    </>

                }
                </tbody>
            </table>



            { singleSavings.payment_channel==='bank' && Number(singleSavings.payment_status)===0
                &&
                <>
                    <BankDetailsWrapperComponent
                        bank_name={singleSavings.bank_name}
                        account_number={singleSavings.account_number}
                        amount_paid={singleSavings.amount_paid}
                        account_name={singleSavings.account_name}
                        expires_at={singleSavings.expires_at}
                        dispatch={dispatch}
                        loading={isLoading_t}
                        requeryNow={requeryNow}
                        showBtn={true}
                    />
                </>
            }

        </div>


    </>
}


export default ViewSavings