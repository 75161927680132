import { Link } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "../../../config/redux/reducers"
import { MemebersState } from "../../../config/redux/reducers/tmsMemeberReducerState"
import { Spinner } from "../../../components"
import { AuthState } from "../../../config/redux/reducers/tmsAuthReducer"
import { remote_url } from "../../../config/urls/remote"
import { types } from "../../../config/redux/types"
import { postRequest } from "../../../config/redux/actions"
import {removeBackDropModal} from "../../../config/action";
import {CustomModalWrapper} from "../_components";

type FormProps = {
    show : boolean,
    setShowAddNewMemberForm: any

}
//
type FormValues ={
    name: string,
    email: string,
    password : string,
    phone_no: string,
    first_name:string,
    last_name:string,
    role:string
}
const AddNewMember:React.FC<FormProps>= ({show,setShowAddNewMemberForm}) => {
    const dispatch = useDispatch()
    const { register, handleSubmit, formState: { errors },reset,setValue } = useForm<FormValues>()
    const {token,account_key,user,role_id} = useSelector<AppState,AuthState>((state)=> state.tmsAuthReducer)
    const {loading_,error_msg,success_msg} = useSelector<AppState,MemebersState>((state)=> state.tmsMemeberReducerState)


      const addNewMember = (data:FormValues) => {
        data.name = data.first_name + " " + data.last_name
            const postData = {
                token,
                url: remote_url.newMemebers,
                actionType : types.CREATE_USER,
                postData : data,
                secretData : {
                    email:user.email,
                    mac_id :user.mac_id,
                    r_id: role_id
                },
                errorAction : types.SET_ERROR,
                accountKey: account_key,
                loading :{
                    isLoading :false,
                    isLoading_ : true,
                    isLoading_t : false
                }
            }
            dispatch(postRequest(postData))
      }

      const clearData = () => {
         reset()
          setShowAddNewMemberForm(false)
      }

      if(success_msg){
          setValue('name','')
          setValue('email','')
          setValue('password','')
          setValue('phone_no','')
          setValue('role','')
          setShowAddNewMemberForm('')
          removeBackDropModal()
      }





    return <>
            <CustomModalWrapper
                onClickFunc={setShowAddNewMemberForm}  modalTitle={'New Member'}>



                    <div className="offcanvas-body">
                        {
                             <form onSubmit={handleSubmit(addNewMember)}>

                                {/*  */}
                                <div>
                                    {/*  */}
                                    <div className="mb-3">
                                        <label htmlFor="simpleinput" className="form-label">Name</label>
                                        <input type="text"  
                                        className={`form-control ${errors.first_name && 'is-invalid'}`}
                                        {...register('first_name',{
                                                    required: '*First name is Required',
                                                    minLength: { value: 2, message: 'First name is too short' },
                                            })}
                                        />
                                          {errors.first_name  && (<div className="invalid-feedback">{errors.first_name.message}</div>)}
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="simpleinput" className="form-label">Last Name</label>
                                        <input type="text"
                                               className={`form-control ${errors.last_name && 'is-invalid'}`}
                                               {...register('last_name',{
                                                   required: '*First name is Required',
                                                   minLength: { value: 2, message: 'First name is too short' },
                                               })}
                                        />
                                        {errors.last_name  && (<div className="invalid-feedback">{errors.last_name.message}</div>)}
                                    </div>
                                    {/* Email */}
                                    <div className="mb-3">
                                        <label htmlFor="simpleinput" className="form-label">Email</label>
                                        <input type="email"  
                                         className={`form-control ${errors.email && 'is-invalid'}`} 
                                        {...register('email',{
                                                    required: 'email is Required',
                                                    minLength: { value: 2, message: 'Email is too short' },
                                            })}
                                        />
                                          {errors.email  && (<div className="invalid-feedback">{errors.email.message}</div>)}
                                    </div>
                                    {/* Phone Number */}
                                    <div className="mb-3">
                                        <label htmlFor="simpleinput" className="form-label">Phone Number</label>
                                        <input type="phone_no"  
                                        className={`form-control ${errors.phone_no && 'is-invalid'}`} 
                                        {...register('phone_no',{
                                                    required: 'phone no is Required',
                                                    minLength: { value: 2, message: 'Name is too short' },
                                            })}
                                        />
                                          {errors.phone_no  && (<div className="invalid-feedback">{errors.phone_no.message}</div>)}
                                    </div>
                                    {/*  */}
                                    <div className="mb-3">
                                        <label htmlFor="simpleinput" className="form-label">Memeber Role</label>
                                        <select 
                                        
                                         className={`form-select mb-3 ${errors.email && 'is-invalid'}`} 
                                        {...register('role',{
                                                    required: '*Member Role is Required',
                                            })}
                                        >
                                        <option value=''>Select Member Role </option>
                                            {/*<option value='super_amdin'>Super Admin</option>*/}
                                            <option value='member'> Member </option>
                                            <option value='admin'> Admin </option>
                                        </select>
                                          {errors.role  && (<div className="invalid-feedback">{errors.role.message}</div>)}
                                    </div>
                                    {
                                        loading_
                                        ? 
                                        <Spinner color='primary'/> 
                                        :
                                        <input type="submit" value="add new Members" className="btn btn-primary pull-right float-right" />
                                    }
                                </div>
                                {/*  */}
                             </form>
                        }
                    </div>
    
                    {/* {loading_ && <div className="modal-backdrop fade show"></div>} */}
                </CustomModalWrapper>
    </>
}


export default AddNewMember