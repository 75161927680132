import {url} from "../urls"
import {
  Accounts,
  AccountSignUp, AccountVerified,
  ConfirmEmailPage,
  ForgotPassword,
  LockScreen,
  Login,
  Logout, MemberSignUp, RejectInvite,
  ResetPassword,
  VerifyEmail
} from "../../pages/auth"
//Dashboard
import {Dashboard} from "../../pages/admin/dashboard";

import {Loans, LoansApplications, LoansDashboard, LoansSettings, LoanVerify} from "../../pages/admin/loans";

import { AuditLog } from "../../pages/admin/audit_log";
import { UserDashboard, UserDetail } from "../../pages/admin/users";
import { BranchHome } from "../../pages/admin/branch";
import {MyAccountSettings, MyProfile} from "../../pages/admin/settings/personal";
import { SupportsDashboard, SupportTicketDetail } from "../../pages/admin/supports";
import { BankListing } from "../../pages/admin/settings/pages";
import { ThriftAccountSettings, ThriftDocuments } from "../../pages/admin/settings/thrifts";
import PreLogout from "../../pages/admin/PreLogout";
import ChangePassword from "../../pages/admin/settings/personal/ChangePassword";
import { Pricing, SubscriptionVerify, ThriftSubscription } from "../../pages/admin/settings/subscription";
import {Expenses, ExpenseDashboard, ExpenseVerification} from "../../pages/admin/expenses";
import {EmailSms} from "../../pages/admin/communication/email_sms";
import {Notifications} from "../../pages/admin/communication/notification";

import NotFoundPage from "../../pages/errorPages/NotFoundPage";
import {ReferralHome} from "../../pages/admin/referral";
import {AdsHome, BlogHome} from "../../pages/admin/marketing";
import SuccessScreen from "../../pages/auth/SuccessScreen";
import {AllOrders, AllProducts} from "../../_versionTwo/pages/storeFront";
import {Settlement} from "../../_versionTwo/pages/settlement_reconciliation";


export const authRoute = [
  {
    url : url.home,
    component:<Login/>
  },
  {
    url:url.successScreen,
    component: <SuccessScreen/>
  },
  {
  url : url.rejectInvite,
    component: <RejectInvite/>
  },
  {
    url: url.verifyEmail,
    component: <VerifyEmail/>
  },
  {
    url : url.verifiedAccount,
    component: <AccountVerified/>
  },

  {
    url : url.accounts,
    component : <Accounts/>
  },
  {
    url:url.signIn,
    component : <Login/>
  },
  {
    url:url.accSignUp,
    component: <AccountSignUp/>
  },
    //successScreen

  {
    url:url.accSignUpMember,
    component: <MemberSignUp/>
  },

  {
    url: url.forgotPass,
    component : <ForgotPassword/>
  },
  {
    url: url.confirmAccPage,
    component : <ConfirmEmailPage/>
  },
  {
    url: url.logout,
    component : <Logout/>
  },
  {
    url: url.accLock,
    component : <LockScreen/>
  }, 
  {
    url : url.resetPass,
    component : <ResetPassword/>
  },
  {
    url : url.dashboard,
    component: <Dashboard/>
  },

  {
    url: url.loansDashboard,
    component : <LoansDashboard/>
  },
  {
    url: url.loans,
    component : <Loans/>
  },
   {
    url: url.loansSettings,
    component : <LoansSettings/>
  },
  {
    url: url.loanVerify,
    component: <LoanVerify/>
  },
  {
    url: url.loansApp,
    component : <LoansApplications/>
  },

  {
    url: url.auditLogDashboard,
    component : <AuditLog/>
  },
  {
    url : url.users,
    component : <UserDashboard/>
  },
  {
    url: url.userDetails,
    component : <UserDetail/>
  },
  {
    url : url.branch,
    component : <BranchHome/>
  },
  {
    url: url.thriftAccountSettings,
    component : <ThriftAccountSettings/>
  },
  {
    url: url.thriftAccountDocuments,
    component : <ThriftDocuments/>
  },
  {
    url: url.pricing,
    component : <Pricing/>
  },
  {
    url : url.thriftSubscriptions,
    component : <ThriftSubscription/>
  },
  {
    url : url.subscriptionCallback,
    component : <SubscriptionVerify/>
  },
  {
    url : url.thriftBankListing,
    component : <BankListing/>
  },
  {
    url: url.myAccount,
    component : <MyAccountSettings/>
  },
  {
    url : url.myProfile,
    component: <MyProfile/>
  },
  {
    url : url.changePassword,
    component: <ChangePassword/>
  },
  {
    url : url.supportsHome,
    component : <SupportsDashboard/>
  },
  {
    url: url.supportDetail,
    component : <SupportTicketDetail/>
  },
  {
    url : url.expenses,
    component : <Expenses/>
  },
  {
    url : url.expensesVerification,
    component : <ExpenseVerification/>
  },
  {
    url: url.expensesDashboard,
    component : <ExpenseDashboard/>
  },
  {
    url: url.preLogOut,
    component : <PreLogout/>
  },
  {
    url : url.emailSms,
    component: <EmailSms/>
  },
  {
   url :"*",
    component: <NotFoundPage/>
  },
  //
  {
    url : url.pushNotification,
    component : <Notifications/>
  },
  {
    url : url.referralHome,
    component:  <ReferralHome/>
  },
  {
    url : url.adsHome,
    component: <AdsHome/>
  },
  {
    url:url.blogHome,
    component: <BlogHome/>
  },
  //StoreFront
  {
    url :url.allOrders,
    component: <AllOrders/>
  },
  {
    url  :  url.allProducts,
    component: <AllProducts/>
  },
//Settlement
  {
    url   : url.settlements,
    component: <Settlement/>
  }

]




