// import React, {useState} from 'react';
import {Route, Routes} from "react-router-dom"
import {authRoute, savingsRoute} from './config/routes/routes';

import {onMessageListener} from './firebase';
import {useDispatch} from "react-redux";
import {setGeneralAction} from "./config/redux/actions";
import {types} from "./config/redux/types";

import NotificationsSystem, {atalhoTheme, useNotifications,setUpNotifications} from 'reapop'
import {useEffect} from "react";
const App : React.FC = () => {

    const dispatch = useDispatch()
    const {notifications, dismissNotification} = useNotifications()

    useEffect(()=> {
        setUpNotifications({
            defaultProps: {
                position: 'top-right',
                dismissible: true,
                showDismissButton : true
            },
            generateId: () => new Date().getTime().toString()
        })
    })

    onMessageListener().then(payload => {
        console.log(payload);
        let notificationPayload = {
            body: payload?.notification?.body,
            image : payload?.notification?.image,
            title: payload?.notification?.title,

            btn : payload?.data?.btn,
            iconText : payload?.data?.iconText,
            id: payload?.data?.id,
            modalContentBg: payload?.data?.modalContentBg,
            link: payload?.data?.link,
        }

        dispatch(setGeneralAction(types.SET_PUSH_NOTIFICATION, notificationPayload))
    }).catch(err => console.log('failed: ', err));





  return (
  <>
      <NotificationsSystem
          // 2. Pass the notifications you want Reapop to display.
          notifications={notifications}
          // 3. Pass the function used to dismiss a notification.
          dismissNotification={(id) => dismissNotification(id)}
          // 4. Pass a builtIn theme or a custom theme.
          theme={atalhoTheme}
      />

      <Routes>
        {
            authRoute.map((i,j=0) => {
                return <Route key={j} path={i.url} element={i.component} />
            })
        }
        

        {
            savingsRoute.map((i,j=0) => {
                return <Route key={j} path={i.url} element={i.component} />
            })

        }
      </Routes>
  </>
  );
}


export default App;
