import DashboardWrapper from "../../DashboardWrapper";
import BreadCrumb from "../../layouts/BreadCrumb";
import {ComingSoon} from "../../../../components";

const BlogHome = () => {
    return <DashboardWrapper>
        <BreadCrumb pageTitle={'Ads'} main={'Marketing'} current={'Ads'}/>
        <ComingSoon imgName={'tms-coming-soon'}/>
    </DashboardWrapper>
}

export default BlogHome