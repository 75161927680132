// import model
// actions props
//types
//define state for reducer
// intial state
// create the reducers
// export
import {AllThriftSavingsModel, SavingsProps, SavingsSummary, SavingsSummaryProps,} from "../../model/authModel";

import {ActionProps} from "../actions";

import {types} from "../types";


export interface SavingsReducerState {
    loading: boolean,
    loading_: boolean,
    isLoading_t: boolean,
    success_msg: string,
    error_msg: string,
    singleSavings: AllThriftSavingsModel,
    requery: boolean,
    allThriftSavings: AllThriftSavingsModel[],
    transaction_reference: string,
    transaction_id: string,
    newDataBot: boolean,
    summary: SavingsSummary[],
    savingsFilter: {
        fromDate: string,
        toDate: string,
        status: number
    }
}

//
const initialState = {
    loading: false,
    loading_: false,
    isLoading_t: false,
    success_msg: '',
    error_msg: '',
    singleSavings: SavingsProps,
    requery: false,
    allThriftSavings: [],
    transaction_reference: '',
    transaction_id: '',
    newDataBot: false,
    summary: [],
    savingsFilter: {
        fromDate: '',
        toDate: '',
        status: 1
    }
}


const tmsSavingsReducer = (state: SavingsReducerState = initialState, action: ActionProps) => {
    //
    switch (action.type) {

        case types.CLEAR_MSG:
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                success_msg: '',
                error_msg: '',
                requery: false
            }

        case types.SET_LOADING:
            return {
                ...state,
                loading: action.loading?.isLoading,
                loading_: action.loading?.isLoading_,
                isLoading_t: action.loading?.isLoading_t,
                success_msg: '',
                error_msg: '',
                requery: false
            }
        case types.SET_ERROR:
            return {
                ...state,
                loading: false,
                loading_: false,
                isLoading_t: false,
                requery: false,
                success_msg: '',
                error_msg: action.payload
            }
        case types.SET_TRANS_REF:
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                success_msg: '',
                error_msg: '',
                transaction_reference: action?.payload?.transaction_ref,
                transaction_id : action?.payload?.trans_id
            }
        //
        case types.CREATE_SAVINGS:
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                success_msg: action.payload?.message,
                error_msg: '',
                singleSavings: action.payload?.data.singleSavings,
                allThriftSavings: action.payload?.data.savings,
                summary: action.payload?.data.savingsSummary,
                newDataBot: true,
                transaction_reference: '',

            }
        case types.UPDATE_SAVINGS_DETAILS:
            return {
                ...state,
                loading: false,
                loading_: false,
                loading_t: false,
                success_msg: action.payload?.message,
                error_msg: '',
                // singleSavings: action.payload?.data.singleSavings,
                allThriftSavings: action.payload?.data.savings,
                summary: action.payload?.data.savingsSummary,
                newDataBot: true,
                transaction_reference: '',
            }
        case types.RESET_SAVINGS_BOT:
            return {
                ...state,
                newDataBot: false,
                requery: false
            }
        case types.GET_SAVINGS:
            return {
                ...state,
                loading: false,
                loading_: false,
                isLoading_t: false,
                success_msg: action.payload?.message, //action.payload?.message,
                error_msg: '',
                singleSavings: '',
                allThriftSavings: action.payload?.data.data,
                summary: action.payload?.data.savingsSummary,
                transaction_reference: '',
                newDataBot: true
            }
        case types.VERIFY_SAVINGS:
            return {
                ...state,
                loading: false,
                loading_: false,
                isLoading_t: false,
                error_msg: '',
                success_msg: action.payload?.message,
                singleSavings: action.payload?.data,
                transaction_reference: '',
                requery: false
            }
        case types.REQUERY_SAVINGS:
            return {
                ...state,
                loading: false,
                loading_: false,
                isLoading_t: false,
                error_msg: '',
                success_msg: '',
                singleSavings: action.payload?.data.singleSavings,
                allThriftSavings: action.payload?.data.savings,
                summary: action.payload?.data.savingsSummary,
                transaction_reference: '',
                requery: true
            }

        case types.SET_SINGLE_SAVINGS:
            return {
                ...state,
                loading: false,
                loading_: false,
                isLoading_t: false,
                error_msg: '',
                success_msg: '',
                singleSavings: action.payload,
                requery: false
                // transaction_reference:'',
            }

        case types.SET_SINGLE_EDIT_SAVINGS:
            return {
                ...state,
                loading: false,
                loading_: false,
                isLoading_t: false,
                error_msg: '',
                success_msg: '',
                singleSavings: action.payload,
                requery: false

            }


        case types.CLEAR_SAVING_DATA:
            return {
                ...state,
                loading: false,
                loading_: false,
                error_msg: '',
                success_msg: '',
                singleSavings: '',
                transaction_reference: ''
            }
        case types.SET_SAVINGS_FILTER:
            return {
                ...state,
                savingsFilter: {
                    fromDate: action.payload?.fromDate,
                    toDate: action.payload?.toDate,
                    status: action.payload?.status
                }
            }
        case types.LOG_OUT:
            return {
                ...state,
                loading: false,
                loading_: false,
                isLoading_t: false,
                error_msg: '',
                success_msg: '',
                singleSavings: '',
                transaction_reference: '',
                //
                allThriftSavings: [],
                newDataBot: false,
                summary: [],
                requery: false,
                savingsFilter: {
                    fromDate: '',
                    toDate: '',
                    status: 1
                }
            }

        default:
            return state;
    }

}

export default tmsSavingsReducer