import { useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"
import { Link, Navigate } from "react-router-dom"
import { AppState } from "../../config/redux/reducers"
import { AuthState } from "../../config/redux/reducers/tmsAuthReducer"
import { url } from "../../config/urls"
import AuthWrapper from "./AuthWrapper"
import { remote_url } from "../../config/urls/remote"
import { types } from "../../config/redux/types"
import { postRequest, setGeneralAction } from "../../config/redux/actions"
import { Spinner } from "../../components"
import { ErrorAlert } from "../../components/alert"
import {useState} from "react";
import { Header } from "./components"


// const FormValues
type FormValues ={
    password : string
}

const LockScreen = () => {
    const { register, handleSubmit, formState: { errors },reset } = useForm<FormValues>()
    const [showPassword,setShowPassword] =useState(false)
    const {user,account_key,token,loading,error_msg,success_msg,role_id} = useSelector<AppState,AuthState>((state) => state.tmsAuthReducer)
    const dispatch = useDispatch()
    
    const loginAgain = (data:FormValues) => {
        //
        const postData = {
            token,
            url:remote_url.loginAgain,
            actionType: types.LOGIN_AGAIN,
            postData : {
                email : user.email,
                password : data.password
            },
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            // reset,
            errorAction : types.SET_ERROR,
            loading :{
                isLoading :true,
                isLoading_ : false,
                isLoading_t: false
            },
            accountKey :account_key
        }
        //
        dispatch(postRequest(postData))
      
    }

    const handleLogout = () => {
        //
        const postData = {
            token,
            accountKey:account_key,
            url:remote_url.logout,
            actionType: types.LOG_OUT,
            postData : [],
            secretData : {
                email:user.email,
                mac_id :user.mac_id,
                r_id: role_id
            },
            errorAction : types.LOG_OUT,
            loading :{
                isLoading :true,
                isLoading_ : false,
                loading_t : false
            }
        }
        dispatch(postRequest(postData))
    }

     if(success_msg){
        if(window.location.pathname === '/account-locked'){
            window.location.replace(`${url.dashboard}`);
        }else{
            window.location.replace(`${window.location.pathname}`);
        }
    }
    //
    if(error_msg==='Unauthenticated.' || token===''){
        dispatch(setGeneralAction(types.LOG_OUT,[]))
        window.location.replace(`${url.signIn}`);
        return <Navigate to={url.signIn}    />
    }
 
    return <AuthWrapper>
   <div className="card"> 
   <Header/>

        <div className="card-body p-4">
            <div className="text-center w-75 m-auto">
                <img src={user?.profile_image !=='' ? user?.profile_image : user?.alternate_profile_image} height="80" width={'80'}
                     alt="user" className="rounded-circle shadow"/>
                <h4 className="text-dark-50 text-center mt-3 fw-bold">Hi ! {user?.name} </h4>
                <p className="text-muted mb-4">Enter your password to access the cooperative dashboard.</p>
            </div>

            <form onSubmit={handleSubmit(loginAgain)}>
                {error_msg &&  <ErrorAlert msg={error_msg}/> }
               
                <div className="mb-3">
                    <label htmlFor="password" className="form-label">Password</label>
                    <input type='hidden' value={user?.email} name='email'/>
                    <div className={"input-group input-group-merge"}>
                        <input
                            className={`form-control ${errors?.password && 'is-invalid'}`}
                            type={showPassword? "text" :"password"}
                            id="password"
                            placeholder="Enter your password"
                            {...register('password',{
                                required: 'password is required',
                                minLength: { value: 6, message: 'Your Password is too Short' },
                            })}
                        />
                        <div className="input-group-text"
                            onClick={()=>setShowPassword(!showPassword)}
                        >
                            <span className="password-eye"></span>
                        </div>
                    </div>
                     {errors?.password  && (<div className="invalid-feedback">{errors?.password?.message}</div>)}
                </div>

                <div className="mb-0 text-center">
                    {
                        loading 
                        ? 
                        <Spinner color="primary"/> 
                        :
                        <button className="btn btn-primary" type="submit">Log In</button>
                    }
                </div>
            </form>
        </div>
    </div>
                      

    <div className="row mt-3">
        <div className="col-12 text-center">
            <p className="text-muted">Not you?
                <Link to={'#0'} className="text-muted ms-1 ">
                    <b className={'text-muted'} onClick={()=>handleLogout()}><u>Logout</u></b></Link>
            </p>
        </div>
    </div>

    </AuthWrapper>
}

export default LockScreen