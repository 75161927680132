import {Link, Navigate, useParams, useSearchParams} from "react-router-dom"
import { url } from "../../config/urls"
import AuthWrapper from "./AuthWrapper"
import {remote_url} from "../../config/urls/remote";
import {types} from "../../config/redux/types";
import {postRequest} from "../../config/redux/actions";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {ErrorAlert, SuccessAlert} from "../../components/alert";
import {AppState} from "../../config/redux/reducers";
import {AuthState} from "../../config/redux/reducers/tmsAuthReducer";
import {Spinner} from "../../components";
import {useEffect, useRef, useState} from "react";
import {Footer, Header} from "./components";
import {pageKeys} from "./SuccessScreen";

type UrlParams = {
    t?: string,
    e: any,
}
type FormValues = {
    token : string,
    old_password : string,
    password : string,
    confirm_password : string
}

 const ResetPassword = () => {
     let {t, e} = useParams<UrlParams>()
     const { register, handleSubmit, setValue ,formState: { errors },watch,getValues } = useForm<FormValues>({mode:'onTouched'})
     const {loading,success_msg,error_msg} = useSelector<AppState,AuthState>((state)=> state.tmsAuthReducer)
     const [searchParams, setSearchParams]  = useSearchParams()
     const [showConfirmPassword, setConfirmPassword] = useState(false)
     const [showPassword, setPassword] = useState(false)

     const password = useRef({});
     password.current = watch("confirm_password", "");

     const dispatch = useDispatch()
     //
     const resetPassword = (data:FormValues) => {
         let token = searchParams.get("token")
         const postData = {
             token : '',
             url:remote_url.resetPass,
             actionType: types.PASSWORD_RESET,
             postData : {
                 email: e ,
                 ti : t,
                 password : data.password,
                 token : data.token
             },
             secretData : {
                 email:'',
                 mac_id :'',
                 r_id:0
             },
             // reset,
             errorAction : types.SET_ERROR,
             loading :{
                 isLoading :true,
                 isLoading_ : false,
                 isLoading_t: false
             },
             accountKey :''
         }
         //
         dispatch(postRequest(postData))
     }


     if(success_msg){
         return <Navigate to={`${url.successScreenPre}${pageKeys[5]}`}/>
     }

     // if(success_msg){
     //     setValue('password','')
     //     setValue('old_password','')
     //     setValue('confirm_password','')
     // }
  return (
    <AuthWrapper>
        {success_msg && <SuccessAlert msg={success_msg}/>}
        {error_msg && <ErrorAlert msg={error_msg}/>}
          <div className="card">
              <Header/>
                            
                <div className="card-body p-4">
                    <div className="text-center w-75 m-auto">
                        <h4 className="text-dark-50 text-center mt-0 fw-bold">Reset Password</h4>
                        <p className="text-muted mb-4"> {''}</p>
                    </div>

                    <form onSubmit={handleSubmit(resetPassword)}>

                        <div className="mb-3">
                            <label htmlFor="" className="form-label">OTP</label>
                            <div className={'input-group input-group-merge'}>
                                <input
                                    type={'text'}
                                    className={`form-control ${ (errors.token || error_msg) && 'is-invalid'}`}
                                    {...register('token',{
                                        required: 'otp token is required',
                                        minLength:{value: 6, message:'otp is too short'}
                                    })}
                                />
                                {errors.token  && (<div className="invalid-feedback">{errors.token.message}</div>)}
                            </div>
                        </div>


                        <div className="mb-3">
                            <label htmlFor="" className="form-label">New Password</label>
                            <div className={'input-group input-group-merge'}>
                                <input
                                    type={showPassword?'text':'password'}
                                    className={`form-control ${ (errors.password || error_msg) && 'is-invalid'}`}
                                    {...register('password',{
                                        required: 'password is required',
                                        minLength:{value: 6, message:'password too short'}
                                    })}
                                />
                                <div className="input-group-text" onClick={()=>setPassword(!showPassword)}>
                                    <span className="password-eye"></span>
                                </div>
                                {errors.password  && (<div className="invalid-feedback">{errors.password.message}</div>)}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="" className="form-label">Confirm New Password</label>
                            <div className={'input-group input-group-merge'}>
                                <input
                                    type={showConfirmPassword?'text':'password'}
                                    className={`form-control ${ (errors.confirm_password || error_msg) && 'is-invalid'}`}
                                    {...register('confirm_password',{
                                        required: 'confirm  password is required',
                                        minLength:{value: 6, message:'password too short'},
                                        validate: value => value === getValues('password') || "The passwords do not match"
                                    })}
                                />
                                <div className="input-group-text" onClick={()=>setConfirmPassword(!showConfirmPassword)}>
                                    <span className="password-eye"></span>
                                </div>
                                {errors.confirm_password  && (<div className="invalid-feedback">{errors.confirm_password.message}</div>)}
                            </div>
                        </div>

                        <div className="mb-0 text-center">
                            {loading
                                ?
                                <Spinner color={'primary'}/>
                                :
                                <button className="btn btn-primary" type="submit">Reset Password</button>
                            }

                        </div>
                    </form>
                </div>
            </div>

        <Footer url={url.home} linkText={'Log In'} callToActionText={'Back to'}/>

    </AuthWrapper>
  )
}



export default ResetPassword